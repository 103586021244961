<div *ngIf="selectedUserChats.length > 0 && isCurrentChat">
  <div style="display: block;">
    <app-chat-top-view [selectedUserName]="selectedUserName"></app-chat-top-view>
  </div>
  <div class="w-100" style="position: relative !important; top: 87px !important; overflow-y: hidden;background: #141822; ">
    <div #scrollMe (scroll)="chatScrolled($event)" [fluidHeight] [minHeight]="200" [bottomOffset]="100">
      <div style="margin-bottom: 85px !important;">
        <app-chat-bubble *ngFor="let chatData of selectedUserChats" [chatData]="chatData"></app-chat-bubble>
      </div>
      <div class="onInputClassSendMessage" [ngClass]="selectedUserChats.length >= 10 ? 'positionSticky' : 'positionFixed'">
        <div class="send_message_input">
          <div style="width: 88%;">
            <input #msgInput id="msgInput" [(ngModel)]="messageInput" (input)="toggleSendBtn()" type="text" (keyup.enter)="sendMessage()"
              (click)="messageType(0)" placeholder="Message"/>
          </div>
          <div style="display: flex;justify-content: space-between;width: 9.5%;margin-right: 10px;">
            <div class="custom_imgClass mr-3">      
                <i class="bx bxs-image" (click)="messageType(1)"><input (change)="onFileSelected($event)" style="cursor: pointer !important;" type="file" accept="image/*"></i>
            </div>
            <!-- <div class="mr-1" (click)="sendMessage()"> -->
            <div class="mr-1" (click)="sendMessage()" >
              <i class='bx bxl-telegram text-light' [ngClass]="disabledSendBtn ? 'disableSendBtn':''"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="scrollButton" (click)="scrollToBottom()" *ngIf="scrollBarStopBtn && selectedUserChats.length > 10">
      <div *ngIf="scrollBarStopBtn">
        <i class='bx bx-chevrons-down' ></i>
        <div class="isNotSeenBadge" *ngIf="isNotSeenBadge > 0">
          <div class="text-light">
            {{isNotSeenBadge}}
          </div>
        </div>
      </div> 
    </div>
  </div>
  <!-- <div class="mt-3 bg-white py-3" style="position: fixed; bottom: 0 !important; width: 73.3%;">
        <div class="custom-input-wrapper">
          <input id="msgInput" [(ngModel)]="messageInput" type="text" (keyup.enter)="sendMessage()" (click)="messageType(0)" class="form-control custom-input" style="height: 45px; box-shadow: #8897a6 -1px 0px 8px;" placeholder="Write Something" />
            <i class="bx bxs-image custom_image_btn" (click)="messageType(1)"><input type="file" accept="image/*"></i>
          <button class="custom-send-btn" type="submit" (click)="sendMessage()">
            <i class='bx bxl-telegram text-light'></i>
          </button>
        </div>
      </div> -->
</div>

<div *ngIf="!isCurrentChat">
  <div class="flex justify-content-center align-items-center" style="height: 100vh;background-color: #303133;">
    <img src="../../../../assets/images/No_chat_image.png" height="450px" width="500px">
  </div>
</div>

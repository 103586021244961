import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vishesh-chat',
  templateUrl: './vishesh-chat.component.html',
  styleUrls: ['./vishesh-chat.component.scss']
})
export class VisheshChatComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

<!-- User Details -->
<div class="pt-3 mt-1">
    <app-user-details [dispatcherDetails]="dispatcherDetails"></app-user-details>
    
    <div class="mt-3">
        <div class="onInput">
            <i class='bx bx-search-alt-2 h2 ml-4 text-light mb-0 mr-2'></i>
            <input type="text" id="userSearch" (input)="searchUsers($event)" class="h4 px-1 py-3 mb-0 text-light" style="width: 80%;" autocomplete="off"
                placeholder="Search Users">
        </div>
    </div>
    
    <div class="recentChatsView"  *ngIf="userAndChatDataArray.length > 0">
        <div class="custom_chat" *ngFor="let chat of isSearchedValue ? searchResults : userAndChatDataArray">
            <label class="radio-button">
              <input type="radio" name="recentChat" [checked]="chat.user.userId == isChatBubble" (click)="clickedRecentChat(chat)">
              <div class="isActiveClass">
                  <div class="flex align-items-center ml-3 w-75">
                      <div>
                        <div class="highStringName"> {{Global.getShortName(chat.user.userName)}}</div>
                      </div>
                    <div class="ml-3">
                      <div class="h4 text-light">{{chat.user.userName}}</div>
                      <div class="onShowLastMessage" *ngIf="chat.chats[chat.chats.length - 1].messageType == 3 && chat.chats[chat.chats.length - 1].bookingJson != null || chat.chats[chat.chats.length - 1].messageType == 0 || chat.chats[chat.chats.length - 1].messageType == 1 || chat.chats[chat.chats.length - 1].messageType == 2">{{chat.chats[chat.chats.length - 1].messageType != 1 ? chat.chats[chat.chats.length - 1].message : 'Photo'}}</div>
                      <div class="onShowLastMessage" *ngIf="chat.chats[chat.chats.length - 1].messageType == 3 && chat.chats[chat.chats.length - 1].bookingJson == undefined">Oops Something went wrong! <i class='bx bx-x-circle' style='color:#fd0303'></i></div>
                    </div>
                  </div>
                  <div class="mr-3 flex align-items-center">
                      <div>
                          <div class="h5" style="width: 55px;color: #a3a6b6;">{{showLastMessageTime(chat.chats[chat.chats.length - 1].createdAt)}}</div>
                          <div class="flex align-items-end justify-content-center">
                            <div>
                              <div *ngIf="chat.user.isBadge > 0" class="badge bg-primary rounded-pill">{{chat.user.isBadge}}</div>
                              <div *ngIf="chat.user.isGreenTick == 1 && chat.user.isBadge == undefined"><i class='bx bx-check h3 text-secondary' ></i></div>
                              <div *ngIf="chat.user.isGreenTick == 0 && chat.user.isBadge == undefined"><i class='bx bx-check-double h3' style='color:#1ed891'></i></div>
                            </div>
                          </div>
                      </div>
                  </div>
              </div>
            </label>
          </div>
    </div>

    <div class="mt-4 currentlyNoAnyChats" *ngIf="userAndChatDataArray.length == 0">
      <div>
        <div class="text-light" style="text-shadow: 1px 1px 2px black, 0 0 1em #407bff, 0 0 0.2em #b972e4;">
          No chats .....
        </div>
      </div>
    </div>
</div>
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { ModalComponent } from 'ng-modal-lib';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CallmethodService } from '../services/callmethod.service';
import { DataShareService } from '../services/data-share.service';
import { GlobalService } from '../services/global.service';
import { PopupService } from '../services/popup.service';

@Component({
  selector: 'app-search-job',
  templateUrl: './search-job.component.html',
  styleUrls: ['./search-job.component.scss']
})

export class SearchJobComponent implements OnInit {

  environment: any = environment;
  selected: any;
  beforeOrAfterDateSelect:any;
  singleDatePicker: boolean = true;
  rangeDatePicker: boolean = true;
  alwaysShowCalendars: boolean = true;
  dateRangePicker: any =
    {
      // 'This Month': [moment().startOf('month'), moment().endOf('month')],
      // 'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    }
  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }
  
  private subscription: Subscription = new Subscription;
	private unsubscribe$ = new Subject<void>();

  moment: any = moment;

  constructor(
    public CallMethod: CallmethodService,
    public shareData: DataShareService,
    public Global: GlobalService,
    public Popup: PopupService,

  ) { 
   
  }

  ngOnInit(): void {
    this.alwaysShowCalendars = true;
    this.singleDatePicker = true;
    this.rangeDatePicker = true;
    this.subscription = this.shareData.getData().subscribe(data => {
      if (data) {
        if (data.function == 'searchJob') {
          this.searchJobModalShow();
        }
        else if (data.function == 'changeThemeColor') {
          this.searchJobDetailsModal.modalHeader.nativeElement.style.backgroundColor = this.Global.first_color;
          this.searchJobDetailsModal.modalFooter.nativeElement.style.backgroundColor = this.Global.third_color;
          this.searchJobDetailsModal.modalRoot.nativeElement.style.border = '1px solid '+ this.Global.first_color; 
        }
      }
    });
  }

  ngOnDestroy(): void {
		this.subscription.unsubscribe();
    this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

  @ViewChild("searchJobDetails") public searchJobDetailsModal!: ModalComponent;
  @ViewChild("passenger") passenger!: ElementRef;

  showsearchJobModel: boolean = false;
  setStyle: boolean = false;
  userSearchShimmer: boolean = false;
  searchValue: string = '';

  searchJobModalShow(searchValue: string = '') {
    this.searchJobDetailsModal.modalRoot.nativeElement.style.zIndex = this.searchJobDetailsModal.getMaxModalIndex() + 2;
    if (this.showsearchJobModel == false) {
      setTimeout(() => {
        this.searchJobDetailsModal.modalRoot.nativeElement.style.top = environment.windowPopupStyle.searchJob.top;
      }, 0);

      if (this.searchJobDetailsModal.modalBody.nativeElement.style.height.trim() == '') {
        this.searchJobDetailsModal.modalBody.nativeElement.style.height = environment.windowPopupStyle.searchJob.height;
        let width = parseInt(environment.windowPopupStyle.searchJob.width.replace('px', ''));
        if(window.innerWidth < parseInt(environment.windowPopupStyle.searchJob.width.replace('px', ''))){
          width = window.innerWidth - 2;
        }
        this.searchJobDetailsModal.modalRoot.nativeElement.style.width = width.toString() + 'px'; 
      }

      this.searchJobDetailsModal.modalHeader.nativeElement.style.backgroundColor = this.Global.first_color;
      this.searchJobDetailsModal.modalFooter.nativeElement.style.backgroundColor = this.Global.third_color;
      this.searchJobDetailsModal.modalRoot.nativeElement.style.border = '1px solid '+ this.Global.first_color; 

      this.searchJobDetailsModal.show();
      this.showsearchJobModel = true;
    }

    setTimeout(() => { // this will make the execution after the above boolean has changed
      this.passenger.nativeElement.focus();
    }, 500);

    if (searchValue != '') {
      this.searchValue = searchValue;
      this.passenger.nativeElement.value = searchValue;
      this.getsearchJob(0);
    }
  }

  searchJobList: any = [];
  jobCurrentpage: number = 0;
  searchJobCount: number = 0;
  searchJobLimit: number = 10;
  searchJobShimmer: boolean = false;
  getsearchJob(page: any = 0) {
    this.jobCurrentpage = page;
    let requestObj = this.submitSearchData();
    if (requestObj != false) {
      this.searchJobShimmer = true;
      this.CallMethod.getSearchJob(requestObj).pipe(takeUntil(this.unsubscribe$)).subscribe((result: any) => {
        this.searchJobList = [];
        if (result.status) {
          this.searchJobList = result.data;
          this.searchJobCount = result.totalRecords;
        }

        this.searchJobShimmer = false;
        this.isSearchClick = true;
      });
    } else {
      this.Popup.toasterMessage({
        'status': 'warning',
        'title': 'please...',
        'message': 'select date',
      });
    }
    // if((this.selectDateRangeType == 0 && this.beforeDate != "") || (this.selectDateRangeType == 1 && this.afterDate != "") ||(this.selectDateRangeType == 1 && this.startDate != "" && this.endDate != "") ){
    // }
  }

  submitSearchData() {

    let searchJobOffset = (this.jobCurrentpage == 0) ? 0 : ((this.jobCurrentpage * this.searchJobLimit) - this.searchJobLimit);

    let requestObj: any = {
      offset: searchJobOffset,
      limit: this.searchJobLimit,
    }
    this.filterArray = {};

    requestObj!.passengerName = this.passengerName;
    requestObj!.passengerPhone = this.passengerPhone;
    requestObj!.pickupAddress = this.pickupAddress;
    requestObj!.dropAddress = this.dropAddress;
    requestObj!.vehicle = this.vehicle;
    requestObj!.accountId = this.accountId;
    requestObj!.status = this.status;
    requestObj!.dateRangeType = this.selectDateRangeType;

    if(this.status != 'all'){
      this.filterArray.status = this.status;
    }
    if (this.bookingId != "") {
      requestObj.bookingId = this.bookingId;
      this.filterArray.bookingId = this.bookingId;
    } else {
      requestObj.bookingId = "";
    }
    if (this.passengerPhone != "") {
      this.filterArray.phone = this.passengerPhone;
    }
    if (this.passengerName != "") {
      this.filterArray.name = this.passengerName;
    }
    if (this.accountId != "") {
      this.filterArray.accountId = this.accountId;
    }

    if (this.vehicle != "") {
      this.filterArray.vehicle = this.vehicle;
    }
    if (this.pickupAddress != "" || this.dropAddress != "") {
      if (this.pickupAddress == "" || this.dropAddress == "") {
        this.filterArray.location = this.pickupAddress == "" ? this.dropAddress : this.pickupAddress;
      } else {
        this.filterArray.location = this.pickupAddress + ' -to- ' + this.dropAddress;
      }
    }
    if (this.selectDateRangeType == 0) {
      if (this.beforeDate != "") {
        requestObj!.beforeDate = this.beforeDate;
        this.filterArray.date = this.beforeDate;
      }
      else {
        return false;
      }
    } else if (this.selectDateRangeType == 1) {
      if (this.afterDate != "") {
        requestObj!.afterDate = this.afterDate;
        this.filterArray.date = this.afterDate;
      } else {
        return false;
      }

    } else if (this.selectDateRangeType == 2) {
      if (this.startDate != "" && this.endDate != "") {
        requestObj!.startDate = this.startDate;
        requestObj!.endDate = this.endDate;
        this.filterArray.date = this.startDate + ' -to- ' + this.endDate;
      } else {
        return false;
      }
    }
    return requestObj;
  }

  filterArray: any = {}
  closeSearchJobModel() {
    this.showsearchJobModel = false;
    this.setStyle = false;
  }

  removeFilter(key: any) {
    let isOldStatusSetToAll = false;
    if (key =='bookingId') {
      this.bookingId = "";
     ( <HTMLInputElement>document.getElementById('searchBooking')).value = "";
    }
    else if (key == 'name') {
      this.passengerName = "";
    }
    else if (key == 'phone') {
      this.passengerPhone = "";
    }
    else if (key == 'accountId') {
      this.accountId = "";
    }
    else if (key == 'vehicle') {
      this.vehicle = "";
    }
    else if (key == 'date') {
      this.beforeDate = "";
      this.afterDate = "";
      this.startDate = "";
      this.endDate = "";
      this.selected = "";
      this.beforeOrAfterDateSelect = "";
      this.selectDateRangeType = 3;
    }
    else if (key == 'location') {
      this.pickupAddress = ""
      this.dropAddress = ""
    }
    else if (key == 'status') {
      if(this.status != 'all'){
        isOldStatusSetToAll = true;
      }
      this.status = 'all';
    }

    delete this.filterArray[key];
    if(key != 'status' || isOldStatusSetToAll ){
      this.getsearchJob(0);
    }
  }


  passengerName: string = "";
  passengerPhone: string = "";
  pickupAddress: string = "";
  dropAddress: string = "";
  vehicle: string = "";
  accountId: string = "";
  status: string = "all";
  startDate: string = "";
  endDate: string = "";

  beforeDate: string = ""
  afterDate: string = ""
  selectDateRangeType = 3;
  isSearchClick: boolean = false;
  maxRange = moment().format('YYYY-MM-DD')

  bookingStatusDropDown(event: any) {
    this.status = event.target.value;
  }
  DateRangeTypeDropDown(event: any) {
    this.selectDateRangeType = event.target.value;
    delete this.filterArray['date']
    this.endDate = "";
    this.beforeDate = "";
    this.afterDate = "";
    this.startDate = "";
    this.selected = "";
    this.beforeOrAfterDateSelect = "";
    
    if (this.filterArray.date && this.selectDateRangeType == 3) {
      delete this.filterArray['date']
    }
  }

  beforeAfterDateSelect(event: any, Type: number) {
    this.startDate = "";
    this.endDate = "";
    this.afterDate = "";
    this.beforeDate = "";
    this.selected = "";

    if(event.startDate != null && this.endDate != null){
      if (Type == 0) {
        this.beforeDate = moment(event.endDate).format('YYYY-MM-DD 23:59:59');
        this.filterArray.date = this.beforeDate;
  
      } else if (Type == 1) {
        this.afterDate = moment(event.endDate).format('YYYY-MM-DD 23:59:59');
        this.filterArray.date = this.afterDate;
      }
    }
  }

  dateRangeSelect(event: any) {
    this.afterDate = "";
    this.beforeDate = "";
    this.startDate = "";
    this.endDate = "";
    this.beforeOrAfterDateSelect = "";
    
    if(event.startDate != null && this.endDate != null){
      this.startDate = moment(event.startDate).format('YYYY-MM-DD HH:mm:ss');
      this.endDate = moment(event.endDate).format('YYYY-MM-DD 23:59:59');
    }
    if (this.startDate != "" && moment(this.startDate, 'YYYY-MM-DD HH:mm:ss').isValid() && this.endDate != "" && moment(this.endDate, 'YYYY-MM-DD 23:59:59').isValid()) {
      this.filterArray.date = this.startDate + ' -to- ' + this.endDate;
    }
  }

  bookingId: string = "";
  getBookingId(event: any) {
    this.bookingId = event.target.value;
  }

  copyBooking(bookingData: any) {
    this.shareData.shareData({ 'function': 'copyBookingDataByCloseJob', 'data': bookingData });
  }

  setBookingDetails(bookingData: any) {
    this.shareData.shareData({ 'function': 'openBookingDetailsByCloseJob', 'data': bookingData });
    if (this.setStyle == false) {
      setTimeout(() => {
        let left:any = parseInt("" + window.innerWidth) - parseInt(this.searchJobDetailsModal.modalRoot.nativeElement.style.width.replace('px', '')) - parseInt(environment.windowPopupStyle.bookingDetails.width.replace('px', '')) - 4;
        if(left < 0){
          let width = parseInt(this.searchJobDetailsModal.modalRoot.nativeElement.style.width.replace('px', '')) + left;
          width -= 2; 
          this.searchJobDetailsModal.modalRoot.nativeElement.style.width = width.toString() + 'px';
          left = 2;
        }
        this.searchJobDetailsModal.modalRoot.nativeElement.style.left = left.toString() + 'px';
        this.searchJobDetailsModal.modalBody.nativeElement.style.height = environment.windowPopupStyle.bookingDetails.height;

      }, 0);
      this.setStyle = true;
    }
  }

  isShowFilterBar: boolean = true;
  showHideFilter() {
    if (this.isShowFilterBar == true) {
      this.isShowFilterBar = false;
    }
    else {
      this.isShowFilterBar = true;
    }
  }
}

import { AgmMap, MapsAPILoader } from '@agm/core';
import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Router } from '@angular/router';
import { SplitterType } from 'igniteui-angular';
import * as moment from 'moment';
import * as momentTimezone from 'moment-timezone';
import { ModalComponent } from 'ng-modal-lib';
import { ContextMenuComponent } from 'ngx-contextmenu';
import { IMqttMessage } from 'ngx-mqtt';
import { ConfirmEventType, ConfirmationService, MessageService } from 'primeng/api';
import { Paginator } from 'primeng/paginator';
import { Subject, Subscription, of } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, map, mergeMap, takeUntil } from 'rxjs/operators';
import { environment } from "src/environments/environment";
import { CallmethodService } from '../services/callmethod.service';
import { DataShareService } from '../services/data-share.service';
import { EventMqttService } from '../services/event-mqtt.service';
import { GlobalService } from '../services/global.service';
import { PopupService } from '../services/popup.service';

declare var $: any;
declare const google: any;


@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
	providers: [ConfirmationService, MessageService]
})

export class HomeComponent implements OnInit, OnDestroy, AfterViewInit {


	totalDriverBreakCount: number = 0;
	moment: any = moment;
	momentTimezone: any = momentTimezone;
	environment: any = environment;
	onlyCompanyDateFormat: string = 'YYYY/MM/DD';

	@ViewChild('paginator') paginator!: Paginator;
	@ViewChild('AgmMap', { static: true }) AgmMap!: AgmMap;
	map!: google.maps.Map;
	zoom: number = 15;

	@ViewChild('rightClickJobAssginMenu') public rightClickJobAssginMenu!: ContextMenuComponent;
	@ViewChild('rightClickVehicleFilterMenu') public rightClickVehicleFilterMenu!: ContextMenuComponent;
	@ViewChild('rightClickActiveBookingFilterMenu') public rightClickActiveBookingFilterMenu!: ContextMenuComponent;
	@ViewChild('rightClickUnAssignBookingFilterMenu') public rightClickUnAssignBookingFilterMenu!: ContextMenuComponent;
	@ViewChild('rightClickZoneListFilterMenu') public rightClickZoneListFilterMenu!: ContextMenuComponent;

	// splitter 
	public typeHorizontal = SplitterType.Horizontal;
	public typeVertical = SplitterType.Vertical;

	locationsForm: FormGroup = new FormGroup({
		createJobAddressList: new FormControl('', [Validators.required]),
	});
	passengersForm: FormGroup = new FormGroup({});  /* create Job passengersForm */
	editLocationsForm: FormGroup = new FormGroup({});  /* Edit Job locationsForm */
	editPassengersForm: FormGroup = new FormGroup({});  /* Edit Job passengersForm */

	intervalId: any = null;
	unassignBookingRemainder: any = null;
	private subscription: Subscription = new Subscription;
	private subscriptionSearchAutoComplete: Subscription = new Subscription;
	private subscriptionDispatcher: Subscription = new Subscription;
	private subscriptionDriverTrack: Subscription = new Subscription;
	private subscriptionShareData: Subscription = new Subscription;
	private unsubscribe$ = new Subject<void>();

	// ! Start Filters===========================
	unAssignFilterDataForm: FormGroup = new FormGroup({});
	unAssignfilterStatus: any = [];
	activeFilterDataForm: FormGroup = new FormGroup({});
	activeFilterStatus: any = [];
	filterStatus: any = [];
	filterBookingType: any = [];
	filterPaymentType: any = [];
	filterCarType: any = [];
	filterBookingFlag: any = [];
	filterSearchBy: any = [];
	filterByDate: any = [];
	filterByTime: any = [];
	filterBySurge: any = [];
	filterByAscDescType: any = [];
	selectedItems: any = {};

	disabled = false;
	ShowFilter = false;
	closeDropDownOnSelection = false;
	limitSelection = false;
	secondDropdownSettings: any = {};
	singleDropdownSettings: any = {};
	dropdownSettings: any = {};

	vehicleSelectedItems: any = {};
	vehiclefilterDataForm: FormGroup = new FormGroup({});

	driverBookingFilterStatus: any = [];
	driverBookingFilterSearchBy: any = [];
	driverFilterCarType: any = [];

	zoneListfilterDataForm: FormGroup = new FormGroup({});
	setDriverToZone: any = [];
	zoneListSelectedItems: any = {}

	isshortChatBubble: boolean = false
	vehicleFilterApplied: boolean = false;
	reqObjForFilter: any
	searchString: any
	isDriverSearchOn: boolean = false
	constructor(
		public Global: GlobalService,
		public Popup: PopupService,
		public CallMethod: CallmethodService,
		public mqtt: EventMqttService,
		public Router: Router,
		private formBuilder: FormBuilder,
		public shareData: DataShareService,
		private mapsAPILoader: MapsAPILoader,
		public confirmationService: ConfirmationService,
		public messageService: MessageService
	) {
		this.alwaysShowCalendars = true;

		this.subscriptionShareData = this.shareData.getData().subscribe(data => {
			if (data) {
				console.log("data Share  ::::::::::::::::::::::::", data);
				if (data.function == 'setUserDetails') {
					this.setUserDetails(data.data);
				}
				else if (data.function == 'reloadData') {
					localStorage.removeItem('allDriverscachedDriversData')
					window.location.reload();
					// this.subscriptionShareData.unsubscribe();
					// this.ngOnInit();
				}
				else if (data.function == 'copyBookingData' || data.function == 'copyBookingDataByCloseJob') {
					this.copyBookingData('customerBookingCopy', data.data);
				}
				else if (data.function == 'renderData') {
					this.checkCreatebookingValidation = (this.createbookingArea.nativeElement.contains(this.Global.renderData.target)) ? true : false;
				}
				else if (data.function == 'reloadUnassignBookingList') {
					this.setUnassignData(this.unassignBookings);
				}
				else if (data.function == 'bookingDetailPopupCopyBooking') {
					this.copyBookingData('bookingDetailPopupCopyBooking', data.data);
				}
				else if (data.function == 'bookingDetailPopupEditBooking') {
					console.log("data Share selectEditBooking ::::::::::::::::::::::::", data.data);

					this.selectEditBooking(data.data);
				}
				else if (data.function == 'setOnlineDriverList') {
					this.setDriverList({
						'isReloadVehicleList': true,
						'isReloadZoneWiseDriver': true,
					});
				}
				else if (data.function == 'setTarrifValue') {
					this.tariffStatus = this.Global.tarrif == 'custom' ? false : true;
				}
				else if (data.function == 'mapReloadForDriverMarkerLabel') {
					let center: any = JSON.parse(JSON.stringify(this.map.getCenter()));
					this.reloadMapData({
						'location': [center.lat, center.lng]
					});
				}
				else if (data.function == 'setDriverLimit') {
					this.ngOnInit();
				}
				else if (data.function == 'changeThemeColor') {
					this.driverDetailsModal.modalHeader.nativeElement.style.backgroundColor = this.Global.first_color;
					this.driverDetailsModal.modalFooter.nativeElement.style.backgroundColor = this.Global.third_color;
					this.driverDetailsModal.modalRoot.nativeElement.style.border = '1px solid ' + this.Global.first_color;
				}
				else if (data.function == 'startUnassignBookingRemainder') {
					clearInterval(this.unassignBookingRemainder);
					this.unassignBookingRemainder = setInterval(() => {
						this.checkUnassignBookingPassedTime();
					}, 30000);
				}
				else if (data.function == 'stopUnassignBookingRemainder') {
					clearInterval(this.unassignBookingRemainder);
				}
				else if (data.function == 'getChatDriverList') {
					this.shareData.shareData({ 'function': 'passChatDriverList', 'data': this.allDriverList });
				}


				// if (data.function == "checkUserBalance" && data.data != "") {
				// 	console.log("checkUserBalance>?>>>>>>>>>> data share", data.data);
				// 	this.checkUserBalance(data.data)
				// }

			}
		});

		console.log('******  this.Global.timeZone ******', this.Global.timeZone);
		this.targetTime = moment().tz(this.Global.timeZone);
		console.log('****** this.targetTime ******', this.targetTime);
		this.calculateTimeRemaining(this.targetTime, this.Global.timeZone);

		this.locationsForm = this.formBuilder.group({
			pickupPoint: "",
			dropPoint: "",
			stops: this.formBuilder.array([]),
		});

		this.editLocationsForm = this.formBuilder.group({
			pickupPoint: "",
			dropPoint: "",
			stops: this.formBuilder.array([]),
		});

		this.passengersForm = this.formBuilder.group({
			passengersDetails: this.formBuilder.array([this.newPassenger()]),
		});

		this.editPassengersForm = this.formBuilder.group({
			passengersDetails: this.formBuilder.array([this.newPassenger()]),
		});

		this.Global.createBookingShowsPopup = this.Global.getLocalStorage({ key: 'createBookingShowsPopup' });
	}
	unApproveListParams: any = {
		limit: 10,
		offset: 0,
	}
	destroyInterval: any = 0;
	getCompanyTimeZone: any = "";
	searchAssignDriverSubject: Subject<string> = new Subject<string>();
	ngOnInit(): void {
		this.ngOnDestroy("ngOnInit");
		this.Global.disable = true;

		this.searchAssignDriverSubject.pipe(
			debounceTime(300) // Adjust the time as needed
		).subscribe(value => {
			this.driverListSearch(value);
		});

		this.mapReady().then((res) => {
			this.Global.dashboard().then((res: any) => {
				if (res.status) {

					this.getCompanyTimeZone = this.Global.timeZone;
					console.log('****** this.getCompanyTimeZone ******', this.getCompanyTimeZone);

					// ! ====== getAllUnApproveList ========
					this.getAllUnApproveList(this.unApproveListParams);
					this.zoneWiseDriverShimmer = false
					if (this.Global.isSetDriverLimit == true) {
						setTimeout(() => {
							this.setAreaIcon({
								location: [this.Global.companyDetails.companyLat, this.Global.companyDetails.companyLng],
								'isBound': true
							})
						}, 0);
					}
					else {
						console.log('*****>>>  this.getDrivers API <<<******');

						let vehicleListFilterRequest = this.Global.getLocalStorage({ key: 'vehicleListFilterRequest' });

						if (vehicleListFilterRequest != undefined) {
							this.vehicleFilterCall();
						}

						this.getDrivers({
							'loadShimmer': true,
							onlineDriver: true
						}).then((res: any) => {

							if (res) {
								console.log('response online drivers', res.drivers)



								// this.setDriverMarkerLabel(res.drivers);
								// let vehicleListFilterRequest = this.Global.getLocalStorage({ key: 'vehicleListFilterRequest' });

								// if (vehicleListFilterRequest != undefined) {
								// 	this.vehicleFilterCall();
								// }
								let selectedZoneFilterOption = this.Global.getLocalStorage({ key: 'zoneListFilterFormValue' });
								if (selectedZoneFilterOption != undefined && selectedZoneFilterOption.setDriverToZone.length > 0 && selectedZoneFilterOption.setDriverToZone[0].itemId == 1) {
									this.setAllDriversToDefaultZone(this.onlineDriverList)
								}
								this.newRideSetDriverId = (this.Global.appMode == 1) ? 'automatic' : 'manual';
								let format = this.Global.dateFormat.split(" ");
								this.onlyCompanyDateFormat = format[0];
								this.isReloadMapData = false;

								this.reloadMapData({
									'location': [this.Global.companyDetails.companyLat, this.Global.companyDetails.companyLng],
									"variant": this.findDriverVariant,
									"count": this.findDriverCount,
									'loadMarker': true
								});
							}
						});




					}

					this.subscriptionDispatcher = this.mqtt.topic("dispatcher_" + this.Global.companyDetails.companyId).subscribe((data: IMqttMessage) => {
						try {
							let item = JSON.parse(data.payload.toString());
							this.eventHandler(item);
						} catch (error) {
						}
					});

					this.subscriptionDispatcher = this.mqtt.topic("userChat_" + this.Global.companyDetails.companyId).subscribe((data: IMqttMessage) => {
						console.log(':::::::::::::::::::::::::: this.Global.companyDetails.companyId ::::::::::::::::::::::::::', this.Global.companyDetails.companyId);
						try {
							let item = JSON.parse(data.payload.toString());
							this.eventHandler(item);
						} catch (error) {
							console.log(':::::::::::::::::::::::::: error ::::::::::::::::::::::::::', error);
						}
					});

					this.subscriptionDriverTrack = this.mqtt.topic("track_driver_" + this.Global.companyDetails.companyId).subscribe((data: IMqttMessage) => {
						try {
							let item = JSON.parse(data.payload.toString());

							this.trackDrivers({
								'driverId': item.driverId,
								'locationData': item.locationData,
							});
						} catch (error) {
						}
					});

					this.minDate = moment().tz(this.Global.timeZone);
					let format = this.Global.dateFormat.split(' ')[0];
					this.dateFormat = format;

					let cars = this.Global.cars;

					let filterCarArr = [];

					for (let i = 0; i < cars.length; i++) {
						filterCarArr.push({ 'itemId': cars[i].carTypeId, 'itemText': cars[i].carType })
					}

					this.filterCarType = filterCarArr;
					this.driverFilterCarType = filterCarArr;

					this.setMinDate = moment().tz(this.Global.timeZone);
					this.getUnassignBooking(0);

					this.setFillterData();

					this.geocoder = new google.maps.Geocoder();
					let line = (this.Global.isMapDark) ? this.Global.mapPolylineStyles.line.dark : this.Global.mapPolylineStyles.line.light;
					this.polyLine = new google.maps.Polyline({
						strokeOpacity: 0,
						icons: [
							{
								icon: {
									path: "M 0,-1 0,1",
									strokeOpacity: 1,
									strokeColor: line,
									scale: 4,
								},
								offset: "0",
								repeat: "15px",
							},
						],
					});

					this.subscriptionSearchAutoComplete = this.searchAutoComplete.pipe(
						map((event: any) => event.target.value),
						debounceTime(300),
						distinctUntilChanged(),
						mergeMap((search: any) => of(search).pipe(delay(0))),
					).subscribe((data: any) => {
						this.getAutoSearch(data);
					});

					this.subscription = this.searchCorporateAccount.pipe(
						map((event: any) => event.target.value),
						debounceTime(300),
						distinctUntilChanged(),
						mergeMap((search: any) => of(search).pipe(delay(0))),
					).subscribe((data: any) => {
						this.newRideAccountIdCall(data);
					});

					this.intervalId = setInterval(() => {
						this.addClockTimeInFreeTime();
						this.addClockTimeInWaitTime();
						this.calculatePickupTimeDelay();
						this.calculateDropTimeDelay();
						this.calculatePaymentDelay();
						this.calculateAcceptTimeDelay();
					}, 1000);

					if (this.Global.audioObj.unassignTab.bookingAssignRemainder == true) {
						this.unassignBookingRemainder = setInterval(() => {
							this.checkUnassignBookingPassedTime();
						}, 30000);
					}

					this.Global.disable = false;
				}
			});
		});

		let getDriverBreakCount = (localStorage.getItem('totalDriverBreakCount'));
		console.log('****** ngOnInit getDriverBreakCount ******', getDriverBreakCount);
		if (getDriverBreakCount) {
			this.totalDriverBreakCount = JSON.parse(getDriverBreakCount);
		}

		const isReopenTab = localStorage.getItem('isReopenTab');
		console.log('****** ngOnInit isReopenTab ******', isReopenTab);

		if (isReopenTab) {
			localStorage.setItem('isReopenTab', JSON.stringify(0));
			// You don't remove 'allDriverscachedDriversData' here when refreshing.
			// Reload your data and perform any necessary actions here.
		}


	}

	onDriverSearch(event: KeyboardEvent) {
		const input = (event.target as HTMLInputElement).value;
		console.log('****** onDriverSearch input ******', input);
		this.searchAssignDriverSubject.next(input);
	}

	ngAfterViewInit() {



		this.calculateTimeRemaining(this.targetTime, this.getCompanyTimeZone);
		this.destroyInterval = setInterval(() => {
			this.calculateTimeRemaining(this.targetTime, this.getCompanyTimeZone);
		}, 1000);
	}
	ngOnDestroy(callType: string = ""): void {
		if (callType != "ngOnInit") {
			this.subscriptionShareData.unsubscribe();
		}

		this.subscriptionDispatcher.unsubscribe();
		this.subscriptionDriverTrack.unsubscribe();
		this.subscriptionSearchAutoComplete.unsubscribe();
		this.subscription.unsubscribe();
		clearInterval(this.intervalId);
		clearInterval(this.unassignBookingRemainder);
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
		clearInterval(this.destroyInterval);
	}
	mapReady() {
		return new Promise((resolve) => {
			this.mapsAPILoader
				.load()
				.then(() => {
					this.AgmMap.mapReady.subscribe((map: google.maps.Map): void => {
						this.AgmMap.gestureHandling = 'cooperative';
						this.AgmMap.fitBounds = true;
						this.map = map;
					});

					resolve(true);
				})
				.catch()
				.finally();
		});
	}
	setFillterData() {
		// ! Custom Selected Filter 
		this.unAssignfilterStatus = [
			{ itemId: 6, itemText: 'Unavailable' },
			{ itemId: 1, itemText: 'Pending' },

		];

		this.activeFilterStatus = [
			{ itemId: 3, itemText: 'Accept' },
			{ itemId: 7, itemText: 'Arrived' },
			{ itemId: 8, itemText: 'on Route' },
			{ itemId: 81, itemText: 'Pending Payment' },

		];

		this.filterBookingType = [
			{ itemId: 0, itemText: 'Now' },
			{ itemId: 1, itemText: 'Later' },

		];

		this.filterBookingFlag = [
			{ itemId: 0, itemText: 'City Ride' },
			{ itemId: 1, itemText: 'Rental' },
			{ itemId: 2, itemText: 'Out Station' },
			{ itemId: 3, itemText: 'Restaurant' },

		];

		this.filterPaymentType = [
			{ itemId: 0, itemText: 'Cash' },
			{ itemId: 1, itemText: 'Card' },
			{ itemId: 2, itemText: 'Credit' },
			{ itemId: 3, itemText: 'ApplePay' },

		];

		this.filterSearchBy = [
			{ itemId: 'locationJson', itemText: 'Location' },
			{ itemId: 'userJson', itemText: 'User' },
			{ itemId: 'accountId', itemText: 'Account Id' },
			{ itemId: 'dBookId', itemText: 'Booking Id' },
		];

		this.filterByDate = [
			{ itemId: 0, itemText: 'Whenever ' },
			{ itemId: 1, itemText: 'After' },
			{ itemId: 2, itemText: 'Before' },
			{ itemId: 3, itemText: 'Between' }
		];

		this.filterByAscDescType = [
			{ itemId: 1, itemText: 'Asc' },
			{ itemId: -1, itemText: 'Desc' },
		];

		this.filterByTime = [
			{ itemId: 'pickupTime', itemText: 'Pickup Time' },
			{ itemId: 'assignTime', itemText: 'Assign Time' },
		];

		this.filterBySurge = [
			{ itemId: 1, itemText: 'No Surge' },
			{ itemId: 2, itemText: 'Surge Up' },
			{ itemId: 0, itemText: 'Surge Down' },
		];

		this.driverBookingFilterStatus = [
			{ itemId: 0, itemText: 'Free' },
			{ itemId: 3, itemText: 'Accepted' },
			{ itemId: 7, itemText: 'Arrived' },
			{ itemId: 8, itemText: 'On Trip' },
			{ itemId: 81, itemText: 'Pending Payment' },
		];

		this.driverBookingFilterSearchBy = [
			{ itemId: 'driverName', itemText: 'Driver Name' },
			{ itemId: 'mobile', itemText: 'Driver Phone' },
			{ itemId: 'driverCustomId', itemText: 'driverCustomId' },
			// { itemId: 'driverCustomId', itemText: 'Custom ID' },
		];

		this.setDriverToZone = [
			{ itemId: 1, itemText: 'Without Zone' },
			{ itemId: 2, itemText: 'Zone Wise' },
		];

		this.dropdownSettings = {
			singleSelection: false,
			idField: 'itemId',
			textField: 'itemText',
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			itemsShowLimit: 1,
			allowSearchFilter: this.ShowFilter
		};

		this.secondDropdownSettings =
		{
			singleSelection: false,
			idField: 'itemId',
			textField: 'itemText',
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			itemsShowLimit: 2,
			allowSearchFilter: this.ShowFilter
		};

		this.singleDropdownSettings =
		{
			singleSelection: true,
			closeDropDownOnSelection: true,
			idField: 'itemId',
			textField: 'itemText',
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			itemsShowLimit: 1,
			allowSearchFilter: this.ShowFilter
		};

		this.unAssignFilterDataForm = this.formBuilder.group({
			filterStatus: [this.selectedItems.filterStatus],
			bookingType: [this.selectedItems.bookingType],
			bookingFlag: [this.selectedItems.bookingFlag],
			paymentType: [this.selectedItems.paymentType],
			searchBy: [this.selectedItems.searchBy],
			carType: [this.selectedItems.carType],
			dateTime: [this.selectedItems.dateTime],
			timeColumn: [this.selectedItems.timeColumn],
			ascDescType: [this.selectedItems.ascDescType],
			surgeMultiplier: [this.selectedItems.surgeMultiplier],

		});

		this.activeFilterDataForm = this.formBuilder.group({
			filterStatus: [this.selectedItems.filterStatus],
			bookingType: [this.selectedItems.bookingType],
			bookingFlag: [this.selectedItems.bookingFlag],
			paymentType: [this.selectedItems.paymentType],
			searchBy: [this.selectedItems.searchBy],
			carType: [this.selectedItems.carType],
			dateTime: [this.selectedItems.dateTime],
			timeColumn: [this.selectedItems.timeColumn],
			ascDescType: [this.selectedItems.ascDescType],
			surgeMultiplier: [this.selectedItems.surgeMultiplier],

		});

		this.vehiclefilterDataForm = this.formBuilder.group({
			driverBookingSearchBy: [this.vehicleSelectedItems.driverBookingSearchBy],
			driverBookingStatus: [this.vehicleSelectedItems.driverBookingStatus],
			drivercarType: [this.vehicleSelectedItems.drivercarType],
		});

		this.zoneListfilterDataForm = this.formBuilder.group({
			setDriverToZone: [this.zoneListSelectedItems.setDriversToZone]
		})

		this.unassignFilterCount = this.Global.getLocalStorage({ key: 'UnassignfilterCount' }) != undefined ? this.Global.getLocalStorage({ key: 'UnassignfilterCount' }) : 0;
		this.activeFilterCount = this.Global.getLocalStorage({ key: 'activeFilterCount' }) != undefined ? this.Global.getLocalStorage({ key: 'activeFilterCount' }) : 0;
		this.vehicleFilterCount = this.Global.getLocalStorage({ key: 'vehicleListFilterCount' }) != undefined ? this.Global.getLocalStorage({ key: 'vehicleListFilterCount' }) : 0;
		this.zoneListFilterCount = this.Global.getLocalStorage({ key: 'zoneListFilterCount' }) != undefined ? this.Global.getLocalStorage({ key: 'zoneListFilterCount' }) : 0;
	}
	outClickClosed() {
		const menu = document.getElementById('menu') as HTMLMenuElement;
		const vehicleMenu = document.getElementById('vehicle-list-menu') as HTMLMenuElement;
		const outClick = document.getElementById('out-click') as HTMLDivElement;
		const filterIconToggle = document.getElementById('filterIcon') as HTMLAnchorElement;
		menu.classList.remove('show')
		vehicleMenu.classList.remove('show');
		filterIconToggle.classList.remove('open');
		outClick.style.display = "none";
	}
	dateTimeType: number = 0;
	dateTimeFilter(event: any) {
		this.dateTimeType = event.itemId;
	}
	startDate: string = "";
	endDate: string = "";
	selectedDateRange: any;
	beforeOrAfterDateSelect: any;
	dateFormat: string = 'YYYY/MM/DD';
	alwaysShowCalendars: boolean;
	maxDate: any;
	minDate: any;
	ranges: any = {
		'Today': [moment(), moment()],
		'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
		'Last 7 Days': [moment().subtract(6, 'days'), moment()],
		'Last 30 Days': [moment().subtract(29, 'days'), moment()],
		'This Month': [moment().startOf('month'), moment().endOf('month')],
		'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
	}
	dateRangeSelect(event: any, type: number) {
		this.startDate = "";
		this.endDate = "";
		this.beforeOrAfterDateSelect = "";
		this.selectedDateRange = "";
		if (event.startDate != null && event.endDate != null) {
			if (type == 3) {
				if (event.startDate != null && this.endDate != null) {
					this.startDate = moment(event.startDate).format('YYYY-MM-DD HH:mm:ss');
					this.endDate = moment(event.endDate).format('YYYY-MM-DD 23:59:59');
				}
			} else if (type == 2 || type == 1) {
				this.startDate = moment(event.endDate).format('YYYY-MM-DD 23:59:59');
			}
		}
	}
	makeKeyStringForRequest(str: string) {
		let text = str.replace(/ +/g, "");
		return text.charAt(0).toLowerCase() + text.slice(1);
	}
	closeFilterContextMenu() {
		this.vehicleFilterMenu = false;
		this.zoneListFilterMenu = false;
		this.bookingFilterMenu = false;
	}
	resetAllFilter(FilterTabName: string) {
		this.vehicleFilterApplied = false;
		if (FilterTabName == 'unassign') {
			this.Global.removeLocalStorage({ key: 'UnassignfilterFormValue' })
			this.Global.removeLocalStorage({ key: 'UnassignfilterRequest' })
			this.Global.removeLocalStorage({ key: 'UnassignfilterCount' });
			this.unassignFilterCount = 0;
			this.getUnassignBooking(0);
			this.unassignFilterSearchInput = "";
		}
		else if (FilterTabName == 'active') {
			this.Global.removeLocalStorage({ key: 'activeFilterCount' });
			this.Global.removeLocalStorage({ key: 'activeFilterRequest' })
			this.Global.removeLocalStorage({ key: 'activeFilterFormValue' })
			this.activeFilterCount = 0;
			this.activeFiltersearchInput = "";
			this.getActiveBooking(0)
		}
		else if (FilterTabName == 'vehicle') {
			this.Global.removeLocalStorage({ key: 'vehicleListFilterCount' });
			this.Global.removeLocalStorage({ key: 'vehicleListFilterRequest' })
			this.Global.removeLocalStorage({ key: 'vehicleListFilterFormValue' })
			this.vehicleFilterCount = 0;
			console.log('this.vehicleListFiltersearchInput----------', this.vehicleListFiltersearchInput)
			this.vehicleListFiltersearchInput = "";
			if (this.Global.isSetDriverLimit == true) {
				this.reloadMap();
			}
			else {
				this.getDrivers({
					'onlineDriver': true,
					'isReloadVehicleList': true,
					'isReloadZoneWiseDriver': false,
					'loadShimmer': 1
				});
			}
		}
		else if (FilterTabName == 'zoneList') {
			this.Global.removeLocalStorage({ key: 'zoneListFilterCount' });
			this.Global.removeLocalStorage({ key: 'zoneListFilterFormValue' })
			this.zoneListFilterCount = 0;
			this.isSurgeZoneChecked = true;
			this.getZoneDetails('zoneList').then((res: any) => {
				if (res.status) {
					this.setZoneWiseDriver_new(this.onlineDriverList);
				}
			});
		}
	}
	vehicleListFiltersearchInput: string = "";
	vehicleFilterCount: number = 0;
	isFilterApplied: boolean = false;
	// reqObjForFilter: any
	// searchString: any
	vehicleListAppliedFilter(page: any = 0) {
		this.filterDriverCurrentPage = page;
		this.filterDriverOffset = (page == 0) ? 0 : ((page * this.filterDriverLimit) - this.filterDriverLimit);
		this.isFilterApplied = true
		let formValue = this.vehiclefilterDataForm.value;
		let request: any = {};
		this.vehicleFilterCount = 0;
		this.isDriverSearchOn = false;
		console.log('this.vehicleListFiltersearchInput----------', this.vehicleListFiltersearchInput)
		console.log('form value ____________', formValue)
		let search = this.vehicleListFiltersearchInput;
		request['search'] = search;
		this.searchString = request['search'];
		console.log('__________search string ', this.searchString)
		formValue.search = search != null ? search : '';

		let searchBy = formValue.driverBookingSearchBy;
		this.vehicleFilterCount = searchBy != null && searchBy.length > 0 ? ++this.vehicleFilterCount : this.vehicleFilterCount;
		if (searchBy != null && searchBy.length > 0 && searchBy.length != this.driverBookingFilterSearchBy.length) {
			let searchByArr: any = {};
			for (let i = 0; i < searchBy.length; i++) {
				let text = this.makeKeyStringForRequest(searchBy[i].itemText)
				// searchByArr[text] = searchBy[i].itemId
				searchByArr[searchBy[i].itemId] = text
			}
			request['searchBy'] = searchByArr;
		}

		let status = formValue.driverBookingStatus;
		this.vehicleFilterCount = status != null && status.length > 0 ? ++this.vehicleFilterCount : this.vehicleFilterCount;
		if (status != null && status.length > 0 && status.length != this.driverBookingFilterStatus.length) {
			let statusArr: any = {};
			for (let i = 0; i < status.length; i++) {
				let text = this.makeKeyStringForRequest(status[i].itemText)
				statusArr[text] = status[i].itemId
			}
			request['status'] = statusArr;
		}
		else {
			request['status'] = {};
		}

		let carType = formValue.drivercarType;
		this.vehicleFilterCount = carType != null && carType.length > 0 ? ++this.vehicleFilterCount : this.vehicleFilterCount;
		if (carType != null && carType.length > 0 && carType.length != this.filterCarType.length) {
			let carTypeArr: any = {};
			for (let i = 0; i < carType.length; i++) {
				let text = this.makeKeyStringForRequest(carType[i].itemText)
				carTypeArr[text] = carType[i].itemId
			}
			request['carType'] = carTypeArr;
		}
		else {
			request['carType'] = {};
		}
		console.log('774 _________________', request);
		this.reqObjForFilter = request;
		console.log('\\\\\\\\\ reqObj check-----------', this.reqObjForFilter)
		this.vehicleFilterCall(request);
		this.Global.setLocalStorage({ key: 'vehicleListFilterCount', value: this.vehicleFilterCount });
		this.Global.setLocalStorage({ key: 'vehicleListFilterFormValue', value: formValue });

	}
	vehicleFilterCall(requestObj: any = {}) {
		let passObj: any = {};
		console.log('****** requestObj ******', requestObj);
		if (Object.keys(requestObj).length == 0) {
			let req = this.Global.getLocalStorage({ key: 'vehicleListFilterRequest' });

			if (req == undefined) {
				passObj = {
					'flag': 1
				}

			} else {
				passObj = req;
			}
		}
		else {
			passObj = requestObj;
			passObj.flag = 1
			// passObj.search = '';
		}

		this.getDrivers({
			'request': passObj,
			'isVehicleFilter': true,
			'onlineDriver': true,
			'isReloadVehicleList': true,
			'isReloadZoneWiseDriver': false,
			'loadShimmer': 1
			// 'loadShimmer': this.Global.OnEventShowShimmer == true ? 1 : 0
		}).then((res) => {
			// this.setDriverMarkerLabel(nearestDriver.drivers);
			this.Global.setLocalStorage({ key: 'vehicleListFilterRequest', value: passObj });
		});
	}
	vehicleFilterMenu: boolean = false;
	bookingFilterMenu: boolean = false;
	vehiclefilterContextMenu(FilterTabName: string) {
		this.vehicleFilterMenu = true;
		let formValue = this.Global.getLocalStorage({ key: 'vehicleListFilterFormValue' });
		if (formValue != undefined) {
			let setFormValue = {
				driverBookingSearchBy: formValue.driverBookingSearchBy != null ? formValue.driverBookingSearchBy : [],
				driverBookingStatus: formValue.driverBookingStatus != null ? formValue.driverBookingStatus : [],
				drivercarType: formValue.drivercarType != null ? formValue.drivercarType : [],
			}
			this.vehiclefilterDataForm.setValue(setFormValue);
			this.vehicleListFiltersearchInput = formValue.search != null ? formValue.search : ''
			console.log('this.vehicleListFiltersearchInput----------', this.vehicleListFiltersearchInput)
		}
	}
	// zone list filter
	zoneListFilterCount: number = 0;
	zoneListFilterMenu: boolean = false;
	zoneListAppliedFilter() {
		let formValue = this.zoneListfilterDataForm.value;
		this.zoneListFilterCount = 0;

		let selectedZoneType = formValue.setDriverToZone;
		if (selectedZoneType != null && selectedZoneType.length > 0) {

			if (selectedZoneType[0].itemId == 1) {
				this.setAllDriversToDefaultZone(this.onlineDriverList)
			} else if (selectedZoneType[0].itemId == 2) {
				this.isSurgeZoneChecked = true;
				this.getZoneDetails('zoneList').then((res: any) => {
					if (res.status) {
						this.setZoneWiseDriver_new(this.onlineDriverList);
					}
				});

			}
			++this.zoneListFilterCount;
		} else {
			this.zoneListFilterCount = 0;
		}
		// this.vehicleFilterCall(request);
		this.Global.setLocalStorage({ key: 'zoneListFilterCount', value: this.zoneListFilterCount });
		this.Global.setLocalStorage({ key: 'zoneListFilterFormValue', value: formValue });
	}
	zoneListFilterContextMenu() {
		this.zoneListFilterMenu = true;
		let formValue = this.Global.getLocalStorage({ key: 'zoneListFilterFormValue' });

		if (formValue != undefined) {
			let setFormValue = {
				setDriverToZone: formValue.setDriverToZone != null ? formValue.setDriverToZone : [],
			}
			this.zoneListfilterDataForm.setValue(setFormValue);
		}
	}
	setAllDriversToDefaultZone(driverList: any) {
		this.Global.surgePriceZone = [];
		this.Global.surgePriceZone.push({
			'zoneId': '',
			'zoneName': 'Default',
			'driverList': {
				'freeDriverList': [],
				'acceptedBookingDriverList': [],
				'ontripBookingDriverList': [],
				'completedBookingDriverList': [],
			}
		});

		for (let i = 0; i < driverList.length; i++) {
			this.blinkDriverObj[driverList[i].driverId + '_blink'] = { isBlink: driverList[i].isDriverAssignBooking, blinkTime: driverList[i].bookingElapsedTime }

			let driverData = {
				'driverId': driverList[i].driverId,
				'driverName': driverList[i].driverName,
				'driverCustomId': driverList[i].driverCustomId,
				'slideToComplete': driverList[i].slideToComplete,
				'freeTime': driverList[i].freeTime,
				'acceptedTripTime': driverList[i].acceptedTripTime,
				'arrivedTripTime': driverList[i].arrivedTripTime,
				'beginTripTime': driverList[i].beginTripTime,
			}

			if (driverList[i].bookingStatusFlag <= 0) {
				this.Global.surgePriceZone[0].driverList.freeDriverList.push(driverData);
			}
			else if (driverList[i].bookingStatusFlag == 3) {
				this.Global.surgePriceZone[0].driverList.acceptedBookingDriverList.push(driverData);
			}
			else if (driverList[i].bookingStatusFlag == 7) {
				this.Global.surgePriceZone[0].driverList.ontripBookingDriverList.push(driverData);
			}
			else if (driverList[i].bookingStatusFlag == 8) {
				this.Global.surgePriceZone[0].driverList.completedBookingDriverList.push(driverData);
			}
		}

		for (let i = 0; i < this.Global.surgePriceZone.length; i++) {
			this.Global.surgePriceZone[i].driverList.freeDriverList = this.Global.sortArrayOfObject(this.Global.surgePriceZone[i].driverList.freeDriverList, 'freeTime', this.Global.onlineDriverListSortBy.freeDriverList);
			this.Global.surgePriceZone[i].driverList.acceptedBookingDriverList = this.Global.sortArrayOfObject(this.Global.surgePriceZone[i].driverList.acceptedBookingDriverList, 'acceptedTripTime', this.Global.onlineDriverListSortBy.acceptedBookingDriverList);
			this.Global.surgePriceZone[i].driverList.ontripBookingDriverList = this.Global.sortArrayOfObject(this.Global.surgePriceZone[i].driverList.ontripBookingDriverList, 'arrivedTripTime', this.Global.onlineDriverListSortBy.ontripBookingDriverList);
			this.Global.surgePriceZone[i].driverList.completedBookingDriverList = this.Global.sortArrayOfObject(this.Global.surgePriceZone[i].driverList.completedBookingDriverList, 'beginTripTime', this.Global.onlineDriverListSortBy.completedBookingDriverList);
		}

	}

	createBookingNotifyData: any[] = [];
	createNotifyBookingIds: any[] = [];
	userChatBubble: any
	eventHandler(opts: any) {

		if (opts.statusFlag) {

			if (opts.statusFlag != this.Global.statusFlags['DRIVER_ZONE_LOCATION']) {
				console.log("HomePage eventHandler", opts);
			}

			if (opts.statusFlag == this.Global.statusFlags['NEW_BOOKING_CREATE']) {
				console.log('***** booking opts.status *****', opts.status);
				let distanceVariant = (opts.distanceVariant == 1) ? 'miles' : 'km';
				console.log("opts device", opts.isDevice);
				console.log('***** this.searchTaskBy *****', this.searchTaskBy);
				console.log('***** opts.bookingType == 1 && opts.status == 12 && this.searchTaskBy != "" *****', opts.bookingType == 1 && opts.status == 12 && this.searchTaskBy == "");
				if (opts.bookingType == 1 && opts.status == 12 && this.searchTaskBy == "") {
					// console.log('***** opts.bookingType == 1 && opts.status == 12 *****', opts.bookingType == 1 && opts.status == 12);
					console.log('*****createBookingNotifyData new add notify  *****', this.createBookingNotifyData);
					console.log('***** opts *****', opts);
					this.createNotifyBookingIds.push(opts.bookingId);
					this.createBookingNotifyData.push({
						'bookingId': opts.bookingId ?? null,
						'dispatcherName': opts.dispatcherName ?? null,
						'dBookId': opts.dBookId ?? null,
						'currency': opts.currency ?? null,
						'userJson': opts.userJson ?? null,
						'carTypeName': opts.taxiType ?? null,
						'locationJson': opts.locationJson ?? null,
						'pickupTime': opts.pickupTime ?? null,
						'assignTime': opts.assignTime ?? null,
						'finalAmount': (opts.estimatedFinalAmount) ? opts.estimatedFinalAmount : 0.00,
						'paymentType': (opts.paymentType) ? opts.paymentType : 0,
						'totalApproxKm': (opts.totalApproxKm) ? (opts.totalApproxKm.toFixed(environment.roundUpNumber)) : 0.00,
						'totalApproxTime': (opts.totalApproxTime) ? (opts.totalApproxTime.toFixed(environment.roundUpNumber)) : 0,
						'distanceVariant': distanceVariant ?? null,
						'isRestaurant': opts.is_restaurant ?? null,
						'bookingFlag': opts.bookingFlag ?? null,
						"accountId": opts.accountId ?? null,
						"taxiType": opts.taxiType ?? null,
						"timeType": opts.timeType ?? null,
						"bookingType": opts.bookingType ?? null,
						"priority": opts.priority ?? null,
						"estimatedAmount": opts.estimatedAmount ? opts.estimatedAmount.toFixed(environment.priceRoundUpNumber) : 0.00,
						"estimatedTaxAmount": opts.estimatedTaxAmount ?? null,
						"estimatedFinalAmount": opts.estimatedFinalAmount ? opts.estimatedFinalAmount.toFixed(environment.priceRoundUpNumber) : 0.00,
						"estimatedDiscountAmount": opts.estimatedDiscountAmount ?? null,
						"is_restaurant": opts.is_restaurant ?? null,
						"surgeMultiplier": opts.surgeMultiplier ?? null,
						"isBox": opts.isBox ? opts.isBox : 0,
						'carColor': opts.carColor ?? null,
						'codAmound': opts.cod_amount ?? null,
						'isDevice': opts.isDevice ?? null,
						'isRepet': opts.isRepet ?? null,
						'repetTicks': opts.repetTicks ?? null,
						'isOpenPopup': false,
						'toll': opts.toll,
						'serviceCharges': opts.serviceCharges,
						'airportParking': opts.airportParking,
						'priceFromAirportZone': opts.priceFromAirportZone ? opts.priceFromAirportZone : opts.isAirport,
						'isAirport': opts.isAirport,
					});
					console.log('***** this.createBookingNotifyData  after push*****', this.createBookingNotifyData);
					this.totalUnAprroveCount += 1
					this.activeBookingPopUp(true);

					if (this.Global.audioObj.createBookingPopupAlert.createBookingPopupNotify == true) {
						let bookingAlert: any = document.querySelector('#newCreateOrderNotification');
						bookingAlert.play();
					}
				}
				// this.closeCreatedPopUp();
				if (this.isOpenBookingTab == 'unassign') {
					this.addUnassignData(opts.assignTime, opts.bookingId);
					console.log("all create booking data count", this.totalUnassignBooking);
				}
			}
			else if (opts.statusFlag == this.Global.statusFlags['DRIVER_NEW_REQUEST_DISPATCHER']) {
				if (this.isOpenBookingTab == 'assign') {
					this.addAssignData(opts.assignTime, opts.bookingId);
				}
				else if (this.isOpenBookingTab == 'unassign') {
					this.removeUnassignData(opts.assignTime, opts.bookingId);
				}
				else if (this.isOpenBookingTab == 'active') {
					this.removeActiveData(opts.assignTime, opts.bookingId);
				}

				let driverObj: any = {
					'isBlink': true,
					'updateDriverData': { 'bookingElapsedTime': opts.bookingElapsedTime, 'isDriverAssignBooking': 1 },
					'driverId': opts.driverId,
				}
				if (opts.oldDriver && Object.keys(opts.oldDriver).length > 0) {
					driverObj.updateOldDriverData = { 'isDriverAssignBooking': opts.oldDriver.isDriverAssignBooking };
					driverObj.oldDriverId = opts.oldDriver.driverId;
				}
				this.setDriverList(driverObj);
			}
			else if (opts.statusFlag == this.Global.statusFlags['DRIVER_ACCEPT_TRIP']) {
				if (opts.status == 12 && opts.bookingType == 1) {
					this.removeCreatedBookingNotify(opts.bookingId);
				}
				if (this.isOpenBookingTab == 'active') {
					this.addActiveData(opts.assignTime, opts.bookingId);
				}

				else if (this.isOpenBookingTab == 'assign') {
					this.removeAssignData(opts.assignTime, opts.bookingId);
				}

				let userJson = opts.userJson != undefined && opts.userJson.length != 0 ? opts.userJson : []
				let updateOnlineDriverObj: any = {
					'bookingStatusFlag': opts.driverStatus,
					'jobs': opts.jobs,
					'isDriverAssignBooking': opts.isDriverAssignBooking,
					'slideToComplete': opts.slidedToComplete,
					'locationJson': opts.locationJson,
					'userJson': userJson,
					'zoneId': opts.zoneId
				};

				if (opts.pickupTripTime) {
					updateOnlineDriverObj.pickupTripTime = opts.pickupTripTime;
				}
				if (opts.acceptedTripTime) {
					updateOnlineDriverObj.acceptedTripTime = opts.acceptedTripTime;
				}
				if (opts.arrivedTripTime) {
					updateOnlineDriverObj.arrivedTripTime = opts.arrivedTripTime;
				}
				if (opts.beginTripTime) {
					updateOnlineDriverObj.beginTripTime = opts.beginTripTime;
				}
				if (opts.bookingFlag) {
					updateOnlineDriverObj.bookingFlag = opts.bookingFlag;
				}
				if (opts.totalApproxTime) {
					updateOnlineDriverObj.totalApproxTime = opts.totalApproxTime;
				}

				if (this.selectedBooking != {} && this.selectedBooking.bookingId == opts.bookingId) {
					this.clearEditBooking();
					this.selectedBooking = {};
					this.forceFullyCompletedDialog = false;
				}

				this.setDriverList({
					'isBlink': true,
					'updateDriverData': updateOnlineDriverObj,
					'driverId': opts.driverId,
					'isReloadVehicleList': true,
					'isReloadZoneWiseDriver': true,
				});

				if (opts.jobs == 1) {
					this.busyDriverCount += 1;
					this.freeDriverCount -= 1;
				}

				this.updateDriverMarker(opts.driverId);
			}
			else if (opts.statusFlag == this.Global.statusFlags['DRIVER_ARRIVED_TRIP']) {
				// slide to arrived
				if (this.isOpenBookingTab == 'active') {
					this.activeBookings = this.updateArrayWithObject(this.activeBookings, ['bookingId', opts.bookingId], { 'setColor': 2, 'locationJson': opts.booking.locationJson });
				}

				let userJson = opts.userJson != undefined && opts.userJson.length != 0 ? opts.userJson : [];
				let updateOnlineDriverObj: any = {
					'bookingStatusFlag': opts.driverStatus,
					'slideToComplete': opts.slidedToComplete,
					'locationJson': opts.locationJson,
					'userJson': userJson,
					'zoneId': opts.zoneId
				};
				if (opts.pickupTripTime != undefined) {
					updateOnlineDriverObj.pickupTripTime = opts.pickupTripTime;
				}
				if (opts.acceptedTripTime != undefined) {
					updateOnlineDriverObj.acceptedTripTime = opts.acceptedTripTime;
				}
				if (opts.arrivedTripTime != undefined) {
					updateOnlineDriverObj.arrivedTripTime = opts.arrivedTripTime;
				}
				if (opts.beginTripTime != undefined) {
					updateOnlineDriverObj.beginTripTime = opts.beginTripTime;
				}
				if (opts.bookingFlag != undefined && opts.bookingFlag !== "") {
					updateOnlineDriverObj.bookingFlag = opts.bookingFlag;
				}
				if (opts.totalApproxTime != undefined) {
					updateOnlineDriverObj.totalApproxTime = opts.totalApproxTime;
				}

				if (this.selectedBooking != {} && this.selectedBooking.bookingId == opts.bookingId) {
					this.clearEditBooking();
					this.selectedBooking = {};
					this.forceFullyCompletedDialog = false;
				}


				this.setDriverList({
					'updateDriverData': updateOnlineDriverObj,
					'driverId': opts.driverId,
					'isReloadVehicleList': true,
					'isReloadZoneWiseDriver': true,
				});
				this.updateDriverMarker(opts.driverId);
			}
			else if (opts.statusFlag == this.Global.statusFlags['DRIVER_UNAVAILABLE_DISPATCHER']) {
				// if (this.isOpenBookingTab == 'assign' && this.isOpentabBookingIds.includes(opts.bookingId)) {
				// if (this.isOpenBookingTab == 'assign') {
				// 	this.getAssignBooking(this.assignBookingCurrentpage);
				// }
				// else if (this.isOpenBookingTab == 'unassign') {
				// 	this.addUnassignData(opts.assignTime, opts.bookingId);
				// }

				if (this.selectedBooking != {} && this.selectedBooking.bookingId == opts.bookingId) {
					this.clearEditBooking();
					this.selectedBooking = {};
					this.forceFullyCompletedDialog = false;
				}
			}
			else if (opts.statusFlag == this.Global.statusFlags['DRIVER_BEGIN_TRIP']) {
				if (this.isOpenBookingTab == 'active') {
					this.activeBookings = this.updateArrayWithObject(this.activeBookings, ['bookingId', opts.bookingId], { 'setColor': 3 });
				}

				let userJson = opts.userJson != undefined && opts.userJson.length != 0 ? opts.userJson : []
				let updateOnlineDriverObj: any = {
					'bookingStatusFlag': opts.driverStatus,
					'beginTripTime': opts.beginTripTime,
					'arrivedTripTime': opts.arrivedTripTime,
					'slideToComplete': opts.slidedToComplete,
					'locationJson': opts.locationJson,
					'userJson': userJson,
					'zoneId': opts.zoneId
				};

				if (opts.pickupTripTime != undefined) {
					updateOnlineDriverObj.pickupTripTime = opts.pickupTripTime;
				}
				if (opts.acceptedTripTime != undefined) {
					updateOnlineDriverObj.acceptedTripTime = opts.acceptedTripTime;
				}
				if (opts.arrivedTripTime != undefined) {
					updateOnlineDriverObj.arrivedTripTime = opts.arrivedTripTime;
				}
				if (opts.beginTripTime != undefined) {
					updateOnlineDriverObj.beginTripTime = opts.beginTripTime;
				}
				if (opts.bookingFlag != undefined && opts.bookingFlag !== "") {
					updateOnlineDriverObj.bookingFlag = opts.bookingFlag;
				}
				if (opts.totalApproxTime != undefined) {
					updateOnlineDriverObj.totalApproxTime = opts.totalApproxTime;
				}

				if (this.selectedBooking != {} && this.selectedBooking.bookingId == opts.bookingId) {
					this.clearEditBooking();
					this.selectedBooking = {};
					this.forceFullyCompletedDialog = false;
				}

				this.setDriverList({
					'updateDriverData': updateOnlineDriverObj,
					'driverId': opts.driverId,
					'isReloadVehicleList': true,
					'isReloadZoneWiseDriver': true,
				});

				this.updateDriverMarker(opts.driverId);
			}
			else if (opts.statusFlag == this.Global.statusFlags['DRIVER_SLIDE_TO_COMPLETED_TRIP']) {
				if (this.isOpenBookingTab == 'active') {

					let updateBookingData = {
						'setColor': 4,
						'finalAmount': (opts.actual_final_amount) ? ((Math.round(opts.actual_final_amount * 1000) / 1000).toFixed(environment.priceRoundUpNumber)) : 0.00,
						'actual_amount': opts.actual_amount,
						'actual_discount_amount': opts.actual_discount_amount,
						'actual_final_amount': opts.actual_final_amount,
						'actual_tax_amount': opts.actual_tax_amount,
						'arrears': opts.arrears,
						'slidedToComplete': 1,
					}

					this.activeBookings = this.updateArrayWithObject(this.activeBookings, ['bookingId', opts.bookingId], updateBookingData);
				}

				let userJson = opts.userJson != undefined && opts.userJson.length != 0 ? opts.userJson : []
				let updateOnlineDriverObj: any = {
					'bookingStatusFlag': opts.driverStatus,
					'slideToComplete': opts.slidedToComplete,
					'locationJson': opts.locationJson,
					'userJson': userJson,
					'zoneId': opts.zoneId
				};
				if (opts.pickupTripTime != undefined) {
					updateOnlineDriverObj.pickupTripTime = opts.pickupTripTime;
				}
				if (opts.acceptedTripTime != undefined) {
					updateOnlineDriverObj.acceptedTripTime = opts.acceptedTripTime;
				}
				if (opts.arrivedTripTime != undefined) {
					updateOnlineDriverObj.arrivedTripTime = opts.arrivedTripTime;
				}
				if (opts.beginTripTime != undefined) {
					updateOnlineDriverObj.beginTripTime = opts.beginTripTime;
				}
				if (opts.bookingFlag != undefined && opts.bookingFlag !== "") {
					updateOnlineDriverObj.bookingFlag = opts.bookingFlag;
				}
				if (opts.totalApproxTime != undefined) {
					updateOnlineDriverObj.totalApproxTime = opts.totalApproxTime;
				}
				if (opts.finishTripTime != undefined) {
					updateOnlineDriverObj.finishTripTime = opts.finishTripTime;
				}

				if (this.selectedBooking != {} && this.selectedBooking.bookingId == opts.bookingId) {
					this.clearEditBooking();
					this.selectedBooking = {};
					this.forceFullyCompletedDialog = false;
				}

				this.setDriverList({
					'updateDriverData': updateOnlineDriverObj,
					'driverId': opts.driverId,
					'isReloadVehicleList': true,
					'isReloadZoneWiseDriver': true,
				});
				this.updateDriverMarker(opts.driverId);
			}
			else if (opts.statusFlag == this.Global.statusFlags['COMPLETED_BOOKING'] || opts.statusFlag == this.Global.statusFlags['FORCEFULLY_DELIVERED']) {
				if (this.isOpenBookingTab == 'active') {
					this.removeActiveData(opts.assignTime, opts.bookingId);
				}
				else if (this.isOpenBookingTab == 'assign' && this.isOpentabBookingIds.includes(opts.bookingId)) {
					this.removeAssignData(opts.assignTime, opts.bookingId);
				}

				let userJson = opts.userJson != undefined && opts.userJson.length != 0 ? opts.userJson : [];
				let updateOnlineDriverObj: any = {
					'arrivedTripTime': opts.arrivedTripTime,
					'bookingStatusFlag': opts.driverStatus,
					'freeTime': opts.freeTime,
					'jobs': opts.jobs,
					'slideToComplete': opts.slidedToComplete,
					'locationJson': opts.locationJson,
					'userJson': userJson,
					'zoneId': opts.zoneId,
					'goHome': opts.goHome
				};
				if (opts.pickupTripTime != undefined) {
					updateOnlineDriverObj.pickupTripTime = opts.pickupTripTime;
				}
				if (opts.acceptedTripTime != undefined) {
					updateOnlineDriverObj.acceptedTripTime = opts.acceptedTripTime;
				}
				if (opts.arrivedTripTime != undefined) {
					updateOnlineDriverObj.arrivedTripTime = opts.arrivedTripTime;
				}
				if (opts.beginTripTime != undefined) {
					updateOnlineDriverObj.beginTripTime = opts.beginTripTime;
				}
				if (opts.bookingFlag != undefined && opts.bookingFlag !== "") {
					updateOnlineDriverObj.bookingFlag = opts.bookingFlag;
				}
				if (opts.totalApproxTime != undefined) {
					updateOnlineDriverObj.totalApproxTime = opts.totalApproxTime;
				}
				if (opts.finishTripTime != undefined) {
					updateOnlineDriverObj.finishTripTime = opts.finishTripTime;
				}
				if (this.selectedBooking != {} && this.selectedBooking.bookingId == opts.bookingId) {
					this.clearEditBooking();
					this.selectedBooking = {};
					this.forceFullyCompletedDialog = false;

				}

				if (opts.statusFlag == 19) {
					updateOnlineDriverObj.isDriverAssignBooking = opts.isDriverAssignBooking;
				}

				this.setDriverList({
					'updateDriverData': updateOnlineDriverObj,
					'driverId': opts.driverId,
					'isReloadVehicleList': true,
					'isReloadZoneWiseDriver': true,
				});
				this.updateDriverMarker(opts.driverId);

				if (this.isOpenCreateEditdraftjob == 1 && this.selectedBooking.bookingId == opts.bookingId) {
					this.selectedBooking = {};
					this.clearEditBooking();
					this.forceFullyCompletedDialog = false;

				}

				if (opts.driverStatus <= 0) {
					this.busyDriverCount -= 1;
					this.freeDriverCount += 1;
				}
			}
			else if (opts.statusFlag == this.Global.statusFlags['FAIL_TO_FIND_NEAREST_DRIVER_DISPATCHER']) {
				if ((opts.oldDriverId != null &&
					opts.oldDriverId != 'manual' &&
					opts.oldDriverId != 'automatic' &&
					opts.oldDriverId != '' && opts.oldDriverId != {}) || opts.isFromPendingOrder == true) {

					if (this.isOpenBookingTab == 'assign') {
						this.addAssignData(opts.assignTime, opts.bookingId);
					}
					else if (this.isOpenBookingTab == 'unassign' && this.isOpentabBookingIds.includes(opts.bookingId)) {
						this.addUnassignData(opts.assignTime, opts.bookingId);
					}

					if (this.selectedBooking != {} && this.selectedBooking.bookingId == opts.bookingId) {
						this.clearEditBooking();
						this.selectedBooking = {};
					}
				}
			}
			else if (opts.statusFlag == this.Global.statusFlags['BOOKING_TRANSFER_TO_DRIVER']) {

				if (this.isOpenBookingTab == 'unassign' &&
					(
						(opts.bookingOldStatus == 1 &&
							(
								Object.keys(opts.oldDriver).length == 0 ||
								opts.oldDriver.driverId == '' ||
								opts.oldDriver.driverId == 'automatic' ||
								opts.oldDriver.driverId == 'manual'
							)
						) ||
						opts.bookingOldStatus == 6)
				) {
					this.removeUnassignData(opts.assignTime, opts.bookingId);
				}
				else if (this.isOpenBookingTab == 'assign') {
					this.addAssignData(opts.assignTime, opts.bookingId);
				}
				else if (this.isOpenBookingTab == 'active' && (opts.bookingOldStatus == 3 || opts.bookingOldStatus == 7 || opts.bookingOldStatus == 8)) {
					this.removeActiveData(opts.assignTime, opts.bookingId);
				}

				if (this.selectedBooking != {} && this.selectedBooking.bookingId == opts.bookingId) {
					this.clearEditBooking();
					this.selectedBooking = {};
					this.forceFullyCompletedDialog = false;
				}

				let updateDriver: any = {
					'updateDriverData': { 'isDriverAssignBooking': opts.isDriverAssignBooking, 'bookingElapsedTime': opts.bookingElapsedTime },
					'driverId': opts.driverId,
					'isBlink': true,
				};

				if (opts.oldDriver && Object.keys(opts.oldDriver).length > 0) {
					let userJson = opts.oldDriver.userJson != undefined && opts.oldDriver.userJson.length != 0 ? opts.oldDriver.userJson : [];

					let updateOnlineDriverObj: any = {
						'bookingStatusFlag': opts.oldDriver.driverStatus,
						'freeTime': opts.oldDriver.driverStatus <= 0 ? opts.oldDriver.freeTime : 'Busy',
						'isDriverAssignBooking': opts.oldDriver.isDriverAssignBooking,
						'jobs': opts.oldDriver.jobs,
						'slideToComplete': opts.oldDriver.slidedToComplete,
						'locationJson': opts.oldDriver.locationJson,
						'userJson': userJson
					};

					if (opts.oldDriver.pickupTripTime != undefined) {
						updateOnlineDriverObj.pickupTripTime = opts.oldDriver.pickupTripTime;
					}
					if (opts.oldDriver.acceptedTripTime != undefined) {
						updateOnlineDriverObj.acceptedTripTime = opts.oldDriver.acceptedTripTime;
					}
					if (opts.oldDriver.arrivedTripTime != undefined) {
						updateOnlineDriverObj.arrivedTripTime = opts.oldDriver.arrivedTripTime;
					}
					if (opts.oldDriver.beginTripTime != undefined) {
						updateOnlineDriverObj.beginTripTime = opts.oldDriver.beginTripTime;
					}
					if (opts.oldDriver.bookingFlag != undefined && opts.oldDriver.bookingFlag !== "") {
						updateOnlineDriverObj.bookingFlag = opts.oldDriver.bookingFlag;
					}
					if (opts.oldDriver.totalApproxTime != undefined) {
						updateOnlineDriverObj.totalApproxTime = opts.oldDriver.totalApproxTime;
					}

					updateDriver.updateOldDriverData = updateOnlineDriverObj;
					updateDriver.oldDriverId = opts.oldDriver.driverId;

					if (opts.oldDriver.driverStatus <= 0) {
						this.busyDriverCount -= 1;
						this.freeDriverCount += 1;
					}
				}

				updateDriver.isReloadVehicleList = true;

				this.setDriverList(updateDriver);
				this.updateDriverMarker(opts.oldDriver.driverId);
			}
			else if (opts.statusFlag == this.Global.statusFlags['USER_CANCEL_TRIP'] || opts.statusFlag == this.Global.statusFlags['DRIVER_CANCEL_TRIP'] || opts.statusFlag == this.Global.statusFlags['DISPATCHER_CANCEL_TRIP']) {
				console.log('***** opts.statusFlag *****', opts.statusFlag);
				if (opts.status == 12 && opts.bookingType == 1) {
					this.removeCreatedBookingNotify(opts.bookingId);
					console.log("this.removeCreatedBookingNotify USER_CANCEL_TRIP", this.createNotifyBookingIds.length);
				}
				// this.closeCreatedPopUp();

				if (this.Global.audioObj.bookingAlert.driverCancelBooking == true && opts.statusFlag == this.Global.statusFlags['DRIVER_CANCEL_TRIP']) {
					this.setAlertData();
				}

				if (this.isOpenBookingTab == 'unassign' && ((opts.status == 1 && (opts.driverId == '' || opts.driverId == 'automatic' || opts.driverId == 'manual')) || opts.status == 6)) {
					this.removeUnassignData(opts.assignTime, opts.bookingId);
				}
				else if (this.isOpenBookingTab == 'assign' && (opts.status == 1 && (opts.driverId != '' || opts.driverId != 'automatic' || opts.driverId != 'manual'))) {
					this.removeAssignData(opts.assignTime, opts.bookingId);
				}
				else if (this.isOpenBookingTab == 'active' && (opts.status == 3 || opts.status == 7 || opts.status == 8)) {
					this.removeActiveData(opts.assignTime, opts.bookingId);
				}

				if (this.selectedBooking != {} && this.selectedBooking.bookingId == opts.bookingId) {
					this.clearEditBooking();
					this.selectedBooking = {};
					this.forceFullyCompletedDialog = false;
				}

				let userJson = opts.userJson != undefined && opts.userJson.length != 0 ? opts.userJson : []
				let updateOnlineDriverObj: any = {
					'bookingStatusFlag': opts.driverStatus,
					'freeTime': opts.freeTime,
					'jobs': opts.jobs,
					'isDriverAssignBooking': opts.isDriverAssignBooking,
					'slideToComplete': opts.slidedToComplete,
					'locationJson': opts.locationJson,
					'userJson': userJson,
					'zoneId': opts.zoneId
				}

				if (opts.pickupTripTime != undefined) {
					updateOnlineDriverObj.pickupTripTime = opts.pickupTripTime;
				}
				if (opts.acceptedTripTime != undefined) {
					updateOnlineDriverObj.acceptedTripTime = opts.acceptedTripTime;
				}
				if (opts.arrivedTripTime != undefined) {
					updateOnlineDriverObj.arrivedTripTime = opts.arrivedTripTime;
				}
				if (opts.beginTripTime != undefined) {
					updateOnlineDriverObj.beginTripTime = opts.beginTripTime;
				}
				if (opts.bookingFlag != undefined && opts.bookingFlag !== "") {
					updateOnlineDriverObj.bookingFlag = opts.bookingFlag;
				}
				if (opts.totalApproxTime != undefined) {
					updateOnlineDriverObj.totalApproxTime = opts.totalApproxTime;
				}

				if (opts.driverId != undefined && opts.driverId != '') {
					this.setDriverList({
						'updateDriverData': updateOnlineDriverObj,
						'driverId': opts.driverId,
						'isReloadVehicleList': true,
						'isReloadZoneWiseDriver': true,
					});
					this.updateDriverMarker(opts.driverId);
				}

				if (opts.driverStatus <= 0) {
					this.busyDriverCount -= 1;
					this.freeDriverCount += 1;
				}
			}
			else if (opts.statusFlag == this.Global.statusFlags['DRIVER_REJECT_BOOKING'] || opts.statusFlag == this.Global.statusFlags['ASSIGN_DRIVER_REJECT']) {

				if ((opts.isAutomaticReject == 0 && this.Global.audioObj.bookingAlert.rejectBooking == true) || (opts.isAutomaticReject == 1 && this.Global.audioObj.bookingAlert.rejectBookingAutomatic == true)) {
					this.setAlertData();
				}

				if (!opts.isEmergency) {
					// if (this.isOpenBookingTab == 'assign' && this.isOpentabBookingIds.includes(opts.bookingId)) {
					if (this.isOpenBookingTab == 'assign') {
						this.getAssignBooking(this.assignBookingCurrentpage);
					}
					else if (this.isOpenBookingTab == 'unassign') {
						this.addUnassignData(opts.assignTime, opts.bookingId);
					}

					if (this.selectedBooking != {} && this.selectedBooking.bookingId == opts.bookingId) {
						this.clearEditBooking();
						this.selectedBooking = {};


					}

					let updateDriver = {
						'isDriverAssignBooking': opts.isDriverAssignBooking,
						'bookingStatusFlag': opts.driverStatus,
						'slideToComplete': opts.slidedToComplete,
						'locationJson': opts.locationJson,
					}

					this.setDriverList({
						'isBlink': true,
						'updateDriverData': updateDriver,
						'driverId': opts.driverId,
					});
				}
			}
			else if (opts.statusFlag == this.Global.statusFlags['DRIVER_OFFLINE'] || opts.statusFlag == this.Global.statusFlags['DRIVER_ONLINE']) {
				if (opts.statusFlag == this.Global.statusFlags['DRIVER_ONLINE']) {
					this.onlineDriverCount += 1;
					this.offlineDriverCount -= 1;
					this.freeDriverCount += 1;
				}
				else if (opts.statusFlag == this.Global.statusFlags['DRIVER_OFFLINE']) {

					if (this.Global.manageAlertPopup.offlineDriver == true) {
						this.Popup.toasterMessage({
							'status': 'warning',
							'title': opts.driverData.driverName + ' went offline',
							'message': '',
						});
					}

					this.onlineDriverCount -= 1;
					this.offlineDriverCount += 1;
					this.freeDriverCount -= 1;
				}

				if (this.Global.isSetDriverLimit != true || (opts.statusFlag == this.Global.statusFlags['DRIVER_OFFLINE'] && this.nearestDriverIds.includes(opts.driverData.driverId))) {
					this.setOnlineOfflineDriverData([opts.driverData]);
				}

				this.shareData.shareData({ function: 'driverAlertMqtt', 'data': { 'statusFlag': opts.statusFlag, 'driverName': opts.driverData.driverName } })
			}
			else if (opts.statusFlag == this.Global.statusFlags['BOOKING_SAVE_TO_DRAFT'] || opts.statusFlag == this.Global.statusFlags['DELETE_DRAFT_BOOKING']) {
				if (this.isOpenCreateEditdraftjob == 2) {
					this.getDraftBooking(0);
				}
			}
			else if (opts.statusFlag == this.Global.statusFlags['DISPATCHER_UPDATE_BOOKING']) {
				if (this.isOpenBookingTab == 'unassign') {
					this.updateUnassignBooking(opts.bookingId);
					// this.addUnassignData(opts.assignTime, opts.bookingId);
				} else if (this.isOpenBookingTab == 'assign') {
					if (this.isOpentabBookingIds.includes(opts.bookingId)) {
						this.getAssignBooking(this.unassignBookingCurrentpage, this.Global.OnEventShowShimmer == true ? 0 : 1);
					}
				} else if (this.isOpenBookingTab == 'active') {
					if (this.isOpentabBookingIds.includes(opts.bookingId)) {
						console.log('****** DISPATCHER_UPDATE_BOOKING this.activeBookingCurrentpage ******', this.activeBookingCurrentpage);
						this.getActiveBooking(this.activeBookingCurrentpage, this.Global.OnEventShowShimmer == true ? 0 : 1);
					}
				}

				if (this.selectedBooking != {} && this.selectedBooking.bookingId == opts.bookingId) {
					this.clearEditBooking();
					this.selectedBooking = {};
					this.forceFullyCompletedDialog = false;
				}

				if (opts.oldDriver && Object.keys(opts.oldDriver).length > 0) {

					let userJson = opts.oldDriver.userJson != undefined && opts.oldDriver.userJson.length != 0 ? opts.oldDriver.userJson : [];

					let updateOnlineDriverObj: any = {
						'bookingStatusFlag': opts.oldDriver.driverStatus,
						'freeTime': opts.oldDriver.driverStatus <= 0 ? opts.oldDriver.freeTime : 'Busy',
						'isDriverAssignBooking': opts.oldDriver.isDriverAssignBooking,
						'jobs': opts.oldDriver.jobs,
						'slideToComplete': opts.oldDriver.slidedToComplete,
						'userJson': userJson,
						'locationJson': opts.oldDriver.locationJson
					};
					if (opts.oldDriver.pickupTripTime != undefined) {
						updateOnlineDriverObj.pickupTripTime = opts.pickupTripTime;
					}
					if (opts.oldDriver.acceptedTripTime != undefined) {
						updateOnlineDriverObj.acceptedTripTime = opts.acceptedTripTime;
					}
					if (opts.oldDriver.arrivedTripTime != undefined) {
						updateOnlineDriverObj.arrivedTripTime = opts.oldDriver.arrivedTripTime;
					}
					if (opts.oldDriver.beginTripTime != undefined) {
						updateOnlineDriverObj.beginTripTime = opts.oldDriver.beginTripTime;
					}
					if (opts.oldDriver.bookingFlag != undefined && opts.oldDriver.bookingFlag !== "") {
						updateOnlineDriverObj.bookingFlag = opts.oldDriver.bookingFlag;
					}
					if (opts.oldDriver.totalApproxTime != undefined) {
						updateOnlineDriverObj.totalApproxTime = opts.oldDriver.totalApproxTime;
					}

					this.setDriverList({
						'updateDriverData': updateOnlineDriverObj,
						'driverId': opts.oldDriver.driverId,
						'isReloadVehicleList': true,
						'isReloadZoneWiseDriver': true,
					});


					if (opts.oldDriver.driverStatus <= 0) {
						this.busyDriverCount -= 1;
						this.freeDriverCount += 1;
					}

					this.updateDriverMarker(opts.oldDriver.driverId);
				}
			}
			else if (opts.statusFlag == this.Global.statusFlags['DISPATCHER_ADD_STICKY_NOTE'] || opts.statusFlag == this.Global.statusFlags['DISPATCHER_EDIT_STICKY_NOTE'] || opts.statusFlag == this.Global.statusFlags['DISPATCHER_DELETE_STICKY_NOTE']) {
				this.shareData.shareData({ 'function': 'mqttShareToSticky', 'mqttData': opts });
			}
			else if (opts.statusFlag == this.Global.statusFlags['BOOKING_EDIT']) {
				if (this.isOpenBookingTab == 'unassign') {
					this.updateUnassignBooking(opts.bookingId);
					this.addUnassignData(opts.assignTime, opts.bookingId);
				} else if (this.isOpenBookingTab == 'assign') {
					if (this.isOpentabBookingIds.includes(opts.bookingId)) {
						this.getAssignBooking(this.unassignBookingCurrentpage, this.Global.OnEventShowShimmer == true ? 0 : 1);
					}
				} else if (this.isOpenBookingTab == 'active') {
					if (this.isOpentabBookingIds.includes(opts.bookingId)) {
						console.log('****** BOOKING_EDIT this.activeBookingCurrentpage ******', this.activeBookingCurrentpage);

						this.getActiveBooking(this.activeBookingCurrentpage, this.Global.OnEventShowShimmer == true ? 0 : 1);
					}
				}

				if (this.selectedBooking != {} && this.selectedBooking.bookingId == opts.bookingId) {
					this.clearEditBooking();
					this.selectedBooking = {};
					this.forceFullyCompletedDialog = false;

				}

				this.setDriverList({
					'updateDriverData': { 'locationJson': opts.locationJson },
					'driverId': opts.driverId,
					'isReloadVehicleList': true,
					'isReloadZoneWiseDriver': true,
				});
			}
			else if (opts.statusFlag == this.Global.statusFlags['DRIVER_STOP_ARRIVED_TRIP']) {
				if (this.isOpenBookingTab == 'active') {
					if (this.isOpentabBookingIds.includes(opts.bookingId)) {
						this.activeBookings = this.updateArrayWithObject(this.activeBookings, ['bookingId', opts.bookingId], { 'locationJson': opts.locationJson });
					}
				}

				if (this.selectedBooking != {} && this.selectedBooking.bookingId == opts.bookingId) {
					this.clearEditBooking();
					this.selectedBooking = {};
					this.forceFullyCompletedDialog = false;

				}

				if (opts.driverId != undefined && opts.driverId != "") {
					this.setDriversZone(opts.driverId, opts.zoneId)
				}
			}
			else if (opts.statusFlag == this.Global.statusFlags['DISPATCHER_SEND_MSG'] || opts.statusFlag == this.Global.statusFlags['DISPATCHER_SEND_MESSAGE'] || opts.statusFlag == this.Global.statusFlags['DRIVER_SEND_MESSAGE']) {
				this.shareData.shareData({ 'function': 'mqttShareToChat', 'mqttData': opts });
			}
			else if (opts.statusFlag == this.Global.statusFlags['DRIVER_SET_CURRENT_CAR'] || opts.statusFlag == this.Global.statusFlags['DRIVER_EDIT_CURRENT_CAR'] || opts.statusFlag == this.Global.statusFlags['DRIVER_SET_PREFERENCES']) {
				let updateOnlineDriverObj: any = {};
				if (opts.carTypeName != undefined && opts.carTypeName != '') {
					updateOnlineDriverObj.carTypeName = opts.carTypeName;
				}
				if (opts.carId != undefined && opts.carId != '') {
					updateOnlineDriverObj.carType = opts.carId;
				}

				updateOnlineDriverObj.preferences = this.setDriverPreferences({
					'driver_car_type_name_preferences': opts.driverCarTypeNamePreferences,
					'driver_car_type_name_preferences_delivery': opts.driverCarTypeNamePreferencesDelivery,
					'driver_car_type_name_preferences_airport': opts.driverCarTypeNamePreferenceAirport,
					'driver_car_type_name_preferences_outStation': opts.driverCarTypeNamePreferencesOutStation,
					'driver_car_type_name_preferences_rental': opts.driverCarTypeNamePreferencesRental,
					'driver_car_type_name_preferences_restaurant': opts.drivercarTypeNamePreferencesRestaurant,
				});

				this.setDriverList({
					'updateDriverData': updateOnlineDriverObj,
					'driverId': opts.driverId,
					'isReloadVehicleList': true,
					'isReloadZoneWiseDriver': true,
					'nearestDriverPreference': true,
				});

				if (opts.statusFlag != 21) {
					this.setDriversZone(opts.driverId, opts.zoneId)
				}
			}
			else if (opts.statusFlag == this.Global.statusFlags['DRIVER_ZONE_LOCATION']) {
				if (opts.driverId != undefined && opts.driverId != "" && opts.zoneId != undefined) {
					if (this.Global.isSetDriverLimit == false || (this.Global.isSetDriverLimit == true && this.nearestDriverIds.includes(opts.driverId))) {
						this.setDriversZone(opts.driverId, opts.zoneId);
					}
				}
			}
			else if (opts.statusFlag == this.Global.statusFlags['SYSTEM_SWITCH_OFF_DRIVER_ALERT']) {
				if (this.Global.manageAlertPopup.offlineDriverWarning == true) {
					this.Popup.toasterMessage({
						'status': 'error',
						'title': opts.driverName,
						'message': 'has received offline warning number' + opts.popupNumber,
					});
				}

				let updateOnlineDriverObj: any = {
					'driverWarningPopupNumber': opts.popupNumber,
				};
				this.setDriverList({
					'updateDriverData': updateOnlineDriverObj,
					'driverId': opts.driverId,
				});
				this.shareData.shareData({ function: 'driverAlertMqtt', 'data': { 'statusFlag': opts.statusFlag } })

			}
			else if (opts.statusFlag == this.Global.statusFlags['DRIVER_REPAIR']) {

				let updateOnlineDriverObj: any = {
					'driverWarningPopupNumber': opts.popupNumber,
				};
				this.setDriverList({
					'updateDriverData': updateOnlineDriverObj,
					'driverId': opts.driverId,
				});

			}
			else if (opts.statusFlag == this.Global.statusFlags['SURGE_MULTIPLIER']) {
				let selectedZoneFilterOption = this.Global.getLocalStorage({ key: 'zoneListFilterFormValue' });
				if (selectedZoneFilterOption == undefined || selectedZoneFilterOption.setDriverToZone.length < 0 || selectedZoneFilterOption.setDriverToZone[0].itemId != 1) {
					this.isSurgeZoneChecked = true;
					this.getZoneDetails('zoneList').then((res: any) => {
						if (res.status) {
							this.setZoneWiseDriver_new(this.onlineDriverList);
						}
					});
				}
			}
			else if (opts.statusFlag == this.Global.statusFlags['DISPATCHER_UPDATE_ROLE']) {
				if (opts.dispatcherId == undefined || (opts.dispatcherId != undefined && opts.dispatcherId == this.Global.dispatcherDetails.dispatcherId)) {
					this.dispatcherRole({
						'dispatcherId': this.Global.dispatcherDetails.dispatcherId
					});
				}
			}
			else if (opts.statusFlag == this.Global.statusFlags['DRIVER_CANCEL_TO_MANUAL']) {
				if (this.Global.audioObj.bookingAlert.driverCancelBooking == true) {
					this.setAlertData();
				}

				if (this.isOpenBookingTab == 'unassign') {
					this.addUnassignData(opts.assignTime, opts.bookingId);
				}
				else if (this.isOpenBookingTab == 'active' && (opts.status == 3 || opts.status == 7 || opts.status == 8)) {
					this.removeActiveData(opts.assignTime, opts.bookingId);
				}

				let userJson = opts.userJson != undefined && opts.userJson.length != 0 ? opts.userJson : []
				let updateOnlineDriverObj: any = {
					'bookingStatusFlag': opts.driverStatus,
					'freeTime': opts.freeTime,
					'jobs': opts.jobs,
					'isDriverAssignBooking': opts.isDriverAssignBooking,
					'slideToComplete': opts.slidedToComplete,
					'locationJson': opts.locationJson,
					'userJson': userJson,
					'zoneId': opts.zoneId
				}

				if (opts.pickupTripTime != undefined) {
					updateOnlineDriverObj.pickupTripTime = opts.pickupTripTime;
				}
				if (opts.acceptedTripTime != undefined) {
					updateOnlineDriverObj.acceptedTripTime = opts.acceptedTripTime;
				}
				if (opts.arrivedTripTime != undefined) {
					updateOnlineDriverObj.arrivedTripTime = opts.arrivedTripTime;
				}
				if (opts.beginTripTime != undefined) {
					updateOnlineDriverObj.beginTripTime = opts.beginTripTime;
				}
				if (opts.bookingFlag != undefined && opts.bookingFlag !== "") {
					updateOnlineDriverObj.bookingFlag = opts.bookingFlag;
				}
				if (opts.totalApproxTime != undefined) {
					updateOnlineDriverObj.totalApproxTime = opts.totalApproxTime;
				}

				if (opts.driverId != undefined && opts.driverId != '') {
					this.setDriverList({
						'updateDriverData': updateOnlineDriverObj,
						'driverId': opts.driverId,
						'isReloadVehicleList': true,
						'isReloadZoneWiseDriver': true,
					});
					this.updateDriverMarker(opts.driverId);
				}
			}
			else if (opts.statusFlag == this.Global.statusFlags['DRIVER_CANCEL_TO_AUTOMATIC']) {
				if (this.Global.audioObj.bookingAlert.driverCancelBooking == true) {
					this.setAlertData();
				}
				if (this.isOpenBookingTab == 'unassign') {
					this.addUnassignData(opts.assignTime, opts.bookingId);
				}

				if (this.isOpenBookingTab == 'assign') {
					this.addAssignData(opts.assignTime, opts.bookingId);
				}
				else if (this.isOpenBookingTab == 'active' && (opts.status == 3 || opts.status == 7 || opts.status == 8)) {
					this.removeActiveData(opts.assignTime, opts.bookingId);
				}

				let userJson = opts.userJson != undefined && opts.userJson.length != 0 ? opts.userJson : []
				let updateOnlineDriverObj: any = {
					'bookingStatusFlag': opts.driverStatus,
					'freeTime': opts.freeTime,
					'jobs': opts.jobs,
					'isDriverAssignBooking': opts.isDriverAssignBooking,
					'slideToComplete': opts.slidedToComplete,
					'locationJson': opts.locationJson,
					'userJson': userJson,
					'zoneId': opts.zoneId
				}

				if (opts.pickupTripTime != undefined) {
					updateOnlineDriverObj.pickupTripTime = opts.pickupTripTime;
				}
				if (opts.acceptedTripTime != undefined) {
					updateOnlineDriverObj.acceptedTripTime = opts.acceptedTripTime;
				}
				if (opts.arrivedTripTime != undefined) {
					updateOnlineDriverObj.arrivedTripTime = opts.arrivedTripTime;
				}
				if (opts.beginTripTime != undefined) {
					updateOnlineDriverObj.beginTripTime = opts.beginTripTime;
				}
				if (opts.bookingFlag != undefined && opts.bookingFlag !== "") {
					updateOnlineDriverObj.bookingFlag = opts.bookingFlag;
				}
				if (opts.totalApproxTime != undefined) {
					updateOnlineDriverObj.totalApproxTime = opts.totalApproxTime;
				}

				if (opts.driverId != undefined && opts.driverId != '') {
					this.setDriverList({
						'updateDriverData': updateOnlineDriverObj,
						'driverId': opts.driverId,
						'isReloadVehicleList': true,
						'isReloadZoneWiseDriver': true,
					});
					this.updateDriverMarker(opts.driverId);
				}
			}
			else if (opts.statusFlag == this.Global.statusFlags['DRIVER_SET_GO_HOME']) {
				this.setDriverList({
					'updateDriverData': { 'goHome': opts.goHome },
					'driverId': opts.driverId,
					'isReloadVehicleList': true,
				});
			}
			else if (opts.statusFlag == this.Global.statusFlags['DRIVER_SET_BACK_TO_BACK']) {

				console.log(">>>>>>>>>>>B2B<<<<<<<<<<<<", this.driverDetail);
				this.setDriverList({
					'updateDriverData': { 'isOnBackToBack': opts.backToBack },
					'driverId': opts.driverId,
					'isReloadVehicleList': true,
				});
				// this.updateArrayWithObject(this.driverDetail, ['driverId', opts.driverId], { backToBackRide: opts.backToBack });
				// this.driverDetail['backToBackRide'] == opts.backToBack
				this.driverDetail.backToBackRide = opts.backToBack
				console.log(">>>>>>>>>>>B2B 2222<<<<<<<<<<<<", this.driverDetail);

			}
			else if (opts.statusFlag == this.Global.statusFlags['ACCEPT_CREATED_BOOKING']) {
				console.log(" ACCEPT_CREATED_BOOKING this.createNotifyBookingIds:", this.createNotifyBookingIds);
				// if(opts.status == 12 && opts.bookingType == 1)
				// {
				this.addUnassignData(opts.assignTime, opts.bookingId);
				this.removeCreatedBookingNotify(opts.bookingId);
				// this.closeCreatedPopUp();
				// }
			}
			else if (opts.statusFlag == this.Global.statusFlags['USER_SEND_MESSAGE_DISPATCHER']) {
				this.isshortChatBubble = true
				this.userChatBubble = opts.chats[0]
				console.log('***** this.userChatBubble *****', this.userChatBubble);
			}
			else if (opts.statusFlag == this.Global.statusFlags['DRIVER_BREAK_ON']) {

				console.log('****** this.Global.statusFlags[DRIVER_BREAK_ON] ******', opts);

				console.log('****** DRIVER_BREAK_ON totalDriverBreakCount ******', this.totalDriverBreakCount);


				let updateOnlineDriverObj: any = {
					'breakCount': opts.driverData.breakCount,
					'onTimeBreak': moment(this.Global.getCompanyCurrentTime(), this.Global.dateFormat).format('YYYY-MM-DD HH:mm:ss'),
					'isDriverOnBreak': opts.driverData.isDriverOnBreak,
					'offTimeBreak': opts.driverData.offTimeBreak,
					'driverStatus': opts.driverData.driverStatus,
					'bookingStatusFlag': opts.driverData.bookingStatusFlag,
					'freeTime': opts.driverData.freeTime,
					'zoneId': opts.driverData.zoneId
				}

				console.log('--------------------- DRIVER_BREAK_ON updateOnlineDriverObj ---------------------', updateOnlineDriverObj)

				if (opts.driverData.pickupTripTim) {
					updateOnlineDriverObj.pickupTripTime = opts.driverData.pickupTripTime;
				}
				if (opts.driverData.acceptedTripTim) {
					updateOnlineDriverObj.acceptedTripTime = opts.driverData.acceptedTripTime;
				}
				if (opts.driverData.arrivedTripTim) {
					updateOnlineDriverObj.arrivedTripTime = opts.driverData.arrivedTripTime;
				}
				if (opts.driverData.beginTripTim) {
					updateOnlineDriverObj.beginTripTime = opts.driverData.beginTripTime;
				}
				if (opts.driverData.bookingFlag && opts.driverData.bookingFlag !== "") {
					updateOnlineDriverObj.bookingFlag = opts.driverData.bookingFlag;
				}
				if (opts.driverData.totalApproxTim) {
					updateOnlineDriverObj.totalApproxTime = opts.driverData.totalApproxTime;
				}

				if (opts.driverData.driverId && opts.driverData.driverId != '') {
					this.setDriverList({
						'updateDriverData': updateOnlineDriverObj,
						'driverId': opts.driverData.driverId,
						'isReloadVehicleList': true,
						'isReloadZoneWiseDriver': true,
					});
					this.updateDriverMarker(opts.driverData.driverId);
				}

				this.Popup.toasterMessage({
					'status': 'warning',
					'title': opts.driverData.driverName + ' went for break',
					'message': '',
				});

				this.totalDriverBreakCount = opts.totalDriverBreakCount
				localStorage.setItem('totalDriverBreakCount', opts.totalDriverBreakCount)

				this.shareData.shareData({ function: 'driverAlertMqtt', 'data': { 'statusFlag': opts.statusFlag, 'driverName': opts.driverData.driverName } })

				this.shareData.shareData({ function: 'driverBreakAlertMqtt', 'data': 1 })

			}
			else if (opts.statusFlag == this.Global.statusFlags['DRIVER_BREAK_OFF']) {

				this.totalDriverBreakCount = opts.totalDriverBreakCount
				console.log('****** DRIVER_BREAK_OFF totalDriverBreakCount ******', this.totalDriverBreakCount);
				localStorage.setItem('totalDriverBreakCount', opts.totalDriverBreakCount)

				let updateOnlineDriverObj: any = {
					'breakCount': opts.driverData.breakCount,
					'onTimeBreak': opts.driverData.onTimeBreak,
					'isDriverOnBreak': opts.driverData.isDriverOnBreak,
					'offTimeBreak': opts.driverData.offTimeBreak,
					'driverStatus': opts.driverData.driverStatus,
					'bookingStatusFlag': opts.driverData.bookingStatusFlag,
					'freeTime': opts.driverData.freeTime,
					'zoneId': opts.driverData.zoneId
				}


				if (opts.driverData.pickupTripTim) {
					updateOnlineDriverObj.pickupTripTime = opts.driverData.pickupTripTime;
				}
				if (opts.driverData.acceptedTripTim) {
					updateOnlineDriverObj.acceptedTripTime = opts.driverData.acceptedTripTime;
				}
				if (opts.driverData.arrivedTripTim) {
					updateOnlineDriverObj.arrivedTripTime = opts.driverData.arrivedTripTime;
				}
				if (opts.driverData.beginTripTim) {
					updateOnlineDriverObj.beginTripTime = opts.driverData.beginTripTime;
				}
				if (opts.driverData.bookingFlag && opts.driverData.bookingFlag !== "") {
					updateOnlineDriverObj.bookingFlag = opts.driverData.bookingFlag;
				}
				if (opts.driverData.totalApproxTim) {
					updateOnlineDriverObj.totalApproxTime = opts.driverData.totalApproxTime;
				}


				if (opts.driverData.driverId && opts.driverData.driverId != '') {
					this.setDriverList({
						'updateDriverData': updateOnlineDriverObj,
						'driverId': opts.driverData.driverId,
						'isReloadVehicleList': true,
						'isReloadZoneWiseDriver': true,
					});
					this.updateDriverMarker(opts.driverData.driverId);
				}

				if (this.Global.manageAlertPopup.offlineDriver == true) {
					this.Popup.toasterMessage({
						'status': 'warning',
						'title': opts.driverData.driverName + ' has been released from break',
						'message': '',
					});
				}

				this.shareData.shareData({ function: 'driverBreakAlertMqtt', 'data': 1 })


				this.shareData.shareData({ function: 'driverAlertMqtt', 'data': { 'statusFlag': opts.statusFlag, 'driverName': opts.driverData.driverName } })

			}
			else if (opts.statusFlag == this.Global.statusFlags['INTERNATIONAL_ALERT']) {
				this.setInternationalAlert();
			}
		}
	}

	removeCreatedBookingNotify(bookingIds: any) {
		console.log('***** bookingIds *****', bookingIds);
		console.log('***** this.createBookingNotifyData *****', this.createBookingNotifyData);
		let getIndex = this.createBookingNotifyData.findIndex(((bookingId: any) => bookingId.bookingId == bookingIds));
		console.log("getIndex :: ", getIndex);
		if (getIndex != -1) {
			this.createBookingNotifyData.splice(getIndex, 1);
			this.createNotifyBookingIds.splice(getIndex, 1);
			this.totalUnAprroveCount -= 1
			console.log('***** this.totalUnAprroveCount *****', this.totalUnAprroveCount);
		}
		else {
			if (this.createBookingNotifyData.length == 1) {
				this.getAllUnApproveList(this.unApproveListParams)
			}
			else {

				let req = {
					limit: this.pageSize,
					offset: (this.pageSize * this.pageIndex),
				}
				this.getAllUnApproveList(req)
			}
		}

	}

	closeCreatedPopUp() {
		console.log("this.createNotifyBookingIds.length", this.createNotifyBookingIds.length);
		if (this.createNotifyBookingIds.length == 0) {
			let booking_btn = $("#bookingPopUp .icon");
			let booking_box = $("#bookingPopUp .messenger");
			booking_btn.removeClass("expanded");
			setTimeout(() => {
				booking_box.removeClass("expanded");
				this.isExpand = false;
			}, 100);
		}
	}

	dispatcherRole(opts: any) {
		this.CallMethod.getDispatcherRoles(opts).subscribe((result: any) => {
			if (result.status) {
				this.Global.setRoleJson(result.dispatcherRoleJson);
				this.Global.setLocalStorage({
					'key': 'dispatcherRoleName',
					'value': result.dispatcherRoleName
				});
			}
		});
	}
	//!------------------------ un assign, assign and active tab start------------------------
	unassignFilterCount = 0;
	activeFilterCount = 0;
	unassignFilterSearchInput: string = "";
	activeFiltersearchInput: string = "";
	appliedFilters(tab: string) {
		let formValue;
		let search;
		let filterCount = 0;
		let request: any = {};

		if (tab == 'unassign') {
			formValue = this.unAssignFilterDataForm.value;
			search = this.unassignFilterSearchInput;
			this.unassignFilterCount = 0;
			filterCount = this.unassignFilterCount;
		} else if (tab == 'active') {
			formValue = this.activeFilterDataForm.value;

			search = this.activeFiltersearchInput;
			this.activeFilterCount = 0;
			filterCount = this.activeFilterCount;
		} else {
			this.Popup.toasterMessage({
				'status': 'error',
				'title': ' please select valid tab',
				'message': '',
			});
			return false;
		}

		request['search'] = search;
		formValue.search = search != null ? search : '';

		let searchBy = formValue.searchBy;
		filterCount = searchBy != null && searchBy.length > 0 ? ++filterCount : filterCount;
		if (searchBy != null && searchBy.length > 0 && searchBy.length != this.filterSearchBy.length) {
			let searchByArr: any = {};
			for (let i = 0; i < searchBy.length; i++) {
				let text = this.makeKeyStringForRequest(searchBy[i].itemText)
				searchByArr[text] = searchBy[i].itemId
			}
			request['searchBy'] = searchByArr;

		}
		else {
			request['searchBy'] = {};
		}

		let bookingType = formValue.bookingType;
		filterCount = bookingType != null && bookingType.length > 0 ? ++filterCount : filterCount;
		if (bookingType != null && bookingType.length > 0 && bookingType.length != this.filterBookingType.length) {
			for (let i = 0; i < bookingType.length; i++) {
				request['isLater'] = bookingType[i].itemId;
			}
		}
		else {
			request['isLater'] = 2;
		}

		let status = formValue.filterStatus;
		filterCount = status != null && status.length > 0 ? ++filterCount : filterCount;

		if (status != null && status.length > 0 && status.length != this.filterStatus.length) {
			let statusArr: any = {};
			for (let i = 0; i < status.length; i++) {
				let text = this.makeKeyStringForRequest(status[i].itemText)
				statusArr[text] = status[i].itemId
			}
			request['status'] = statusArr;
		}
		else {
			request['status'] = {};
		}

		let bookingFlag = formValue.bookingFlag;
		filterCount = bookingFlag != null && bookingFlag.length > 0 ? ++filterCount : filterCount;
		if (bookingFlag != null && bookingFlag.length > 0 && bookingFlag.length != this.filterBookingFlag.length) {
			let bookingFlagArr: any = {};
			for (let i = 0; i < bookingFlag.length; i++) {
				let text = this.makeKeyStringForRequest(bookingFlag[i].itemText)
				if (bookingFlag[i].itemId == 3) {
					request['isRestaurant'] = 1;
				} else {
					bookingFlagArr[text] = bookingFlag[i].itemId;
					request['isRestaurant'] = 0;
				}
			}
			request['bookingFlag'] = bookingFlagArr;
		}
		else {
			request['bookingFlag'] = {};
		}

		let paymentType = formValue.paymentType;
		filterCount = paymentType != null && paymentType.length > 0 ? ++filterCount : filterCount;
		if (paymentType != null && paymentType.length > 0 && paymentType.length != this.filterPaymentType.length) {
			let paymentTypeArr: any = {};
			for (let i = 0; i < paymentType.length; i++) {
				let text = this.makeKeyStringForRequest(paymentType[i].itemText)
				paymentTypeArr[text] = paymentType[i].itemId
			}
			request['paymentType'] = paymentTypeArr;
		}
		else {
			request['paymentType'] = {};
		}

		let surgeMultiplier = formValue.surgeMultiplier;
		filterCount = surgeMultiplier != null && surgeMultiplier.length > 0 ? ++filterCount : filterCount;
		if (surgeMultiplier != null && surgeMultiplier.length > 0 && surgeMultiplier.length != this.filterBySurge.length) {
			let surgeMultiplierArr: any = {};
			for (let i = 0; i < surgeMultiplier.length; i++) {
				let text = this.makeKeyStringForRequest(surgeMultiplier[i].itemText)
				surgeMultiplierArr[text] = surgeMultiplier[i].itemId
			}
			request['surgeMultiplier'] = surgeMultiplierArr;
		}
		else {
			request['surgeMultiplier'] = {};
		}

		let carType = formValue.carType;
		filterCount = carType != null && carType.length > 0 ? ++filterCount : filterCount;
		if (carType != null && carType.length > 0 && carType.length != this.filterCarType.length) {
			let carTypeArr: any = {};
			for (let i = 0; i < carType.length; i++) {
				let text = this.makeKeyStringForRequest(carType[i].itemText)
				carTypeArr[text] = carType[i].itemId
			}
			request['carType'] = carTypeArr;
		}
		else {
			request['carType'] = {};
		}

		let dateTime = formValue.dateTime;
		filterCount = dateTime != null && dateTime.length > 0 ? ++filterCount : filterCount;
		if (dateTime != null && dateTime.length > 0) {
			request['dateTimeType'] = dateTime[0].itemId
			this.dateTimeType = dateTime[0].itemId;
			if (dateTime[0].itemId != 0) {
				this.startDate == "" ? formValue.startDate : ''
				if (this.startDate == "") {
					this.Popup.toasterMessage({
						'status': 'error',
						'title': ' please select date',
						'message': '',
					});
					return false;
				} else {
					request['startDate'] = this.startDate;
					formValue.startDate = this.startDate;
					if (dateTime[0].itemId == 3) {
						request['endDate'] = this.endDate;
						formValue.endDate = this.endDate;
					}
				}

			}
		} else {
			request['dateTimeType'] = 0;
			this.dateTimeType = 0;
		}

		let timeColumn = formValue.timeColumn;
		if (timeColumn != null && timeColumn.length > 0) {
			request['dateTimeFilterBy'] = timeColumn[0].itemId
			++filterCount
		} else {
			request['dateTimeFilterBy'] = 'pickupTime'
		}

		let ascDescType = formValue.ascDescType;
		if (ascDescType != null && ascDescType.length > 0) {
			request['dateTimeFilterOrderBy'] = ascDescType[0].itemId;
			++filterCount
		} else {
			request['dateTimeFilterOrderBy'] = -1
		}

		if (tab == 'unassign') {
			this.getUnassignBooking(0, this.Global.OnEventShowShimmer == true ? 0 : 1, request);
			this.Global.setLocalStorage({ key: 'UnassignfilterCount', value: filterCount });
			this.Global.setLocalStorage({ key: 'UnassignfilterFormValue', value: formValue });
			this.unassignFilterCount = filterCount;
		} else if (tab == 'active') {
			this.getActiveBooking(0, this.Global.OnEventShowShimmer == true ? 0 : 1, request);
			this.Global.setLocalStorage({ key: 'activeFilterCount', value: filterCount });
			this.Global.setLocalStorage({ key: 'activeFilterFormValue', value: formValue });
			this.activeFilterCount = filterCount;
		}

		return true;
	}
	bookingfilterContextMenu(FilterTabName: string) {
		this.bookingFilterMenu = true;
		let formvalue = this.Global.getLocalStorage({ key: 'UnassignfilterFormValue' });
		if (FilterTabName == 'active') {
			formvalue = this.Global.getLocalStorage({ key: 'activeFilterFormValue' });
		}
		if (formvalue != undefined) {
			let setFormValue = {
				ascDescType: formvalue.ascDescType != null ? formvalue.ascDescType : [],
				bookingFlag: formvalue.bookingFlag != null ? formvalue.bookingFlag : [],
				bookingType: formvalue.bookingType != null ? formvalue.bookingType : [],
				carType: formvalue.carType != null ? formvalue.carType : [],
				dateTime: formvalue.dateTime != null ? formvalue.dateTime : [],
				filterStatus: formvalue.filterStatus != null ? formvalue.filterStatus : [],
				paymentType: formvalue.paymentType != null ? formvalue.paymentType : [],
				searchBy: formvalue.searchBy != null ? formvalue.searchBy : [],
				timeColumn: formvalue.timeColumn != null ? formvalue.timeColumn : [],
				surgeMultiplier: formvalue.surgeMultiplier != null ? formvalue.surgeMultiplier : [],
			}
			if (FilterTabName == 'unassign') {
				this.unAssignFilterDataForm.setValue(setFormValue);
				this.unassignFilterSearchInput = formvalue.search != null ? formvalue.search : ''
			} else {
				this.activeFilterDataForm.setValue(setFormValue);
				this.activeFiltersearchInput = formvalue.search != null ? formvalue.search : '';
			}
			if (formvalue.dateTime != null && formvalue.dateTime.length > 0) {
				this.dateTimeType = formvalue.dateTime[0].itemId;
				if (this.dateTimeType != 0 && formvalue.startDate != undefined && formvalue.startDate != null) {
					if (this.dateTimeType == 1 || this.dateTimeType == 2) {
						this.beforeOrAfterDateSelect = { start: moment(formvalue.startDate), end: moment(formvalue.startDate) };
						this.startDate = formvalue.startDate;
					} else {
						if (this.dateTimeType == 3 && formvalue.endDate != undefined && formvalue.endDate != null) {
							this.selectedDateRange = { start: moment(formvalue.startDate), end: moment(formvalue.endDate) }
							this.startDate = formvalue.startDate;
							this.endDate = formvalue.endDate;
						}
					}
				}
			}
		}
	}
	isOpenBookingTab: string = 'unassign';
	isOpentabBookingIds: any = [];
	isOpentabBookingsCompareAssignTime: any = [];
	unassign_assign_active_tab(event: any) {
		if (event.index == 0) {
			this.isOpenBookingTab = 'unassign';
			this.getUnassignBooking(0, this.Global.OnEventShowShimmer == true ? 0 : 1).then(() => {
				if (this.Global.isSetDriverLimit == true) {
					let center: any = JSON.parse(JSON.stringify(this.map.getCenter()));
					this.reloadMapData({
						'location': [center.lat, center.lng]
					});
				}
			});
		}
		else if (event.index == 1) {
			this.isOpenBookingTab = 'assign';
			this.getAssignBooking(0, this.Global.OnEventShowShimmer == true ? 0 : 1).then(() => {
				// if (this.Global.isSetDriverLimit == true) {
				// let center: any = JSON.parse(JSON.stringify(this.map.getCenter()));
				// this.reloadMapData({
				// 	'location': [center.lat, center.lng]
				// });
				// }
			});
		}
		else if (event.index == 2) {
			this.isOpenBookingTab = 'active';
			this.getActiveBooking(0, this.Global.OnEventShowShimmer == true ? 0 : 1).then((res) => {
				// if (this.Global.isSetDriverLimit == true) {
				// 	let center: any = JSON.parse(JSON.stringify(this.map.getCenter()));
				// 	this.reloadMapData({'location': [center.lat, center.lng]});
				// }
			});
		}
	}
	//***************** unassign booking start ********************	
	UnassignBookingLimit: number = 10;
	unassignBookingCurrentpage: number = 0;
	isUnassignBookingShimmer: boolean = false;
	unassignBookings: any = [];
	totalUnassignBooking: number = 0;
	getUnassignBooking(page: any, loadShimmer: number = 0, requestObj: any = {}) {
		return new Promise((resolve) => {
			page = (page >= 0) ? page : 0;
			this.isUnassignBookingShimmer = (loadShimmer) ? true : false;

			let totalPage = Math.ceil(this.totalUnassignBooking / this.UnassignBookingLimit);
			if (totalPage < page) {
				page = totalPage;
			}

			this.unassignBookingCurrentpage = page;

			let unassignBookingOffset = (page == 0) ? 0 : ((page * this.UnassignBookingLimit) - this.UnassignBookingLimit);
			let passObj: any = {};
			if (Object.keys(requestObj).length == 0) {
				let req = this.Global.getLocalStorage({ key: 'UnassignfilterRequest' });

				if (req == undefined) {
					passObj = {
						'bookingType': 1,
						'limit': this.UnassignBookingLimit,
						'offset': unassignBookingOffset
					}
				} else {
					passObj = req;
					passObj.limit = this.UnassignBookingLimit;
					passObj.offset = unassignBookingOffset;
					passObj.bookingType = 1;
				}
			} else {
				passObj = requestObj;
				passObj.limit = this.UnassignBookingLimit;
				passObj.offset = unassignBookingOffset;
				passObj.bookingType = 1;

			}

			this.CallMethod.get_Unassign_Assign_Active_Booking(passObj).pipe(takeUntil(this.unsubscribe$)).subscribe((result: any) => {
				console.log("===== Get unassign bookings :: ", result);
				console.log('***** result.data *****', result.data);
				if (result.status == true) {
					this.Global.setLocalStorage({ key: 'UnassignfilterRequest', value: passObj });
					this.setUnassignData(result.data);
					this.totalUnassignBooking = (result.totalRecords) ? result.totalRecords : 0;
					console.log("this.totalUnassignBooking:::::", this.totalUnassignBooking);

				}
				this.isUnassignBookingShimmer = true;

				return resolve(true);
			});
		});
	}
	setUnassignData(unassignBooking: any) {
		console.log('***** unassignBooking *****', unassignBooking);
		this.unassignBookings = [];
		this.isOpentabBookingIds = [];
		this.isOpentabBookingsCompareAssignTime = [];
		if (unassignBooking.length > 0) {
			this.isOpentabBookingsCompareAssignTime.push(unassignBooking[0].assignTime);
			this.isOpentabBookingsCompareAssignTime.push(unassignBooking[unassignBooking.length - 1].assignTime);

			for (let i = 0; i < unassignBooking.length; i++) {

				let locationJson = [];

				if (unassignBooking[i].locationJson && unassignBooking[i].locationJson != '') {
					try {
						unassignBooking[i].locationJson = JSON.parse(unassignBooking[i].locationJson)
					} catch {
						unassignBooking[i].locationJson = unassignBooking[i].locationJson
					}
				}
				if (unassignBooking[i].is_destination_later == 1) {
					locationJson.push(unassignBooking[i].locationJson[0]);
					locationJson.push({
						"lat": 0,
						"long": 0,
						"address": "Destination later"
					});
				}
				else {
					locationJson = unassignBooking[i].locationJson;
				}

				let distanceVariant = (unassignBooking[i].distanceVariant == 1) ? 'miles' : 'km';

				let driverComment = {};
				if (unassignBooking[i].driverComment != '') {
					if (typeof unassignBooking[i].driverComment != 'object') {
						driverComment = { 'Driver info': unassignBooking[i].driverComment }
					}
					else {
						driverComment = unassignBooking[i].driverComment
					}
				}

				let userComment = {};
				if (unassignBooking[i].userComment != '') {
					if (typeof unassignBooking[i].userComment != 'object') {
						userComment = { 'info': unassignBooking[i].userComment };
					}
					else {
						userComment = unassignBooking[i].userComment;
					}
				}

				let compairAlertTime = 'pickupTime';
				if (this.Global.calculateUnAssignBookingAlertTimeBasedOn == 'assignTime') {
					compairAlertTime = 'assignTime';
				}

				let setColor = 1;
				if (unassignBooking[i].status == 6) {
					setColor = 2;
				}
				else if (moment(this.Global.getCompanyCurrentTime(), this.Global.dateFormat).add(Number(this.Global.unassignBookingAlertTime), 'minutes').format('YYYY-MM-DD HH:mm:ss') >= unassignBooking[i][compairAlertTime]) {
					setColor = 2;
				}
				else if (moment(this.Global.getCompanyCurrentTime(), this.Global.dateFormat).isBetween(unassignBooking[i].assignTime, unassignBooking[i].pickupTime)) {
					setColor = 3;
				}

				this.isOpentabBookingIds.push(unassignBooking[i].bookingId);
				console.log('****** unassignBooking[i].isStreetPickup ******', unassignBooking[i].isStreetPickup);

				this.unassignBookings.push({
					'bookingId': unassignBooking[i].bookingId,
					'dBookId': unassignBooking[i].dBookId,
					'currency': unassignBooking[i].currency,
					'userJson': unassignBooking[i].userJson,
					'carTypeName': unassignBooking[i].taxiType,
					'locationJson': locationJson,
					'pickupTime': unassignBooking[i].pickupTime,
					'assignTime': unassignBooking[i].assignTime,
					'finalAmount': (unassignBooking[i].estimatedFinalAmount) ? ((Math.round(unassignBooking[i].estimatedFinalAmount * 1000) / 1000).toFixed(environment.priceRoundUpNumber)) : 0.00,
					'paymentType': (unassignBooking[i].paymentType) ? unassignBooking[i].paymentType : 0,
					'totalApproxKm': (unassignBooking[i].totalApproxKm) ? ((Math.round(unassignBooking[i].totalApproxKm * 100) / 100).toFixed(environment.roundUpNumber)) : 0.00,
					'totalApproxTime': (unassignBooking[i].totalApproxTime) ? ((Math.round(unassignBooking[i].totalApproxTime * 100) / 100).toFixed(environment.roundUpNumber)) : 0,
					'distanceVariant': distanceVariant,
					'info': userComment,
					'driverInfo': driverComment,
					'status': unassignBooking[i].status,
					'isRestaurant': unassignBooking[i].is_restaurant,
					'bookingFlag': unassignBooking[i].bookingFlag,
					'setColor': setColor,
					"driverId": unassignBooking[i].driverId,
					"zoneId": unassignBooking[i].zoneId,
					"accountId": unassignBooking[i].accountId,
					"taxiType": unassignBooking[i].taxiType,
					"carTypeId": unassignBooking[i].carTypeId,
					"timeType": unassignBooking[i].timeType,
					"bookingType": unassignBooking[i].bookingType,
					"userComment": unassignBooking[i].userComment,
					"driverComment": unassignBooking[i].driverComment,
					"baseFare": unassignBooking[i].baseFare,
					"tripDistancePrice": unassignBooking[i].tripDistancePrice,
					"tripTimePrice": unassignBooking[i].tripTimePrice,
					"totalPerson": unassignBooking[i].totalPerson,
					"handicappedTotal": unassignBooking[i].handicappedTotal,
					"priority": unassignBooking[i].priority,
					"flightNo": unassignBooking[i].flightNo,
					"roomNo": unassignBooking[i].roomNo,
					"estimatedAmount": unassignBooking[i].estimatedAmount,
					"estimatedTaxAmount": unassignBooking[i].estimatedTaxAmount,
					"estimatedFinalAmount": unassignBooking[i].estimatedFinalAmount,
					"estimatedDiscountAmount": unassignBooking[i].estimatedDiscountAmount,
					"couponDetails": unassignBooking[i].couponDetails,
					"otp": unassignBooking[i].otp,
					"tarrif": unassignBooking[i].tarrif,
					"carDetails": unassignBooking[i].carDetails,
					"slidedToComplete": unassignBooking[i].slidedToComplete,
					"is_destination_later": unassignBooking[i].is_destination_later,
					"driverAllowance": unassignBooking[i].driverAllowance,
					"nightAllowance": unassignBooking[i].nightAllowance,
					"is_restaurant": unassignBooking[i].is_restaurant,
					"stopPrice": unassignBooking[i].stopPrice,
					"surgeMultiplier": unassignBooking[i].surgeMultiplier,
					"totalBags": unassignBooking[i].totalBags,
					"isRepet": unassignBooking[i].isRepet,
					"repetTicks": unassignBooking[i].repetTicks,
					"isBox": unassignBooking[i].isBox ? unassignBooking[i].isBox : 0,
					'boxItems': unassignBooking[i].boxItem ? unassignBooking[i].boxItem : {},
					'taxJson': unassignBooking[i].taxJson,
					'carColor': unassignBooking[i].carColor,
					'estimatedExtraCharges': unassignBooking[i].estimatedExtraCharges,
					'actualExtraCharges': unassignBooking[i].actualExtraCharges,
					'extraChargesJson': unassignBooking[i].extraChargesJson,
					'toll': unassignBooking[i].toll,
					'serviceCharges': unassignBooking[i].serviceCharges,
					'airportParking': unassignBooking[i].airportParking,
					'priceFromAirportZone': unassignBooking[i].priceFromAirportZone ? unassignBooking[i].priceFromAirportZone : unassignBooking[i].isAirport,
					'isAirport': unassignBooking[i].isAirport,
					'isStreetPickup': unassignBooking[i].isStreetPickup,
				});
			}

			console.log("===== this.unassignBookings :: ", this.unassignBookings);

			this.checkUnassignBookingPassedTime();
		}
	}
	addUnassignData(assignTime: string, bookingId: string) {
		if (this.unassignFilterCount <= 0) {
			this.totalUnassignBooking += 1;
			// this.totalUnAprroveCount -= 1;
			console.log(" ADD this.totalUnassignBooking:::", this.totalUnassignBooking);
			if (
				(
					(this.unassignBookingCurrentpage == 1 || this.unassignBookingCurrentpage == 0)
					&& (
						(this.totalUnassignBooking - 1) < this.UnassignBookingLimit
						|| (
							this.isOpentabBookingsCompareAssignTime.length != 0
							&& this.isOpentabBookingsCompareAssignTime[1] >= assignTime
						)
					)
				)
				|| ((((this.totalUnassignBooking - 1) / this.unassignBookingCurrentpage) < this.UnassignBookingLimit) && this.isOpentabBookingsCompareAssignTime[1] <= assignTime)
				|| moment(assignTime).isBetween(this.isOpentabBookingsCompareAssignTime[0], this.isOpentabBookingsCompareAssignTime[1])
			) {
				this.getUnassignBooking(this.unassignBookingCurrentpage, this.Global.OnEventShowShimmer == true ? 0 : 1);
			}
			else {
				if (
					(((this.totalUnassignBooking - 1) / this.unassignBookingCurrentpage) != this.UnassignBookingLimit && this.isOpentabBookingsCompareAssignTime[1] > assignTime)
					|| (((this.totalUnassignBooking - 1) / this.unassignBookingCurrentpage) == this.UnassignBookingLimit && this.isOpentabBookingsCompareAssignTime[1] < assignTime)
				) { }
				else {
					let page = Math.ceil((this.totalUnassignBooking - 1) / this.UnassignBookingLimit);
					if (this.unassignBookingCurrentpage == page) {
						this.unassignBookingCurrentpage = page + 1;
					}
				}
			}
		}
	}
	removeUnassignData(assignTime: string, bookingId: string) {

		console.log("bookingId::", bookingId);

		console.log(" remove this.totalUnassignBooking:::", this.totalUnassignBooking);
		if (this.unassignFilterCount <= 0 && this.isOpentabBookingIds.includes(bookingId)) {
			this.totalUnassignBooking -= 1;
			console.log("if remove this.totalUnassignBooking:::", this.totalUnassignBooking);
			let page = Math.ceil((this.totalUnassignBooking) / this.UnassignBookingLimit);

			console.log("remove page::::", page);
			if (this.isOpentabBookingIds.includes(bookingId)) {
				if (
					(
						(
							this.unassignBookingCurrentpage == 0
							|| this.unassignBookingCurrentpage == 1
						)
						&& this.totalUnassignBooking <= this.UnassignBookingLimit
					)
					|| (this.unassignBookingCurrentpage == page)
				) {
					this.unassignBookings = this.removeObjectInArray(this.unassignBookings, ['bookingId', bookingId]);
				}
				else {
					this.getUnassignBooking(this.unassignBookingCurrentpage, this.Global.OnEventShowShimmer == true ? 0 : 1);
				}
			}
			else {
				if (((this.totalUnassignBooking + 1) / (Math.ceil((this.totalUnassignBooking + 1) / this.UnassignBookingLimit))) < this.UnassignBookingLimit) {
					if (this.isOpentabBookingsCompareAssignTime[0] > assignTime) {
						this.unassignBookingCurrentpage = this.unassignBookingCurrentpage - 1;
					}
					else {
						if (this.unassignBookingCurrentpage < page) {
							if (this.unassignBookingCurrentpage != 0 && this.unassignBookingCurrentpage != 1) {
								this.unassignBookingCurrentpage = this.unassignBookingCurrentpage - 1;
							}
						}
						else {
							this.unassignBookingCurrentpage = page;
						}
					}
				}
			}
		}
		else {
			console.log("else remove this.totalUnassignBooking:::", this.totalUnassignBooking);
		}
	}
	updateUnassignBooking(bookingId: string) {
		if (this.isOpentabBookingIds.includes(bookingId)) {
			this.getUnassignBooking(this.unassignBookingCurrentpage, this.Global.OnEventShowShimmer == true ? 0 : 1);
		}
	}
	checkUnassignBookingPassedTime() {
		if (this.unassignBookings.length > 0) {
			let companyCurrentTime1 = this.Global.getCompanyCurrentTime();
			let companyCurrentTime = moment(companyCurrentTime1, this.Global.dateFormat).add(parseInt("" + this.Global.unassignBookingAlertTime), 'minutes').format('YYYY-MM-DD HH:mm:ss');
			for (let i = 0; i < this.unassignBookings.length; i++) {
				let compairAlertTime = 'pickupTime';
				if (this.Global.calculateUnAssignBookingAlertTimeBasedOn == 'assignTime') {
					compairAlertTime = 'assignTime';
				}
				let compairTime = moment(this.unassignBookings[i][compairAlertTime]).format('YYYY-MM-DD HH:mm:ss');

				if (compairTime <= companyCurrentTime) {
					let minutes = moment(companyCurrentTime1, this.Global.dateFormat).diff(moment(compairTime, 'YYYY-MM-DD HH:mm:ss'), 'minutes');
					if (this.Global.unassignBookingAlertOffTime >= minutes) {
						setTimeout(() => {
							this.bookingalert_audio();
						}, 0);
						break;
					}
				}
			}
		}
	}
	//***************** assign booking start ********************

	//***************** assign booking start ********************	
	assignBookingLimit: number = 10;
	assignBookingCurrentpage: number = 0;
	isAssignBookingShimmer: boolean = false;
	assignBookings: any = [];
	totalAssignBooking: number = 0;
	getAssignBooking(page: any, loadShimmer = 0) {
		return new Promise((resolve) => {
			page = (page >= 0) ? page : 0;
			this.isAssignBookingShimmer = (loadShimmer) ? true : false;

			let totalPage = Math.ceil(this.totalAssignBooking / this.assignBookingLimit);
			if (totalPage < page) {
				page = totalPage;
			}

			this.assignBookingCurrentpage = page;
			let assignBookingOffset = (page == 0) ? 0 : ((page * this.assignBookingLimit) - this.assignBookingLimit);

			let passObj = {
				'bookingType': 2,
				'limit': this.assignBookingLimit,
				'offset': assignBookingOffset
			}

			this.CallMethod.get_Unassign_Assign_Active_Booking(passObj).pipe(takeUntil(this.unsubscribe$)).subscribe((result: any) => {
				if (result.status) {
					this.assignBookings = [];
					this.isOpentabBookingIds = [];
					this.isAssignBookingShimmer = true;

					if (result.data.length > 0) {

						this.isOpentabBookingsCompareAssignTime = [];
						this.isOpentabBookingsCompareAssignTime.push(result.data[0].assignTime);
						this.isOpentabBookingsCompareAssignTime.push(result.data[result.data.length - 1].assignTime);

						for (let i = 0; i < result.data.length; i++) {
							let locationJson = [];
							if (result.data[i].is_destination_later == 1) {
								locationJson.push(result.data[i].locationJson[0]);
								locationJson.push({
									"lat": 0,
									"long": 0,
									"address": "Destination later"
								});
							}
							else {
								locationJson = result.data[i].locationJson;
							}

							let distanceVariant = (result.data[i].distanceVariant == 1) ? 'miles' : 'km';

							let driverComment = {};
							if (result.data[i].driverComment != '') {
								if (typeof result.data[i].driverComment != 'object') {
									driverComment = { 'Driver info': result.data[i].driverComment }
								}
								else {
									driverComment = result.data[i].driverComment
								}
							}

							let userComment = {};
							if (result.data[i].userComment != '') {
								if (typeof result.data[i].userComment != 'object') {
									userComment = {
										'info': result.data[i].userComment
									}
								}
								else {
									userComment = result.data[i].userComment
								}
							}

							if (result.data[i].driver_json && result.data[i].driver_json != '') {
								try {
									result.data[i].driver_json = JSON.parse(result.data[i].driver_json)
								} catch {
									result.data[i].driver_json = result.data[i].driver_json
								}
							}
							this.isOpentabBookingIds.push(result.data[i].bookingId);
							this.assignBookings.push({
								'bookingId': result.data[i].bookingId,
								'dBookId': result.data[i].dBookId,
								'currency': result.data[i].currency,
								'userJson': result.data[i].userJson,
								'carTypeName': result.data[i].taxiType,
								'locationJson': locationJson,
								'pickupTime': moment(result.data[i].pickupTime).format('YYYY-MM-DD HH:mm:ss'),
								'assignTime': moment(result.data[i].assignTime).format('YYYY-MM-DD HH:mm:ss'),
								'finalAmount': (result.data[i].estimatedFinalAmount) ? ((Math.round(result.data[i].estimatedFinalAmount * 1000) / 1000).toFixed(environment.priceRoundUpNumber)) : 0.00,
								'paymentType': (result.data[i].paymentType) ? result.data[i].paymentType : 0,
								'totalApproxKm': (result.data[i].totalApproxKm) ? ((Math.round(result.data[i].totalApproxKm * 100) / 100).toFixed(environment.roundUpNumber)) : 0.00,
								'totalApproxTime': (result.data[i].totalApproxTime) ? ((Math.round(result.data[i].totalApproxTime * 100) / 100).toFixed(environment.roundUpNumber)) : 0,
								'distanceVariant': distanceVariant,
								'driverName': (result.data[i].driver_json && result.data[i].driver_json != '' && result.data[i].driver_json.driverName) ? result.data[i].driver_json.driverName : '--',
								'driverJson': (result.data[i].driver_json && result.data[i].driver_json != '') ? result.data[i].driver_json : {},
								'info': userComment,
								'driverInfo': driverComment,
								'accountId': (result.data[i].accountId) ? result.data[i].accountId : "",
								'isRestaurant': result.data[i].is_restaurant,
								'bookingFlag': result.data[i].bookingFlag,
								'status': result.data[i].status,
								'baseFare': result.data[i].baseFare,
								'tripDistancePrice': result.data[i].tripDistancePrice,
								'tripTimePrice': result.data[i].tripTimePrice,
								'stopPrice': result.data[i].stopPrice,
								'estimatedAmount': result.data[i].estimatedAmount,
								'couponDetails': result.data[i].couponDetails,
								'estimatedDiscountAmount': result.data[i].estimatedDiscountAmount,
								'estimatedTaxAmount': result.data[i].estimatedTaxAmount,
								'estimatedFinalAmount': result.data[i].estimatedFinalAmount,
								'bookingType': result.data[i].bookingType,
								'surgeMultiplier': result.data[i].surgeMultiplier,
								'totalBags': result.data[i].totalBags,
								'zoneId': result.data[i].zoneId,
								'carTypeId': result.data[i].carTypeId,
								'taxiType': result.data[i].taxiType,
								'carDetails': result.data[i].carDetails,
								'driverId': result.data[i].driverId,
								'tarrif': result.data[i].tarrif,
								'totalPerson': result.data[i].totalPerson,
								'handicappedTotal': result.data[i].handicappedTotal,
								'priority': result.data[i].priority,
								'roomNo': result.data[i].roomNo,
								'flightNo': result.data[i].flightNo,
								'userComment': result.data[i].userComment,
								'driverComment': result.data[i].driverComment,
								"isRepet": result.data[i].isRepet,
								"repetTicks": result.data[i].repetTicks,
								"isBox": result.data[i].isBox ? result.data[i].isBox : 0,
								'boxItems': result.data[i].boxItem ? result.data[i].boxItem : {},
								'taxJson': result.data[i].taxJson,
								'carColor': result.data[i].carColor,
								'findDriverType': result.data[i].find_driver_type,
								'estimatedExtraCharges': result.data[i].estimatedExtraCharges,
								'actualExtraCharges': result.data[i].actualExtraCharges,
								'extraChargesJson': result.data[i].extraChargesJson,
								'toll': result.data[i].toll,
								'serviceCharges': result.data[i].serviceCharges,
								'airportParking': result.data[i].airportParking,
								'priceFromAirportZone': result.data[i].priceFromAirportZone ? result.data[i].priceFromAirportZone : result.data[i].isAirport,
								'isAirport': result.data[i].isAirport,
								'isStreetPickup': result.data[i].isStreetPickup,
							});
						}
					}

					this.totalAssignBooking = (result.totalRecords) ? result.totalRecords : 0;


					if (this.Global.isSetDriverLimit == true) {
						let center: any = JSON.parse(JSON.stringify(this.map.getCenter()));
						this.reloadMapData({
							'location': [center.lat, center.lng]
						});
					}
				}

				return resolve(true);
			});
		});
	}
	addAssignData(assignTime: string, bookingId: string) {
		this.totalAssignBooking += 1;
		if (
			(
				(this.assignBookingCurrentpage == 1 || this.assignBookingCurrentpage == 0)
				&& (
					(this.totalAssignBooking - 1) < this.assignBookingLimit
					|| (
						this.isOpentabBookingsCompareAssignTime.length != 0
						&& this.isOpentabBookingsCompareAssignTime[1] >= assignTime
					)
				)
			)
			|| ((((this.totalAssignBooking - 1) / this.assignBookingCurrentpage) < this.assignBookingLimit) && this.isOpentabBookingsCompareAssignTime[1] <= assignTime)
			|| moment(assignTime).isBetween(this.isOpentabBookingsCompareAssignTime[0], this.isOpentabBookingsCompareAssignTime[1])
		) {
			this.getAssignBooking(this.assignBookingCurrentpage, this.Global.OnEventShowShimmer == true ? 0 : 1);
		}
		else {
			if (
				(((this.totalAssignBooking - 1) / this.assignBookingCurrentpage) != this.assignBookingLimit && this.isOpentabBookingsCompareAssignTime[1] > assignTime)
				|| (((this.totalAssignBooking - 1) / this.assignBookingCurrentpage) == this.assignBookingLimit && this.isOpentabBookingsCompareAssignTime[1] < assignTime)
			) { }
			else {
				let page = Math.ceil((this.totalAssignBooking - 1) / this.assignBookingLimit);
				if (this.assignBookingCurrentpage == page) {
					this.assignBookingCurrentpage = page + 1;
				}
			}
		}
	}
	removeAssignData(assignTime: any, bookingId: any) {
		this.totalAssignBooking -= 1;
		let page = Math.ceil((this.totalAssignBooking) / this.assignBookingLimit);

		if (this.isOpentabBookingIds.includes(bookingId)) {
			if (
				(
					(
						this.assignBookingCurrentpage == 0
						|| this.assignBookingCurrentpage == 1
					)
					&& this.totalAssignBooking <= this.assignBookingLimit
				)
				|| (this.assignBookingCurrentpage == page)
			) {
				this.assignBookings = this.removeObjectInArray(this.assignBookings, ['bookingId', bookingId]);
			}
			else {
				this.getAssignBooking(this.assignBookingCurrentpage, this.Global.OnEventShowShimmer == true ? 0 : 1);
			}
		}
		else {
			if (((this.totalAssignBooking + 1) / (Math.ceil((this.totalAssignBooking + 1) / this.assignBookingLimit))) < this.assignBookingLimit) {
				if (this.isOpentabBookingsCompareAssignTime[0] > assignTime) {
					this.assignBookingCurrentpage = this.assignBookingCurrentpage - 1;
				}
				else {
					if (this.assignBookingCurrentpage < page) {
						if (this.assignBookingCurrentpage != 0 && this.assignBookingCurrentpage != 1) {
							this.assignBookingCurrentpage = this.assignBookingCurrentpage - 1;
						}
					}
					else {
						this.assignBookingCurrentpage = page;
					}
				}
			}
		}
	}
	//***************** active booking start ********************

	//***************** active booking start ********************	
	activeBookingLimit: number = 10;
	activeBookingCurrentpage: number = 0;
	isActiveBookingShimmer: boolean = false;
	activeBookings: any = [];
	totalActiveBooking: number = 0;
	getActiveBooking(page: any, loadShimmer = 0, requestObj: any = {}) {
		return new Promise((resolve) => {
			page = (page >= 0) ? page : 0;
			this.isActiveBookingShimmer = (loadShimmer) ? true : false;

			let totalPage = Math.ceil(this.totalActiveBooking / this.activeBookingLimit);
			if (totalPage < page) {
				page = totalPage;
			}

			this.activeBookingCurrentpage = page;
			let activeBookingOffset = (page == 0) ? 0 : ((page * this.activeBookingLimit) - this.activeBookingLimit);
			let passObj: any = {};
			if (Object.keys(requestObj).length == 0) {
				let req = this.Global.getLocalStorage({ key: 'activeFilterRequest' });

				if (req == undefined) {
					passObj = {
						'bookingType': 3,
						'limit': this.activeBookingLimit,
						'offset': activeBookingOffset
					}
				} else {
					passObj = req;
					passObj.limit = this.UnassignBookingLimit;
					passObj.offset = activeBookingOffset;
					passObj.bookingType = 3;
				}
			} else {
				passObj = requestObj;
				passObj.limit = this.UnassignBookingLimit;
				passObj.offset = activeBookingOffset;
				passObj.bookingType = 3;
			}

			this.CallMethod.get_Unassign_Assign_Active_Booking(passObj).pipe(takeUntil(this.unsubscribe$)).subscribe((result: any) => {
				if (result.status) {
					this.activeBookings = [];
					this.isOpentabBookingIds = [];
					this.isActiveBookingShimmer = true;

					if (result.data.length > 0) {
						this.isOpentabBookingsCompareAssignTime = [];
						this.isOpentabBookingsCompareAssignTime.push(result.data[0].assignTime);
						this.isOpentabBookingsCompareAssignTime.push(result.data[result.data.length - 1].assignTime);

						for (let i = 0; i < result.data.length; i++) {
							let locationJson = [];
							if (result.data[i].is_destination_later == 1) {
								locationJson.push(result.data[i].locationJson[0]);
								locationJson.push({
									"lat": 0,
									"long": 0,
									"address": "Destination later"
								});
							}
							else {
								locationJson = result.data[i].locationJson;
							}

							let distanceVariant = (result.data[i].distanceVariant == 1) ? 'miles' : 'km';

							let driverComment = {};
							if (result.data[i].driverComment != '') {
								if (typeof result.data[i].driverComment != 'object') {
									driverComment = {
										'Driver info': result.data[i].driverComment
									}
								}
								else {
									driverComment = result.data[i].driverComment
								}
							}

							let userComment = {};
							if (result.data[i].userComment != '') {
								if (typeof result.data[i].userComment != 'object') {
									userComment = {
										'info': result.data[i].userComment
									}
								}
								else {
									userComment = result.data[i].userComment
								}
							}

							this.isOpentabBookingIds.push(result.data[i].bookingId);

							let setColor = 1;
							if (result.data[i].status == 7) {
								setColor = 2;
							}
							else if (result.data[i].status == 8 && result.data[i].slidedToComplete == 0) {
								setColor = 3;
							}
							else if (result.data[i].status == 8 && result.data[i].slidedToComplete == 1) {
								setColor = 4;
							}

							if (result.data[i].driver_json && result.data[i].driver_json != '') {
								try {
									result.data[i].driver_json = JSON.parse(result.data[i].driver_json)
								} catch {
									result.data[i].driver_json = result.data[i].driver_json
								}
							}

							let finalAmount: any = 0;
							if (result.data[i].slidedToComplete == 1) {
								finalAmount = (result.data[i].actual_final_amount) ? ((Math.round(result.data[i].actual_final_amount * 1000) / 1000).toFixed(environment.priceRoundUpNumber)) : 0.00;
								// finalAmount += (result.data[i].arrears + result.data[i].actual_tax_amount);
							}
							else {
								finalAmount = (result.data[i].estimatedFinalAmount) ? ((Math.round(result.data[i].estimatedFinalAmount * 1000) / 1000).toFixed(environment.priceRoundUpNumber)) : 0.00
							}

							this.activeBookings.push({
								'bookingId': result.data[i].bookingId,
								'dBookId': result.data[i].dBookId,
								'currency': result.data[i].currency,
								'userJson': result.data[i].userJson,
								'carTypeName': result.data[i].taxiType,
								'locationJson': locationJson,
								'pickupTime': moment(result.data[i].pickupTime).format('YYYY-MM-DD HH:mm:ss'),
								'assignTime': moment(result.data[i].assignTime).format('YYYY-MM-DD HH:mm:ss'),
								'finalAmount': finalAmount,
								'paymentType': (result.data[i].paymentType) ? result.data[i].paymentType : 0,
								'totalApproxKm': (result.data[i].totalApproxKm) ? ((Math.round(result.data[i].totalApproxKm * 100) / 100).toFixed(environment.roundUpNumber)) : 0.00,
								'totalApproxTime': (result.data[i].totalApproxTime) ? ((Math.round(result.data[i].totalApproxTime * 100) / 100).toFixed(environment.roundUpNumber)) : 0,
								'distanceVariant': distanceVariant,
								'info': userComment,
								'accountId': (result.data[i].accountId) ? result.data[i].accountId : "",
								'driverInfo': driverComment,
								'setColor': setColor,
								'isRestaurant': result.data[i].is_restaurant,
								'bookingFlag': result.data[i].bookingFlag,
								'slidedToComplete': (result.data[i].slidedToComplete != undefined) ? result.data[i].slidedToComplete : 0,
								'baseFare': result.data[i].baseFare,
								'tripDistancePrice': result.data[i].tripDistancePrice,
								'tripTimePrice': result.data[i].tripTimePrice,
								'stopPrice': result.data[i].stopPrice,
								'estimatedAmount': result.data[i].estimatedAmount,
								'couponDetails': result.data[i].couponDetails,
								'estimatedDiscountAmount': result.data[i].estimatedDiscountAmount,
								'estimatedTaxAmount': result.data[i].estimatedTaxAmount,
								'estimatedFinalAmount': result.data[i].estimatedFinalAmount,
								'bookingType': result.data[i].bookingType,
								'status': result.data[i].status,
								'driverJson': result.data[i].driver_json && result.data[i].driver_json != '' && result.data[i].driver_json != undefined ? result.data[i].driver_json : {},
								'surgeMultiplier': result.data[i].surgeMultiplier,
								'totalBags': result.data[i].totalBags,
								'zoneId': result.data[i].zoneId,
								'carTypeId': result.data[i].carTypeId,
								'taxiType': result.data[i].taxiType,
								'carDetails': result.data[i].carDetails,
								'driverId': result.data[i].driverId,
								'tarrif': result.data[i].tarrif,
								'totalPerson': result.data[i].totalPerson,
								'handicappedTotal': result.data[i].handicappedTotal,
								'priority': result.data[i].priority,
								'roomNo': result.data[i].roomNo,
								'flightNo': result.data[i].flightNo,
								'userComment': result.data[i].userComment,
								'driverComment': result.data[i].driverComment,
								"isRepet": result.data[i].isRepet,
								"repetTicks": result.data[i].repetTicks,
								"isBox": result.data[i].isBox ? result.data[i].isBox : 0,
								'boxItems': result.data[i].boxItem ? result.data[i].boxItem : {},
								'actual_amount': result.data[i].actual_amount,
								'actual_discount_amount': result.data[i].actual_discount_amount,
								'actual_final_amount': result.data[i].actual_final_amount,
								'actual_tax_amount': result.data[i].actual_tax_amount,
								'taxJson': result.data[i].taxJson,
								'carColor': result.data[i].carColor,
								'arrears': result.data[i].arrears,
								'findDriverType': result.data[i].find_driver_type,
								'estimatedExtraCharges': result.data[i].estimatedExtraCharges,
								'actualExtraCharges': result.data[i].actualExtraCharges,
								'extraChargesJson': result.data[i].extraChargesJson,
								'estimatedBoxItemPrice': result.data[i].estimatedBoxItemPrice,
								'toll': result.data[i].toll,
								'serviceCharges': result.data[i].serviceCharges,
								'airportParking': result.data[i].airportParking,
								'priceFromAirportZone': result.data[i].priceFromAirportZone ? result.data[i].priceFromAirportZone : result.data[i].isAirport,
								'isAirport': result.data[i].isAirport,
								'isStreetPickup': result.data[i].isStreetPickup,
							});
						}
					}

					this.Global.setLocalStorage({ key: 'activeFilterRequest', value: passObj });
					this.totalActiveBooking = (result.totalRecords) ? result.totalRecords : 0;

					if (this.Global.isSetDriverLimit == true) {
						let center: any = JSON.parse(JSON.stringify(this.map.getCenter()));
						this.reloadMapData({
							'location': [center.lat, center.lng]
						});
					}

					return resolve(true);
				}
			})

		});
	}
	addActiveData(assignTime: string, bookingId: string) {
		if (this.activeFilterCount <= 0) {
			this.totalActiveBooking += 1;
			if (
				(
					(this.activeBookingCurrentpage == 1 || this.activeBookingCurrentpage == 0)
					&& (
						(this.totalActiveBooking - 1) < this.activeBookingLimit
						|| (
							this.isOpentabBookingsCompareAssignTime.length != 0
							&& this.isOpentabBookingsCompareAssignTime[1] >= assignTime
						)
					)
				)
				|| ((((this.totalActiveBooking - 1) / this.activeBookingCurrentpage) < this.activeBookingLimit) && this.isOpentabBookingsCompareAssignTime[1] <= assignTime)
				|| moment(assignTime).isBetween(this.isOpentabBookingsCompareAssignTime[0], this.isOpentabBookingsCompareAssignTime[1])
			) {
				this.getActiveBooking(this.activeBookingCurrentpage, this.Global.OnEventShowShimmer == true ? 0 : 1);
			}
			else {
				if (
					(((this.totalActiveBooking - 1) / this.activeBookingCurrentpage) != this.activeBookingLimit && this.isOpentabBookingsCompareAssignTime[1] > assignTime)
					|| (((this.totalActiveBooking - 1) / this.activeBookingCurrentpage) == this.activeBookingLimit && this.isOpentabBookingsCompareAssignTime[1] < assignTime)
				) { }
				else {
					let page = Math.ceil((this.totalActiveBooking - 1) / this.activeBookingLimit);
					if (this.activeBookingCurrentpage == page) {
						this.activeBookingCurrentpage = page + 1;
					}
				}
			}
		}
	}
	removeActiveData(assignTime: string, bookingId: string) {
		if (this.activeFilterCount <= 0 || this.isOpentabBookingIds.includes(bookingId)) {
			this.totalActiveBooking -= 1;
			let page = Math.ceil((this.totalActiveBooking) / this.activeBookingLimit);
			console.log('****** page ******', page);
			console.log('****** this.activeBookingCurrentpage ******', this.activeBookingCurrentpage);
			if (this.isOpentabBookingIds.includes(bookingId)) {
				if (
					(
						(
							this.activeBookingCurrentpage == 0
							|| this.activeBookingCurrentpage == 1
						)
						&& this.totalActiveBooking <= this.activeBookingLimit
					)
					|| (this.activeBookingCurrentpage == page)
				) {
					this.activeBookings = this.removeObjectInArray(this.activeBookings, ['bookingId', bookingId]);
				}
				else {
					this.getActiveBooking(this.activeBookingCurrentpage, this.Global.OnEventShowShimmer == true ? 0 : 1);
				}
			}
			else {
				// if (((this.totalActiveBooking + 1) / (Math.ceil((this.totalActiveBooking + 1) / this.activeBookingLimit))) < this.activeBookingLimit) {
				// 	if (this.isOpentabBookingsCompareAssignTime[0] > assignTime) {
				// 		this.activeBookingCurrentpage = this.activeBookingCurrentpage - 1;
				// 	}
				// 	else {
				// 		if (this.activeBookingCurrentpage < page) {
				// 			if (this.activeBookingCurrentpage != 0 && this.activeBookingCurrentpage != 1) {
				// 				this.activeBookingCurrentpage = this.activeBookingCurrentpage - 1;
				// 			}
				// 		}
				// 		else {
				// 			this.activeBookingCurrentpage = page;
				// 		}
				// 	}
				// }
			}

			// this.forceFullyCompletedDailog = false;
		}
	}
	//!------------------------ un assign, assign and active tab end-------------------------

	//!---------------------Driver details popup start---------------------------------------
	@ViewChild("driverDetailsModal") public driverDetailsModal!: ModalComponent;
	showDriverDetailsPopup: boolean = false;
	driverDetails(driverId: string = "") {
		if (this.showDriverDetailsPopup == false) {
			this.driverDetailsModal.modalRoot.nativeElement.style.zIndex = this.driverDetailsModal.getMaxModalIndex() + 2;
			setTimeout(() => {
				this.driverDetailsModal.modalRoot.nativeElement.style.top = environment.windowPopupStyle.driverInfo.top;
			}, 0);

			if (this.driverDetailsModal.modalBody.nativeElement.style.height.trim() == '') {
				this.driverDetailsModal.modalBody.nativeElement.style.height = environment.windowPopupStyle.driverInfo.height;
				let width = parseInt(environment.windowPopupStyle.driverInfo.width.replace('px', ''));
				if (window.innerWidth < parseInt(environment.windowPopupStyle.driverInfo.width.replace('px', ''))) {
					width = window.innerWidth - 2;
				}
				this.driverDetailsModal.modalRoot.nativeElement.style.width = width.toString() + 'px';
			}

			this.driverDetailsModal.modalHeader.nativeElement.style.backgroundColor = this.Global.first_color;
			this.driverDetailsModal.modalFooter.nativeElement.style.backgroundColor = this.Global.third_color;
			this.driverDetailsModal.modalRoot.nativeElement.style.border = '1px solid ' + this.Global.first_color;

			this.driverDetailsModal.show();
			this.showDriverDetailsPopup = true;
		}

		this.getDriverDetails(driverId);
	}
	closeDriverDetailsModel() {
		this.showDriverDetailsPopup = false;
	}
	driverDetailsBtn: boolean = false;
	driverDetail: any = null;
	getDriverDetails(driverId: string = "") {
		return new Promise((resolve, reject) => {
			this.driverDetailsBtn = true;
			this.CallMethod.getdriverDetails({ "driverId": driverId }).pipe(takeUntil(this.unsubscribe$)).subscribe((result: any) => {
				if (result.status) {
					if (result.data) {
						console.log('Driver details result data________', result.data)
						let driverDetails: any = {
							bookingId: result.data['bookingId'],
							carIcon: result.data['carIcon'] ? environment.baseUrl + environment.imageUrl + environment.Cars + result.data['carIcon'] : "../../assets/images/noImage.png",
							carTypeName: result.data['carTypeName'],
							color: result.data['color'],
							driverEarning: result.data['driverEarning'],
							driverId: result.data['driverId'],
							driverImage: result.data['driverImage'] ? environment.baseUrl + environment.imageUrl + environment.Driver + result.data['driverImage'] : "",
							driverFLName: this.Global.getFirstCharacter(result.data['driverName']),
							driverName: result.data['driverName'],
							carBrandName: result.data['carBrandName'],
							carModelName: result.data['carModelName'],
							driverTotalJobs: result.data['driverTotalJobs'],
							freeTime: result.data['freeTime'],
							licensePlate: result.data['licensePlate'],
							mobile: result.data['mobile'],
							rating: result.data['rating'],
							seatCapacity: result.data['seatCapacity'],
							driverStatus: result.data['driverStatus'],
							currentBalance: result.data['currentBalance'],
							location: result.data['location'],
							currency: result.data['currency'],
							preferences: result.data['driverPreference'],
							estimateCompleteBookingTime: moment(result.data['estimateCompleteBookingTime'], 'YYYY-MM-DD HH:mm:ss').format(this.Global.dateFormat),
							bookingData: [],
							backToBackRide: result.data['backToBackRide'],
							lastLocationTimeStamp: result.data['lastLocationUpdatedTime']
						}
						driverDetails.preferences = this.setDriverPreferences({
							'driver_car_type_name_preferences': result.data['driverCityRidePreferences'],
							'driver_car_type_name_preferences_delivery': result.data['driverDeliveryPreference'],
							'driver_car_type_name_preferences_airport': result.data['driverAirportPreferences'],
							'driver_car_type_name_preferences_outStation': result.data['driverOutstationPreferences'],
							'driver_car_type_name_preferences_rental': result.data['driverRentalPreferences'],
							'driver_car_type_name_preferences_restaurant': result.data['driverRestaurantPreferences'],
						});

						driverDetails.onlineTime = moment.utc(moment.duration(result.data['onlineTime'], "minutes").asMilliseconds()).format("HH:mm")

						if (result.data.bookingData.length > 0) {
							//locationJson
							for (let i = 0; i < result.data.bookingData.length; i++) {

								if (result.data.bookingData[i].locationJson.length > 0) {
									for (let j = 0; j < result.data.bookingData[i].locationJson.length; j++) {
										if (j == 0) {
											result.data.bookingData[i].locationJson[j].addressId = "pickup"
										} else if (j == result.data.bookingData[i].locationJson.length - 1) {
											result.data.bookingData[i].locationJson[j].addressId = "drop"
										} else {
											result.data.bookingData[i].locationJson[j].addressId = ""
										}
									}

								}

								//estimateFinishTime
								if (result.data.bookingData[i].status == 3 || result.data.bookingData[i].status == 7) {
									result.data.bookingData[i].estimatedFinishTime = moment(result.data.bookingData[i].pickupTime, 'YYYY-MM-DD HH:mm:ss').add(result.data.bookingData[i].totalApproxTime, 'minutes').format(this.Global.dateFormat)
								} else if (result.data.bookingData[i].status == 8) {
									result.data.bookingData[i].estimatedFinishTime = moment(result.data.bookingData[i].beginTime, 'YYYY-MM-DD HH:mm:ss').add(result.data.bookingData[i].totalApproxTime, 'minutes').format(this.Global.dateFormat)
								}
								result.data.bookingData[i].distanceVariant = (result.data.bookingData[i].distanceVariant == 1) ? 'miles' : 'km';

								//estimateFinishTime background color
								let companyTime = moment(this.Global.getCompanyCurrentTime(), this.Global.dateFormat).format('YYYY-MM-DD HH:mm:ss')
								let finishTime = moment(result.data.bookingData[i].estimatedFinishTime, this.Global.dateFormat).format('YYYY-MM-DD HH:mm:ss');
								if (moment(finishTime).diff(companyTime, 'minutes') <= 15 && moment(finishTime).diff(companyTime, 'minutes') >= 0) {
									if (moment(finishTime).diff(companyTime, 'minutes') <= 5) {
										result.data.bookingData[i].estimateFinishTimeColor = { 'background-color': '#981b1b' }
									} else {
										result.data.bookingData[i].estimateFinishTimeColor = { 'background-color': '#d87606' }
									}
								} else if (moment(finishTime).diff(companyTime, 'minutes') < 0) {
									result.data.bookingData[i].estimateFinishTimeColor = { 'background-color': '#981b1b' }
								} else {
									result.data.bookingData[i].estimateFinishTimeColor = { 'background-color': '#059669' }
								}
								//userJson
								result.data.bookingData[i].userJson = result.data.bookingData[i].userJson.length > 0 ? JSON.parse(result.data.bookingData[i].userJson) : []
							}
							driverDetails.bookingData = result.data['bookingData'];
						}
						console.log("result.data['bookingData']", result.data['bookingData']);

						this.driverDetail = driverDetails;
					}

				}
				this.driverDetailIsCredit = this.Global.roleJson.driver_wallet_debit['add'] == 1 ? 0 : 1
				this.driverDetailsBtn = false;
				return resolve({ status: true })
			});
		})

	}
	driverDetailIsCredit: number = this.Global.roleJson.driver_wallet_debit['add'] == 1 ? 0 : 1;
	driverCreditDebitOption(isCredit: number) {
		this.driverDetailIsCredit = isCredit;
	}
	driverDetailsAmount: number = 0;
	driverDetailsDescriptions: string = "";
	loaderTransactionButton: boolean = true;
	driverDetailsTranction(driverId: string) {
		if (this.driverDetailsAmount > 0) {
			let amount = +this.driverDetailsAmount;
			if (this.driverDetailIsCredit === 0) {
				amount = -this.driverDetailsAmount
			}
			let request = {
				driverId: driverId,
				amount,
				description: this.driverDetailsDescriptions
			}
			this.loaderTransactionButton = false;
			this.CallMethod.driverWalletUpdate(request).pipe(takeUntil(this.unsubscribe$)).subscribe((result: any) => {
				if (result.status) {
					this.Popup.toasterMessage({
						'status': 'success',
						'title': 'Success',
						'message': 'Tractions Successfully Done...',
					});
					this.driverDetail.currentBalance = result.currentBalance
					this.driverDetailsAmount = 0;
					this.driverDetailsDescriptions = "";
				} else if (result.status == false && result.statusCode == 1) {
					this.Popup.toasterMessage({
						'status': 'warning',
						'title': 'Daily Transaction limit exceed',
						'message': 'Remaining Transaction Amount ' + result.remainTransactionAmount,
					});
				}
				this.loaderTransactionButton = true;
			});

		} else {
			this.Popup.toasterMessage({
				'status': 'error',
				'title': 'Oops !!',
				'message': 'Please enter amount valid amount or more than zero',
			});
		}
	}
	//!---------------------Driver details popup end---------------------------------------
	freeTimeObj: any = {}
	waitTimeObj: any = {}
	blinkDriverObj: any = {}
	paymentDelayObj: any = {}
	dropTimeDelayObj: any = {}
	pickupTimeDelayObj: any = {}
	acceptTimeDelayObj: any = {}
	calculatewaitTime(arrivedTripTime: any, driverId: any, bookingFlag: number) {
		if (arrivedTripTime != '00:00:00') {
			arrivedTripTime = moment(arrivedTripTime).format('YYYY-MM-DD HH:mm:ss')
			let companyTime = moment(this.Global.getCompanyCurrentTime(), this.Global.dateFormat).format('YYYY-MM-DD HH:mm:ss');
			if (moment(companyTime).diff(arrivedTripTime, 'hours') >= 24) {
				this.waitTimeObj[driverId + '_waitTime'] = this.Global.getAgoTime(arrivedTripTime)
			} else {
				let newtime = moment.utc(moment(companyTime, 'YYYY-MM-DD HH:mm:ss').diff(moment(arrivedTripTime, 'YYYY-MM-DD HH:mm:ss'))).format("HH:mm:ss")
				this.waitTimeObj[driverId + '_waitTime'] = { 'waitTime': newtime, 'bookingFlag': bookingFlag }
			}
		}
	}
	addClockTimeInFreeTime() {
		let companyTime = moment(this.Global.getCompanyCurrentTime(), this.Global.dateFormat).format('YYYY-MM-DD HH:mm:ss');
		for (let freeTime in this.freeTimeObj) {
			if (moment(this.freeTimeObj[freeTime], "HH:mm:ss", true).isValid()) {
				if (moment(companyTime).diff(this.freeTimeObj[freeTime], 'days') > 0) {
					this.freeTimeObj[freeTime] = this.Global.getAgoTime(this.freeTimeObj[freeTime])
				} else {
					let free = moment(this.freeTimeObj[freeTime], 'HH:mm:ss').format("HH:mm:ss");
					let addSecond = moment(free, 'HH:mm:ss').add(1, 'seconds')
					this.freeTimeObj[freeTime] = moment(addSecond).format("HH:mm:ss")
				}
			}
		}
	}
	addClockTimeInWaitTime() {
		let companyTime = moment(this.Global.getCompanyCurrentTime(), this.Global.dateFormat).format('YYYY-MM-DD HH:mm:ss');
		for (let waitTimeData in this.waitTimeObj) {
			if (moment(this.waitTimeObj[waitTimeData]['waitTime'], "HH:mm:ss", true).isValid()) {
				if (moment(companyTime).diff(this.waitTimeObj[waitTimeData]['waitTime'], 'hours') >= 24) {
					this.waitTimeObj[waitTimeData]['waitTime'] = this.Global.getAgoTime(this.waitTimeObj[waitTimeData]['waitTime'])
				} else {
					let free = moment(this.waitTimeObj[waitTimeData]['waitTime'], 'HH:mm:ss').format("HH:mm:ss");
					let addSecond = moment(free, 'HH:mm:ss').add(1, 'seconds')
					this.waitTimeObj[waitTimeData]['waitTime'] = moment(addSecond).format("HH:mm:ss")
					let waitTimeSplit = this.waitTimeObj[waitTimeData]['waitTime'].split(':');
					let totalMinutes = parseInt(("" + waitTimeSplit[0] * 60)) + parseInt(waitTimeSplit[1]);
					let waitTimeLimit = this.waitTimeObj[waitTimeData]['bookingFlag'] == 0 ? this.Global.waitTime : this.waitTimeObj[waitTimeData]['bookingFlag'] == 1 ? this.Global.rentalWaitTime : this.Global.outStationWaitTime

					// console.log('****** totalMinutes ******', totalMinutes);
					// console.log('****** waitTimeLimit ******', waitTimeLimit);

					// console.log('****** totalMinutes >= Number(waitTimeLimit) ******', totalMinutes >= Number(waitTimeLimit));

					if (totalMinutes >= Number(waitTimeLimit)) {
						let waitTimeElement = document.getElementById(waitTimeData) as HTMLInputElement;
						// console.log('****** waitTimeElement ******', waitTimeElement);
						if (waitTimeElement != null) {
							waitTimeElement.style.color = this.Global.waitTimeDelayColor;
							// console.log('****** this.Global.waitTimeDelayColor ******', this.Global.waitTimeDelayColor);
							// console.log('****** waitTimeElement.style.color ******', waitTimeElement.style.color);
						}
					}
				}
			}
		}
	}
	calculatePickupTimeDelay() {
		for (let pickupObj in this.pickupTimeDelayObj) {
			let pickupTime = moment(this.pickupTimeDelayObj[pickupObj]['pickupTime'], 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
			if (this.pickupTimeDelayObj[pickupObj]['arrivedTripTime'] != '00:00:00' && this.pickupTimeDelayObj[pickupObj]['arrivedTripTime'] != 'Invalid date') {
				let arrivedTripTime = moment(this.pickupTimeDelayObj[pickupObj]['arrivedTripTime'], 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
				let isDriverNotArrivedOnTime = moment(arrivedTripTime).isAfter(pickupTime);

				if (isDriverNotArrivedOnTime) {
					let delay = moment.utc(moment(arrivedTripTime, 'YYYY-MM-DD HH:mm:ss').diff(moment(pickupTime, 'YYYY-MM-DD HH:mm:ss'))).format("HH:mm:ss");
					this.pickupTimeDelayObj[pickupObj]['blink'] = false;
					this.pickupTimeDelayObj[pickupObj]['delayTime'] = delay;
					this.pickupTimeDelayObj[pickupObj]['show'] = true;
				}
			} else {
				let companyTime = moment(this.Global.getCompanyCurrentTime(), this.Global.dateFormat).format('YYYY-MM-DD HH:mm:ss');
				let isCurrentTimePassedPickupTime = moment(companyTime).isAfter(pickupTime);

				if (isCurrentTimePassedPickupTime) {
					let delay = moment.utc(moment(companyTime, 'YYYY-MM-DD HH:mm:ss').diff(moment(pickupTime, 'YYYY-MM-DD HH:mm:ss'))).format("HH:mm:ss");
					let addSecond = moment(delay, 'HH:mm:ss').add(1, 'seconds');
					this.pickupTimeDelayObj[pickupObj]['delayTime'] = moment(addSecond).format("HH:mm:ss");
					this.pickupTimeDelayObj[pickupObj]['blink'] = true;
					this.pickupTimeDelayObj[pickupObj]['show'] = true;
				}
			}
		}
	}
	calculateAcceptTimeDelay() {
		for (let acceptTimeObj in this.acceptTimeDelayObj) {

			let acceptedTripTime = moment(this.acceptTimeDelayObj[acceptTimeObj]['acceptedTripTime'], 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
			if (this.acceptTimeDelayObj[acceptTimeObj]['arrivedTripTime'] != '00:00:00' && this.acceptTimeDelayObj[acceptTimeObj]['arrivedTripTime'] != 'Invalid date') {
				let arrivedTripTime = moment(this.acceptTimeDelayObj[acceptTimeObj]['arrivedTripTime'], 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
				let isDriverNotStartedTripTime = moment(arrivedTripTime).isAfter(acceptedTripTime);

				if (isDriverNotStartedTripTime) {
					let delay = moment.utc(moment(arrivedTripTime, 'YYYY-MM-DD HH:mm:ss').diff(moment(acceptedTripTime, 'YYYY-MM-DD HH:mm:ss'))).format("HH:mm:ss");
					this.acceptTimeDelayObj[acceptTimeObj]['blink'] = false;
					this.acceptTimeDelayObj[acceptTimeObj]['delayTime'] = delay;
					this.acceptTimeDelayObj[acceptTimeObj]['show'] = true;
				}
			} else {
				let companyTime = moment(this.Global.getCompanyCurrentTime(), this.Global.dateFormat).format('YYYY-MM-DD HH:mm:ss');
				let isCurrentTimePassedAcceptTime = moment(companyTime).isAfter(acceptedTripTime);


				let delay = moment.utc(moment(companyTime, 'YYYY-MM-DD HH:mm:ss').diff(moment(acceptedTripTime, 'YYYY-MM-DD HH:mm:ss'))).format("HH:mm:ss");
				let addSecond = moment(delay, 'HH:mm:ss').add(1, 'seconds');

				if (isCurrentTimePassedAcceptTime) {
					this.acceptTimeDelayObj[acceptTimeObj]['delayTime'] = moment(addSecond).format("HH:mm:ss");
					this.acceptTimeDelayObj[acceptTimeObj]['blink'] = true;
					this.acceptTimeDelayObj[acceptTimeObj]['show'] = true;
				}
			}
		}
	}
	calculateDropTimeDelay() {
		for (let dropTimeObj in this.dropTimeDelayObj) {
			let estimatedDropTime = moment(this.dropTimeDelayObj[dropTimeObj]['estimatedDropTime'], 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
			if (this.dropTimeDelayObj[dropTimeObj]['actualDropTime'] != '00:00:00' && this.dropTimeDelayObj[dropTimeObj]['actualDropTime'] != 'Invalid date') {
				let actualDropTime = moment(this.dropTimeDelayObj[dropTimeObj]['actualDropTime'], 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
				let isDriverNotArrivedOnTime = moment(actualDropTime).isAfter(estimatedDropTime);

				if (isDriverNotArrivedOnTime) {
					let delay = moment.utc(moment(actualDropTime, 'YYYY-MM-DD HH:mm:ss').diff(moment(estimatedDropTime, 'YYYY-MM-DD HH:mm:ss'))).format("HH:mm:ss");
					this.dropTimeDelayObj[dropTimeObj]['blink'] = false;
					this.dropTimeDelayObj[dropTimeObj]['delayTime'] = delay;
					this.dropTimeDelayObj[dropTimeObj]['show'] = true;
				}

			} else {
				let companyTime = moment(this.Global.getCompanyCurrentTime(), this.Global.dateFormat).format('YYYY-MM-DD HH:mm:ss');
				let isCurrentTimePassedEstimatedDropTime = moment(companyTime).isAfter(estimatedDropTime);
				if (isCurrentTimePassedEstimatedDropTime) {
					let delay = moment.utc(moment(companyTime, 'YYYY-MM-DD HH:mm:ss').diff(moment(estimatedDropTime, 'YYYY-MM-DD HH:mm:ss'))).format("HH:mm:ss");
					let addSecond = moment(delay, 'HH:mm:ss').add(1, 'seconds');
					this.dropTimeDelayObj[dropTimeObj]['delayTime'] = moment(addSecond).format("HH:mm:ss");
					this.dropTimeDelayObj[dropTimeObj]['blink'] = true;
					this.dropTimeDelayObj[dropTimeObj]['show'] = true;
				}
			}
		}
	}

	setAcceptedTripTimeDelayObj(acceptedTripTime: string, arrivedTripTime: string, driverId: string) {
		acceptedTripTime = moment(acceptedTripTime, 'YYYY-MM-DD HH:mm:ss').add(this.Global.acceptDelay.time, 'minutes').format('YYYY-MM-DD HH:mm:ss');

		this.acceptTimeDelayObj['accept_' + driverId] = {
			acceptedTripTime: moment(acceptedTripTime, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
			blink: false,
			arrivedTripTime: moment(arrivedTripTime, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
			delayTime: moment().startOf('day').format("HH:mm:ss"),
			show: false
		};

	}

	setpickupTimeDelayObj(pickupTime: string, arrivedTripTime: string, driverId: string) {
		pickupTime = moment(pickupTime, 'YYYY-MM-DD HH:mm:ss').add(this.Global.pickupDelay.time, 'minutes').format('YYYY-MM-DD HH:mm:ss');

		this.pickupTimeDelayObj['pickup_' + driverId] = {
			pickupTime: moment(pickupTime, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
			blink: false,
			arrivedTripTime: moment(arrivedTripTime, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
			delayTime: moment().startOf('day').format("HH:mm:ss"),
			show: false
		};
	}
	setDropTimeDelayObj(estimatedDropTime: string, actualDropTime: string, driverId: string) {
		estimatedDropTime = moment(estimatedDropTime, 'YYYY-MM-DD HH:mm:ss').add(this.Global.dropDelay.time, 'minutes').format('YYYY-MM-DD HH:mm:ss');

		this.dropTimeDelayObj['drop_' + driverId] = {
			estimatedDropTime: moment(estimatedDropTime, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
			blink: false,
			actualDropTime: moment(actualDropTime, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
			delayTime: moment().startOf('day').format("HH:mm:ss"),
			show: false
		};

	}
	setPaymentDelayObj(actualDropTime: string, driverId: string) {
		actualDropTime = moment(actualDropTime, 'YYYY-MM-DD HH:mm:ss').add(this.Global.paymentDelayTime.time, 'minutes').format('YYYY-MM-DD HH:mm:ss');

		this.paymentDelayObj['payment_' + driverId] = {
			blink: false,
			actualDropTime: moment(actualDropTime, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
			delayTime: moment().startOf('day').format("HH:mm:ss"),
			show: false
		};
	}
	calculatePaymentDelay() {
		for (let paymentObj in this.paymentDelayObj) {
			let actualDropTime = moment(this.paymentDelayObj[paymentObj]['actualDropTime'], 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
			if (actualDropTime != 'Invalid date' && moment(actualDropTime, "YYYY-MM-DD HH:mm:ss", true).isValid()) {
				let companyTime = moment(this.Global.getCompanyCurrentTime(), this.Global.dateFormat).format('YYYY-MM-DD HH:mm:ss');
				let isDriverNotCompletePaymentOnTime = moment(companyTime).isAfter(actualDropTime);

				if (isDriverNotCompletePaymentOnTime) {
					let delay = moment.utc(moment(companyTime, 'YYYY-MM-DD HH:mm:ss').diff(moment(actualDropTime, 'YYYY-MM-DD HH:mm:ss'))).format("HH:mm:ss");
					let addSecond = moment(delay, 'HH:mm:ss').add(1, 'seconds');
					this.paymentDelayObj[paymentObj]['delayTime'] = moment(addSecond).format("HH:mm:ss");
					this.paymentDelayObj[paymentObj]['blink'] = true;
					this.paymentDelayObj[paymentObj]['show'] = true;
				}
			}
		}
	}
	setDriverFreeTimeObj(opts: any) {
		let companyTime = moment(this.Global.getCompanyCurrentTime(), this.Global.dateFormat).format('YYYY-MM-DD HH:mm:ss');
		if (moment(companyTime).diff(opts.freeTime, 'hours') >= 24) {
			this.freeTimeObj[opts.driverId + '_freeTime'] = this.Global.getAgoTime(opts.freeTime)
		} else {
			let newtime = moment.utc(moment(companyTime, 'YYYY-MM-DD HH:mm:ss').diff(moment(opts.freeTime, 'YYYY-MM-DD HH:mm:ss'))).format("HH:mm:ss")
			this.freeTimeObj[opts.driverId + '_freeTime'] = newtime;
		}
	}


	// Define a function to get data from local storage
	getDataFromLocalStorage(key: string): any {
		const data = localStorage.getItem(key);
		if (data) {
			const parsedData = JSON.parse(data);
			console.log('****** parsedData ******', parsedData);
			const currentTime = new Date().getTime();
			if (parsedData.timestamp && currentTime - parsedData.timestamp <= environment.driverOfflineCachingDuration) {
				return parsedData.value;
			}
		}
		return null;
	}

	// Define a function to store data in local storage
	setDataInLocalStorage(key: string, value: any, isReload: boolean): void {
		console.log('****** setDataInLocalStorage value ******', value);
		const currentTime = new Date().getTime();
		const dataToStore = JSON.stringify({ value, timestamp: currentTime, isReload: isReload });
		// if (isReload) {
		localStorage.setItem(key, dataToStore);
		// }
	}


	onlineDriverCount: any = 0;
	offlineDriverCount: any = 0;
	alllineDriverCount: any = 0;
	busyDriverCount: any = 0;
	freeDriverCount: any = 0;
	filterDriverLimit: number = 10;
	filterDriverOffset: number = 0;
	filterDriverCurrentPage: number = 0;
	filterDriverTotalCount: number = 0;
	getDrivers(opts: any = {}) {
		return new Promise((resolve, reject) => {


			console.log('****** getDrivers opts >>>>>>> ******', opts);

			let request: any = {};
			this.driverListShimmer = (opts.loadShimmer) ? true : false;
			if (opts.request != undefined) {
				request = opts.request;
				// request.search = ''
				request.search = opts.request.search
			}
			else {
				if (opts.onlineDriver == true) {
					request.flag = 1;
				}
				else if (opts.offDriver == true) {
					request.flag = 2;
				}
				else {
					request.flag = 3;
				}
			}

			if (this.Global.isSetDriverLimit == true) {
				request.driverIds = this.nearestDriverIds;
			}

			if (this.Global.isSetDriverLimit == true && ((opts.isVehicleFilter != undefined && opts.isVehicleFilter == true) || opts.callType != undefined && opts.callType == 'setAssign')) {
				request.driverIds = undefined;
				request.limit = this.filterDriverLimit;
				request.offset = this.filterDriverOffset;
			}

			if (opts.isVehicleFilter && !this.isDriverSearchOn) {
				this.vehicleFilterApplied = true;
				request.search = ''
				console.log('_____this.isDriverSearchOn_____', this.isDriverSearchOn)
				console.log('formValue for filter 1')
				if (!this.reqObjForFilter) {
					let formValue = this.Global.getLocalStorage({ key: 'vehicleListFilterFormValue' });
					console.log('formValue for filter 2', formValue)
					if (formValue) {
						this.searchString = formValue.search
						this.reqObjForFilter = {}
						this.reqObjForFilter.searchBy = {}
						// this.reqObjForFilter.searchBy = {[formValue.driverBookingSearchBy[0].itemText.replaceAll(' ', '')] : formValue.driverBookingSearchBy[0].itemId.replaceAll(' ', '')};
						for (let i = 0; i < formValue.driverBookingSearchBy?.length; i++) {
							let text = this.makeKeyStringForRequest(formValue.driverBookingSearchBy[i].itemText)
							// searchByArr[text] = searchBy[i].itemId
							this.reqObjForFilter.searchBy[formValue.driverBookingSearchBy[i].itemId] = text
						};

						if (formValue.drivercarType && formValue.drivercarType?.length > 0) {
							console.log('_____Entered in carType condition....')
							this.reqObjForFilter.carType = {}
							for (const Obj of formValue.drivercarType) {
								console.log('OBJ_______', Obj)
								const keys = Object.keys(Obj);
								console.log('keys extracted__________', keys)
								for (let i = 0; i < 1; i++) {
									const key = Obj[keys[i + 1]]; // Get the value from index 1, 3, 5, etc.
									const value = Obj[keys[i]]; // Get the key from index 0, 2, 4, etc.
									console.log('key and value pair______', key, value)
									if (key !== undefined && value !== undefined) { // Check to avoid undefined errors
										console.log('_____Entered in carType condition second %%%....')
										this.reqObjForFilter.carType[key] = value; // Set the key-value pair
									}
								}
							}
						}
						if (formValue.driverBookingStatus && formValue.driverBookingStatus?.length > 0) {
							console.log('_____Entered in status condition status....')
							this.reqObjForFilter.status = {}
							for (const Obj of formValue.driverBookingStatus) {
								console.log('OBJ_______', Obj)
								const keys = Object.keys(Obj);
								console.log('keys extracted status__________', keys)
								for (let i = 0; i < 1; i++) {
									const key = Obj[keys[i + 1]];
									const value = Obj[keys[i]];
									console.log('key and value pair______', key, value)
									if (key !== undefined && value !== undefined) {
										console.log('_____Entered in status condition second %%%....')
										this.reqObjForFilter.status[key] = value;
									}
								}
							}
						}
						// this.reqObjForFilter.driverBookingStatus = formValue.driverBookingStatus;
						// this.reqObjForFilter.drivercarType = formValue.drivercarType;
						// this.reqObjForFilter.carType = formValue.carType;

						console.log('reqObjFor filter  3', this.reqObjForFilter)
					}
				}
			}

			else if (opts.isVehicleFilter == undefined && !this.vehicleFilterApplied == true) {
				this.vehicleFilterApplied = false
			}

			console.log('****** opts.isVehicleFilter  ******', opts.isVehicleFilter);

			console.log('****** this.vehicleListFiltersearchInput ******', this.vehicleListFiltersearchInput);
			const allDriverscachedDriversData = this.getDataFromLocalStorage('allDriverscachedDriversData');
			const onlineDriverscachedDriversData = this.getDataFromLocalStorage('onlineDriverscachedDriversData');
			const offlineDriverscachedDriversData = this.getDataFromLocalStorage('offlineDriverscachedDriversData');
			const filterDriverListcached = this.getDataFromLocalStorage('filterDriverListcached');
			console.log('****** allDriverscachedDriversData ******', allDriverscachedDriversData?.allDrivers);
			console.log('****** onlineDriverscachedDriversData ******', onlineDriverscachedDriversData?.onlineDrivers);
			console.log('****** offlineDriverscachedDriversData ******', offlineDriverscachedDriversData?.offlineDrivers);
			console.log('****** filterDriverListcached ******', filterDriverListcached?.filterDriverList);
			console.log('****** onlineDriverList*********', this.onlineDriverList);
			console.log('****** this.searchingDriverListArray******', this.searchingDriverListArray)

			// if ((allDriverscachedDriversData && allDriverscachedDriversData?.allDrivers.length > 0) && this.isFilterApplied == false && this.Global.isSetDriverLimit == false) { //! vishesh change 
			// 	// if ((allDriverscachedDriversData && allDriverscachedDriversData.length > 0)) {


			// 	console.log('****** ifffffffffffffffffffffffffffffffffffffffffffffffffffff ******',);

			// 	if (opts.onlineDriver == true) {
			// 		if (opts.isVehicleFilter != undefined && opts.isVehicleFilter == true) {
			// 			// if (this.Global.isSetDriverLimit == true) {
			// 			this.setOnlineDriver(onlineDriverscachedDriversData.onlineDrivers);
			// 			this.setAllDriver(onlineDriverscachedDriversData.onlineDrivers);
			// 			// }
			// 			this.setVehicleFilterDriver(filterDriverListcached.filterDriverList);
			// 		}
			// 		else {
			// 			this.setOnlineDriver(onlineDriverscachedDriversData.onlineDrivers);
			// 			this.setAllDriver(onlineDriverscachedDriversData.onlineDrivers);
			// 		}
			// 	}
			// 	else if (opts.offDriver == true) {
			// 		this.setOfflineDriver(offlineDriverscachedDriversData.offlineDrivers);
			// 	}
			// 	else {
			// 		this.setAllDriver(allDriverscachedDriversData.onlineDrivers);
			// 		this.setOnlineDriver(allDriverscachedDriversData.onlineDrivers);
			// 	}

			// 	let setDriverList: any = {}
			// 	if (opts.isReloadVehicleList == undefined || opts.isReloadVehicleList == true) {
			// 		setDriverList.isReloadVehicleList = true;
			// 		setDriverList.setDriverListShimmer = opts.loadShimmer;
			// 	}

			// 	if (opts.isReloadZoneWiseDriver == undefined || opts.isReloadZoneWiseDriver == true) {
			// 		setDriverList.isReloadZoneWiseDriver = true;
			// 	}

			// 	this.shareData.shareData({ 'function': 'allDriverForChat', 'driver': this.allDriverList });
			// 	this.setDriverList(setDriverList);
			// 	resolve({ status: true, 'drivers': onlineDriverscachedDriversData.onlineDrivers });

			// }
			// else {	
			console.log('OnGoing Request::::::::::::', request)
			this.CallMethod.getAllDriverList(request).pipe(takeUntil(this.unsubscribe$)).subscribe((result: any) => {
				if (result.status) {
					console.log('######### result', result)
					console.log('this.searchingDriverListArray right after call api', this.searchingDriverListArray)

					this.alllineDriverCount = result.allDriverCount;
					this.onlineDriverCount = result.onlineDriverCount;
					this.offlineDriverCount = result.offlineDriverCount;
					this.busyDriverCount = result.busyDriverCount;
					this.freeDriverCount = result.freeDriverCount;
					this.filterDriverTotalCount = result.driverCount;
					this.totalDriverBreakCount = result.totalDriverBreakCount;

					localStorage.setItem('totalDriverBreakCount', result.totalDriverBreakCount)

					console.log('****** opts.isVehicleFilter != undefined && opts.isVehicleFilter == true ******', opts.isVehicleFilter != undefined && opts.isVehicleFilter == true);
					console.log('this.isFilterApplied____________', opts.isVehicleFilter);
					if (opts.isVehicleFilter == undefined) {
						this.isFilterApplied = false
					}
					// if (opts.isVehicleFilter != undefined && opts.isVehicleFilter == true) {
					// 	this.setDataInLocalStorage('filterDriverListcached', { status: true, filterDriverList: result.online }, true);
					// }
					// else {
					// 	this.setDataInLocalStorage('allDriverscachedDriversData', { status: true, allDrivers: result.online }, true);
					// 	this.setDataInLocalStorage('onlineDriverscachedDriversData', { status: true, onlineDrivers: result.online }, true);
					// 	this.setDataInLocalStorage('offlineDriverscachedDriversData', { status: true, offlineDrivers: result.offline }, true);
					// }  
					console.log('########## result.online drivers', result.online)
					if (opts.onlineDriver == true && !opts.request?.search) {
						if (opts.isVehicleFilter != undefined && opts.isVehicleFilter == true && this.isFilterApplied) {
							console.log('set online driver_______1')
							// if (this.Global.isSetDriverLimit == true) {
							// this.setOnlineDriver(result.online);
							// this.setAllDriver(result.online);
							// } 
							this.setVehicleFilterDriver(result.online);
						}
						else {
							console.log('set online driver_______1.2')
							// this.setOnlineDriver(result.online);
							this.setAllDriver(result.online);
							this.setVehicleFilterDriver(result.online);
						}
					}
					else if (opts.onlineDriver == true && opts.request?.search) {
						if (opts.isVehicleFilter != undefined && opts.isVehicleFilter == true) {
							console.log('set online driver_______2')
							console.log('result online driver_______4', result.online)
							// if (this.Global.isSetDriverLimit == true) {
							this.setOnlineDriverFromSearch(result.online);
							this.setOnlineDriver(result.online);
							// } 
							this.setVehicleFilterDriver(result.online);
						}
						else {
							console.log('set online driver_______2.1')
							console.log('result online driver_______4', result.online)
							this.setOnlineDriverFromSearch(result.online);
							// this.setAllDriver(result.online);
						}
					}
					else if (opts.offDriver == true && !opts.request?.search) {
						console.log('set offline driver_______3')
						this.setOfflineDriver(result.offline);
					}
					else if (opts.offDriver == true && opts.request?.search) {
						console.log('set offline driver_______4')
						console.log('result offline driver_______4', result.offline)
						this.setOfflineDriverFromSearch(result.offline);
					}
					else if (opts.allDriver == true && !opts.request?.search) {
						console.log('set all driver_______3')
						this.setAllDriver(result.all);
						// this.setOfflineDriver(result.offline);
					}
					else if (opts.allDriver == true && opts.request?.search) {
						console.log('set all driver_______4')
						this.setAllDriverFromSearch(result.all);

					}
					else if (!opts.request?.search) {
						console.log('set offline driver_______3')

						this.setOnlineDriver(result.online);
					}

					console.log('****** opts.callType ******', opts.callType);
					// if (opts.callType == undefined) {
					// 	console.log('set offline driver_______4')
					// 	this.setAllDriver(result.online);	
					// }

					let setDriverList: any = {}
					if (opts.isReloadVehicleList == undefined || opts.isReloadVehicleList == true) {
						setDriverList.isReloadVehicleList = true;
						setDriverList.setDriverListShimmer = opts.loadShimmer;
					}

					if (opts.isReloadZoneWiseDriver == undefined || opts.isReloadZoneWiseDriver == true) {
						setDriverList.isReloadZoneWiseDriver = true;
					}

					this.shareData.shareData({ 'function': 'allDriverForChat', 'driver': this.onlineDriverList });
					this.setDriverList(setDriverList);
					resolve({ status: true, 'drivers': result.online });
				} else {
					this.driverListShimmer = false;
					this.zoneWiseDriverShimmer = false;
				}
			});
			// }
		});
	}




	// Modify the getDrivers function to use local storage caching
	// getDriverListNew(opts: any = {}): Observable<any> {
	// 	console.log('******getDriverListNew opts ******', opts);
	// 	const cachedData = this.getDataFromLocalStorage('cachedDriversData');
	// 	console.log('****** local stoage cachedData ******', cachedData);
	// 	// if (cachedData) {
	// 	// 	console.log('****** cachedData ******', cachedData);
	// 	// 	return of(cachedData);
	// 	// }

	// 	return new Observable((observer) => {
	// 		console.log('****** observer ******', observer);
	// 		let request: any = {};
	// 		this.driverListShimmer = (opts.loadShimmer) ? true : false;
	// 		if (opts.request != undefined) {
	// 			request = opts.request;
	// 		}
	// 		else {
	// 			if (opts.onlineDriver == true) {
	// 				request.flag = 1;
	// 			}
	// 			else if (opts.offDriver == true) {
	// 				request.flag = 2;
	// 			}
	// 			else {
	// 				request.flag = 3;
	// 			}
	// 		}

	// 		if (this.Global.isSetDriverLimit == true) {
	// 			request.driverIds = this.nearestDriverIds;
	// 		}

	// 		if (this.Global.isSetDriverLimit == true && ((opts.isVehicleFilter != undefined && opts.isVehicleFilter == true) || opts.callType != undefined && opts.callType == 'setAssign')) {
	// 			request.driverIds = undefined;
	// 			request.limit = this.filterDriverLimit;
	// 			request.offset = this.filterDriverOffset;
	// 		}
	// 		console.log('****** request ******', request);
	// 		this.CallMethod.getAllDriverList(request).pipe(takeUntil(this.unsubscribe$)).subscribe((result: any) => {
	// 			if (result.status) {
	// 				console.log('*****>>> getAllDriverList result <<<******');
	// 				this.alllineDriverCount = result.allDriverCount;
	// 				this.onlineDriverCount = result.onlineDriverCount;
	// 				this.offlineDriverCount = result.offlineDriverCount;
	// 				this.busyDriverCount = result.busyDriverCount;
	// 				this.freeDriverCount = result.freeDriverCount;
	// 				this.filterDriverTotalCount = result.driverCount;




	// 				if (opts.onlineDriver == true) {
	// 					if (opts.isVehicleFilter != undefined && opts.isVehicleFilter == true) {
	// 						if (this.Global.isSetDriverLimit == true) {
	// 							this.setOnlineDriver(result.online);
	// 						}

	// 						this.setVehicleFilterDriver(result.online);
	// 					}
	// 					else {
	// 						this.setOnlineDriver(result.online);
	// 					}
	// 				}
	// 				else if (opts.offDriver == true) {
	// 					this.setOfflineDriver(result.offline);
	// 				}
	// 				else {
	// 					this.setAllDriver(result.all);
	// 				}

	// 				let setDriverList: any = {}
	// 				if (opts.isReloadVehicleList == undefined || opts.isReloadVehicleList == true) {
	// 					setDriverList.isReloadVehicleList = true;
	// 					setDriverList.setDriverListShimmer = opts.loadShimmer;
	// 				}

	// 				if (opts.isReloadZoneWiseDriver == undefined || opts.isReloadZoneWiseDriver == true) {
	// 					setDriverList.isReloadZoneWiseDriver = true;
	// 				}

	// 				this.shareData.shareData({ 'function': 'allDriverForChat', 'driver': this.allDriverList });
	// 				this.setDriverList(setDriverList);

	// 				// Store the result in local storage
	// 				this.setDataInLocalStorage('allDriverscachedDriversData', { status: true, drivers: result.all }, false);

	// 				observer.next({ status: true, drivers: result.online });
	// 				observer.complete();
	// 			} else {
	// 				this.driverListShimmer = false;
	// 				this.zoneWiseDriverShimmer = false;
	// 			}
	// 		});
	// 	});
	// }




	setDriverObj(driverData: any = {}) {
		console.log('****** setDriverObj driverData ******', driverData);
		let freeTime = driverData.freeTime && driverData.freeTime != "" ? driverData.freeTime : moment().startOf('day').format("HH:mm:ss");
		let pickupTripTime = driverData.pickupTripTime && driverData.pickupTripTime != "" ? driverData.pickupTripTime : driverData.pickupTime ? driverData.pickupTime : '0000-00-00 00:00:00';
		let actualDropTime = driverData.finishTripTime && driverData.finishTripTime != "" ? driverData.finishTripTime : '0000-00-00 00:00:00';

		console.log('****** pickupTripTime ******', pickupTripTime);
		console.log('****** driverData.mobile ******', driverData.mobile);

		let driver: any = {
			'driverId': driverData.driverId,
			'driverName': driverData.driverName,
			'driverPhone': driverData.mobile ? driverData.mobile : driverData.driverPhone,
			'location': driverData.coords,
			// 'location': [driverData.coords?.length > 1 ? driverData.coords[0] : 0, driverData.coords?.length > 1 ? driverData.coords[0] : 0],
			'zoneId': driverData.zoneId,
			'bookingStatusFlag': driverData.bookingStatusFlag,
			'carTypeName': driverData.carTypeName,
			'driverStatus': driverData.driverStatus,
			'carType': driverData.carType,
			'driverCustomId': driverData.driverCustomId == undefined || driverData.driverCustomId == 0 || driverData.driverCustomId == "" ? driverData.driverName : driverData.driverCustomId,
			'pickUpAddress': (driverData.location_json && driverData.location_json.length > 0) ? driverData.location_json[0].address : '---',
			// 'userJson': (driverData.user_json  && driverData.user_json.length != 0) ? driverData.user_json : [],
			'userJson': (driverData.user_json && driverData.user_json.length > 0)
				? driverData.user_json
				: (driverData.userJson ? driverData.userJson : []),
			'jobs': driverData.jobs ? driverData.jobs : 0,
			'waitTime': driverData.waitTime && driverData.waitTime != "" && !driverData.waitTime.includes("Invalid date") ? driverData.waitTime : moment().startOf('day').format("HH:mm:ss"),
			'freeTime': freeTime,
			'onTimeBreak': driverData.onTimeBreak,
			'offTimeBreak': driverData.freeTime,
			'pickupTripTime': pickupTripTime,
			'finishTripTime': actualDropTime,
			'totalApproxTime': driverData.totalApproxTime,
			'arrivedTripTime': driverData.arrivedTripTime ? driverData.arrivedTripTime : moment().startOf('day').format("HH:mm:ss"),
			'beginTripTime': driverData.beginTripTime ? driverData.beginTripTime : moment().startOf('day').format("HH:mm:ss"),
			'acceptedTripTime': driverData.acceptedTripTime ? driverData.acceptedTripTime : moment().startOf('day').format("HH:mm:ss"),
			// 'slideToComplete': driverData.slided_to_complete ? driverData.slided_to_complete : 0,
			'slideToComplete': (driverData.slided_to_complete) ? driverData.slided_to_complete : (driverData.slideToComplete) ? driverData.slideToComplete : 0,
			// 'isDriverAssignBooking': driverData.is_driver_assign_booking ? driverData.is_driver_assign_booking : 0,
			'isDriverAssignBooking': (driverData.is_driver_assign_booking) ? driverData.is_driver_assign_booking : (driverData.isDriverAssignBooking) ? driverData.isDriverAssignBooking : 0,
			'bookingElapsedTime': driverData.bookingElapsedTime ? moment(driverData.bookingElapsedTime).format('YYYY-MM-DD HH:mm:ss') : moment(this.Global.getCompanyCurrentTime(), this.Global.dateFormat).format('YYYY-MM-DD HH:mm:ss'),
			'bookingFlag': driverData.bookingFlag,
			// 'locationJson': (driverData.location_json != undefined && driverData.location_json.length > 0) ? driverData.location_json : [],
			'isPreferenceBlink': false,
			'isDriverOnBreak': driverData.isDriverOnBreak, //! vishesh - new added
			// 'driverWarningPopupNumber': driverData.driver_warning_popup_number != undefined ? driverData.driver_warning_popup_number : 0,
			// 'goHome': driverData.go_home != undefined ? driverData.go_home : 0,
			// 'isOnBackToBack': driverData.is_on_back_to_back != undefined ? driverData.is_on_back_to_back : 0,
			'driverWarningPopupNumber': (driverData.driver_warning_popup_number) ? driverData.driver_warning_popup_number : (driverData.driverWarningPopupNumber) ? driverData.driverWarningPopupNumber : 0,
			'goHome': (driverData.go_home) ? driverData.go_home : (driverData.goHome) ? driverData.goHome : 0,
			'isOnBackToBack': (driverData.is_on_back_to_back) ? driverData.is_on_back_to_back : (driverData.isOnBackToBack) ? driverData.isOnBackToBack : 0,

		}

		console.log('****** driver.driverPhone ******', driver.driverPhone);

		if (driverData.location_json && driverData.location_json.length > 0) {
			driverData.locationJson = driverData.location_json;
		} else if (driverData.locationJson && driverData.locationJson.length > 0) {
			driverData.locationJson = driverData.locationJson;
		} else {
			driverData.locationJson = [];
		}

		driver.locationJson = driverData.locationJson;

		console.log('****** driver.locationJson ******', driver.locationJson);

		if ((driverData.location_json && driverData.location_json.length > 0) || (driverData.locationJson != undefined && driverData.locationJson.length > 0)) {
			let lastLocation = driverData.location_json || driverData.locationJson;
			if (lastLocation[lastLocation.length - 1]?.address === undefined) {
				driver.dropAddress = 'Destination Later';
			} else {
				driver.dropAddress = lastLocation[lastLocation.length - 1].address;
			}
		} else {
			driver.dropAddress = '---';
		}

		// if (driverData.location_json != undefined && driverData.location_json.length != 0) {
		// 	if (driverData.location_json[driverData.location_json.length - 1].address == undefined) {
		// 		driver.dropAddress = 'Destination Later';
		// 	} else {
		// 		driver.dropAddress = driverData.location_json[driverData.location_json.length - 1].address;
		// 	}
		// } else {
		// 	driver.dropAddress = '---';
		// }
		console.log('****** driverData.preferences ******', driverData.preferences);

		driver.preferences = this.setDriverPreferences({
			'driver_car_type_name_preferences': driverData.preferences?.hasOwnProperty('cityRide') ? driverData.preferences.cityRide : driverData.driver_car_type_name_preferences ? driverData.driver_car_type_name_preferences : [],
			'driver_car_type_name_preferences_delivery': driverData.preferences?.hasOwnProperty('delivery') ? driverData.preferences.delivery : driverData.driver_car_type_name_preferences_delivery ? driverData.driver_car_type_name_preferences_delivery : [],
			'driver_car_type_name_preferences_airport': driverData.preferences?.hasOwnProperty('airport') ? driverData.preferences.airport : driverData.driver_car_type_name_preferences_airport ? driverData.driver_car_type_name_preferences_airport : [],
			'driver_car_type_name_preferences_outStation': driverData.preferences?.hasOwnProperty('outStation') ? driverData.preferences.outStation : driverData.driver_car_type_name_preferences_outStation ? driverData.driver_car_type_name_preferences_outStation : [],
			'driver_car_type_name_preferences_rental': driverData.preferences?.hasOwnProperty('rental') ? driverData.preferences.rental : driverData.driver_car_type_name_preferences_rental ? driverData.driver_car_type_name_preferences_rental : [],
			'driver_car_type_name_preferences_restaurant': driverData.preferences?.hasOwnProperty('restaurant') ? driverData.preferences.restaurant : driverData.driver_car_type_name_preferences_restaurant ? driverData.driver_car_type_name_preferences_restaurant : [],
		});

		console.log('****** main driver.preferences  ******', driver.preferences);
		console.log('****** driver ******', driver);
		console.log('****** driver.waitTime ******', driver.waitTime);

		return driver;
	}
	cityRidePreferences: any[] = [];
	deliveryPreferences: any[] = [];
	airportPreferences: any[] = [];
	outStationPreferences: any[] = [];
	rentalPreferences: any[] = [];
	restaurantPreferences: any[] = [];
	setDriverPreferences(driverData: any = {}) {
		let preferences: any = {};
		console.log('****** driverData  setDriverPreferences******', driverData);
		this.cityRidePreferences = driverData.driver_car_type_name_preferences ? driverData.driver_car_type_name_preferences : [];
		this.deliveryPreferences = driverData.driver_car_type_name_preferences_delivery ? driverData.driver_car_type_name_preferences_delivery : [];
		this.airportPreferences = driverData.driver_car_type_name_preferences_airport ? driverData.driver_car_type_name_preferences_airport : [];
		this.outStationPreferences = driverData.driver_car_type_name_preferences_outStation ? driverData.driver_car_type_name_preferences_outStation : [];
		this.rentalPreferences = driverData.driver_car_type_name_preferences_rental ? driverData.driver_car_type_name_preferences_rental : [];
		this.restaurantPreferences = driverData.driver_car_type_name_preferences_restaurant ? driverData.driver_car_type_name_preferences_restaurant : [];


		if (this.cityRidePreferences.length > 0) {
			preferences['cityRide'] = this.cityRidePreferences;
		}
		if (this.deliveryPreferences.length > 0) {
			preferences['delivery'] = this.deliveryPreferences;
		}
		if (this.outStationPreferences.length > 0) {
			preferences['outStation'] = this.outStationPreferences;
		}
		if (this.rentalPreferences.length > 0) {
			preferences['rental'] = this.rentalPreferences;
		}
		if (this.restaurantPreferences.length > 0) {
			preferences['restaurant'] = this.restaurantPreferences;
		}
		if (this.airportPreferences.length > 0) {
			preferences['airport'] = this.airportPreferences;
		}
		console.log('****** preferences ******', preferences);
		return preferences;
	}
	allDriverList: any[] = [];
	setAllDriver(drivers: any) {
		this.onlineDriverList = [];
		this.offlineDriverList = [];
		this.allDriverList = [];
		this.filterDriverList = [];
		if (drivers.length > 0) {

			for (let i = 0; i < drivers.length; i++) {
				console.log('_______ Drivers from Loop+++++++', drivers[i])
				console.log('checking coords length', drivers[i].coords?.length > 0)
				console.log('_____Entering in if condition___', drivers[i].coords?.length > 0)
				let driverData = this.setDriverObj(drivers[i]);
				console.log('****** all Driver  driverData ******', driverData);
				this.allDriverList.push(driverData);
				if (drivers[i].driverStatus == 1 || drivers[i].driverStatus == 2) {
					this.onlineDriverList.push(driverData);
				} else if (drivers[i].driverStatus == 0) {
					this.offlineDriverList.push(driverData);
				}
			}

			this.setDriverMarkerLabel(this.onlineDriverList)
			if (this.Global.isSetDriverLimit == true) {
				this.searchingDriverListArray = this.allDriverList;
			}
		}
	}

	allDriverListWithSearch: any[] = [];
	setAllDriverFromSearch(drivers: any) {
		console.log('this.Global.isSetDriverLimit__________*******', this.Global.isSetDriverLimit)
		this.allDriverListWithSearch = [];
		this.filterDriverList = [];
		if (drivers.length > 0) {
			for (let i = 0; i < drivers.length; i++) {
				let driverData = this.setDriverObj(drivers[i]);
				console.log('****** all Driver  driverData ******', driverData);
				this.allDriverListWithSearch.push(driverData);
			}
			// if (this.Global.isSetDriverLimit == true) {
			if (!this.vehicleFilterApplied || this.isDriverSearchOn) {
				console.log('this.Global.isSetDriverLimit is true in allDriverFromSearch________')
				this.searchingDriverListArray = this.allDriverListWithSearch;
				console.log('searchingDriverListArray_________', this.searchingDriverListArray)
			}
		}
	}

	onlineDriverList: any[] = [];
	setOnlineDriver(drivers: any) {
		console.log('****** setOnlineDriver drivers ******', drivers);
		console.log('this.searchingDriverListArray in setonlinedriver', this.searchingDriverListArray)
		this.onlineDriverList = [];
		this.filterDriverList = [];
		if (drivers.length > 0) {
			for (let i = 0; i < drivers.length; i++) {
				let driverData = this.setDriverObj(drivers[i]);
				this.onlineDriverList.push(driverData);
			}
			if (this.Global.isSetDriverLimit == true && !this.vehicleFilterApplied) {
				console.log('updating search driver list array')
				this.searchingDriverListArray = this.onlineDriverList;
			}
			console.log('****** onlineDriverList ******', this.onlineDriverList);
		}
	}

	offlineDriverList: any[] = [];
	setOfflineDriver(drivers: any) {
		this.offlineDriverList = [];
		if (drivers.length > 0) {
			for (let i = 0; i < drivers.length; i++) {
				let driverData = this.setDriverObj(drivers[i]);
				this.offlineDriverList.push(driverData);
			}
			if (this.Global.isSetDriverLimit == true) {
				this.searchingDriverListArray = this.offlineDriverList;
			}
		}
	}

	onlineDriverWithSearch: any[] = [];
	setOnlineDriverFromSearch(drivers: any) {
		console.log('****** setOnlineDriver drivers ******', drivers);
		console.log('this.searchingDriverListArray in setonlinedriverwithsearch', this.searchingDriverListArray)
		this.onlineDriverWithSearch = [];
		// this.filterDriverList = [];
		if (drivers.length > 0) {
			for (let i = 0; i < drivers.length; i++) {
				let driverData = this.setDriverObj(drivers[i]);
				this.onlineDriverWithSearch.push(driverData);
			}
			// if (this.Global.isSetDriverLimit == true) {
			if (!this.vehicleFilterApplied || this.isDriverSearchOn) {
				console.log('updating search driver list array')
				this.searchingDriverListArray = this.onlineDriverWithSearch;
			}
			console.log('****** onlineDriverWithSearch ******', this.onlineDriverWithSearch);
		}
	}

	offlineDriverWithSearch: any[] = [];
	setOfflineDriverFromSearch(drivers: any) {
		this.offlineDriverWithSearch = [];
		console.log('setOffline driver drivers length', drivers.length);
		console.log(':::::: this.global.isSetDriverLimit', this.Global.isSetDriverLimit)
		if (drivers.length > 0) {
			for (let i = 0; i < drivers.length; i++) {
				let driverData = this.setDriverObj(drivers[i]);
				this.offlineDriverWithSearch.push(driverData);
			}

			if (!this.vehicleFilterApplied || this.isDriverSearchOn) {
				console.log('updating search driver list array')
				this.searchingDriverListArray = this.offlineDriverWithSearch;
			}

			console.log('****** offlineDriverWithSearch ******', this.offlineDriverWithSearch);
		}
	}

	filterDriverList: any = [];
	setVehicleFilterDriver(drivers: any) {
		console.log('--------------------- setVehicleFilterDriver drivers ---------------------', drivers)
		this.filterDriverList = [];
		if (drivers.length > 0) {
			for (let i = 0; i < drivers.length; i++) {
				let driverData = this.setDriverObj(drivers[i]);
				this.filterDriverList.push(driverData);
			}
		}
		console.log('--------------------- setVehicleFilterDriver this.filterDriverList ---------------------', this.filterDriverList)
		this.setDataInLocalStorage('filterDriverListcached', { status: true, filterDriverList: this.filterDriverList }, true);

	}
	setDriverList(opts: any) {
		console.log("Set Driver List setDriverList >>>>>>>>>>>>>>", opts);
		console.log('*********this.online driver list*******', this.onlineDriverList)
		if (opts.updateOldDriverData != undefined && opts.oldDriverId && Object.keys(opts.updateOldDriverData).length > 0) {
			if (opts.isBlink != undefined && opts.isBlink == true && opts.updateOldDriverData.isDriverAssignBooking != undefined) {
				this.blinkDriverObj[opts.oldDriverId + '_blink'].isBlink = opts.updateOldDriverData.isDriverAssignBooking;
			}
			console.log('****** old driver id  ******',);
			console.log('*********this.online driver list*******', this.onlineDriverList)
			this.onlineDriverList = this.updateArrayWithObject(this.onlineDriverList, ['driverId', opts.oldDriverId], opts.updateOldDriverData);
			this.allDriverList = this.updateArrayWithObject(this.allDriverList, ['driverId', opts.oldDriverId], opts.updateOldDriverData);
			console.log('****** this.onlineDriverList ******', this.onlineDriverList);
			this.setDataInLocalStorage('onlineDriverscachedDriversData', { status: true, onlineDrivers: this.onlineDriverList }, false);
			this.setDataInLocalStorage('allDriverscachedDriversData', { status: true, allDrivers: this.allDriverList }, false);

			if (this.vehicleFilterCount > 0) {
				this.updateArrayWithObject(this.filterDriverList, ['driverId', opts.oldDriverId], opts.updateOldDriverData);
			}
		}

		if (opts.updateDriverData != undefined && opts.driverId && Object.keys(opts.updateDriverData).length > 0) {
			if (opts.isBlink != undefined && opts.isBlink == true && opts.updateDriverData.isDriverAssignBooking != undefined) {
				this.blinkDriverObj[opts.driverId + '_blink'].isBlink = opts.updateDriverData.isDriverAssignBooking;
				if (opts.updateDriverData.bookingElapsedTime != undefined) {
					this.blinkDriverObj[opts.driverId + '_blink'].blinkTime = opts.updateDriverData.bookingElapsedTime;
				}
			}

			console.log('******before break this.onlineDriverList ******', this.onlineDriverList);

			let isAlreadyExists = this.onlineDriverList.find(driver => driver.driverId == opts.driverId)
			console.log('****** find in array this.onlineDriverList ******', isAlreadyExists);
			if (isAlreadyExists) {
				this.onlineDriverList = this.updateArrayWithObject(this.onlineDriverList, ['driverId', opts.driverId], opts.updateDriverData);
			}


			console.log('****** break this.onlineDriverList ******', this.onlineDriverList);

			this.allDriverList = this.updateArrayWithObject(this.allDriverList, ['driverId', opts.driverId], opts.updateDriverData);
			this.setDataInLocalStorage('onlineDriverscachedDriversData', { status: true, onlineDrivers: this.onlineDriverList }, false);
			this.setDataInLocalStorage('allDriverscachedDriversData', { status: true, allDrivers: this.allDriverList }, false);

			if (this.vehicleFilterCount > 0) {
				this.filterDriverList = this.updateArrayWithObject(this.filterDriverList, ['driverId', opts.driverId], opts.updateDriverData);
				this.setDataInLocalStorage('filterDriverListcached', { status: true, filterDriverList: this.filterDriverList }, true);
			}

			if (opts.nearestDriverPreference && opts.nearestDriverPreference == true && this.nearestDriverList.length > 0) {
				let nearestDriverIndex = this.nearestDriverList.findIndex(((obj: any) => obj.driverId == opts.driverId));
				if (nearestDriverIndex != -1) {
					if (this.nearestDriverListSelectedBookingType == 0) {
						this.nearestDriverList[nearestDriverIndex].preferences = opts.updateDriverData.preferences.cityRide ? opts.updateDriverData.preferences.cityRide : [];
					}
					else if (this.nearestDriverListSelectedBookingType == 1) {
						this.nearestDriverList[nearestDriverIndex].preferences = opts.updateDriverData.preferences.rental ? opts.updateDriverData.preferences.rental : [];
					}
					else if (this.nearestDriverListSelectedBookingType == 2) {
						this.nearestDriverList[nearestDriverIndex].preferences = opts.updateDriverData.preferences.outStation ? opts.updateDriverData.preferences.outStation : [];
					}
				}
			}

		}



		if (opts.isReloadVehicleList != undefined && opts.isReloadVehicleList == true) {
			this.driverListShimmer = opts.setDriverListShimmer ? true : false
			console.log('--------------------- this.vehicleFilterCount ---------------------', this.vehicleFilterCount)
			console.log('--------------------- this.filterDriverList ---------------------', this.filterDriverList)
			console.log('--------------------- this.onlineDriverList ---------------------', this.onlineDriverList)
			if (this.vehicleFilterCount > 0) {
				this.setVehicleDriverList(this.onlineDriverList);
			}
			else {
				this.setVehicleDriverList(this.onlineDriverList);
			}
			// this.driverListShimmer = false;
		}
		if (opts.isReloadZoneWiseDriver != undefined && opts.isReloadZoneWiseDriver == true) {
			this.setZoneWiseDriver_new(this.onlineDriverList);
		}
	}

	// ! vishesh 
	formatTime(milliseconds: number): string {
		const hours = Math.floor(milliseconds / 3600000);
		milliseconds -= hours * 3600000;
		const minutes = Math.floor(milliseconds / 60000);
		milliseconds -= minutes * 60000;
		const seconds = Math.floor(milliseconds / 1000);

		return `${hours}h ${minutes}m ${seconds}s`;
	}

	targetTime: any = new Date;
	timeRemaining: number = 0;

	// ! new vishesh
	calculateTimeRemaining(date?: any, timezone?: any) {
		// console.log('****** this.getCompanyTimeZone ******', this.getCompanyTimeZone);
		let currentTime: any = moment()
		if (this.getCompanyTimeZone) {
			currentTime = moment().tz(this.getCompanyTimeZone).format('YYYY-MM-DD HH:mm:ss');
			this.targetTime = moment(date).format('YYYY-MM-DD HH:mm:ss');
		}
		// console.log('****** timezone ******', timezone);
		// console.log('****** currentTime ******', currentTime);
		// console.log('****** this.targetTime ******', this.targetTime);
		const timeDiff: any = moment(currentTime).diff(moment(this.targetTime));
		// this.timeRemaining = Math.max(0, timeDiff);
		this.timeRemaining = timeDiff;
		// console.log('****** this.timeRemaining ******', this.timeRemaining);
	}

	driverListShimmer: boolean = true;
	freeDriverList: any = [];
	acceptedBookingDriverList: any = [];
	ontripBookingDriverList: any = [];
	completedBookingDriverList: any = [];
	showDriversDriverIds: any = [];
	setVehicleDriverList(driverList: any) {
		this.freeDriverList = [];
		this.acceptedBookingDriverList = [];
		this.ontripBookingDriverList = [];
		this.completedBookingDriverList = [];
		this.waitTimeObj = {};
		this.dropTimeDelayObj = {};
		this.pickupTimeDelayObj = {};
		this.showDriversDriverIds = [];
		console.log('########### 4354 search string ###########', this.searchString);
		console.log('_______ this.vehicleFilterApplied', this.vehicleFilterApplied);
		if (driverList && this.reqObjForFilter && !this.vehicleFilterApplied == false) {
			this.reqObjForFilter.search = this.searchString;
			console.log('########## 4350 #########', this.reqObjForFilter)
			console.log('########## 4351 #########', driverList)
			driverList = this.filterListOfDriver(driverList, this.reqObjForFilter)
			console.log('driver List withh filter function applied-----------', driverList)
		}
		console.log('--------------------- setVehicleDriverList driverList ---------------------', driverList)
		console.log(':::**************** this.completedBookingDriverList', this.completedBookingDriverList)
		for (let i = 0; i < driverList.length; i++) {
			this.blinkDriverObj[driverList[i].driverId + '_blink'] = { isBlink: driverList[i].isDriverAssignBooking, blinkTime: driverList[i].bookingElapsedTime }

			this.showDriversDriverIds.push(driverList[i].driverId);
			driverList[i].pickUpAddress = (driverList[i].locationJson != undefined && driverList[i].locationJson.length != 0) ? driverList[i].locationJson[0].address : '---';
			if (driverList[i].locationJson != undefined && driverList[i].locationJson.length != 0) {
				if (driverList[i].locationJson[driverList[i].locationJson.length - 1].address == undefined) {
					driverList[i].dropAddress = 'Destination Later';
				} else {
					driverList[i].dropAddress = driverList[i].locationJson[driverList[i].locationJson.length - 1].address;
				}
			} else {
				driverList[i].dropAddress = '---';
			}
			this.setAcceptedTripTimeDelayObj(driverList[i].acceptedTripTime, driverList[i].arrivedTripTime, driverList[i].driverId);

			if (driverList[i].bookingStatusFlag <= 0) {
				console.log('if status flag 1 ++++++++++++')
				driverList[i].waitTime = moment().startOf('day').format("HH:mm:ss");
				driverList[i].freeTime = moment(driverList[i].freeTime).format('YYYY-MM-DD HH:mm:ss');
				this.setDriverFreeTimeObj({
					'driverId': driverList[i].driverId,
					'freeTime': driverList[i].freeTime
				});
				this.freeDriverList.push(driverList[i]);
			}
			else if (driverList[i].bookingStatusFlag == 3) {
				console.log('if status flag 3 ++++++++++++')
				this.setpickupTimeDelayObj(driverList[i].pickupTripTime, driverList[i].arrivedTripTime, driverList[i].driverId)
				driverList[i].freeTime = "Busy"
				driverList[i].waitTime = moment().startOf('day').format("HH:mm:ss");
				this.acceptedBookingDriverList.push(driverList[i]);

			}
			else if (driverList[i].bookingStatusFlag == 7) {
				console.log('if status flag 7 ++++++++++++')
				this.setpickupTimeDelayObj(driverList[i].pickupTripTime, driverList[i].arrivedTripTime, driverList[i].driverId);
				driverList[i].finishTripTime = "";
				driverList[i].freeTime = "Busy";

				this.calculatewaitTime(driverList[i].arrivedTripTime, driverList[i].driverId, driverList[i].bookingFlag);
				this.ontripBookingDriverList.push(driverList[i]);


			}
			else if (driverList[i].bookingStatusFlag == 8) {
				console.log('if status flag 8 ++++++++++++')

				driverList[i].freeTime = "Busy";

				if (driverList[i].slideToComplete == 1) {
					this.setPaymentDelayObj(driverList[i].finishTripTime, driverList[i].driverId)
				}
				let beginTripTime = moment(driverList[i].beginTripTime).format('YYYY-MM-DD HH:mm:ss');
				let arrivedTripTime = moment(driverList[i].arrivedTripTime).format('YYYY-MM-DD HH:mm:ss');
				let waitTimeMinutes = moment(beginTripTime).diff(arrivedTripTime, 'minutes');
				let waitTimeLimit = driverList[i].bookingFlag == 0 ? this.Global.waitTime : driverList[i].bookingFlag == 1 ? this.Global.rentalWaitTime : this.Global.outStationWaitTime;
				let estimatedDropTime = moment(beginTripTime, 'YYYY-MM-DD HH:mm:ss').add(driverList[i].totalApproxTime, 'minutes').format('YYYY-MM-DD HH:mm:ss');
				this.setpickupTimeDelayObj(driverList[i].pickupTripTime, driverList[i].arrivedTripTime, driverList[i].driverId);

				this.setDropTimeDelayObj(estimatedDropTime, driverList[i].finishTripTime, driverList[i].driverId);

				if (waitTimeMinutes >= 1440) {
					let labal = (moment(beginTripTime).diff(arrivedTripTime, 'days') > 1) ? "days" : "day";
					driverList[i].waitTime = moment(beginTripTime).diff(arrivedTripTime, 'days') + " " + labal;
				} else {
					let newtime = moment.utc(moment(driverList[i].beginTripTime, 'YYYY-MM-DD HH:mm:ss').diff(moment(driverList[i].arrivedTripTime, 'YYYY-MM-DD HH:mm:ss'))).format("HH:mm:ss")
					driverList[i].waitTime = newtime

				}

				if (moment(beginTripTime).diff(arrivedTripTime, 'minutes') >= Number(waitTimeLimit)) {
					driverList[i].waitTimeColor = true;
				} else {
					driverList[i].waitTimeColor = false;
				}
				this.completedBookingDriverList.push(driverList[i]);

				console.log('this.completedBookingDriverList ##########', this.completedBookingDriverList)
			}
		}
		this.driverListShimmer = false;
		this.freeDriverList = this.Global.sortArrayOfObject(this.freeDriverList, 'freeTime', this.Global.onlineDriverListSortBy.freeDriverList);
		this.acceptedBookingDriverList = this.Global.sortArrayOfObject(this.acceptedBookingDriverList, 'acceptedTripTime', this.Global.onlineDriverListSortBy.acceptedBookingDriverList);
		this.ontripBookingDriverList = this.Global.sortArrayOfObject(this.ontripBookingDriverList, 'arrivedTripTime', this.Global.onlineDriverListSortBy.ontripBookingDriverList);
		this.completedBookingDriverList = this.Global.sortArrayOfObject(this.completedBookingDriverList, 'beginTripTime', this.Global.onlineDriverListSortBy.completedBookingDriverList);

		console.log('****** this.freeDriverList ******', this.freeDriverList);
		console.log('****** this.acceptedBookingDriverList ******', this.acceptedBookingDriverList);
		console.log('****** this.ontripBookingDriverList ******', this.ontripBookingDriverList);
		console.log('****** this.completedBookingDriverList ******', this.completedBookingDriverList);
	}

	filterListOfDriver(driverList: any, requestObj: any) {
		const searchTerm = requestObj.search?.toLowerCase(); // Get the search term once
		const { carType = {}, status = {} } = requestObj;
		console.log('carType obj++++++++', carType)
		if (driverList.length === 0) return driverList; // Early return if no search term or empty list

		return driverList.filter((driver: any) => {
			let matchesSearch = searchTerm.length === 0;
			// Check each property in searchBy
			for (const key in requestObj.searchBy) {
				if (requestObj.searchBy.hasOwnProperty(key)) {
					const propertyKey = requestObj.searchBy[key];
					const driverValue = driver[propertyKey];

					// Check if the property exists and includes the search term
					if (driverValue && driverValue.toLowerCase().includes(searchTerm)) {
						matchesSearch = true; // Found a match
						break; // No need to check other properties
						// return true;
					}
				}
			}

			let matchesCarType = Object.keys(carType).length == 0; // Assume it matches unless proven otherwise

			if (Object.keys(carType).length > 0) {
				const keys = Object.keys(carType);
				for (let i = 0; i < keys.length; i++) {
					const key = keys[i];
					const value = carType[key];

					console.log('--------------------- drivers for filter ---------------------', driver);
					console.log('carType keys_____', key)
					if (driver.carType && driver.carType.length > 0) {
						console.log('_____driver driverName____', driver.driverName)
						if (driver.carType === value) {
							matchesCarType = true; // Found a match
							break; // Exit loop on first match
						}
					}

					if (driver.preferences && Object.keys(driver.preferences).length) {
						console.log('_____driver preferences____', driver.driverName, driver.preferences)
						for (let k = 0; k < Object.keys(driver.preferences).length; k++) {
							for (let j = 0; j < driver.preferences[Object.keys(driver.preferences)[k]].length; j++) {
								if (driver.preferences[Object.keys(driver.preferences)[k]][j].toLowerCase() === key.toLowerCase()) {
									matchesCarType = true; // Found a match
									console.log('condition for preferences is matched_________')
									break; // Exit loop on first match
								}
							}
					  }
					}

					else {
						matchesCarType = false
						// break
					}
				}
			}

			let matchesStatus = Object.keys(status).length == 0; // Assume it matches unless proven otherwise

			if (Object.keys(status).length > 0) {
				const keys = Object.keys(status);
				for (let i = 0; i < keys.length; i++) {
					const key = keys[i];
					const value = status[key];
					if (driver.bookingStatusFlag === value) {
						matchesStatus = true; // Found a match
						break; // Exit loop on first match
					}
				}
			}

			// Return true if all filters match		
			console.log('matchesSearch_________', matchesSearch);
			console.log('matchesCarType_________', matchesCarType);
			console.log('matchesStatus_________', matchesStatus);

			return matchesSearch && matchesCarType && matchesStatus;
			// return false;
		});
	}

	zoneWiseDriverTab(event: any) {

	}
	zoneWiseDriverShimmer: boolean = true;
	setZoneWiseDriver_new(driverList: any) {
		console.log('****** setZoneWiseDriver_new driverList ******', driverList);
		if (this.Global.surgePriceZone.length > 0) {
			for (let i = 0; i < this.Global.surgePriceZone.length; i++) {
				this.Global.surgePriceZone[i].driverList = {};
				this.Global.surgePriceZone[i].driverList.freeDriverList = [];
				this.Global.surgePriceZone[i].driverList.acceptedBookingDriverList = [];
				this.Global.surgePriceZone[i].driverList.ontripBookingDriverList = [];
				this.Global.surgePriceZone[i].driverList.completedBookingDriverList = [];
			}

			for (let i = 0; i < driverList.length; i++) {
				this.blinkDriverObj[driverList[i].driverId + '_blink'] = { isBlink: driverList[i].isDriverAssignBooking, blinkTime: driverList[i].bookingElapsedTime }

				let driverData = {
					'driverId': driverList[i].driverId,
					'driverName': driverList[i].driverName,
					'driverCustomId': driverList[i].driverCustomId,
					'slideToComplete': driverList[i].slideToComplete,
					'freeTime': driverList[i].freeTime,
					'acceptedTripTime': driverList[i].acceptedTripTime,
					'arrivedTripTime': driverList[i].arrivedTripTime,
					'beginTripTime': driverList[i].beginTripTime,
					'isDriverOnBreak': driverList[i].isDriverOnBreak,
				}

				let objIndex = this.Global.surgePriceZone.findIndex(((obj: any) => obj.zoneId == driverList[i].zoneId));

				if (objIndex >= 0) {
					if (driverList[i].bookingStatusFlag <= 0) {
						this.Global.surgePriceZone[objIndex].driverList.freeDriverList.push(driverData);
					}
					else if (driverList[i].bookingStatusFlag == 3) {
						this.Global.surgePriceZone[objIndex].driverList.acceptedBookingDriverList.push(driverData);
					}
					else if (driverList[i].bookingStatusFlag == 7) {
						this.Global.surgePriceZone[objIndex].driverList.ontripBookingDriverList.push(driverData);
					}
					else if (driverList[i].bookingStatusFlag == 8) {
						this.Global.surgePriceZone[objIndex].driverList.completedBookingDriverList.push(driverData);
					}
				}
				else {
					if (driverList[i].bookingStatusFlag <= 0) {
						this.Global.surgePriceZone[0].driverList.freeDriverList.push(driverData);
					}
					else if (driverList[i].bookingStatusFlag == 3) {
						this.Global.surgePriceZone[0].driverList.acceptedBookingDriverList.push(driverData);
					}
					else if (driverList[i].bookingStatusFlag == 7) {
						this.Global.surgePriceZone[0].driverList.ontripBookingDriverList.push(driverData);
					}
					else if (driverList[i].bookingStatusFlag == 8) {
						this.Global.surgePriceZone[0].driverList.completedBookingDriverList.push(driverData);
					}
				}
			}

			for (let i = 0; i < this.Global.surgePriceZone.length; i++) {
				this.Global.surgePriceZone[i].driverList.freeDriverList = this.Global.sortArrayOfObject(this.Global.surgePriceZone[i].driverList.freeDriverList, 'freeTime', this.Global.onlineDriverListSortBy.freeDriverList);
				this.Global.surgePriceZone[i].driverList.acceptedBookingDriverList = this.Global.sortArrayOfObject(this.Global.surgePriceZone[i].driverList.acceptedBookingDriverList, 'acceptedTripTime', this.Global.onlineDriverListSortBy.acceptedBookingDriverList);
				this.Global.surgePriceZone[i].driverList.ontripBookingDriverList = this.Global.sortArrayOfObject(this.Global.surgePriceZone[i].driverList.ontripBookingDriverList, 'arrivedTripTime', this.Global.onlineDriverListSortBy.ontripBookingDriverList);
				this.Global.surgePriceZone[i].driverList.completedBookingDriverList = this.Global.sortArrayOfObject(this.Global.surgePriceZone[i].driverList.completedBookingDriverList, 'beginTripTime', this.Global.onlineDriverListSortBy.completedBookingDriverList);
			}
		}
	}
	setDriversZone(driverId: string, zoneId: string) {
		let objIndex = this.onlineDriverList.findIndex(((obj: any) => obj.driverId == driverId));

		if (objIndex != -1) {
			let driverOldZoneId = this.onlineDriverList[objIndex].zoneId;

			if (driverOldZoneId != zoneId) {
				let driverStatus = this.onlineDriverList[objIndex].bookingStatusFlag;
				let statusArr: any = {
					0: 'freeDriverList',
					3: 'acceptedBookingDriverList',
					7: 'ontripBookingDriverList',
					8: 'completedBookingDriverList',
				}
				if (driverStatus < 0) {
					driverStatus = 0;
				}
				let status = statusArr[driverStatus];

				this.onlineDriverList = this.updateArrayWithObject(this.onlineDriverList, ['driverId', driverId], { zoneId: zoneId });

				this.setDataInLocalStorage('onlineDriverscachedDriversData', { status: true, onlineDrivers: this.onlineDriverList }, false);


				let oldZoneArrIndex = this.Global.surgePriceZone.findIndex(((obj: any) => obj.zoneId == driverOldZoneId));
				if (oldZoneArrIndex != -1) {
					let driverIndex = this.Global.surgePriceZone[oldZoneArrIndex].driverList[status].findIndex(((obj: any) => obj.driverId == driverId));
					let driverData = this.Global.surgePriceZone[oldZoneArrIndex].driverList[status][driverIndex];
					this.removeObjectInArray(this.Global.surgePriceZone[oldZoneArrIndex].driverList[status], ['driverId', driverId]);
					let newZoneArrIndex = this.Global.surgePriceZone.findIndex(((obj: any) => obj.zoneId == zoneId));
					this.Global.surgePriceZone[newZoneArrIndex].driverList[status].push(driverData)

				}
			}
		}
	}
	mapMarkerDriversList: any = [];
	nearestDriverIds: any = [];
	findDriverVariant: number = 500;
	findDriverCount: number = 500;
	setDriverInMap(location: any) {
		return new Promise((resolve, reject) => {
			this.findDriverCount = (this.Global.isSetDriverLimit == true) ? 10 : this.findDriverCount;

			let postData = {
				"lat": location[0],
				"long": location[1],
				"driverType": 4,
				"variant": this.findDriverVariant,
				"count": this.findDriverCount
			}


			this.CallMethod.getNearestDriver(postData).pipe(takeUntil(this.unsubscribe$)).subscribe((result: any) => {
				console.log("setDriverInMap:::::::", result);
				if (result.status == true) {
					this.nearestDriverIds = [];
					for (let i = 0; i < result.nearestDriver.length; i++) {
						this.nearestDriverIds.push(result.nearestDriver[i].driverId);

					}

					if (this.isOpenBookingTab == 'active') {
						for (let i = 0; i < this.activeBookings.length; i++) {
							if (!this.nearestDriverIds.includes(this.activeBookings[i].driverId)) {
								result.nearestDriver.push({
									"driverId": this.activeBookings[i].driverId,
									"driverName": this.activeBookings[i].driverJson.driverName,
									"location": this.activeBookings[i].driverJson.location,
									"carType": this.activeBookings[i].carTypeId
								});
								this.nearestDriverIds.push(this.activeBookings[i].driverId);
							}
						}
					}

					return resolve({ 'driverData': result.nearestDriver });
				}
			});
		});
	}
	setDriverMarkerLabel(driverArr: any, isPush: boolean = false) {
		if (isPush != true) {
			this.mapMarkerDriversList = [];
		}
		console.log('****** driverArr ******', driverArr);
		for (let i = 0; i < driverArr.length; i++) {
			let markerObj: any = {
				id: driverArr[i].driverId,
				lat: driverArr[i].location[0],
				lng: driverArr[i].location[1],
				driverName: driverArr[i].driverName,
				driverLocationData: [[driverArr[i].location]],
				infoWindowIsOpen: false,
				icon: {
					url: this.setDriverCarIcon(driverArr[i].driverId),
					scaledSize: {
						height: this.Global.driverMarkerIcon.height,
						width: this.Global.driverMarkerIcon.width
					},
					labelOrigin: this.Global.driverMarkerIcon.labelOrigin,
				},
			}
			console.log(i, 'Driver Icon number--------', this.setDriverCarIcon(driverArr[i].driverId))
			let index = this.allDriverList.findIndex(((obj: any) => obj.driverId == driverArr[i].driverId));
			let driverCustomId = (index >= 0 && this.allDriverList[index].driverCustomId != undefined) ? this.allDriverList[index].driverCustomId : '';
			if (this.Global.driverMarkerLabel.isShow == true) {
				markerObj.label = {
					color: this.Global.driverMarkerLabel.color,
					fontFamily: this.Global.driverMarkerLabel.fontFamily,
					fontSize: this.Global.driverMarkerLabel.fontSize,
					fontWeight: this.Global.driverMarkerLabel.fontWeight,
					text: driverCustomId
				}
			}

			this.mapMarkerDriversList.push(markerObj);
		}
		console.log('mapmarkers driver List____********', this.mapMarkerDriversList)
	}
	setOnlineOfflineDriverData(driverData: any) {
		console.log('****** setOnlineOfflineDriverData driverData ******', driverData);
		for (let i = 0; i < driverData.length; i++) {
			let driver = this.setDriverObj(driverData[i]);

			this.allDriverList = this.updateArrayWithObject(this.allDriverList, ['driverId', driver.driverId], driver);

			let zoneIndex = this.Global.surgePriceZone.findIndex(((obj: any) => obj.zoneId == driver.zoneId));
			if (driver.driverStatus == 1) {
				this.offlineDriverList = this.removeObjectInArray(this.offlineDriverList, ['driverId', driver.driverId]);

				// this.onlineDriverList.push(driver);

				let isAlreadyAdded = this.onlineDriverList.find(o => o.driverId === driver.driverId)
				console.log('****** isAlreadyAdded ******', isAlreadyAdded);
				if (isAlreadyAdded) {
					this.onlineDriverList = this.updateArrayWithObject(this.onlineDriverList, ['driverId', driver.driverId], driver);
				}
				else {
					this.onlineDriverList.push(driver);
				}

				// this.setDataInLocalStorage('onlineDriverscachedDriversData', { status: true, onlineDrivers: this.onlineDriverList });
				// this.setDataInLocalStorage('offlineDriverscachedDriversData', { status: true, offlineDrivers: this.offlineDriverList });


				this.blinkDriverObj[driver.driverId + '_blink'] = { isBlink: driver.isDriverAssignBooking, blinkTime: driver.bookingElapsedTime };

				if (this.vehicleFilterCount <= 0) {
					this.setDriverFreeTimeObj({
						'driverId': driver.driverId,
						'freeTime': driver.freeTime
					});
					this.freeDriverList.push(driver);
					this.freeDriverList = this.Global.sortArrayOfObject(this.freeDriverList, 'freeTime', this.Global.onlineDriverListSortBy.freeDriverList);
				}

				let driverZoneData = {
					'driverId': driver.driverId,
					'driverName': driver.driverName,
					'driverCustomId': driver.driverCustomId,
					'slideToComplete': driver.slideToComplete,
					'freeTime': driver.freeTime,
					'acceptedTripTime': driver.acceptedTripTime,
					'arrivedTripTime': driver.arrivedTripTime,
					'beginTripTime': driver.beginTripTime,
				}

				if (zoneIndex >= 0) {
					this.Global.surgePriceZone[zoneIndex].driverList.freeDriverList.push(driverZoneData);
					this.Global.surgePriceZone[zoneIndex].driverList.freeDriverList = this.Global.sortArrayOfObject(this.Global.surgePriceZone[zoneIndex].driverList.freeDriverList, 'freeTime', this.Global.onlineDriverListSortBy.freeDriverList);
				} else {
					this.Global.surgePriceZone[0].driverList.freeDriverList.push(driverZoneData);
					this.Global.surgePriceZone[0].driverList.freeDriverList = this.Global.sortArrayOfObject(this.Global.surgePriceZone[0].driverList.freeDriverList, 'freeTime', this.Global.onlineDriverListSortBy.freeDriverList);
				}

				let driverMarkerData = [{
					'driverId': driver.driverId,
					'driverName': driver.driverName,
					'location': driverData[i].location,
				}]
				console.log('****** driverData ******', driverData);
				console.log('****** driverData[i].location ******', driverData[i].location);
				this.setDriverMarkerLabel(driverMarkerData, true);
			}
			else if (driver.driverStatus == 0) {
				this.onlineDriverList = this.removeObjectInArray(this.onlineDriverList, ['driverId', driver.driverId]);
				this.freeDriverList = this.removeObjectInArray(this.freeDriverList, ['driverId', driver.driverId]);
				this.mapMarkerDriversList = this.removeObjectInArray(this.mapMarkerDriversList, ['id', driver.driverId]);
				let oldZoneIndex = this.Global.surgePriceZone.findIndex(((obj: any) => obj.zoneId == driverData[i].oldZoneId));
				if (oldZoneIndex >= 0) {
					this.removeObjectInArray(this.Global.surgePriceZone[oldZoneIndex].driverList['freeDriverList'], ['driverId', driver.driverId]);
				}
				this.offlineDriverList.push(driver);


			}
		}
		this.setDataInLocalStorage('onlineDriverscachedDriversData', { status: true, onlineDrivers: this.onlineDriverList }, false);
		this.setDataInLocalStorage('offlineDriverscachedDriversData', { status: true, offlineDrivers: this.offlineDriverList }, false);

		this.setDataInLocalStorage('allDriverscachedDriversData', { status: true, allDrivers: this.allDriverList }, false);
		this.shareData.shareData({ 'function': 'allDriverForChat', 'driver': this.onlineDriverList });
	}
	nearestDriverList: any = [];
	nearestDriverListSelectedBookingType: number = 0;
	getNearestDriverNew(opts: any) {
		return new Promise((resolve) => {
			let postData: any = {
				"lat": opts.location[0],
				"long": opts.location[1],
			}

			postData.driverType = 4;
			if (opts.bookingType != undefined) {
				if (opts.bookingType == 0) {
					postData.driverType = 1;
				}
				else if (opts.bookingType == 1) {
					postData.driverType = 3;
				}
				else if (opts.bookingType == 2) {
					postData.driverType = 2;
				}
			}

			postData.variant = 500;
			if (opts.variant) {
				postData.variant = opts.variant;
			}

			postData.count = 10;
			if (opts.count) {
				postData.count = opts.count;
			}

			this.CallMethod.getNearestDriver(postData).pipe(takeUntil(this.unsubscribe$)).subscribe((result: any) => {
				if (result.status == true) {
					this.nearestDriverListSelectedBookingType = opts.bookingType;
					return resolve({
						'sttaus': true,
						'drivers': result.nearestDriver
					});
				}
			});
		});
	}
	// getNearestDriver(opts: any) {
	// 	return;
	// 	if (this.Global.isSetDriverLimit == true) {
	// 		this.reloadMapData({
	// 			'location': [opts.location[0], opts.location[1]]
	// 		});
	// 	}

	// 	let postData: any = {
	// 		"lat": opts.location[0],
	// 		"long": opts.location[1],
	// 	}

	// 	postData.driverType = 4;
	// 	if (opts.bookingType != undefined) {
	// 		if (opts.bookingType == 0) {
	// 			postData.driverType = 1;
	// 		}
	// 		else if (opts.bookingType == 1) {
	// 			postData.driverType = 3;
	// 		}
	// 		else if (opts.bookingType == 2) {
	// 			postData.driverType = 2;
	// 		}
	// 	}

	// 	postData.variant = 500;
	// 	if (opts.variant) {
	// 		postData.variant = opts.variant;
	// 	}

	// 	postData.count = 10;
	// 	if (opts.count) {
	// 		postData.count = opts.count;
	// 	}

	// 	this.CallMethod.getNearestDriver(postData).pipe(takeUntil(this.unsubscribe$)).subscribe((result: any) => {
	// 		this.nearestDriverList = [];
	// 		if (result.status == true) {
	// 			if (result.nearestDriver.length > 0) {
	// 				for (let index = 0; index < result.nearestDriver.length; index++) {
	// 					let nearestDriverPreference: any = {};

	// 					let indexOnlineDriverObj = this.onlineDriverList.findIndex(((obj: any) => obj.driverId == result.nearestDriver[index].driverId));
	// 					if (indexOnlineDriverObj != -1) {
	// 						let preferences = this.onlineDriverList[indexOnlineDriverObj].preferences;
	// 						//alternative
	// 						if (opts.bookingType == 0) {
	// 							nearestDriverPreference = preferences['cityRide'] != undefined ? preferences['cityRide'] : [];
	// 						}
	// 						else if (opts.bookingType == 1) {
	// 							nearestDriverPreference = preferences['rental'] != undefined ? preferences['rental'] : [];
	// 						}
	// 						else if (opts.bookingType == 2) {
	// 							nearestDriverPreference = preferences['outStation'] != undefined ? preferences['outStation'] : [];
	// 						}
	// 					}

	// 					this.nearestDriverList.push({
	// 						'driverId': result.nearestDriver[index].driverId,
	// 						'driverName': result.nearestDriver[index].driverName,
	// 						'variant': result.nearestDriver[index].distance,
	// 						'preferences': nearestDriverPreference,
	// 						'carTypeName': indexOnlineDriverObj != -1 ? this.onlineDriverList[indexOnlineDriverObj].carTypeName : "",
	// 					});
	// 				}
	// 				this.nearestDriverListSelectedBookingType = opts.bookingType;
	// 			}
	// 		}
	// 	});
	// }
	isReloadMapData: boolean = false;
	lastMapLocation: any = [];
	reloadMapData(opts: any) {

		if (opts.location == undefined || opts.location.length <= 0) {
			let center: any = JSON.parse(JSON.stringify(this.map.getCenter()));
			opts.location = [center.lat, center.lng];
		}

		if (Array.isArray(opts.location)) {
			this.isReloadMapData = true;
			this.lastMapLocation = opts.location;

			let variant = 10;
			let count = 10;
			if (opts.variant == undefined || opts.count == undefined) {
				if (this.Global.findDriverSettings.type == 'variant') {
					variant = this.Global.findDriverSettings.variant;
					count = 10;
				}
				else {
					variant = this.Global.findDriverSettings.variant;
					count = this.Global.findDriverSettings.count;
				}
			}
			else {
				variant = opts.variant;
				count = opts.count;
			}

			this.getNearestDriverNew({
				'location': opts.location,
				'bookingType': (opts.bookingType != undefined) ? opts.bookingType : 4,
				'variant': variant,
				'count': count,
			}).then((nearestDriver: any) => {
				console.log("getNearestDriverNew:::::", this.nearestDriverIds);

				this.nearestDriverIds = [];
				if (this.Global.isSetDriverLimit == true) {
					for (let i = 0; i < nearestDriver.drivers.length; i++) {
						this.nearestDriverIds.push(nearestDriver.drivers[i].driverId);
					}
				}

				let driverList: any = [];
				if (this.Global.isSetDriverLimit == true && (this.isOpenBookingTab == 'active' || this.isOpenBookingTab == 'assign')) {
					let bookingData: any = (this.isOpenBookingTab == 'active') ? this.activeBookings : this.assignBookings;
					for (let i = 0; i < bookingData.length; i++) {
						if (!this.nearestDriverIds.includes(bookingData[i].driverId)) {
							driverList.push({
								"driverId": bookingData[i].driverId,
								"driverName": bookingData[i].driverJson.driverName,
								"location": bookingData[i].driverJson.location,
								"carType": bookingData[i].carTypeId
							});
							this.nearestDriverIds.push(bookingData[i].driverId);
						}
					}
				}

				if (this.Global.isSetDriverLimit == true) {
					this.getDrivers({
						'loadShimmer': false,
						onlineDriver: true
					}).then((res: any) => {
						if (res) {
							let vehicleListFilterRequest = this.Global.getLocalStorage({ key: 'vehicleListFilterRequest' });
							if (vehicleListFilterRequest) {
								this.isFilterApplied = true; //! vishesh - test temp key
								this.vehicleFilterCall();
							}
							let selectedZoneFilterOption = this.Global.getLocalStorage({ key: 'zoneListFilterFormValue' });
							if (selectedZoneFilterOption && selectedZoneFilterOption.setDriverToZone.length > 0 && selectedZoneFilterOption.setDriverToZone[0].itemId == 1) {
								this.setAllDriversToDefaultZone(this.onlineDriverList)
							}
							this.newRideSetDriverId = (this.Global.appMode == 1) ? 'automatic' : 'manual';
							let format = this.Global.dateFormat.split(" ");
							this.onlyCompanyDateFormat = format[0];
							this.isReloadMapData = false;

							if (opts.isOpenDriverList && opts.isOpenDriverList == true) {
								this.setNearestDriverList(nearestDriver.drivers, opts.bookingType);
							}

							nearestDriver.drivers = [...nearestDriver.drivers, ...driverList];
							console.log('*****>>> dfsdfsdfsdfsdfsdfsdfsdfsdfsdfdsfsdfasd <<<******');
							this.setDriverMarkerLabel(nearestDriver.drivers);
						}
					});
				}
				else {
					if (opts.isOpenDriverList == true) {
						this.setNearestDriverList(nearestDriver.drivers, opts.bookingType);
					}

					if (opts.loadMarker == true) {
						this.setDriverMarkerLabel(nearestDriver.drivers);
						console.log('****** nearestDriver.drivers ******', nearestDriver.drivers);
						console.log('*****>>> load makrer <<<******');
					}
				}
			});
		}
	}
	setNearestDriverList(driverList: any, bookingType: number = 0) {
		this.nearestDriverList = [];
		console.log("driverList:::::::::::::::::", driverList);
		let getBusyDriver;
		for (let i = 0; i < driverList.length; i++) {
			let nearestDriverPreference: any = {};
			let indexOnlineDriverObj = this.onlineDriverList.findIndex(((obj: any) => obj.driverId == driverList[i].driverId));
			if (indexOnlineDriverObj != -1) {
				let preferences = this.onlineDriverList[indexOnlineDriverObj].preferences;
				getBusyDriver = this.onlineDriverList[indexOnlineDriverObj].jobs
				console.log("this.onlineDriverList[indexOnlineDriverObj].jobs", getBusyDriver);
				//alternative
				if (bookingType == 0) {
					nearestDriverPreference = preferences['cityRide'] != undefined ? preferences['cityRide'] : [];
				}
				else if (bookingType == 1) {
					nearestDriverPreference = preferences['rental'] != undefined ? preferences['rental'] : [];
				}
				else if (bookingType == 2) {
					nearestDriverPreference = preferences['outStation'] != undefined ? preferences['outStation'] : [];
				}
			}
			if (getBusyDriver == 0) {
				this.nearestDriverList.push({
					'driverId': driverList[i].driverId,
					'driverName': driverList[i].driverName,
					'variant': driverList[i].distance,
					'preferences': nearestDriverPreference,
					'carTypeName': indexOnlineDriverObj != -1 ? this.onlineDriverList[indexOnlineDriverObj].carTypeName : "",
				});
			}
		}
		console.log("setNearestDriverList::::::", this.nearestDriverList);
	}
	setAssignByNearestDriver(driverId: string = "", driverName: string = "") {
		this.selectedAssignDriver.driverId = driverId;
		this.selectedAssignDriver.driverName = driverName;
		this.setAssign(this.selectedBooking.bookingId);
		if (this.isOpenCreateEditdraftjob == 0) {
			this.clearBooking();
		}
		else if (this.isOpenCreateEditdraftjob == 1) {
			this.clearEditBooking();
		}
		this.nearestDriverList = [];
		this.selectedBooking = {};
	}
	setDriverCarIcon(driverId: string) {
		console.log('____ this.onlineDriverList++++++++++++', this.onlineDriverList)
		let objIndex = this.onlineDriverList.findIndex(((obj: any = {}) => obj.driverId == driverId));
		let carType = (objIndex >= 0 && this.onlineDriverList[objIndex] && this.onlineDriverList[objIndex].carType) ? this.onlineDriverList[objIndex].carType : 0;
		let status = (objIndex >= 0 && this.onlineDriverList[objIndex] && this.onlineDriverList[objIndex].bookingStatusFlag) ? this.onlineDriverList[objIndex].bookingStatusFlag : 0;
		let slideToComplete = (objIndex >= 0 && this.onlineDriverList[objIndex] && this.onlineDriverList[objIndex].slideToComplete == 1) ? 1 : 0;
		let isDriverOnBreak = (objIndex >= 0 && this.onlineDriverList[objIndex] && this.onlineDriverList[objIndex].isDriverOnBreak == 1) ? 1 : 0;
		console.log('****** setDriverCarIcon isDriverOnBreak ******', isDriverOnBreak);

		if (objIndex < 0) {
		}

		let carIcon = '_green.png';
		if (status == 3) {
			carIcon = '_purple.png';
		}
		else if (status == 7) {
			carIcon = '_blue.png';
		}
		else if (status == 8 && slideToComplete == 0) {
			carIcon = '_gold.png';
		}
		else if (status == 8 && slideToComplete == 1) {
			carIcon = '_yellow.png';
		}
		else if (isDriverOnBreak == 1) {
			carIcon = '_driverOnBreak.png'
		}
		console.log('****** environment.baseUrl + environment.imageUrl + environment.DispatcherCars + carType + carIcon ******', environment.baseUrl + environment.imageUrl + environment.DispatcherCars + carType + carIcon);
		return environment.baseUrl + environment.imageUrl + environment.DispatcherCars + carType + carIcon;
	}
	updateDriverMarker(driverId: string) {

		let objIndex = this.mapMarkerDriversList.findIndex(((obj: any = {}) => obj.id == driverId));
		let setData = this.mapMarkerDriversList[objIndex];
		setData.icon = {
			url: this.setDriverCarIcon(driverId),
			scaledSize: {
				height: this.Global.driverMarkerIcon.height,
				width: this.Global.driverMarkerIcon.width
			}
		}

		this.mapMarkerDriversList[objIndex] = setData;
	}
	findDriver(driverId: string = "") {
		this.mapMarkerDriversList = this.updateArrayWithObject(this.mapMarkerDriversList, ['infoWindowIsOpen', true], { infoWindowIsOpen: false });
		let objIndex = this.mapMarkerDriversList.findIndex(((obj: any) => obj.id == driverId));
		let driverLatLng: any = {}

		if (objIndex < 0) {
			this.mapMarkerDriversList.push({
				id: driverId,
				lat: this.driverDetail.location[0],
				lng: this.driverDetail.location[1],
				driverName: this.driverDetail.driverName,
				driverLocationData: [[this.driverDetail.location[0], this.driverDetail.location[1]]],
				infoWindowIsOpen: true,
				icon: {
					url: this.setDriverCarIcon(driverId),
					scaledSize: {
						height: this.Global.driverMarkerIcon.height,
						width: this.Global.driverMarkerIcon.width
					}
				},
			});
			driverLatLng = { 'lat': this.driverDetail.location[0], 'lng': this.driverDetail.location[1] }
		} else {
			this.mapMarkerDriversList = this.updateArrayWithObject(this.mapMarkerDriversList, ['id', driverId], { infoWindowIsOpen: true });
			driverLatLng = { 'lat': this.mapMarkerDriversList[objIndex].lat, 'lng': this.mapMarkerDriversList[objIndex].lng }
		}

		if (driverLatLng) {
			this.driverDetailsModal.hide();
			this.directionBound([driverLatLng]);
		}
		else {
			this.Popup.toasterMessage({
				'status': 'warning',
				'title': 'Oops!',
				'message': 'this driver location not found.',
			});
		}
	}
	trackDrivers(driverData: any) {
		if (driverData.locationData.length > 0) {

			for (let i = 0; i < this.mapMarkerDriversList.length; i++) {
				if (this.mapMarkerDriversList[i].id == driverData.driverId) {
					this.mapMarkerDriversList[i].lat = driverData.locationData[driverData.locationData.length - 1].latitude;
					this.mapMarkerDriversList[i].lng = driverData.locationData[driverData.locationData.length - 1].longitude;
					break;
				}
			}
		}
	}
	// **************** start assign driver ***************
	searchingDriverListArray: any = [];
	driverAssignContextPassive: boolean = false;
	isAssignDriverSelected: boolean = false;
	selectedAssignDriver: any = {};
	assignDriverFlag: number = 1;
	@ViewChild("searchAssignDriver") searchAssignDriver!: ElementRef;
	onContextMenu(bookingData: any) {
		this.searchingDriverListArray = [];
		this.driverAssignContextPassive = true;
		this.isUserBalance = false;
		this.isAssignDriverSelected = true;
		this.selectedAssignDriver = {};
		let driverList: any = {
			0: this.offlineDriverList,
			1: this.onlineDriverList,
			2: this.allDriverList,
		};

		this.searchingDriverListArray = driverList[this.assignDriverFlag];

		if (this.Global.isSetDriverLimit == true && Object.entries(bookingData).length > 0) {
			// this.reloadMapData({
			// 	'location': [bookingData.locationJson[0].lat, bookingData.locationJson[0].long],
			// 	'bookingType': bookingData.bookingType,
			// 	'isOpenDriverList': true
			// });
			console.log("bookingData::::::::::::::::::::::::::::::::::::::", bookingData);

			this.selectEditBooking(bookingData);
		}
		console.log("bookingData>>>>>>>>>>>>>>", bookingData);
		// this.getBookingDetails = bookingData
		setTimeout(() => {
			this.searchAssignDriver.nativeElement.focus();
		}, 0);
	}



	userBalance: number = 0;
	isUserBalance: boolean = false;
	userJsonCheckBalance: any = ""
	checkUserBalance(user: any) {
		console.log("user>>>>>>>>> check", user);
		this.userJsonCheckBalance = user.userJson[0];
		if (this.userJsonCheckBalance && this.userJsonCheckBalance.passengerId) {
			this.CallMethod.checkUserBalance({ userId: this.userJsonCheckBalance.passengerId }).subscribe((result: any) => {
				if (result.status) {
					this.userBalance = result.walletBalance;
					this.isUserBalance = true
					console.log("User balance:", this.userBalance);
				} else {
					console.warn("Error fetching user balance:", result);
					this.Popup.toasterMessage({
						'status': 'warning',
						'title': 'Oops!',
						'message': 'Error fetching user balance',
					});
				}
			});
		} else {
			console.warn("Invalid user data:", this.userJsonCheckBalance);
			this.Popup.toasterMessage({
				'status': 'warning',
				'title': 'Oops!',
				'message': 'Invalid user data',
			});
		}
	}

	changeAssignDriverList(value: number) {
		this.assignDriverFlag = value;
		if (this.selectedAssignDriver.driverId != undefined) {
			let selectDriverEl = document.getElementById(this.selectedAssignDriver.driverId) as HTMLElement;
			selectDriverEl.style.backgroundColor = "";
			selectDriverEl.style.color = "";
		}

		// if (value == 0) {
		// 	let request: any = {};
		// 			request.flag = 2;

		// 	if (this.Global.isSetDriverLimit == true) {
		// 		request.driverIds = this.nearestDriverIds;
		// 	}

		// 		this.CallMethod.getAllDriverList(request).pipe(takeUntil(this.unsubscribe$)).subscribe((result: any) => {
		// 			if (result.status) {
		// 				console.log('######### Update result', result)
		// 				if (result.online.length > 0) {
		// 					console.log(result.online)
		// 				}
		// 				if (result.offline.length > 0) {
		// 					this.offlineDriverList = result.offline
		// 					this.searchingDriverListArray = this.offlineDriverList;
		// 					console.log('serachinf driver list for offline', this.searchingDriverListArray[0].driverPhone )
		// 				}
		// 			}}
		// 		)
		// }
		this.selectedAssignDriver = {};
		this.onContextMenu({});
	}
	driverListSearch(event: any) {
		console.log('****** event ******', event);
		let searchValue = event;
		// let searchValue = event['target']['value'].trim();
		console.log('____searchValue******', searchValue);
		this.isDriverSearchOn = true;
		let driverList: any = {
			0: this.offlineDriverList,
			1: this.onlineDriverList,
			2: this.allDriverList,
		}
		console.log('Serach value length', searchValue.length)
		this.searchingDriverListArray = [];
		if (searchValue != "" && searchValue != null && searchValue != undefined) {

			let searchDrivers = this.Global.filterByValueObj(driverList[this.assignDriverFlag], "driverName", searchValue);

			if (searchValue.length >= 3 && searchDrivers.length <= 0) {
				console.log('::::::serach value length grater than zero');
				console.log('::: this.assignDriverFlag', this.assignDriverFlag);
				console.log('___searchvalue inner function', searchValue);

				let req: any = {
					'request': {
						"search": searchValue,
						"searchBy": {
							"driverName": "driverName"
						},
						"status": {},
						"carType": {},
					},
					'callType': 'setAssign',
					'loadShimmer': 0
				}
				if (this.assignDriverFlag == 0) {
					req.request.flag = 2;
					req.offDriver = true;
				}
				else if (this.assignDriverFlag == 1) {
					req.request.flag = 1;
					req.onlineDriver = true;
				}
				else if (this.assignDriverFlag == 2) {
					req.request.flag = 3;
					req.allDriver = true;

				}
				console.log('--------------------- getDrivers req ---------------------', req)
				this.getDrivers(req);
			}
			else {
				this.searchingDriverListArray = searchDrivers;
			}
		} else {
			this.searchingDriverListArray = driverList[this.assignDriverFlag];
			console.log('::::::: searchingDriverListArray', this.searchingDriverListArray)

		}
	}
	selectedDriverClick(driverData: any) {
		if (this.selectedAssignDriver.driverId != undefined) {
			let selectDriverEl = document.getElementById(this.selectedAssignDriver.driverId) as HTMLElement;
			selectDriverEl.style.backgroundColor = "";
			selectDriverEl.style.color = "";
		}

		this.selectedAssignDriver.driverId = driverData.driverId;
		this.selectedAssignDriver.driverName = driverData.driverName;
		this.selectedAssignDriver.driverPhone = driverData.driverPhone;
		this.isAssignDriverSelected = true;

		setTimeout(() => {
			this.searchAssignDriver.nativeElement.focus();
		}, 0);

		let selectDriverEl = document.getElementById(driverData.driverId) as HTMLElement;
		selectDriverEl.style.backgroundColor = this.Global.first_color;
		selectDriverEl.style.color = this.Global.second_color;
	}
	/* Set Assign Call */
	setAssign(bookingId: string) {
		this.driverAssignContextPassive = false;
		this.isUserBalance = false;
		if (this.selectedAssignDriver.driverId != undefined && this.selectedAssignDriver.driverId != '') {
			this.CallMethod.setAssign({ bookingId: bookingId, driverId: this.selectedAssignDriver.driverId }).pipe(takeUntil(this.unsubscribe$)).subscribe((result: any) => {
				if (result.status == true) {
					this.clearEditBooking();
					this.selectedBooking = {};
				}
				else {
					this.driverAssignContextPassive = true;
					if (result.statusCode == 1) {
						this.Popup.toasterMessage({
							status: 'error',
							title: 'Booking not found!',
							message: "Something wrong please try again.",
						});
					}
					else if (result.statusCode == 2) {
						this.Popup.toasterMessage({
							status: 'error',
							title: 'Driver not found!',
							message: "Something wrong please try again.",
						});
					}
					else if (result.statusCode == 3) {
						this.Popup.toasterMessage({
							status: 'error',
							title: 'Only payment is pending!',
							message: "Only payment is pending, So cannot transfer this booking to other driver.",
						});
					}
					else {
						this.Popup.toasterMessage({
							status: 'error',
							title: 'Something wrong!',
							message: "Please Try Again",
						});
					}
				}
			});
		} else {
			this.isAssignDriverSelected = false;
			this.driverAssignContextPassive = true;
		}
	}
	closeAssignDriverPopup() {
		this.driverAssignContextPassive = false;
		this.isAssignDriverSelected = true;
		this.selectedAssignDriver = {};
		this.isUserBalance = false;
	}
	// **************** end assign driver ***************







	// **************** start booking event ***************
	bookingEventDetails(bookingId: string, dBookId: string) {
		this.shareData.shareData({ 'function': 'openBookingEventDetails', 'data': { 'bookingId': bookingId, 'dBookId': dBookId } });
		this.driverAssignContextPassive = false;
		this.isUserBalance = false;
	}
	// **************** end booking event ***************







	// ************** tab event **********





	//**************** all zone start ************
	fillZoneOpacity: number = 0.3;
	zoneStrokeWeight: number = 2;
	isBoundryZoneChecked: boolean = false;
	boundryZoneList: any = [];
	isCreatedZoneChecked: boolean = false;
	isCreatedOneSideZone: boolean = false;
	createdOneSideZoneList: any = [];
	areaZoneList: any = [];
	isAreaZoneChecked: boolean = false;
	isOfferZone: boolean = false;
	offerZoneList: any = [];
	createdZoneList: any = [];
	isAutoCreatedZoneChecked: boolean = false;
	autoCreatedZoneList: any = [];
	isSurgeZoneChecked: boolean = false;
	surgeZoneList: any = [];
	isLoadZoneData: boolean = false;
	isDisabledZoneCheckbox = false;
	getZoneDetails(setZone: string = 'map') {
		return new Promise((resolve, reject) => {
			let zoneName = [];
			this.isLoadZoneData = true;
			this.isDisabledZoneCheckbox = true;

			if (this.isBoundryZoneChecked) {
				zoneName.push('cityBoundry');
			}
			if (this.isCreatedZoneChecked) {
				zoneName.push('createdZone');
			}
			if (this.isCreatedOneSideZone) {
				zoneName.push('createdOneSideZone');
			}
			if (this.isAutoCreatedZoneChecked) {
				zoneName.push('autoCreatedZone');
			}
			if (this.isSurgeZoneChecked) {
				zoneName.push('surgePriceZone');
			}
			if (this.isOfferZone) {
				zoneName.push('offerZone');
			}
			if (this.isAreaZoneChecked) {
				zoneName.push('areaZone');
			}

			this.boundryZoneList = [];
			this.createdZoneList = [];
			this.areaZoneList = [];
			this.offerZoneList = [];
			this.createdOneSideZoneList = []
			this.autoCreatedZoneList = [];
			this.surgeZoneList = [];
			if (this.isBoundryZoneChecked || this.isCreatedZoneChecked || this.isAutoCreatedZoneChecked || this.isSurgeZoneChecked || this.isCreatedOneSideZone || this.isOfferZone || this.isAreaZoneChecked) {
				this.CallMethod.getZone({ 'zoneName': zoneName }).pipe(takeUntil(this.unsubscribe$)).subscribe((result: any) => {
					if (result.status) {
						if (setZone == 'map') {
							this.setZonesInMap(result.data)
						} else if (setZone == 'zoneList') {
							if (result.data.SurgePriceZone != undefined && result.data.SurgePriceZone.length > 0) {
								this.Global.setSurgePriceZoneArray(result.data.SurgePriceZone);
							}
						}
						// if (result.cityBoundry && result.cityBoundry.length > 0) {
						// 	for (let i = 0; i < result.cityBoundry.length; i++) {
						// 		let coordinates = result.cityBoundry[i].loc.coordinates[0];

						// 		let paths = [];
						// 		for (let j = 0; j < coordinates.length; j++) {
						// 			paths.push({
						// 				"lat": coordinates[j][1],
						// 				"lng": coordinates[j][0]
						// 			});
						// 		}

						// 		this.boundryZoneList.push({
						// 			'zoneId': result.cityBoundry[i]._id,
						// 			'zoneName': result.cityBoundry[i].zone_name,
						// 			'zoneLat': result.cityBoundry[i].zone_lat,
						// 			'zoneLng': result.cityBoundry[i].zone_lng,
						// 			'paths': paths,
						// 		});
						// 	}
						// }

						// if (result.SurgePriceZone && result.SurgePriceZone.length > 0) {
						// 	for (let i = 0; i < result.SurgePriceZone.length; i++) {
						// 		let coordinates = result.SurgePriceZone[i].loc.coordinates[0];

						// 		let paths = [];
						// 		for (let j = 0; j < coordinates.length; j++) {
						// 			paths.push({
						// 				"lat": coordinates[j][1],
						// 				"lng": coordinates[j][0]
						// 			});
						// 		}

						// 		this.surgeZoneList.push({
						// 			'zoneId': result.SurgePriceZone[i]._id,
						// 			'zoneName': result.SurgePriceZone[i].zone_name,
						// 			'zoneLat': result.SurgePriceZone[i].zone_lat,
						// 			'zoneLng': result.SurgePriceZone[i].zone_lng,
						// 			'paths': paths,
						// 		});
						// 	}
						// }

						// if (result.offerZone && result.offerZone.length > 0) {
						// 	for (let i = 0; i < result.offerZone.length; i++) {
						// 		let coordinates = result.offerZone[i].loc.coordinates[0];

						// 		let paths = [];
						// 		for (let j = 0; j < coordinates.length; j++) {
						// 			paths.push({
						// 				"lat": coordinates[j][1],
						// 				"lng": coordinates[j][0]
						// 			});
						// 		}

						// 		this.offerZoneList.push({
						// 			'zoneId': result.offerZone[i]._id,
						// 			'zoneName': result.offerZone[i].zone_name,
						// 			'zoneLat': result.offerZone[i].zone_lat,
						// 			'zoneLng': result.offerZone[i].zone_lng,
						// 			'paths': paths,
						// 		});
						// 	}
						// }

						// if (result.autoCreatedZone && result.autoCreatedZone.length > 0) {
						// 	for (let i = 0; i < result.autoCreatedZone.length; i++) {
						// 		let coordinates = result.autoCreatedZone[i].loc.coordinates[0];

						// 		let paths = [];
						// 		for (let j = 0; j < coordinates.length; j++) {
						// 			paths.push({
						// 				"lat": coordinates[j][1],
						// 				"lng": coordinates[j][0]
						// 			});
						// 		}

						// 		this.autoCreatedZoneList.push({
						// 			'zoneId': result.autoCreatedZone[i]._id,
						// 			'zoneName': result.autoCreatedZone[i].zone_name,
						// 			'zoneLat': result.autoCreatedZone[i].zone_lat,
						// 			'zoneLng': result.autoCreatedZone[i].zone_lng,
						// 			'paths': paths,
						// 		});
						// 	}
						// }

						// if (result.createdZone && result.createdZone.length > 0) {
						// 	for (let i = 0; i < result.createdZone.length; i++) {
						// 		let coordinates = result.createdZone[i].loc.coordinates[0];

						// 		let paths = [];
						// 		for (let j = 0; j < coordinates.length; j++) {
						// 			paths.push({
						// 				"lat": coordinates[j][1],
						// 				"lng": coordinates[j][0]
						// 			});
						// 		}

						// 		this.createdZoneList.push({
						// 			'zoneId': result.createdZone[i]._id,
						// 			'zoneName': result.createdZone[i].zone_name,
						// 			'zoneLat': result.createdZone[i].zone_lat,
						// 			'zoneLng': result.createdZone[i].zone_lng,
						// 			'paths': paths,
						// 		});
						// 	}
						// }

						// if (result.createdOneSideZone && result.createdOneSideZone.length > 0) {
						// 	for (let i = 0; i < result.createdOneSideZone.length; i++) {
						// 		let coordinates = result.createdOneSideZone[i].loc.coordinates[0];

						// 		let paths = [];
						// 		for (let j = 0; j < coordinates.length; j++) {
						// 			paths.push({
						// 				"lat": coordinates[j][1],
						// 				"lng": coordinates[j][0]
						// 			});
						// 		}

						// 		this.createdOneSideZoneList.push({
						// 			'zoneId': result.createdOneSideZone[i]._id,
						// 			'zoneName': result.createdOneSideZone[i].zone_name,
						// 			'zoneLat': result.createdOneSideZone[i].zone_lat,
						// 			'zoneLng': result.createdOneSideZone[i].zone_lng,
						// 			'paths': paths,
						// 		});
						// 	}
						// }
					}

					this.isLoadZoneData = false;
					this.isDisabledZoneCheckbox = false;
					return resolve({ 'status': true })
				});
			}
			else {
				this.isLoadZoneData = false;
				this.isDisabledZoneCheckbox = false;
			}
		});
	}
	setZonesInMap(data: any) {
		if (data.cityBoundry && data.cityBoundry.length > 0) {
			for (let i = 0; i < data.cityBoundry.length; i++) {
				let coordinates = data.cityBoundry[i].loc.coordinates[0];

				let paths = [];
				for (let j = 0; j < coordinates.length; j++) {
					paths.push({
						"lat": coordinates[j][1],
						"lng": coordinates[j][0]
					});
				}

				this.boundryZoneList.push({
					'zoneId': data.cityBoundry[i]._id,
					'zoneName': data.cityBoundry[i].zone_name,
					'zoneLat': data.cityBoundry[i].zone_lat,
					'zoneLng': data.cityBoundry[i].zone_lng,
					'paths': paths,
				});
			}
		}

		if (data.SurgePriceZone && data.SurgePriceZone.length > 0) {
			for (let i = 0; i < data.SurgePriceZone.length; i++) {
				let coordinates = data.SurgePriceZone[i].loc.coordinates[0];

				let paths = [];
				for (let j = 0; j < coordinates.length; j++) {
					paths.push({
						"lat": coordinates[j][1],
						"lng": coordinates[j][0]
					});
				}

				this.surgeZoneList.push({
					'zoneId': data.SurgePriceZone[i]._id,
					'zoneName': data.SurgePriceZone[i].zone_name,
					'zoneLat': data.SurgePriceZone[i].zone_lat,
					'zoneLng': data.SurgePriceZone[i].zone_lng,
					'paths': paths,
				});
			}
		}

		if (data.offerZone && data.offerZone.length > 0) {
			for (let i = 0; i < data.offerZone.length; i++) {
				let coordinates = data.offerZone[i].loc.coordinates[0];

				let paths = [];
				for (let j = 0; j < coordinates.length; j++) {
					paths.push({
						"lat": coordinates[j][1],
						"lng": coordinates[j][0]
					});
				}

				this.offerZoneList.push({
					'zoneId': data.offerZone[i]._id,
					'zoneName': data.offerZone[i].zone_name,
					'zoneLat': data.offerZone[i].zone_lat,
					'zoneLng': data.offerZone[i].zone_lng,
					'paths': paths,
				});
			}
		}

		if (data.autoCreatedZone && data.autoCreatedZone.length > 0) {
			for (let i = 0; i < data.autoCreatedZone.length; i++) {
				let coordinates = data.autoCreatedZone[i].loc.coordinates[0];

				let paths = [];
				for (let j = 0; j < coordinates.length; j++) {
					paths.push({
						"lat": coordinates[j][1],
						"lng": coordinates[j][0]
					});
				}

				this.autoCreatedZoneList.push({
					'zoneId': data.autoCreatedZone[i]._id,
					'zoneName': data.autoCreatedZone[i].zone_name,
					'zoneLat': data.autoCreatedZone[i].zone_lat,
					'zoneLng': data.autoCreatedZone[i].zone_lng,
					'paths': paths,
				});
			}
		}

		if (data.createdZone && data.createdZone.length > 0) {
			for (let i = 0; i < data.createdZone.length; i++) {
				let coordinates = data.createdZone[i].loc.coordinates[0];

				let paths = [];
				for (let j = 0; j < coordinates.length; j++) {
					paths.push({
						"lat": coordinates[j][1],
						"lng": coordinates[j][0]
					});
				}

				this.createdZoneList.push({
					'zoneId': data.createdZone[i]._id,
					'zoneName': data.createdZone[i].zone_name,
					'zoneLat': data.createdZone[i].zone_lat,
					'zoneLng': data.createdZone[i].zone_lng,
					'paths': paths,
				});
			}
		}

		if (data.areaZone && data.areaZone.length > 0) {
			for (let i = 0; i < data.areaZone.length; i++) {
				let coordinates = data.areaZone[i].loc.coordinates[0];

				let paths = [];
				for (let j = 0; j < coordinates.length; j++) {
					paths.push({
						"lat": coordinates[j][1],
						"lng": coordinates[j][0]
					});
				}

				this.areaZoneList.push({
					'zoneId': data.areaZone[i]._id,
					'zoneName': data.areaZone[i].zone_name,
					'zoneLat': data.areaZone[i].zone_lat,
					'zoneLng': data.areaZone[i].zone_lng,
					'paths': paths,
				});
			}
		}

		if (data.createdOneSideZone && data.createdOneSideZone.length > 0) {
			for (let i = 0; i < data.createdOneSideZone.length; i++) {
				let coordinates = data.createdOneSideZone[i].loc.coordinates[0];

				let paths = [];
				for (let j = 0; j < coordinates.length; j++) {
					paths.push({
						"lat": coordinates[j][1],
						"lng": coordinates[j][0]
					});
				}

				this.createdOneSideZoneList.push({
					'zoneId': data.createdOneSideZone[i]._id,
					'zoneName': data.createdOneSideZone[i].zone_name,
					'zoneLat': data.createdOneSideZone[i].zone_lat,
					'zoneLng': data.createdOneSideZone[i].zone_lng,
					'paths': paths,
				});
			}
		}
	}
	checkedUnCheckedZone(zone: any, event: MatCheckboxChange): void {
		if (zone == 'boundryZone') {
			this.isBoundryZoneChecked = event.checked;
			if (event.checked) {
				this.getZoneDetails();
			}
			else {
				this.boundryZoneList = [];
			}
		}
		if (zone == 'createdZone') {
			this.isCreatedZoneChecked = event.checked;
			if (event.checked) {
				this.getZoneDetails();
			}
			else {
				this.createdZoneList = [];
			}
		}
		if (zone == 'createdOneSideZone') {
			this.isCreatedOneSideZone = event.checked;
			if (event.checked) {
				this.getZoneDetails();
			}
			else {
				this.createdOneSideZoneList = [];
			}
		}
		if (zone == 'autoCreatedZone') {
			this.isAutoCreatedZoneChecked = event.checked;
			if (event.checked) {
				this.getZoneDetails();
			}
			else {
				this.autoCreatedZoneList = [];
			}
		}
		if (zone == 'surgeZone') {
			this.isSurgeZoneChecked = event.checked;
			if (event.checked) {
				this.getZoneDetails();
			}
			else {
				this.surgeZoneList = [];
			}
		}
		if (zone == 'offerZone') {
			this.isOfferZone = event.checked;
			if (event.checked) {
				this.getZoneDetails();
			}
			else {
				this.offerZoneList = [];
			}
		}
		if (zone == 'areaZone') {
			this.isAreaZoneChecked = event.checked;
			if (event.checked) {
				this.getZoneDetails();
			}
			else {
				this.areaZoneList = [];
			}
		}
	}
	zoneClick(zoneType: string, zoneData: any) {
	}
	async getDriverLatLng(driverId: string = "") {
		console.log('--------------------- driverId ---------------------', driverId)
		console.log('--------------------- this.mapMarkerDriversList ---------------------', this.mapMarkerDriversList)
		console.log('--------------------- this.allDriverList ---------------------', this.allDriverList)
		let ObjectData = this.getArrayInObject(this.mapMarkerDriversList, ['id', driverId]); /* map marker Array */
		if (ObjectData.status == false) {
			ObjectData = this.getArrayInObject(this.allDriverList, ['driverId', driverId]); /* onlineDriverList Array */
		}

		console.log('--------------------- getDriverLatLng ObjectData ---------------------', ObjectData)

		if (ObjectData.data.lat != undefined
			&& ObjectData.data.lng != undefined
		) {
			return { lat: ObjectData.data.lat, lng: ObjectData.data.lng };
		}

		else {
			await this.getDriverDetails(driverId);
			return { lat: this.driverDetail.location[0], lng: this.driverDetail.location[1] };
		}
	}
	driverOffButtonLoader: boolean = false;
	async driverOff(driverId: string = "") {
		let driverLatLng = await this.getDriverLatLng(driverId);
		console.log('--------------------- driverLatLng ---------------------', driverLatLng)
		if (driverLatLng) {
			this.driverOffButtonLoader = true;
			this.CallMethod.driverSwitchs({
				isDispatcher: 1,
				'driverStatus': 0,
				'driverId': driverId,
				'dispatcherName': this.Global.dispatcherDetails.dispatcherName,
				'lat': driverLatLng.lat,
				'long': driverLatLng.lng,
				isApprovedByAdmin: true
			}).pipe(takeUntil(this.unsubscribe$)).subscribe((result: any) => {
				if (result['status']) {
					this.driverDetail['driverStatus'] = 0;
					this.closeDriverDetailsModel();
				} else {
					switch (result['statusCode']) {
						case 1:
							/* driver is already offline */
							break;
						case 2:
							/* driver is already online */
							break;
						case 3:
							/*  you can not offline this driver while his booking is running */
							this.Popup.toasterMessage({
								'status': 'warning',
								'title': 'Oops!',
								'message': 'you can not offline this driver while his booking is running',
							});
							break;
					}
				}
				this.driverOffButtonLoader = false;
			});
		}
		else {
			this.Popup.toasterMessage({
				'status': 'warning',
				'title': 'Oops!',
				'message': 'this driver location not found.',
			});
		}
	}
	// **************** Driveroff end  *****************


	// **************** DriverBackToBack start  *****************
	driverBackToBackButtonLoader: boolean = false;
	driverBackToBackRide(driverId: string = "", status: any) {
		this.driverBackToBackButtonLoader = true

		this.CallMethod.driverBackToBack({
			isDispatcher: 1,
			'status': status == 0 ? 1 : 0,
			'driverId': driverId,
			'dispatcherName': this.Global.dispatcherDetails.dispatcherName,

		}).pipe(takeUntil(this.unsubscribe$)).subscribe((result: any) => {
			console.log("result driverBackToBack", result);

			if (result['status']) {
				this.driverDetail['backToBackRide'] = status == 0 ? 1 : 0;
				this.closeDriverDetailsModel();
			}
			this.driverBackToBackButtonLoader = false;
		});

	}

	// **************** Driveroff end  *****************


	// **************** directionBound start *****************
	directionBound(locations: any) {
		let LatLngBounds = new google.maps.LatLngBounds();
		locations.forEach((item: any, index: any) => {
			LatLngBounds.extend(new google.maps.LatLng(item.lat, item.lng));
		});

		this.map.fitBounds(LatLngBounds);
		this.map.getCenter();
	}
	setAlertData() {
		this.Global.unReadAlert += 1;
		this.Global.setLocalStorage({ 'key': 'unReadAlert', 'value': this.Global.unReadAlert });
		let bookingalert: any = document.querySelector('#sosAndRejectBookingAlert');
		bookingalert.play();
	}

	setInternationalAlert() {
		this.Global.unReadAlert += 1;
		this.Global.setLocalStorage({ 'key': 'unReadAlert', 'value': this.Global.unReadAlert });
		let bookingalert: any = document.querySelector('#InternationalBookingAlert');
		bookingalert.play();
	}

	bookingalert_audio() {
		if (this.Global.audioObj.bookingAlert.rejectBooking) {
			let bookingalert: any = document.querySelector('#assignBookingRemainderAlert');
			bookingalert.play();
		}
	}
	//***************** vehicleList_FutureJob_OfflineDriver ********************
	is_active_vehicleList_FutureJob_OfflineDriver: string = 'vehicleList';
	vehicleList_FutureJob_OfflineDriver(event: any) {
		if (event.index == 0) {
			this.is_active_vehicleList_FutureJob_OfflineDriver = 'vehicleList';
		}
		else if (event.index == 1) {
			this.is_active_vehicleList_FutureJob_OfflineDriver = 'futureJob';
		}
		else if (event.index == 1) {
			this.is_active_vehicleList_FutureJob_OfflineDriver = 'offLineDriver';
		}
	}
	//***************** Start create Booking ******************
	flag: string = "";
	checkCreatebookingValidation: boolean = true;
	public searchAutoComplete = new Subject<KeyboardEvent>();
	@ViewChild('createbookingArea') createbookingArea!: ElementRef;
	autoCompleteSearch(event: any, focus: string = '', inputType: string = '') {
		if (event.target.value && event.target.value.trim() != '' && focus != 'focusOut') {
			this.searchAutoComplete.next(event);
		}
		else {
			if (focus == 'focusOut' && this.checkCreatebookingValidation) {
				let locationIndex = this.locationList.findIndex(obj => obj.addressId === inputType);
				let isValid = false;
				if (locationIndex >= 0) {

					if (this.locationList[locationIndex].address == event.target.value) {
						isValid = true;
					}

				}

				if (event.target.value.trim() == '' || isValid == false) {
					// setTimeout(() => {
					let inputFocus = document.getElementById(event.target.id) as HTMLInputElement;
					inputFocus.focus();
					// }, 0);
				}

			}
		}
	}
	createJobAddressList: any = [];
	editJobAddressList: any = [];
	finAreaJobAddressList: any = [];
	getAutoSearch(value: string = "") {
		if (this.createJobAddressList.length > 0 && (this.flag == '' || this.flag == 'Redis')) {
			this.createJobAddressList = this.Global.filterByValueObj(this.createJobUserAddressList, "fullAddress", value);
		}
		else {
			const req = {
				"sessionId": this.Global.getSessionId(),
				"flag": this.flag,
				"address": value
			};

			this.CallMethod.getAutoCompleteAddress(req).pipe(takeUntil(this.unsubscribe$)).subscribe((result: any) => {
				this.createJobAddressList = [];
				this.editJobAddressList = [];
				this.finAreaJobAddressList = [];
				if (result.status) {
					if (result.data.length > 0) {

						if (this.flag == "" || result.data[0].lat == 0) {
							this.flag = result.data[0].flag;
						}

						for (let i = 0; i < result.data.length; i++) {
							let address = {
								'title': result.data[i].title,
								'address': result.data[i].address,
								'lat': result.data[i].lat,
								'long': result.data[i].long,
								'placeId': result.data[i].placeId,
								'flag': result.data[i].flag
							}

							if (this.isOpenCreateEditdraftjob == 0) {
								this.createJobAddressList.push(address);
								this.finAreaJobAddressList.push(address);
							}
							else if (this.isOpenCreateEditdraftjob == 1) {
								this.editJobAddressList.push(address);
								this.finAreaJobAddressList.push(address);
							}
						}
					}
				}
			});
		}
	}
	get locationArray(): FormArray {
		return this.locationsForm.get("stops") as FormArray
	}
	get editLocationArray(): FormArray {
		return this.editLocationsForm.get("stops") as FormArray;
	}
	addLocaitons() {
		if (this.isOpenCreateEditdraftjob == 0) {
			let pickup = this.locationList.findIndex(obj => obj.addressId === 'pickup');
			let drop = this.locationList.findIndex(obj => obj.addressId === 'drop');

			if (pickup < 0 || drop < 0) {
				if (pickup < 0) {
					let pickupEl = document.getElementById('pickup') as HTMLInputElement;
					pickupEl.focus();
					this.setCreatBookingAlertPopup('Please enter pickup.', 'error');
				}
				else {
					let dropEl = document.getElementById('drop') as HTMLInputElement;
					dropEl.focus();
					this.setCreatBookingAlertPopup('Please enter drop.', 'error');
				}

				return;
			}

			if (this.locationArray.length == environment.locationLimitation) {
				this.setCreatBookingAlertPopup('You Reach your Maximum Limit...', 'error');
				return;
			}

			this.locationArray.push(this.newStop());

			setTimeout(() => {
				let index = this.locationArray.controls.length - 1;
				let stopsEl = document.getElementById('waypoint' + index) as HTMLInputElement;
				if (stopsEl != null) {
					stopsEl.focus();
				}
			}, 0);
		}
		else if (this.isOpenCreateEditdraftjob == 1) {
			let pickup = this.editLocationList.findIndex(obj => obj.addressId === 'pickup');
			let drop = this.editLocationList.findIndex(obj => obj.addressId === 'drop');

			if (pickup < 0 || drop < 0) {
				if (pickup < 0) {
					let pickupEl = document.getElementById('editPickup') as HTMLInputElement;
					pickupEl.focus();
					this.setEditBookingAlertPopup('Please enter pickup.', 'error');
				}
				else {
					let dropEl = document.getElementById('editDrop') as HTMLInputElement;
					dropEl.focus();
					this.setEditBookingAlertPopup('Please enter drop.', 'error');
				}

				return;
			}

			if (this.editLocationArray.length == environment.locationLimitation) {
				this.setEditBookingAlertPopup('You Reach your Maximum Limit...', 'error');
				return;
			}

			this.editLocationArray.push(this.editJobNewStop());

			setTimeout(() => {
				let index = this.editLocationArray.controls.length - 1;
				let stopsEl = document.getElementById('editJobWaypoint' + index) as HTMLInputElement;
				if (stopsEl != null) {
					stopsEl.focus();
				}
			}, 0);
		}
	}
	newStop(): FormGroup {
		return this.formBuilder.group({
			waypoint: '',
		});
	}
	editJobNewStop(): FormGroup {
		return this.formBuilder.group({
			editJobWaypoint: '',
		});
	}
	removeLocationArrayForm(i: number, inputType: string) {

		if (this.isOpenCreateEditdraftjob == 0) {
			let beforeLocationList = this.locationList.length;
			let stopIndex = this.locationList.findIndex(obj => obj.addressId === inputType);
			if (stopIndex >= 0) {
				this.locationList = this.removeObjectInArray(this.locationList, ['addressId', inputType]);
				if (this.locationList.length > 0) {
					this.setLocationArr();
					this.setStopInOut('remove', beforeLocationList, i)
					this.setLocationMap();
					this.getFixedPrice();
				}
			}

			this.locationArray.removeAt(i);
		}
		else if (this.isOpenCreateEditdraftjob == 1) {
			let stopIndex = this.editLocationList.findIndex(obj => obj.addressId === inputType);
			let beforeEditLocationList = this.editLocationList.length;
			if (stopIndex >= 0) {
				this.editLocationList = this.removeObjectInArray(this.editLocationList, ['addressId', inputType]);
				if (this.editLocationList.length > 0) {
					this.setLocationArr();
					this.setEditStopInOut('remove', beforeEditLocationList, i)
					this.setLocationMap();
					this.getFixedPrice(this.selectedBooking.carTypeId);
				}
			}

			this.editLocationArray.removeAt(i);
		}
	}
	locationList: any[] = [];
	editLocationList: any[] = [];
	autoCompleteEnterText(events: any, selectedLocations: any, inputType: string) {
		let index: number = -1;
		if (this.isOpenCreateEditdraftjob == 0) {
			index = this.locationList.findIndex((obj: any = {}) => obj.addressId == inputType);
		}
		else if (this.isOpenCreateEditdraftjob == 1) {
			index = this.editLocationList.findIndex((obj: any = {}) => obj.addressId == inputType);
		}

		if (events.isUserInput === false) {
			return;
		}

		if (selectedLocations.lat == 0 && selectedLocations.long == 0 && selectedLocations.placeId != '') {
			this.Global.sessionId = "";
			this.flag = "";

			this.CallMethod.setAutoCompleteAddress({
				"sessionId": this.Global.getSessionId(),
				"address": selectedLocations.title + " " + selectedLocations.address,
				"title": selectedLocations.title,
				"placeId": selectedLocations.placeId,
			}).pipe(takeUntil(this.unsubscribe$)).subscribe((result: any) => {
				selectedLocations.lat = result.lat;
				selectedLocations.long = result.long;
				this.pushLocationData(index, inputType, selectedLocations);
			});
		}
		else {


			this.pushLocationData(index, inputType, selectedLocations);
		}
	}
	findAreaLocation: any = {};
	isShowFindAreaIcon: boolean = false;
	isShowFindAreaMarkerAnimat: any = 'BOUNCE';
	findArea(events: any, selectedLocations: any, inputType: string) {
		if (selectedLocations.lat == 0 && selectedLocations.long == 0 && selectedLocations.placeId != '') {
			this.Global.sessionId = "";
			this.flag = "";

			this.CallMethod.setAutoCompleteAddress({
				"sessionId": this.Global.getSessionId(),
				"address": selectedLocations.title + " " + selectedLocations.address,
				"title": selectedLocations.title,
				"placeId": selectedLocations.placeId,
			}).pipe(takeUntil(this.unsubscribe$)).subscribe((result: any) => {
				this.setAreaIcon({
					location: [result.lat, result.long],
					'isBound': true
				})
			});
		}
		else {
			this.setAreaIcon({
				location: [selectedLocations.lat, selectedLocations.long],
				'isBound': true
			})
		}
	}
	setAreaIcon(opts: any) {
		this.isShowFindAreaIcon = true;
		if (Array.isArray(opts.location)) {
			this.isShowFindAreaMarkerAnimat = 'BOUNCE';

			this.findAreaLocation.iconUrl = {
				url: this.markerIcon.findAreaUrl,
				scaledSize: this.markerIcon.scaledSize
			}
			this.findAreaLocation.location = { 'lat': opts.location[0], 'lng': opts.location[1] };

			if (opts.isBound == undefined || (opts.isBound != undefined && opts.isBound == true)) {
				this.directionBound([{ lat: parseFloat(opts.location[0]), lng: parseFloat(opts.location[1]) }]);
			}

			this.map.setZoom(14);

			setTimeout(() => {
				this.isShowFindAreaMarkerAnimat = null;
			}, 3000);

			this.reloadMapData({
				'location': [opts.location[0], opts.location[1]],
			});
		}
	}
	@ViewChild("findAreaInput") findAreaInput!: ElementRef;
	async areaMarkerDragEnd($event: any) {
		const locations: any = await this.getAddressForLatlong({ lat: $event.coords.lat, lng: $event.coords.lng })

		if (locations.status == true) {
			this.findAreaInput.nativeElement.value = locations.address;
		}

		this.setAreaIcon({
			location: [$event.coords.lat, $event.coords.lng],
			'isBound': true
		})
	}
	reloadMap() {
		let center: any = JSON.parse(JSON.stringify(this.map.getCenter()));
		this.setAreaIcon({
			location: [center.lat, center.lng],
			'isBound': true
		})
	}


	pushLocationData(index: number, inputType: string, selectedLocations: any) {
		let beforeLocationList = this.locationList.length;
		let beforeEditLocationList = this.editLocationList.length;

		if (index < 0) {
			let address = {
				'address': selectedLocations.title + ", " + selectedLocations.address,
				'title': selectedLocations.title,
				'lat': selectedLocations.lat,
				'long': selectedLocations.long,
				'addressId': inputType
			}

			if (this.isOpenCreateEditdraftjob == 0) {
				this.locationList.push(address);
			}
			else if (this.isOpenCreateEditdraftjob == 1) {
				this.editLocationList.push(address);
			}
		}
		else {
			let address = {
				'address': selectedLocations.title + ", " + selectedLocations.address,
				'title': selectedLocations.title,
				'lat': selectedLocations.lat,
				'long': selectedLocations.long,
				'addressId': inputType,
			}

			if (this.isOpenCreateEditdraftjob == 0) {
				this.locationList[index] = address;
			}
			else if (this.isOpenCreateEditdraftjob == 1) {
				this.editLocationList[index] = address;
			}
		}

		if (inputType == 'pickup') {
			if (this.newRideOptionStatus == false || this.editRideOptionStatus == false) {

				this.setAssignMinutes();
			}

			if (this.isOpenCreateEditdraftjob == 0) {
				this.reloadMapData({
					'location': [this.locationList[0].lat, this.locationList[0].long],
					'bookingType': 0,
					'isOpenDriverList': true
				});
			}
			else if (this.isOpenCreateEditdraftjob == 1) {
				this.reloadMapData({
					'location': [this.editLocationList[0].lat, this.editLocationList[0].long],
					'bookingType': this.selectedBooking.bookingType,
					'isOpenDriverList': true
				});
			}
		}

		this.setLocationArr();

		this.setLocationMap();

		if (this.isOpenCreateEditdraftjob == 0) {
			this.setStopInOut('add', beforeLocationList);
			this.getFixedPrice();
		}
		else if (this.isOpenCreateEditdraftjob == 1) {
			this.setEditStopInOut('add', beforeEditLocationList)
			this.getFixedPrice(this.selectedBooking.carTypeId);
		}
	}
	setStopInOut(addOrRemove: string, beforeLocationListLength: number, removeIndex: number = 1) {
		if (addOrRemove == 'add') {
			if (this.locationList.length >= 3) {
				let controlArray = <FormArray>this.passengersForm.controls["passengersDetails"];
				for (let i = 0; i < this.passengersArray.length; i++) {
					if (beforeLocationListLength != this.locationList.length) {
						if (controlArray.controls[i].get('out')?.value == this.locationList.length - 2) {
							controlArray.controls[i].patchValue({ out: this.locationList.length - 1 });
						}
					}
					else {
						if (controlArray.controls[i].get('out')?.value == this.locationList.length - 1) {
							setTimeout(() => {
								controlArray.controls[i].patchValue({ out: this.locationList.length - 1 });
							}, 0);
						} else {
							setTimeout(() => {
								controlArray.controls[i].patchValue({ out: controlArray.controls[i].get('out')?.value });
							}, 0);
						}
						if (controlArray.controls[i].get('in')?.value == 0) {
							setTimeout(() => {
								controlArray.controls[i].patchValue({ in: 0 });
								controlArray.controls[i].patchValue({ in: controlArray.controls[i].get('in')?.value });
							}, 0);
						} else {
							setTimeout(() => {
								controlArray.controls[i].patchValue({ in: controlArray.controls[i].get('in')?.value });
							}, 0);
						}
					}
				}
			} else {
				for (let i = 0; i < this.passengersArray.length; i++) {
					let controlArray = <FormArray>this.passengersForm.controls["passengersDetails"];
					setTimeout(() => {
						controlArray.controls[i].patchValue({ out: this.locationList.length - 1 })
					}, 0);
				}
			}
		} else {
			let controlArray = <FormArray>this.passengersForm.controls["passengersDetails"];
			for (let i = 0; i < this.passengersArray.length; i++) {
				if (controlArray.controls[i].get('out')?.value == removeIndex + 1) {
					setTimeout(() => {
						controlArray.controls[i].patchValue({ out: this.locationList.length - 1 });
					}, 0);
				}
				else if (controlArray.controls[i].get('out')?.value > removeIndex + 1) {
					setTimeout(() => {
						controlArray.controls[i].patchValue({ out: controlArray.controls[i].get('out')?.value - 1 });
					}, 0);
				}
				if (controlArray.controls[i].get('in')?.value == removeIndex + 1) {
					setTimeout(() => {
						controlArray.controls[i].patchValue({ in: 0 });
					}, 0);
				} else if (controlArray.controls[i].get('in')?.value > removeIndex + 1) {
					setTimeout(() => {
						controlArray.controls[i].patchValue({ in: controlArray.controls[i].get('in')?.value - 1 });
					}, 0);
				}
			}

		}
	}
	setEditStopInOut(addOrRemove: string, beforeEditLocationListLength: number, removeIndex: number = 1) {
		if (addOrRemove == 'add') {
			if (this.editLocationList.length >= 3) {
				let controlArray = <FormArray>this.editPassengersForm.controls["passengersDetails"];
				for (let i = 0; i < this.editPassengersArray.length; i++) {
					if (beforeEditLocationListLength != this.editLocationList.length) {
						if (controlArray.controls[i].get('out')?.value == this.editLocationList.length - 2) {
							controlArray.controls[i].patchValue({ out: this.editLocationList.length - 1 });
						}
					}
					else {
						if (controlArray.controls[i].get('out')?.value == this.editLocationList.length - 1) {
							setTimeout(() => {
								controlArray.controls[i].patchValue({ out: this.editLocationList.length - 1 });
							}, 0);
						} else {
							setTimeout(() => {
								controlArray.controls[i].patchValue({ out: controlArray.controls[i].get('out')?.value });
							}, 0);
						}
						if (controlArray.controls[i].get('in')?.value == 0) {
							setTimeout(() => {
								controlArray.controls[i].patchValue({ in: 0 });
							}, 0);
						} else {
							setTimeout(() => {
								controlArray.controls[i].patchValue({ in: controlArray.controls[i].get('in')?.value });
							}, 0);

						}

					}
				}
			} else {
				for (let i = 0; i < this.editPassengersArray.length; i++) {
					let controlArray = <FormArray>this.editPassengersForm.controls["passengersDetails"];
					setTimeout(() => {
						controlArray.controls[i].patchValue({ out: this.editLocationList.length - 1 })
					}, 0);
				}
			}
		} else {
			let controlArray = <FormArray>this.editPassengersForm.controls["passengersDetails"];
			for (let i = 0; i < this.editPassengersArray.length; i++) {
				if (controlArray.controls[i].get('out')?.value == removeIndex + 1) {
					setTimeout(() => {
						controlArray.controls[i].patchValue({ out: this.editLocationList.length - 1 });
					}, 100);
				}
				else if (controlArray.controls[i].get('out')?.value > removeIndex + 1) {
					setTimeout(() => {
						controlArray.controls[i].patchValue({ out: controlArray.controls[i].get('out')?.value - 1 });

					}, 100);
				}
				if (controlArray.controls[i].get('in')?.value == removeIndex + 1) {
					setTimeout(() => {
						controlArray.controls[i].patchValue({ in: 0 });
					}, 100);
				} else if (controlArray.controls[i].get('in')?.value > removeIndex + 1) {
					setTimeout(() => {
						controlArray.controls[i].patchValue({ in: controlArray.controls[i].get('in')?.value - 1 });

					}, 100);
				}
			}

		}
	}
	outValueSelected(event: any, index: any) {
		let controlArray = <FormArray>this.passengersForm.controls["passengersDetails"];

		controlArray.controls[index].patchValue({ out: event.target.value })

	}
	setLocationArr() {
		console.log('****** setLocationArr locationList ******', this.locationList);
		console.log('****** setLocationArr editLocationList ******', this.editLocationList);
		if (this.isOpenCreateEditdraftjob == 0) {
			if (this.locationList.length > 0) {
				let location = [];
				let pickupIndex = this.locationList.findIndex((obj: any = {}) => obj.addressId == 'pickup');
				if (pickupIndex >= 0) {
					location.push(this.locationList[pickupIndex]);
				}

				let stop = 0;
				for (let i = 0; i < this.locationList.length; i++) {
					if (this.locationList[i].addressId != 'pickup' && this.locationList[i].addressId != 'drop') {
						this.locationList[i].addressId = 'stop' + stop;
						location.push(this.locationList[i]);
						stop++;
					}
				}

				let dropIndex = this.locationList.findIndex((obj: any = {}) => obj.addressId == 'drop');
				if (dropIndex >= 0) {
					location.push(this.locationList[dropIndex]);
				}

				this.locationList = location;
				this.setMarkerArray(this.locationList);
			}
		}
		else if (this.isOpenCreateEditdraftjob == 1) {
			if (this.editLocationList.length > 0) {
				let location = [];
				let pickupIndex = this.editLocationList.findIndex((obj: any = {}) => obj.addressId == 'pickup');
				if (pickupIndex >= 0) {
					location.push(this.editLocationList[pickupIndex]);
				}

				let stop = 0;
				for (let i = 0; i < this.editLocationList.length; i++) {
					if (this.editLocationList[i].addressId != 'pickup' && this.editLocationList[i].addressId != 'drop') {
						this.editLocationList[i].addressId = 'stop' + stop;
						location.push(this.editLocationList[i]);
						stop++;
					}
				}

				let dropIndex = this.editLocationList.findIndex((obj: any = {}) => obj.addressId == 'drop');
				if (dropIndex >= 0) {
					location.push(this.editLocationList[dropIndex]);
				}

				this.editLocationList = location;
				this.setMarkerArray(this.editLocationList);
			}
		}
	}
	markerIcon = {
		findAreaUrl: "../../assets/images/find_area.png",
		pickupUrl: "../../assets/images/pickup.png",
		dropUrl: "../../assets/images/drop.png",
		stopUrl: "../../assets/images/stop.png",
		arrivedStopUrl: "../../assets/images/arrivedStop.png",
		scaledSize: {
			height: 40,
			width: 40
		}
	}
	setMarkerArray(locationArray: any) {
		console.log('****** setMarkerArray locationArray ******', locationArray);
		for (let k = 0; k < locationArray.length; k++) {
			if (locationArray[k].addressId == "pickup") {
				locationArray[k].icon = {
					url: this.markerIcon.pickupUrl,
					scaledSize: this.markerIcon.scaledSize
				}
			}
			else if (locationArray[k].addressId == "drop") {
				locationArray[k].icon = {
					url: this.markerIcon.dropUrl,
					scaledSize: this.markerIcon.scaledSize
				}
			}
			else {
				if (locationArray[k].isStopArrived == 1) {
					locationArray[k].icon = {
						url: this.markerIcon.arrivedStopUrl,
						scaledSize: this.markerIcon.scaledSize
					}
				}
				else {
					locationArray[k].icon = {
						url: this.markerIcon.stopUrl,
						scaledSize: this.markerIcon.scaledSize
					}
				}
			}
		}

		if (this.isOpenCreateEditdraftjob == 0) {
			this.locationList = locationArray;
		}
		else if (this.isOpenCreateEditdraftjob == 1) {
			this.editLocationList = locationArray;
		}

	}
	polyLine: any;
	setMapInterval: any;
	setLocationMap() {
		let location: any = [];
		if (this.isOpenCreateEditdraftjob == 0) {
			location = this.locationList;
		}
		else if (this.isOpenCreateEditdraftjob == 1) {
			location = this.editLocationList;
		}

		let path = []
		for (let i = 0; i < location.length; i++) {
			path.push({ lat: parseFloat(location[i].lat), lng: parseFloat(location[i].long) });
		}

		if (path.length > 0) {
			this.directionBound(path);
		}

		this.polyLine.setPath(path);
		this.polyLine.setMap(this.map);
	}
	locationSwipe: boolean = false;
	editJobLocationSwipe: boolean = false;
	swipe() {
		if (this.isOpenCreateEditdraftjob == 0) {
			if (this.locationList.length > 0) {
				this.locationList = this.locationList.slice(0).reverse();

				let pickupIndex = this.locationList.findIndex((obj: any = {}) => obj.addressId == 'pickup');
				if (pickupIndex >= 0) {
					this.locationList[pickupIndex].addressId = 'drop';
				}

				let dropIndex = this.locationList.findIndex((obj: any = {}) => obj.addressId == 'drop');
				if (dropIndex >= 0) {
					this.locationList[dropIndex].addressId = 'pickup';
				}

				this.setLocationArr();
				this.setMarkerArray(this.locationList);
				this.setStopInOut('add', this.locationList.length);

				this.setLocationMap();

				let pickupIndexNew = this.locationList.findIndex((obj: any = {}) => obj.addressId == 'pickup');
				let dropIndexNew = this.locationList.findIndex((obj: any = {}) => obj.addressId == 'drop');

				if (pickupIndexNew >= 0 && dropIndexNew >= 0) {
					this.locationsForm.patchValue({
						pickupPoint: this.locationList[pickupIndexNew].address,
						dropPoint: this.locationList[dropIndexNew].address,
					});
				}

				for (let j = 0; j < this.locationList.length; j++) {
					if (this.locationList[j].addressId != 'pickup' && this.locationList[j].addressId != 'drop') {
						this.locationArray.at(j - 1).patchValue({ waypoint: this.locationList[j].address });
					}
				}

				this.getFixedPrice();
				this.reloadMapData({
					'location': [this.locationList[0].lat, this.locationList[0].long],
					'bookingType': 0,
					'isOpenDriverList': true
				});
				this.locationSwipe = !this.locationSwipe;
			}
		}
		else if (this.isOpenCreateEditdraftjob == 1) {
			if (this.editLocationList.length > 0) {
				this.editLocationList = this.editLocationList.slice(0).reverse();
				let pickupIndex = this.editLocationList.findIndex((obj: any = {}) => obj.addressId == 'pickup');

				if (pickupIndex >= 0) {
					this.editLocationList[pickupIndex].addressId = 'drop';
				}

				let dropIndex = this.editLocationList.findIndex((obj: any = {}) => obj.addressId == 'drop');
				if (dropIndex >= 0) {
					this.editLocationList[dropIndex].addressId = 'pickup';
				}

				this.setLocationArr();
				this.setMarkerArray(this.editLocationList);
				this.setEditStopInOut('add', this.editLocationList.length)
				this.setLocationMap();

				let pickupIndexNew = this.editLocationList.findIndex((obj: any = {}) => obj.addressId == 'pickup');
				let dropIndexNew = this.editLocationList.findIndex((obj: any = {}) => obj.addressId == 'drop');

				if (pickupIndexNew >= 0 && dropIndexNew >= 0) {
					this.editLocationsForm.patchValue({
						pickupPoint: this.editLocationList[pickupIndexNew].address,
						dropPoint: this.editLocationList[dropIndexNew].address,
					});
				}

				for (let j = 0; j < this.editLocationList.length; j++) {
					if (this.editLocationList[j].addressId != 'pickup' && this.editLocationList[j].addressId != 'drop') {
						this.editLocationArray.at(j - 1).patchValue({ waypoint: this.editLocationList[j].address });
					}
				}

				this.getFixedPrice(this.selectedBooking.carTypeId);
				this.reloadMapData({
					'location': [this.editLocationList[0].lat, this.editLocationList[0].long],
					'bookingType': this.selectedBooking.bookingType,
					'isOpenDriverList': true
				});
				this.editJobLocationSwipe = !this.editJobLocationSwipe;
			}
		}

		if (this.newRideOptionStatus == false || this.editRideOptionStatus == false) {
			this.setAssignMinutes();
		}
	}
	// drag Marker
	async markerDragEnd($event: any, addressId: string) {
		const locations: any = await this.getAddressForLatlong({ lat: $event.coords.lat, lng: $event.coords.lng })
		if (locations.status == true) {
			if (this.isOpenCreateEditdraftjob == 0) {
				if (addressId == "pickup") {
					this.locationsForm.get('pickupPoint')?.setValue(locations.address);
					let pickupIndex = this.locationList.findIndex((obj: any) => obj.addressId == 'pickup');
					if (pickupIndex >= 0) {
						this.locationList[pickupIndex].address = locations.address;
						this.locationList[pickupIndex].lat = $event.coords.lat;
						this.locationList[pickupIndex].long = $event.coords.lng;
					}

					this.reloadMapData({
						'location': [$event.coords.lat, $event.coords.lng],
						'bookingType': 0,
						'isOpenDriverList': true
					});
				} else if (addressId == "drop") {
					this.locationsForm.get('dropPoint')?.setValue(locations.address);
					let dropIndex = this.locationList.findIndex((obj: any) => obj.addressId == 'drop');
					if (dropIndex >= 0) {
						this.locationList[dropIndex].address = locations.address;
						this.locationList[dropIndex].lat = $event.coords.lat;
						this.locationList[dropIndex].long = $event.coords.lng;
					}
				} else if (addressId != "pickup" && addressId != "drop") {
					this.locationArray.at(+addressId.split('stop')[1]).patchValue({ waypoint: locations.address });
					let stopIndex = this.locationList.findIndex((obj: any) => obj.addressId == addressId);
					if (stopIndex >= 0) {
						this.locationList[stopIndex].address = locations.address;
						this.locationList[stopIndex].lat = $event.coords.lat;
						this.locationList[stopIndex].long = $event.coords.lng;
					}
				}
			}
			else if (this.isOpenCreateEditdraftjob == 1) {
				if (addressId == "pickup") {
					this.editLocationsForm.get('pickupPoint')?.setValue(locations.address);
					let pickupIndex = this.editLocationList.findIndex((obj: any) => obj.addressId == 'pickup');
					if (pickupIndex >= 0) {
						this.editLocationList[pickupIndex].address = locations.address;
						this.editLocationList[pickupIndex].lat = $event.coords.lat;
						this.editLocationList[pickupIndex].long = $event.coords.lng;
					}

					this.reloadMapData({
						'location': [$event.coords.lat, $event.coords.lng],
						'bookingType': this.selectedBooking.bookingType,
						'isOpenDriverList': true
					});
				} else if (addressId == "drop") {
					this.editLocationsForm.get('dropPoint')?.setValue(locations.address);
					let dropIndex = this.editLocationList.findIndex((obj: any) => obj.addressId == 'drop');
					if (dropIndex >= 0) {
						this.editLocationList[dropIndex].address = locations.address;
						this.editLocationList[dropIndex].lat = $event.coords.lat;
						this.editLocationList[dropIndex].long = $event.coords.lng;
					}
				} else if (addressId != "pickup" && addressId != "drop") {
					this.editLocationArray.at(+addressId.split('stop')[1]).patchValue({ waypoint: locations.address });
					let stopIndex = this.editLocationList.findIndex((obj: any) => obj.addressId == addressId);
					if (stopIndex >= 0) {
						this.editLocationList[stopIndex].address = locations.address;
						this.editLocationList[stopIndex].lat = $event.coords.lat;
						this.editLocationList[stopIndex].long = $event.coords.lng;
					}
				}
			}

			if (addressId == "pickup" && (this.newRideOptionStatus == false || this.editRideOptionStatus == false)) {
				this.setAssignMinutes();
			}

			this.setLocationMap();

			if (this.isOpenCreateEditdraftjob == 0) {
				this.getFixedPrice();
			}
			else if (this.isOpenCreateEditdraftjob == 1) {
				this.getFixedPrice(this.selectedBooking.carTypeId);
			}
		}
	}
	geocoder: any;
	getAddressForLatlong(latLong = { lat: 0, lng: 0 }) {
		return new Promise((resolve, reject) => {
			this.geocoder.geocode({
				latLng: latLong
			}, (responses: any) => {

				if (responses && responses.length > 0)
					resolve({ status: true, address: responses[0].formatted_address });
				else
					reject({ status: false, data: 'Cannot determine address at this location.' });
			});
		})
	}
	createJobUserAddressList: any = [];
	getUserAddress(event: any) {
		if (event.target.value != '') {
			this.getUserAddressCall(event.target.value);
		}
	}
	getUserAddressCall(value: string = '') {
		if (value != '') {
			let req: any = {
				'mobileNo': value,
			}

			this.CallMethod.getUserAddress(req).pipe(takeUntil(this.unsubscribe$)).subscribe((result: any) => {
				if (result.status) {
					this.createJobUserAddressList = [];
					if (result.addressData.length > 0) {
						for (let i = 0; i < result.addressData.length; i++) {
							let addressSplit = result.addressData[i].address.split(",");
							let title = addressSplit[0];


							addressSplit.splice(0, 1);
							let address = addressSplit.join(",");


							this.createJobUserAddressList.push({
								'fullAddress': result.addressData[i].address,
								'title': title,
								'address': address,
								'lat': result.addressData[i].lat,
								'long': result.addressData[i].lng,
								'placeId': "",
								'flag': ""
							});

						}

						this.createJobAddressList = this.createJobUserAddressList;
					}

				}
			});


		}
	}

	isAccountUser: boolean = false;
	accountUser() {
		this.isAccountUser = true
	}

	selectAccountCar(carTypeId: any) {
		console.log('****** selectAccountCar carTypeId ******', carTypeId);
		this.carTypeChange('', carTypeId.carTypeId)

	}

	carRent: number = 0;
	withoutSurgeCarRent: number = 0;
	discountAmount: number = 0.00;
	selectedCoupon: any = {};
	currency: any = null;
	duration: number = 0;
	distance: number = 0;
	carTypeRentArray: any = [];
	distanceVariant: number = 1;
	surgeMultiplier: number = 1;
	zoneSurgePopup: number = 1;
	zoneId: string = "";
	locationJson: any[] = [];
	showRentInfo: boolean = false;
	showPassengerInfo: boolean = false;
	newRideSelectCarTypeDisable: boolean = true;

	/* Edit Booking Objects */
	editJobCarRent: number = 0;
	editJobSelectedCoupon: any = {};
	withoutSurgeEditJobCarRent: number = 0;
	editJobDiscountAmount: number = 0.00;
	editJobDuration: number = 0;
	editJobDistance: number = 0;
	editCarTypeRentArray: any = [];
	editLocationJson: any[] = [];
	editJobCurrency: any = null;
	editJobDistanceVariant: number = 1;
	editJobSurgeMultiplier: number = 1;
	editJobZoneSurgePopup: number = 1;
	editJobZoneId: string = "";
	editJobShowRentInfo: boolean = false;
	editShowPassengerInfo: boolean = false;
	editNewRideSelectCarTypeDisable: boolean = true;

	carTypeArray: any = { "DAY": [], "NIGHT": [] };
	editJobCarTypeArray: any = { "DAY": [], "NIGHT": [] };
	showRentShimmer: boolean = true;
	getFixedPrice(carTypeId: string = 'default') {
		console.log('--------------------- getFixedPrice carTypeId ---------------------', carTypeId)
		let locationsJson = [];
		let location: any = [];
		if (this.isOpenCreateEditdraftjob == 0) {
			location = this.locationList;
		}
		else if (this.isOpenCreateEditdraftjob == 1) {
			location = this.editLocationList;
		}

		if (location.length > 1) {
			for (let i = 0; i < location.length; i++) {
				let locationJ: any = {
					lat: location[i].lat,
					long: location[i].long,
					address: location[i].address
				};

				if (this.isOpenCreateEditdraftjob == 1) {
					locationJ.isStopArrived = (location[i].isStopArrived != undefined && location[i].isStopArrived == 1) ? 1 : 0;
					locationJ.stoparrivedTripTime = (location[i].stoparrivedTripTime != undefined && location[i].stoparrivedTripTime != '') ? location[i].stoparrivedTripTime : '';
				}

				locationsJson.push(locationJ);
			}

			let req: any = {
				"bookingType": "cityRide",
				"bookingFlag": 0,
				"location": locationsJson,
				"timeType": -1
			}
			console.log('***** get Fixed Price this.passengersForm.value.passengersDetails[0].id *****', this.passengersForm.value.passengersDetails[0]);
			console.log('***** get Fixed Price this.editPassengersForm.value.passengersDetails[0] *****', this.editPassengersForm.value.passengersDetails[0]);
			if (this.isOpenCreateEditdraftjob == 0) {
				console.log('****** this.passengersForm.value.passengersDetails[0]?.id.trim() ******', this.passengersForm.value.passengersDetails[0]?.id);
				console.log('****** this.passengersForm.value.passengersDetails[0].accountId ******', this.passengersForm.value.passengersDetails[0].accountId);
				if (this.passengersForm.value.passengersDetails[0]?.id.trim() != '' && this.passengersForm.value.passengersDetails[0]?.accountId.trim() != '') {
					req.userId = this.passengersForm.value.passengersDetails[0].id;
				}
			}
			else if (this.isOpenCreateEditdraftjob == 1) {
				if (this.editPassengersForm.value.passengersDetails[0]?.id.trim() != '' && this.editPassengersForm.value.passengersDetails[0]?.accountId.trim() != '') {
					req.userId = this.editPassengersForm.value.passengersDetails[0].id;
				}
			}



			this.showRentShimmer = false;
			this.CallMethod.getFixedPrice(req).pipe(takeUntil(this.unsubscribe$)).subscribe((result: any) => {
				this.locationJson = [];
				this.editLocationJson = [];
				if (result.status) {
					// Added variable afterwards
					let passPickupTime = moment(this.Global.getCompanyCurrentTime(), this.Global.dateFormat).format('YYYY-MM-DD HH:mm:ss');

					if (this.isOpenCreateEditdraftjob == 0) {
						this.showRentInfo = true;
						this.showPassengerInfo = true;
						this.newRideSelectCarTypeDisable = false;

						this.carTypeArray["DAY"] = result.carDayTypeRent;
						this.carTypeArray["NIGHT"] = result.carNightTypeRent;

						this.duration = result.duration.toFixed(environment.roundUpNumber);
						this.distance = result.distance.toFixed(environment.roundUpNumber);
						this.currency = result.currency;
						this.distanceVariant = result.distanceVariant;
						this.zoneId = result.zoneId;
						this.locationJson = result.locationJson;
						this.zoneSurgePopup = result.zoneSurgePopup;
						this.isPriceFromAirportZone = result.priceFromAirportZone

						let airportZone = result.airportPolygon

						console.log('****** this.isPriceFromAirportZone ******', this.isPriceFromAirportZone);

						// Added if afterwards
						if (this.pickupTime != "Invalid date" && this.pickupTime != null) {
							passPickupTime = this.pickupTime;
						}


						if (this.locationJson.length > 0 && this.isPriceFromAirportZone == 1 && airportZone.zoneTitle) {
							for (let k = 0; k < this.locationJson.length; k++) {
								if (k == 0) {
									this.locationJson[k].addressId = 'pickup';
									this.locationJson[k].lat = airportZone.zoneLat;
									this.locationJson[k].long = airportZone.zoneLng;
									this.locationJson[k].address = airportZone.zoneTitle;
									this.locationsForm.get('pickupPoint')?.setValue(airportZone.zoneTitle);
								}
								else if (k == (this.locationJson.length - 1)) {
									this.locationJson[k].addressId = 'drop';
									this.locationsForm.get('dropPoint')?.setValue(this.locationJson[k].address);
								}
								// else {
								// 	this.locationArray.push(this.formBuilder.group({
								// 		waypoint: this.locationJson[k].address
								// 	}));
								// }
								this.locationJson[k].isStopArrived = 0;
								this.locationJson[k].isStoparrivedTripTime = "";
							}

							this.locationList = this.locationJson;
							this.locationJson = this.locationJson;
							this.setLocationArr();
							this.setLocationMap();


							console.log('****** this.locationList ******', this.locationList);

						}

					}
					else if (this.isOpenCreateEditdraftjob == 1) {
						this.editJobShowRentInfo = true;
						this.editShowPassengerInfo = true;
						this.editNewRideSelectCarTypeDisable = false;

						this.editJobCarTypeArray["DAY"] = result.carDayTypeRent;
						this.editJobCarTypeArray["NIGHT"] = result.carNightTypeRent;

						console.log("=====this.editJobCarTypeArray :: ", this.editJobCarTypeArray);


						this.editJobDuration = result.duration.toFixed(environment.roundUpNumber);
						this.editJobDistance = result.distance.toFixed(environment.roundUpNumber);
						this.editJobCurrency = result.currency;
						this.editJobDistanceVariant = result.distanceVariant;
						this.editJobZoneId = result.zoneId;
						this.editLocationJson = result.locationJson;
						this.editJobZoneSurgePopup = result.zoneSurgePopup;
						this.editIsPriceFromAirportZone = result.priceFromAirportZone

						let editAirportZone = result.airportPolygon


						console.log('****** this.isPriceFromAirportZone ******', this.isPriceFromAirportZone);

						console.log('****** get Fix Price this.editLocationJson ******', this.editLocationJson);

						// Added if afterwards
						if (this.editPickupTime != "Invalid date" && this.editPickupTime != null) {
							passPickupTime = this.editPickupTime;
						}

						console.log("****** this.editLocationJson.length > 0 && this.isPriceFromAirportZone == 1) ******", this.editLocationJson.length > 0 && this.editIsPriceFromAirportZone == 1);

						if (this.editLocationJson.length > 0 && this.editIsPriceFromAirportZone == 1 && editAirportZone.zoneTitle) {
							for (let k = 0; k < this.editLocationJson.length; k++) {
								if (k == 0) {
									this.editLocationJson[k].addressId = 'pickup';
									this.editLocationJson[k].lat = editAirportZone.zoneLat;
									this.editLocationJson[k].long = editAirportZone.zoneLng;
									this.editLocationJson[k].address = editAirportZone.zoneTitle;
									this.editLocationsForm.get('pickupPoint')?.setValue(editAirportZone.zoneTitle);
								}
								else if (k == (this.editLocationJson.length - 1)) {
									this.editLocationJson[k].addressId = 'drop';
									this.locationsForm.get('dropPoint')?.setValue(this.editLocationJson[k].address);
								}
								// else {
								// 	this.locationArray.push(this.formBuilder.group({
								// 		waypoint: this.editLocationJson[k].address
								// 	}));
								// }
								this.editLocationJson[k].isStopArrived = 0;
								this.editLocationJson[k].isStoparrivedTripTime = "";
							}

							this.editLocationJson = this.editLocationJson;
							this.editLocationList = this.editLocationJson;
							this.setLocationArr();
							this.setLocationMap();
							console.log('****** this.editLocationJson ******', this.editLocationJson);
							console.log('****** this.editLocationList ******', this.editLocationList);
						}

					}
					console.log("=-==== set fixed price this.pickupTime :: ", this.pickupTime);
					console.log("=-==== set fixed price this.editPickupTime :: ", this.editPickupTime);
					console.log("=-----passPickupTime :: ", passPickupTime);


					this.setFixPrice(passPickupTime, carTypeId);
				} else {
					if (result.statusCode == 1) {
						this.Popup.toasterMessage({
							'status': 'error',
							'title': "Oops!",
							'message': "This booking is out of our city limit, Please try booking outstation rides.",
						});
					}
					else if (result.statusCode == 2) {
						this.Popup.toasterMessage({
							'status': 'error',
							'title': "Oops!",
							'message': "We don’t serve at this location. Please try changing your location.",
						});
					}
					else if (result.statusCode == 3) {
						this.Popup.toasterMessage({
							'status': 'error',
							'title': "Oops!",
							'message': "This booking falls in our city limit, Please try our pocket friendly city rides."
						});
					}
					else if (result.statusCode == 4) {
						this.Popup.toasterMessage({
							'status': 'error',
							'title': "Oops!",
							'message': "We have a problem reaching our server.  Please try again later."
						});
					}
					else if (result.statusCode == 5) {
						this.Popup.toasterMessage({
							'status': 'error',
							'title': "Oops!",
							'message': "Unfortunately our services are not available in this area, Please check back later."
						});
					}
					else if (result.statusCode == 6) {
						this.Popup.toasterMessage({
							'status': 'error',
							'title': "Oops!",
							'message': "Car is not available."
						});
					}
				}

				this.showRentShimmer = true;
			});
		}
	}
	setFixPrice(time: any, carTypeId: string = 'default') {
		console.log("=-----set fix price time :: ", time);
		console.log("=-=-=-=--editNewRideSelectCarTypeDisable :: ", this.editNewRideSelectCarTypeDisable);
		console.log("=-=-=-=--this.isOpenCreateEditdraftjob :: ", this.isOpenCreateEditdraftjob);
		console.log('****** setFixPrice carTypeId ******', carTypeId);
		this.Global.carTypeId = ""

		if (this.isOpenCreateEditdraftjob == 0) {
			console.log("===== setFixPrice carTypeId:: setFixPrice ::::::::::::::::::::::", carTypeId);
			console.log("===== setFixPrice this.newRideSelectCarTypeDisable :: ", this.newRideSelectCarTypeDisable);

			if (this.newRideSelectCarTypeDisable == false) {
				console.log("====== this.newRideBookingToDate :: ", this.newRideBookingToDate);

				const dayNight: string = this.Global.getDayNight(time);
				console.log("===>>dayNight:::::", dayNight);
				console.log("=-==== setFixPrice this.carTypeArray :: ", this.carTypeArray);


				this.carTypeRentArray = this.carTypeArray[dayNight];
				console.log("===== this.carTypeRentArray :: ", this.carTypeRentArray);

				console.log("===== setFixPrice carTypeId :: ", carTypeId);

				if (carTypeId == 'default') {
					console.log("===== this.Global.carTypeId :: ", this.Global.carTypeId);

					if (this.Global.carTypeId == undefined || this.Global.carTypeId == "") {
						this.Global.carTypeId = this.carTypeRentArray[0].carTypeId;
						this.carRent = this.carTypeRentArray[0].carRent.toFixed(environment.priceRoundUpNumber);
						this.withoutSurgeCarRent = this.carTypeRentArray[0].subTotalWithoutSurges.toFixed(environment.priceRoundUpNumber);
						console.log("if dont have default car global car");
					}
					this.carTypeChange("", this.Global.carTypeId);
					console.log("if have default car");
				}
				else if (carTypeId == '' || carTypeId == null) {
					console.log("else if cartype change id ", carTypeId);

					this.Global.carTypeId = this.carTypeRentArray[0].carTypeId;
					console.log("else if  this.Global.carTypeId", carTypeId);
					this.carTypeChange("", this.Global.carTypeId);
				}
				else {
					console.log("else dont have default car");

					this.carTypeChange("", carTypeId);
				}
			}
		}
		else if (this.isOpenCreateEditdraftjob == 1) {
			console.log("===== this.editJobSelectedCarTypeData :: ", this.editJobSelectedCarTypeData);

			if (this.editNewRideSelectCarTypeDisable == false) {
				const dayNight: string = this.Global.getDayNight(time);
				console.log("========== setFixPrice edit dayNight :: ", dayNight);

				this.editCarTypeRentArray = this.editJobCarTypeArray[dayNight];
				console.log("===== this.editCarTypeRentArray :: ", this.editCarTypeRentArray);


				if (carTypeId == 'default') {
					if (this.Global.carTypeId == undefined || this.Global.carTypeId == "") {
						this.Global.carTypeId = this.editCarTypeRentArray[0].carTypeId;
						this.editJobCarRent = this.editCarTypeRentArray[0].carRent.toFixed(environment.priceRoundUpNumber);
						this.withoutSurgeEditJobCarRent = this.editCarTypeRentArray[0].subTotalWithoutSurges.toFixed(environment.priceRoundUpNumber);
					}
					this.carTypeChange("", this.Global.carTypeId);
				}
				else {
					this.carTypeChange("", carTypeId);
				}
			}
			// else if(this.editNewRideSelectCarTypeDisable == true){
			// 	console.log("===== this.editJobSelectedCarTypeData.data.carTypeId :: ", this.editJobSelectedCarTypeData.data.carTypeId);

			// 	this.carTypeChange("", this.editJobSelectedCarTypeData.data.carTypeId);
			// }
		}
	}
	newRideTax: number = 0;
	editRideTax: number = 0;
	editRideArrears: number = 0.00;
	newRideTotal: any = 0;
	editRideTotal: number = 0;
	selectedCarTypeData: any = {};
	editJobSelectedCarTypeData: any = {};


	newRideCurrentCarTypeId: any = "";
	editRideCurrentCarTypeId: any = "";

	tollTax: number = 0.000;
	airportParking: number = 0.000;
	serviceCharges: number = 0.000;
	isPriceFromAirportZone: number = 0;

	editTollTax: number = 0.000;
	editAirportParking: number = 0.000;
	editServiceCharges: number = 0.000;
	editIsPriceFromAirportZone: number = 0;

	async carTypeChange(event: any, carTypeId: string) {
		console.log("carTypeId::::::::::::::::::::::::::carTypeChange:::::", carTypeId);

		carTypeId = (carTypeId != "") ? carTypeId : event.target.value;
		console.log("===== carTypeId :: ", carTypeId);

		if (this.isOpenCreateEditdraftjob == 0) {
			console.log("====== carTypeChange this.carTypeRentArray :: ", this.carTypeRentArray);

			this.newRideCurrentCarTypeId = carTypeId;

			this.selectedCarTypeData = await this.getArrayInObject(this.carTypeRentArray, ['carTypeId', carTypeId]);
			console.log("====== carTypeChange this.selectedCarTypeData :: ", this.selectedCarTypeData);
			console.log("====== carTypeChange this.selectedCarTypeData data.extraCharges :: ", this.selectedCarTypeData.data.extraCharges);
			this.carRent = this.selectedCarTypeData?.data.carRent.toFixed(environment.priceRoundUpNumber);
			console.log('****** selectedCarTypeData this.carRent ******', this.carRent);
			if (this.selectedCarTypeData.status) {
				this.airportParking = this.selectedCarTypeData.data.airportParking
				this.serviceCharges = this.selectedCarTypeData.data.serviceCharges
				this.tollTax = this.selectedCarTypeData.data.toll;

				console.log('****** this.airportParking  ******', this.airportParking);
				console.log('****** this.serviceCharges ******', this.serviceCharges);
				console.log('****** this.tollTax ******', this.tollTax);

				// this.carRent = this.carRent + this.airportParking + this.serviceCharges + this.tollTax
			}
			console.log('****** after adding airport tax ******', this.carRent);
			this.withoutSurgeCarRent = this.selectedCarTypeData.data.subTotalWithoutSurges.toFixed(environment.priceRoundUpNumber);
			this.surgeMultiplier = parseFloat(this.selectedCarTypeData.data.surgeMultiplier);

			// if (Object.entries(this.selectedCarTypeData.data.couponData).length !== 0) {
			this.selectCoupon(this.selectedCarTypeData.data.couponData, 'carTypeChange');
			// }

			console.log("===== this.newRideCurrentCarTypeId :: ", this.newRideCurrentCarTypeId);


			// let totalTax = this.Global.taxCalculate(+this.carRent);
			// this.newRideTax = +totalTax.totalTaxAmount.toFixed(environment.roundUpNumber);
			// this.newRideTotal = +(parseFloat(this.carRent.toString()) + totalTax.totalTaxAmount).toFixed(environment.roundUpNumber);
		} else if (this.isOpenCreateEditdraftjob == 1) {

			this.editRideCurrentCarTypeId = carTypeId;

			console.log("=====this.editCarTypeRentArray :: ", this.editCarTypeRentArray);

			this.editJobSelectedCarTypeData = this.getArrayInObject(this.editCarTypeRentArray, ['carTypeId', carTypeId]);
			console.log("===== this.editJobSelectedCarTypeData :: ", this.editJobSelectedCarTypeData);

			// Vishesh - new
			if (this.editJobSelectedCarTypeData.status) {
				this.editJobCarRent = this.editJobSelectedCarTypeData.data.carRent.toFixed(environment.priceRoundUpNumber);
				this.withoutSurgeEditJobCarRent = this.editJobSelectedCarTypeData.data.subTotalWithoutSurges.toFixed(environment.priceRoundUpNumber);
				this.editJobSurgeMultiplier = parseFloat(this.editJobSelectedCarTypeData.data.surgeMultiplier);

				this.editAirportParking = this.editJobSelectedCarTypeData.data.airportParking
				this.editServiceCharges = this.editJobSelectedCarTypeData.data.serviceCharges
				this.editTollTax = this.editJobSelectedCarTypeData.data.toll;
				console.log('****** this.editAirportParking ******', this.editAirportParking);
				console.log('****** this.editServiceCharges ******', this.editServiceCharges);
				console.log('****** this.editTollTax ******', this.editTollTax);
			}
			else {
				// this.isAccountEditUser = true; //! change vishesh l-6942
				this.getFixedPrice()
				console.log('*****>>> you had set corporate car wise for this user <<<******');
			}

			//!old - vishesh
			// this.editJobCarRent = this.editJobSelectedCarTypeData.data.carRent.toFixed(environment.roundUpNumber);
			// 	this.withoutSurgeEditJobCarRent = this.editJobSelectedCarTypeData.data.subTotalWithoutSurges.toFixed(environment.roundUpNumber);
			// 	this.editJobSurgeMultiplier = parseFloat(this.editJobSelectedCarTypeData.data.surgeMultiplier);

			if (this.selectedCarTypeData.data != undefined && this.selectedCarTypeData.data.couponData != undefined && Object.entries(this.selectedCarTypeData.data.couponData).length !== 0) {
				this.selectCoupon(this.selectedCarTypeData.data.couponData, 'carTypeChange');
			}
			// console.log("===== this.editRideCurrentCarTypeId :: ", this.editRideCurrentCarTypeId);

			// this.editJobSelectedCoupon = this.selectedCarTypeData.data.couponData;
			// let totalTax = this.Global.taxCalculate(+this.editJobCarRent);
			// this.editRideTax = +totalTax.totalTaxAmount.toFixed(environment.roundUpNumber);
			// this.editRideTotal = +(parseFloat(this.editJobCarRent.toString()) + totalTax.totalTaxAmount).toFixed(environment.roundUpNumber);
		}

		this.getCouponCall();
		this.clcBookingPrice();
	}
	hoursArray: any[] = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"];
	minutesArray: any[] = ["00", "05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55"];
	newRideSelectedHours: string = "00";
	newRideSelectedMinute: string = "15";
	newRideSelectedAssignTime: string = "15";
	setMinDate: any = null;
	newRideBookingToDate: any = null;
	newRideOptionStatus: boolean = true;
	pickupTime: any = null;
	/* Edit Booking object */
	editRideOptionStatus: boolean = true;
	editPickupTime: any = null;
	editRideSelectedHours: string = "00";
	editRideSelectedMinute: string = "15";
	editRideBookingToDate: any = null;
	editRideBookingToDateTime: any = null;
	setEditMinDate: any = null;
	editRideSelectedAssignTime: string = "15";

	newEstimatedExtraCharges: any = 0.00;
	editEstimatedExtraCharges: any = 0.00;
	newJobWhenRide(when: string) {
		let companyTime = this.Global.getCompanyCurrentTime();
		if (this.isOpenCreateEditdraftjob == 0) {
			if (when === "now") {
				this.newRideOptionStatus = true;
				this.newRide_repeatStatus = false;
				this.newRideSelectedHours = moment(this.Global.getCompanyCurrentTime(), this.Global.dateFormat).format('HH');
				this.newRideSelectedMinute = moment(this.Global.getCompanyCurrentTime(), this.Global.dateFormat).format('mm');
				// this.newRideBookingToDate = moment(companyTime, this.Global.dateFormat).format("YYYY-MM-DD HH:mm:ss");
				// this.newRideBookingFromDate = { start: moment(companyTime, this.Global.dateFormat), end: moment(companyTime, this.Global.dateFormat) };
			}
			else if (when === "later") {
				this.setAssignMinutes();
				this.newRideOptionStatus = false;
				let futureDate = moment(companyTime, this.Global.dateFormat).add(15, 'minutes').format('YYYY-MM-DD HH:mm:ss');
				let time = moment(futureDate).format("HH:mm:ss").split(":");
				this.newRideSelectedHours = time[0]; //hours
				this.newRideSelectedMinute = this.setMinutes(time[1]); //minutes
				// this.newRideBookingToDate = moment(companyTime, this.Global.dateFormat).format("YYYY-MM-DD"); //date
				this.newRideBookingToDate = { start: moment(companyTime, this.Global.dateFormat), end: moment(companyTime, this.Global.dateFormat) }; //date
				this.newRideBookingFromDate = { start: moment(companyTime, this.Global.dateFormat), end: moment(companyTime, this.Global.dateFormat) }; //date
				let min = moment(companyTime, this.Global.dateFormat).format("YYYY-MM-DD");
				this.setMinDate = moment(min);
			}
		} else if (this.isOpenCreateEditdraftjob == 1) {
			if (when === "now") {
				this.editRideOptionStatus = true;
				this.editPickupTime = moment(companyTime, this.Global.dateFormat).format("YYYY-MM-DD HH:mm:ss");


				// Added afterwards
				this.editRideBookingToDate = moment(companyTime, this.Global.dateFormat).format("YYYY-MM-DD"); //date
				this.editRideBookingToDateTime = moment(companyTime, this.Global.dateFormat).format("YYYY-MM-DD HH:mm:ss"); //date
				this.editRideSelectedHours = moment(this.Global.getCompanyCurrentTime(), this.Global.dateFormat).format('HH');
				this.editRideSelectedMinute = moment(this.Global.getCompanyCurrentTime(), this.Global.dateFormat).format('mm');
			}
			else if (when === "later") {
				this.setAssignMinutes();
				this.editRideOptionStatus = false;
				let futureDate = moment(companyTime, this.Global.dateFormat).add(15, 'minutes');
				let time = moment(futureDate).format("HH:mm:ss").split(":");
				this.editRideSelectedHours = time[0]; //hours
				this.editRideSelectedMinute = this.setMinutes(time[1]); //minutes
				this.editRideBookingToDate = moment(companyTime, this.Global.dateFormat).format("YYYY-MM-DD"); //date
				this.editRideBookingToDateTime = moment(companyTime, this.Global.dateFormat).format("YYYY-MM-DD HH:mm:ss"); //date
				let minDate = moment(companyTime, this.Global.dateFormat).format("YYYY-MM-DD");
				this.setEditMinDate = moment(minDate);
				this.editBookingCalenderModel = { start: moment(companyTime, this.Global.dateFormat), end: moment(companyTime, this.Global.dateFormat) };
			}
		}

		this.setPickupTime();
	}
	setAssignMinutes() {
		let lat;
		let lng;
		if (this.isOpenCreateEditdraftjob == 0) {
			lat = this.locationList[0].lat;
			lng = this.locationList[0].long;
		}
		else if (this.isOpenCreateEditdraftjob == 1) {
			lat = this.editLocationList[0].lat;
			lng = this.editLocationList[0].long;
		}

		let duration: any = this.Global.getDuration([{
			"lat": lat,
			"lng": lng
		}]);

		let min: string = "";
		if (duration >= 0 && duration <= 5) {
			min = "5";
		} else if (duration > 5 && duration <= 10) {
			min = "10";
		} else if (duration > 10 && duration <= 15) {
			min = "15";
		} else if (duration > 15 && duration <= 20) {
			min = "20";
		} else if (duration > 20 && duration <= 30) {
			min = "30";
		} else if (duration > 30 && duration <= 45) {
			min = "45";
		} else if (duration > 45 && duration <= 60) {
			min = "60";
		} else if (duration > 60 && duration <= 75) {
			min = "75";
		} else if (duration > 75 && duration <= 90) {
			min = "90";
		} else if (duration > 90 && duration <= 120) {
			min = "120";
		} else if (duration > 120 && duration <= 150) {
			min = "150";
		} else if (duration > 150 && duration <= 180) {
			min = "180";
		} else if (duration > 180 && duration <= 210) {
			min = "210";
		} else if (duration > 210 && duration <= 240) {
			min = "240";
		} else if (duration > 240 && duration <= 270) {
			min = "270";
		} else {
			min = "300";
		}

		if (this.Global.minimumAssignMinutes > parseInt(min)) {
			min = "" + this.Global.minimumAssignMinutes;
		}

		if (this.isOpenCreateEditdraftjob == 0) {
			this.newRideSelectedAssignTime = min;
		}
		else if (this.isOpenCreateEditdraftjob == 1) {
			this.editRideSelectedAssignTime = min;
		}
	}
	newRideLaterHoursChange(event: any) {
		if (this.isOpenCreateEditdraftjob == 0) {
			// this.newRideSelectCarTypeDisable = true; // Added by Kartavya, because in copy booking new ride time doesn't update when day night change.
			this.newRideSelectedHours = event.target.value;
		} else if (this.isOpenCreateEditdraftjob == 1) {
			this.editRideSelectedHours = event.target.value;
		}
		this.setPickupTime();
	}
	newRideLaterMinuteChange(event: any) {
		if (this.isOpenCreateEditdraftjob == 0) {
			this.newRideSelectedMinute = event.target.value;
		} else if (this.isOpenCreateEditdraftjob == 1) {
			this.editRideSelectedMinute = event.target.value;
		}
		this.setPickupTime();
	}
	newRideDateChange(event: any) {
		if (this.isOpenCreateEditdraftjob == 0) {
			console.log("===>>>dateChange:::::::", event.endDate);

			if (event.endDate != null) {
				// this.newRideBookingToDate = moment(event.endDate).format("YYYY-MM-DD");
				this.newRideBookingToDate = { start: moment(event.endDate), end: moment(event.endDate) }
				this.newRideBookingFromDate = { start: moment(event.endDate), end: moment(event.endDate) }
				console.log("====>>>this.newRideBookingToDate:::::::", this.newRideBookingToDate);

				this.minDate = moment(event.endDate)
			} else {
				this.newRideBookingFromDate = { start: moment(this.newRideBookingToDate), end: moment(this.newRideBookingToDate) };
				if (this.newRideOptionStatus == true) {
					this.newRideSelectedHours = moment(this.Global.getCompanyCurrentTime(), this.Global.dateFormat).format('HH');
					this.newRideSelectedMinute = moment(this.Global.getCompanyCurrentTime(), this.Global.dateFormat).format('mm');
				}

			}
		} else if (this.isOpenCreateEditdraftjob == 1) {
			if (event.endDate != null) {
				this.editRideBookingToDate = moment(event.endDate).format("YYYY-MM-DD");
				this.editRideBookingToDateTime = moment(event.endDate).format("YYYY-MM-DD HH:mm:ss");

			}
		}

		this.setPickupTime();
	}
	setPickupTime() {
		console.log("===== setPickupTime call");

		let companyTime = this.Global.getCompanyCurrentTime();
		if (this.isOpenCreateEditdraftjob == 0) {
			let date = moment(companyTime, this.Global.dateFormat).format("YYYY-MM-DD");

			let selectbooktime = moment(companyTime, this.Global.dateFormat).format("YYYY-MM-DD") + " " + this.newRideSelectedHours + ":" + this.newRideSelectedMinute + ":" + '00'
			console.log("===>>selectbooktime::::::::", selectbooktime);
			if (this.newRideBookingToDate != null) {
				date = moment(this.newRideBookingToDate.start).format("YYYY-MM-DD");
				selectbooktime = moment(this.newRideBookingToDate.start).format("YYYY-MM-DD") + " " + this.newRideSelectedHours + ":" + this.newRideSelectedMinute + ":" + '00'
			}
			console.log("====>>>===>>selectbooktime:::::::: after::::::", selectbooktime);
			console.log("=====this.selectedCarTypeData :: ", this.selectedCarTypeData);

			this.pickupTime = moment(selectbooktime).format("YYYY-MM-DD HH:mm:ss");
			console.log("this.selectedCarTypeData.status == true", this.selectedCarTypeData.status);

			this.setFixPrice(this.pickupTime, (this.selectedCarTypeData.status == true) ? this.selectedCarTypeData.data.carTypeId : '');

		} else if (this.isOpenCreateEditdraftjob == 1) {
			console.log("----- inside edit ", this.editRideBookingToDate);

			let selectbooktime = moment(this.editRideBookingToDate).format("YYYY-MM-DD") + " " + this.editRideSelectedHours + ":" + this.editRideSelectedMinute;
			this.editPickupTime = moment(selectbooktime).format("YYYY-MM-DD HH:mm:ss");
			console.log("=====editPickupTime :: ", this.editPickupTime);

			this.setFixPrice(this.editPickupTime, this.editJobSelectedCarTypeData.data.carTypeId);
		}
	}
	/* set Minutes */
	setMinutes(value: any) {
		let minuteValue = parseInt(value);
		let minutes: string = "";
		if (minuteValue > 55) {
			minutes = "55";
		} else if (minuteValue > 50 && minuteValue <= 55) {
			minutes = "55";
		} else if (minuteValue > 45 && minuteValue <= 50) {
			minutes = "50";
		} else if (minuteValue > 40 && minuteValue <= 45) {
			minutes = "45";
		} else if (minuteValue > 35 && minuteValue <= 40) {
			minutes = "40";
		} else if (minuteValue > 30 && minuteValue <= 35) {
			minutes = "35";
		} else if (minuteValue > 25 && minuteValue <= 30) {
			minutes = "30";
		} else if (minuteValue > 20 && minuteValue <= 25) {
			minutes = "25";
		} else if (minuteValue > 15 && minuteValue <= 20) {
			minutes = "20";
		} else if (minuteValue > 10 && minuteValue <= 15) {
			minutes = "15";
		} else if (minuteValue > 5 && minuteValue <= 10) {
			minutes = "10";
		} else if (minuteValue > 0 && minuteValue <= 5) {
			minutes = "05";
		} else {
			minutes = "00";
		}
		return minutes;
	}
	// start passenger list
	public searchCorporateAccount = new Subject<KeyboardEvent>();
	newRideforPassenger(event: any) {
		if (event.target.value.trim() != '') {
			this.searchCorporateAccount.next(event);
		}
	}
	accountDataList: any[] = [];
	editJobAccountDataList: any[] = [];
	newRideAccountIdCall(accountId: any) {
		this.accountDataList = [];
		this.CallMethod.getSearchAccountId({ search: accountId }).pipe(takeUntil(this.unsubscribe$)).subscribe((result: any) => {
			this.accountDataList = [];
			this.editJobAccountDataList = [];
			if (result.status) {
				if (this.isOpenCreateEditdraftjob == 0) {
					this.accountDataList = result.data;
				}
				else if (this.isOpenCreateEditdraftjob == 1) {
					this.editJobAccountDataList = result.data;
				}
			}
		});
	}
	accountDetails(userDetails: any) {
		if (this.isOpenCreateEditdraftjob == 0) {
			if (this.passengersForm.value.passengersDetails[0].name == '' && this.passengersForm.value.passengersDetails[0].phone == '') {
				this.passengersArray.at(0).patchValue({
					accountId: userDetails.userAccountId,
					id: userDetails.userId,
					name: userDetails.userName,
					phone: userDetails.userMobile,
					emailAddress: userDetails.userEmail,
				});
			}
			else {
				this.passengersArray.at(0).patchValue({
					accountId: userDetails.userAccountId,
					id: userDetails.userId,
				});
			}
		}
		else if (this.isOpenCreateEditdraftjob == 1) {
			if (this.editPassengersForm.value.passengersDetails[0].name == '' && this.editPassengersForm.value.passengersDetails[0].phone == '') {
				this.editPassengersArray.at(0).patchValue({
					accountId: userDetails.userAccountId,
					id: userDetails.userId,
					name: userDetails.userName,
					phone: userDetails.userMobile,
					emailAddress: userDetails.userEmail,
				});
			}
			else {
				this.editPassengersArray.at(0).patchValue({
					accountId: userDetails.userAccountId,
					id: userDetails.userId,
				});
			}
		}
	}
	get passengersArray(): FormArray {
		return this.passengersForm.get("passengersDetails") as FormArray;
	}
	get editPassengersArray(): FormArray {
		return this.editPassengersForm.get("passengersDetails") as FormArray;
	}
	newPassenger(): FormGroup {
		return this.formBuilder.group({
			accountId: "",
			id: "",
			name: "",
			phone: "",
			emailAddress: "",
			in: "0",
			out: "1"
		});
	}
	addPassengers() {
		if (this.passengersArray.length == environment.passengerLimitation) {
			this.Popup.toasterMessage({
				'status': 'warning',
				'title': "Stop limit is over",
				'message': "You Reach your Maximum Limit...",
			});
			return;
		}

		if (this.isOpenCreateEditdraftjob == 0) {
			this.passengersArray.push(this.newPassenger());
			let controlArray = <FormArray>this.passengersForm.controls["passengersDetails"];
			if (controlArray.controls[this.passengersArray.length - 1].get('out')?.value == 1) {
				controlArray.controls[this.passengersArray.length - 1].patchValue({ out: this.locationList.length - 1 })
			}
		}
		else if (this.isOpenCreateEditdraftjob == 1) {
			this.editPassengersArray.push(this.newPassenger());
			let editControlArray = <FormArray>this.editPassengersForm.controls["passengersDetails"];

			if (editControlArray.controls[this.editPassengersArray.length - 1].get('out')?.value == 1) {
				editControlArray.controls[this.editPassengersArray.length - 1].patchValue({ out: this.editLocationList.length - 1 })
			}

		}
	}
	removePassengersArrayForm(i: number) {
		if (this.isOpenCreateEditdraftjob == 0) {
			this.passengersArray.removeAt(i);
		}
		else if (this.isOpenCreateEditdraftjob == 1) {
			this.editPassengersArray.removeAt(i);
		}
	}
	newRideSetDriverId: string = (this.Global.appMode == 1) ? 'automatic' : 'manual';
	editRideSetDriverId: string = (this.Global.appMode == 1) ? 'automatic' : 'manual';
	driverAssignTo(event: any) {
		if (this.isOpenCreateEditdraftjob == 0) {
			this.newRideSetDriverId = event.target.value;
		} else if (this.isOpenCreateEditdraftjob == 0) {
			this.editRideSetDriverId = event.target.value;
		}
	}
	tariffStatus: boolean = this.Global.tarrif == 'custom' ? false : true;
	editTariffStatus: boolean = false;
	tariffChange(event: any) {
		if (this.isOpenCreateEditdraftjob == 0) {
			this.tariffStatus = (event.target.value === 'Custom') ? false : true;
		} else if (this.isOpenCreateEditdraftjob == 1) {
			this.editTariffStatus = (event.target.value === 'Custom') ? false : true;
		}
	}
	surgeValueforCustom(event: any) {
		if (this.isOpenCreateEditdraftjob == 0) {
			if (event.target.value <= 0) {
				this.setCreatBookingAlertPopup('Please enter value of surge mulptiplier greater than zero.', 'error');
				return;
			}
			this.surgeMultiplier = +event.target.value;
		} else if (this.isOpenCreateEditdraftjob == 1) {
			if (event.target.value <= 0) {
				this.setEditBookingAlertPopup('Please enter value of surge mulptiplier greater than zero.', 'error');
				return;
			}
			this.editJobSurgeMultiplier = +event.target.value;
		}

		this.clcBookingPrice();
	}
	tariffValueforCustom(event: any) {
		if (this.isOpenCreateEditdraftjob == 0) {
			this.withoutSurgeCarRent = +event.target.value;
		} else if (this.isOpenCreateEditdraftjob == 1) {
			this.withoutSurgeEditJobCarRent = +event.target.value;
			console.log('****** this.withoutSurgeEditJobCarRent ******', this.withoutSurgeEditJobCarRent);
		}

		this.clcBookingPrice();
	}
	clcBookingPrice() {
		if (this.isOpenCreateEditdraftjob == 0) {
			this.carRent = (this.withoutSurgeCarRent * this.surgeMultiplier);
			console.log("=====clcBookingPrice this.carRent :: ", this.carRent);

			this.newEstimatedExtraCharges = 0.00;
			this.discountAmount = 0;

			if (Object.entries(this.selectedCoupon).length !== 0) {
				this.discountAmount = this.clcDiscount(this.selectedCoupon, this.carRent);
			}

			let beforeDiscount = this.carRent - this.discountAmount;

			let totalTax = this.Global.taxCalculate(beforeDiscount);

			console.log("====== beforeDiscount :: ", beforeDiscount);
			console.log('****** totalTax.totalTaxAmount ******', totalTax.totalTaxAmount);
			this.newRideTax = +totalTax.totalTaxAmount.toFixed(environment.priceRoundUpNumber);
			console.log('****** this.tollTax ******', this.tollTax);
			console.log('****** this.newEstimatedExtraCharges ******', this.newEstimatedExtraCharges);
			console.log('****** this.serviceCharges ******', this.serviceCharges);
			console.log('****** this.airportParking ******', this.airportParking);

			// Extra Charges
			this.dispatcherFeesCalculate((this.newRideOptionStatus == true) ? 'now' : 'later', beforeDiscount);
			// this.newRideTotal = +(beforeDiscount + totalTax.totalTaxAmount).toFixed(environment.roundUpNumber);

			console.log('****** (beforeDiscount + totalTax.totalTaxAmount + this.newEstimatedExtraCharges + this.tollTax + this.serviceCharges + this.airportParking) ******', (beforeDiscount + totalTax.totalTaxAmount + this.newEstimatedExtraCharges + this.tollTax + this.serviceCharges + this.airportParking));

			this.newRideTotal = (beforeDiscount + totalTax.totalTaxAmount + this.newEstimatedExtraCharges + this.tollTax + this.serviceCharges + this.airportParking);

			console.log('****** without 3 fixed this.newRideTotal ******', this.newRideTotal);
			this.newRideTotal = parseFloat(this.newRideTotal).toFixed(environment.priceRoundUpNumber)


			console.log("=-==== clcBookingPricethis.newRideTotal :: ", this.newRideTotal);
		} else if (this.isOpenCreateEditdraftjob == 1) {
			this.editJobCarRent = this.withoutSurgeEditJobCarRent * this.editJobSurgeMultiplier;
			this.editJobCarRent = parseFloat(this.editJobCarRent.toFixed(environment.priceRoundUpNumber));
			this.editJobDiscountAmount = 0;

			if (Object.entries(this.editJobSelectedCoupon).length !== 0) {
				this.editJobDiscountAmount = this.clcDiscount(this.editJobSelectedCoupon, this.editJobCarRent);
			}

			let beforeDiscount = this.editJobCarRent - this.editJobDiscountAmount;

			// Extra Charges
			console.log("beforeDiscount:::::::::::::", beforeDiscount);

			this.dispatcherFeesCalculate((this.editRideOptionStatus == true) ? 'now' : 'later', beforeDiscount);
			console.log("this.dispatcherFeesCalculate((this.editRideOptionStatus == true)",);
			console.log("after beforeDiscount:::::::::::::", beforeDiscount);

			let totalTax = this.Global.taxCalculate(beforeDiscount, this.selectedBooking.taxJson);
			this.editRideTax = parseFloat(totalTax.totalTaxAmount.toFixed(environment.priceRoundUpNumber));
			this.editRideTotal = parseFloat((beforeDiscount + totalTax.totalTaxAmount + this.editRideArrears + this.editEstimatedExtraCharges + this.editTollTax + this.editServiceCharges + this.editAirportParking).toFixed(environment.priceRoundUpNumber));

			// vishesh new added 

			// this.newRideTotal = (beforeDiscount + totalTax.totalTaxAmount + this.newEstimatedExtraCharges + this.tollTax + this.serviceCharges + this.airportParking);

			console.log('****** this.editRideTotal ******', this.editRideTotal);
		}
	}

	dispatcherFeesCalculate(bookingType: any, amount: any) {
		;

		if (this.isOpenCreateEditdraftjob == 0) {


			if (this.newRideOptionStatus == true && (this.selectedCarTypeData.data?.extraCharges?.bookingType == 0 || this.selectedCarTypeData.data?.extraCharges.bookingType == 2)) {
				console.log("===== Now");
				if (this.selectedCarTypeData.data.extraCharges.chargeType == 0) {
					this.newEstimatedExtraCharges = (amount * this.selectedCarTypeData.data.extraCharges.chargeData) / 100;
				} else if (this.selectedCarTypeData.data.extraCharges.chargeType == 1) {
					this.newEstimatedExtraCharges = this.selectedCarTypeData.data.extraCharges.chargeData;
				}
				console.log("===== Create now this.newEstimatedExtraCharges :: ", this.newEstimatedExtraCharges);
			} else if (this.newRideOptionStatus == false && (this.selectedCarTypeData.data?.extraCharges.bookingType == 1 || this.selectedCarTypeData.data?.extraCharges.bookingType == 2)) {
				console.log("===== Later");
				if (this.selectedCarTypeData.data?.extraCharges.chargeType == 0) {
					this.newEstimatedExtraCharges = (amount * this.selectedCarTypeData.data.extraCharges.chargeData) / 100;
				} else if (this.selectedCarTypeData.data.extraCharges.chargeType == 1) {
					this.newEstimatedExtraCharges = this.selectedCarTypeData.data.extraCharges.chargeData;
				}
				console.log("===== Create later this.newEstimatedExtraCharges :: ", this.newEstimatedExtraCharges);
			}
		} else if (this.isOpenCreateEditdraftjob == 1) {
			this.editEstimatedExtraCharges = 0;

			if (this.editRideOptionStatus == true && (this.editJobSelectedCarTypeData.data.extraCharges.bookingType == 0 || this.editJobSelectedCarTypeData.data.extraCharges.bookingType == 2)) {
				console.log("===== Now");
				if (this.editJobSelectedCarTypeData.data.extraCharges.chargeType == 0) {

					this.editEstimatedExtraCharges = (amount * this.editJobSelectedCarTypeData.data.extraCharges.chargeData) / 100;
				} else if (this.editJobSelectedCarTypeData.data.extraCharges.chargeType == 1) {
					this.editEstimatedExtraCharges = this.editJobSelectedCarTypeData.data.extraCharges.chargeData;
				}
				console.log("===== Now Create now this.editEstimatedExtraCharges :: ", this.editEstimatedExtraCharges);
			} else if (this.editRideOptionStatus == false && (this.editJobSelectedCarTypeData.data.extraCharges.bookingType == 1 || this.editJobSelectedCarTypeData.data.extraCharges.bookingType == 2)) {
				console.log("===== Later");
				if (this.editJobSelectedCarTypeData.data.extraCharges.chargeType == 0) {
					console.log('--------------------- this.editJobSelectedCarTypeData.data.extraCharges.chargeType ---------------------', this.editJobSelectedCarTypeData.data.extraCharges.chargeType)
					console.log('--------------------- amount ---------------------', amount)
					console.log('--------------------- this.editJobSelectedCarTypeData.data.extraCharges.chargeData ---------------------', this.editJobSelectedCarTypeData.data.extraCharges.chargeData)
					this.editEstimatedExtraCharges = (amount * this.editJobSelectedCarTypeData.data.extraCharges.chargeData) / 100;
					console.log("===== Later per Create later this.editEstimatedExtraCharges :: ", this.editEstimatedExtraCharges);


				} else if (this.editJobSelectedCarTypeData.data.extraCharges.chargeType == 1) {
					this.editEstimatedExtraCharges = this.editJobSelectedCarTypeData.data.extraCharges.chargeData;
					console.log("===== Later flat Create later this.editEstimatedExtraCharges :: ", this.editEstimatedExtraCharges);
				}
				console.log("===== Later Create later this.editEstimatedExtraCharges :: ", this.editEstimatedExtraCharges);
			}
		}

	}

	clcDiscount(couponData: any, amount: number) {
		let discountAmount = 0.00;
		if (Object.entries(couponData).length !== 0) {
			if (couponData.couponDiscountType == 1) {
				let discount = (amount * couponData.couponDiscount) / 100;
				discountAmount = (discount > couponData.couponMaxDiscount) ? couponData.couponMaxDiscount : discount;
			} else {
				discountAmount = couponData.couponDiscount;
				discountAmount = (amount <= couponData.couponDiscount) ? amount : couponData.couponDiscount;
			}
		}

		return discountAmount;
	}
	// repeated Date Functions START
	newRideBookingFromDate: any = null;
	repeatedDateArray: any = null;
	newRide_repeatStatus: boolean = false;
	/* edit Booking Object */
	editRideBookingFromDate: any = null;
	editRepeatedDateArray: any = null;
	editRide_repeatStatus: boolean = false;
	week: any = {
		0: 'sunday',
		1: 'monday',
		2: 'tuesday',
		3: 'wednesday',
		4: 'thursday',
		5: 'friday',
		6: 'saturday',
	}
	newRide_RepeatCheckedOn(checkedStatus: any, callType: string) {
		checkedStatus = (checkedStatus.target != undefined && checkedStatus.target.checked != undefined) ? checkedStatus.target.checked : checkedStatus;

		if (callType == "createBooking") {
			this.newRide_repeatStatus = (checkedStatus == true) ? true : false;
		}
		else if (callType == "editBooking") {
			this.editRide_repeatStatus = (checkedStatus == true) ? true : false;
		}
	}
	repeatOrderdateChanges(callType: string, event: any) {
		console.log('event Repeated:date', event);

		this.repeatedDateArray = [];
		if (callType == "createBooking") {
			// object Create Booking Booking
			if (event.endDate != null) {
				this.newRideBookingFromDate = { start: moment(event.endDate), end: moment(event.endDate) }
			}
			console.log('this.newRideBookingFromDate', this.newRideBookingFromDate);

		}
		else if (callType == "editBooking") {
			// object Edit array Booking
		}

		// this.editRepeatedDateArray 
		this.repeatedDateArray = [{ sunday: [] }, { monday: [] }, { tuesday: [] }, { wednesday: [] }, { thursday: [] }, { friday: [] }, { saturday: [] },];
		let data = [];

		let startDateC = null;
		if (this.newRideBookingToDate != undefined && this.newRideBookingToDate != null && this.newRideBookingToDate.start != undefined && this.newRideBookingToDate.start != null) {
			startDateC = this.newRideBookingToDate.start
		}

		let endDateC = null;
		if (this.newRideBookingFromDate != undefined && this.newRideBookingFromDate != null && this.newRideBookingFromDate.start != undefined && this.newRideBookingFromDate.start != null) {
			endDateC = this.newRideBookingFromDate.start
		}

		if (startDateC != null && endDateC != null) {

			var startDate = moment(startDateC, "YYYY-MM-DD");
			var endDate = moment(endDateC, "YYYY-MM-DD");
			console.log('startDate', startDate);
			console.log('endDate', endDate);


			while (startDate <= endDate) {
				data.push({
					date: startDate.format("YYYY-MM-DD"),
					day: moment(startDate).format("dddd"),
					dayCount: moment(startDate).format("d"),
					timeIs: true,
					times: [{ id: 1, hours: this.newRideSelectedHours, minutes: this.newRideSelectedMinute }],
				});
				startDate = moment(startDate, "YYYY-MM-DD").add(1, "days");
			}
			console.log('data', data);

			for (let i = 0; i < data.length; i++) {
				if (data[i].day == "Sunday") {
					this.repeatedDateArray[0].sunday?.push(data[i]);
				} else if (data[i].day == "Monday") {
					this.repeatedDateArray[1].monday?.push(data[i]);
				} else if (data[i].day == "Tuesday") {
					this.repeatedDateArray[2].tuesday?.push(data[i]);
				} else if (data[i].day == "Wednesday") {
					this.repeatedDateArray[3].wednesday?.push(data[i]);
				} else if (data[i].day == "Thursday") {
					this.repeatedDateArray[4].thursday?.push(data[i]);
				} else if (data[i].day == "Friday") {
					this.repeatedDateArray[5].friday?.push(data[i]);
				} else if (data[i].day == "Saturday") {
					this.repeatedDateArray[6].saturday?.push(data[i]);
				}
			}

			this.repeatedDateArray[0][this.week[0]]['isDayChecked'] = true;
			this.repeatedDateArray[1][this.week[1]]['isDayChecked'] = true;
			this.repeatedDateArray[2][this.week[2]]['isDayChecked'] = true;
			this.repeatedDateArray[3][this.week[3]]['isDayChecked'] = true;
			this.repeatedDateArray[4][this.week[4]]['isDayChecked'] = true;
			this.repeatedDateArray[5][this.week[5]]['isDayChecked'] = true;
			this.repeatedDateArray[6][this.week[6]]['isDayChecked'] = true;

			if (this.repeatedDateArray.length != 0) {
				for (let i = 0; i < this.repeatedDateArray.length; i++) {
					let repeatDate = this.repeatedDateArray[i];
					let key = Object.keys(repeatDate)[0]
					this.repeatDayTotalBookingCount[key] = 0;
					if (repeatDate[key].length != 0) {
						this.repeatDayTotalBookingCount[key] = this.repeatedDateArray[i][key].length
						this.daySelectedRepeatOrder((repeatDate)[key])
						// break;
					}
				}
			}
			this.repeatOrderTotalBookingsSum();
		}
	}
	dayChanges(event: any, daysIndex: number) {
		let dayName = Object.keys(this.repeatedDateArray[daysIndex])[0];

		this.repeatedDateArray[daysIndex][this.week[daysIndex]]['isDayChecked'] = event.target.checked;
		for (let j = 0; j < this.repeatedDateArray[daysIndex][this.week[daysIndex]].length; j++) {
			this.repeatedDateArray[daysIndex][this.week[daysIndex]][j]['timeIs'] = event.target.checked;
			this.repeatedDateArray[daysIndex][this.week[daysIndex]][j]['times'] = [{ id: 1, hours: this.newRideSelectedHours, minutes: this.newRideSelectedMinute }];
		}
		if (event.target.checked) {
			let totalDayBooking = this.repeatedDateArray[daysIndex][this.week[daysIndex]].length;
			this.repeatDayTotalBookingCount[dayName] = totalDayBooking;
		} else {
			this.repeatDayTotalBookingCount[dayName] = 0;

		}

		this.repeatOrderTotalBookingsSum();

	}
	repeatDayTotalBookingCount: any = {};
	repeatDayTotalBookingCountSum: number = 0;
	repeatOrderTotalBookingsSum() {
		this.repeatDayTotalBookingCountSum = 0;

		if (Object.keys(this.repeatDayTotalBookingCount).length > 0) {
			let arrayBookingCount = Object.values(this.repeatDayTotalBookingCount);
			let sum = Number(arrayBookingCount.reduce((sum: any, a) => sum + a, 0));
			this.repeatDayTotalBookingCountSum = sum;
		}
	}
	dateTimeAdd(index: number, event: any, daysIndex: number) {
		let dayName = Object.keys(this.repeatedDateArray[daysIndex])[0];
		if (event.target.checked) {
			this.repeatDayTotalBookingCount[dayName] = this.repeatDayTotalBookingCount[dayName] + 1;
		} else {
			this.repeatDayTotalBookingCount[dayName] = this.repeatDayTotalBookingCount[dayName] - this.repeatedDateArray[daysIndex][this.week[daysIndex]][index]['times'].length;
		}
		this.repeatedDateArray[daysIndex][this.week[daysIndex]][index]['timeIs'] = event.target.checked;
		this.repeatedDateArray[daysIndex][this.week[daysIndex]][index]['times'] = [{ id: 1, hours: this.newRideSelectedHours, minutes: this.newRideSelectedMinute }];

		this.repeatOrderTotalBookingsSum();

	}
	addItem(jindex: number, daysIndex: number) {
		let dayName = Object.keys(this.repeatedDateArray[daysIndex])[0];

		if (this.repeatedDateArray[daysIndex][this.week[daysIndex]][jindex]['times'].length !== 3) {
			this.repeatedDateArray[daysIndex][this.week[daysIndex]][jindex]['times'].push({ id: this.repeatedDateArray[daysIndex][this.week[daysIndex]][jindex]['times'].length + 1, hours: this.newRideSelectedHours, minutes: this.newRideSelectedMinute });
			this.repeatDayTotalBookingCount[dayName] = this.repeatDayTotalBookingCount[dayName] + 1;
			this.repeatOrderTotalBookingsSum();

		}

	}
	removeItem(j: number, i: number, daysIndex: number) {
		let dayName = Object.keys(this.repeatedDateArray[daysIndex])[0];

		this.repeatedDateArray[daysIndex][this.week[daysIndex]][j]['times'].splice(i, 1);
		this.repeatedDateArray[daysIndex][this.week[daysIndex]]['dayTotalBooking'] = this.repeatedDateArray[daysIndex][this.week[daysIndex]]['dayTotalBooking'] - 1;
		this.repeatDayTotalBookingCount[dayName] = this.repeatDayTotalBookingCount[dayName] - 1;
		this.repeatOrderTotalBookingsSum();


	}
	hoursChangeValue(event: any, jindex: number, index: number, daysIndex: number) {
		this.repeatedDateArray[daysIndex][this.week[daysIndex]][jindex]['times'][index]['hours'] = event.target.value;
	}
	minutesChangeValue(event: any, jindex: number, index: number, daysIndex: number) {
		this.repeatedDateArray[daysIndex][this.week[daysIndex]][jindex]['times'][index]['minutes'] = event.target.value;
	}
	cancelAllRepeatOrderDate() {
		this.repeatedDateArray = [];
		this.repeateDateArray = [];
		this.selectedRepeatDayObj = [];
		this.repeatDayTotalBookingCountSum = 0

	}
	repeateDateArray: any[] = [];
	repeatedTimeSubmit() {
		this.repeateDateArray = [];



		if (this.repeatedDateArray != null && this.repeatedDateArray.length > 0) {
			for (let i = 0; i < this.repeatedDateArray.length; i++) {
				for (let k = 0; k < this.repeatedDateArray[i][this.week[i]].length; k++) {
					const weekDays = this.repeatedDateArray[i][this.week[i]];
					if (weekDays[k].timeIs == true) {
						for (let j = 0; j < weekDays[k].times.length; j++) {
							let selectbooktime = moment(weekDays[k].date).format("YYYY-MM-DD") + " " + weekDays[k].times[j].hours + ":" + weekDays[k].times[j].minutes;
							this.repeateDateArray.push({
								date: moment(selectbooktime).format("YYYY-MM-DD HH:mm:ss")
							});
						}
					}
				}
			}

			return true;
		}
		else {
			this.Popup.toasterMessage({
				'status': 'error',
				'title': "Oops!",
				'message': "Repeate booking something wrong.",
			});
			return false;
		}
	}
	selectedRepeatDayObj: any;
	isDaySelected: boolean = false;
	daySelectedRepeatOrder(dayItems: any) {
		this.isDaySelected = true;
		this.selectedRepeatDayObj = dayItems;
	}

	totalPerson: number = 1;
	totalBags: number = 0;
	handiCappedTotal: number = 0;
	carCount: number = 1;
	priority: string = "Automatic";
	roomNo: string = "";
	flightNo: string = "";
	userComment: string = "";
	driverComment: string = "";
	/* edit Booking */
	editJobTotalPerson: number = 1;
	editJobTotalBags: number = 0;
	editJobHandiCappedTotal: number = 0;
	editJobCarCount: number = 1;
	editJobPriority: string = "Automatic";
	editJobRoomNo: string = "";
	editJobFlightNo: string = "";
	editJobUserComment: string = "";
	editJobDriverComment: string = "";

	createErrorPopup: any = {
		isShow: false,
		type: 'error',
		msg: ''
	};
	setCreatBookingAlertPopup(msg: string, type: string = 'error', isConfirm: boolean = false, callType: string = '') {
		this.createErrorPopup = {
			isShow: true,
			type: type,
			msg: msg
		}

		if (isConfirm == true) {
			this.createErrorPopup.isConfirm = true;
			this.createErrorPopup.calltype = callType;
		}
		else {
			setTimeout(() => {
				this.createErrorPopup = {
					isShow: false,
					type: 'error',
					msg: '',
					isConfirm: false
				}
			}, 3000);
		}

	}
	createConfirm(type: string = 'cancel', callType: string = '') {
		if (type == 'cancel') {
			this.createErrorPopup = {
				isShow: false,
				type: 'error',
				msg: '',
				isConfirm: false
			}
		}
		else {
			this.createErrorPopup = {
				isShow: false,
				type: 'error',
				msg: '',
				isConfirm: false
			}
		}
	}
	draftErrorPopup: any = {
		isShow: false,
		type: 'error',
		msg: ''
	};
	setDraftBookingAlertPopup(msg: string, type: string = 'error', isConfirm: boolean = false, callType: string = '', extra: any = {}) {
		this.draftErrorPopup = {
			isShow: true,
			type: type,
			msg: msg
		}

		if (isConfirm == true) {
			this.draftErrorPopup.isConfirm = true;
			this.draftErrorPopup.callType = callType;
			this.draftErrorPopup.extra = extra;
		}
		else {
			setTimeout(() => {
				this.draftErrorPopup = {
					isShow: false,
					type: 'error',
					msg: '',
					isConfirm: false
				}
			}, 3000);
		}
	}
	draftConfirm(type: string = 'cancel') {
		if (type == 'cancel') {
			this.draftErrorPopup = {
				isShow: false,
				type: 'error',
				msg: '',
				isConfirm: false
			}
		}
		else {
			if (this.draftErrorPopup.callType == 'deleteDraftBooking') {
				this.deleteDraftBooking(this.draftErrorPopup.extra);
			}

			this.draftErrorPopup = {
				isShow: false,
				type: 'error',
				msg: '',
				isConfirm: false
			}
		}
	}
	editErrorPopup: any = {
		isShow: false,
		type: 'error',
		msg: ''
	};
	setEditBookingAlertPopup(msg: string, type: string = 'error', isConfirm: boolean = false, callType: string = '') {
		this.editErrorPopup = {
			isShow: true,
			type: type,
			msg: msg
		}

		if (isConfirm == true) {
			this.editErrorPopup.isConfirm = true;
			this.editErrorPopup.calltype = callType;
		}
		else {
			setTimeout(() => {
				this.editErrorPopup = {
					isShow: false,
					type: 'error',
					msg: '',
					isConfirm: false
				}
			}, 3000);
		}

	}
	editConfirm(type: string = 'cancel', callType: string = '') {
		if (type == 'cancel') {
			this.editErrorPopup = {
				isShow: false,
				type: 'error',
				msg: '',
				isConfirm: false
			}
		}
		else {
			if (callType == 'cancelBooking') {
				this.cancelBooking();
			}
			else if (callType == 'cancelAllBooking') {
				this.cancelBooking({ 'isRepet': 1 });
			}
			else if (callType == 'deliveredJobByCash' || callType == 'deliveredJobByCard') {
				this.deliveredJob(callType);
			}

			this.editErrorPopup = {
				isShow: false,
				type: 'error',
				msg: '',
				isConfirm: false
			}
		}
	}
	async deliveredJob(type: string = '') {
		let driverLatLng = await this.getDriverLatLng(this.selectedBooking.driverId);

		console.log('****** this.selectedBooking ******', this.selectedBooking);
		let request: any = {
			'bookingId': this.selectedBooking.bookingId,
			'actualAmount': this.editJobCarRent,
			'surgeMultiplier': this.editJobSurgeMultiplier,
			'location': [driverLatLng.lat, driverLatLng.lng],

		}
		this.finalDeductAmount = +this.finalDeductAmount;
		console.log('****** this.finalDeductAmount ******', this.finalDeductAmount);
		this.finalDeductAmount = +this.finalDeductAmount.toFixed(environment.priceRoundUpNumber);

		const finalAmounts: number = this.editRideTotal + this.finalDeductAmount;

		console.log('****** this.editRideTotal ******', this.editRideTotal);
		console.log('****** this.finalDeductAmount ******', this.finalDeductAmount);
		console.log('****** finalAmounts ******', finalAmounts);
		console.log('****** this.selectedBooking.isBox != 1 && this.selectedBooking.boxItems.boxItemType != 1 ? +this.editRideTotal.toFixed(environment.priceRoundUpNumber) : +finalAmounts.toFixed(environment.priceRoundUpNumber) ******', this.selectedBooking.isBox != 1 && this.selectedBooking.boxItems.boxItemType != 1 ? +this.editRideTotal.toFixed(environment.priceRoundUpNumber) : +finalAmounts.toFixed(environment.priceRoundUpNumber));

		console.log('****** this.selectedBooking.isBox ******', this.selectedBooking.isBox);
		console.log('****** this.selectedBooking.boxItems.boxItemType ******', this.selectedBooking.boxItems.boxItemType);

		if (type == 'deliveredJobByCash') {
			request.cardPayment = 0;
			request.cashPayment = this.selectedBooking.isBox != 1 && this.selectedBooking.boxItems.boxItemType != 1 ? +this.editRideTotal.toFixed(environment.priceRoundUpNumber) : +finalAmounts.toFixed(environment.priceRoundUpNumber);
			request.deliveredBy = 'cash';
		}
		else {
			request.cashPayment = 0;
			request.cardPayment = this.selectedBooking.isBox != 1 && this.selectedBooking.boxItems.boxItemType != 1 ? +this.editRideTotal.toFixed(environment.priceRoundUpNumber) : +finalAmounts.toFixed(environment.priceRoundUpNumber);
			request.deliveredBy = 'card';
			if (this.selectedBooking.isRestaurant == 1) {

				if ((document.getElementById('deliveredWithWallet') as HTMLInputElement).checked == true) {
					request.isDeductFromWallet = true;
				} else if ((document.getElementById('deliveredWithoutWallet') as HTMLInputElement).checked) {
					request.isDeductFromWallet = false;
				}
			}
		}

		console.log('****** force delivered request ******', request);
		if (this.selectedBooking.isBox == 1 && this.selectedBooking.boxItems.boxItemType == 1) {
			request.actualBoxItemPrice = this.finalDeductAmount;
			console.log("request.actualBoxItemPrice", request.actualBoxItemPrice);
		}

		console.log('****** final request ******', request);


		this.CallMethod.deliveredJob(request).pipe(takeUntil(this.unsubscribe$)).subscribe((result: any) => {
			if (result.status) {
				this.setEditBookingAlertPopup('Booking successfully Delivered.', 'success');
				this.selectedBooking = {};
				this.forcefullyDeliveredClosePopup();
				this.forceFullyCompletedDialog = false;
				this.finalDeductAmount = 0;
			}
			else {
				if (result.statusCode == 1) {
					this.setEditBookingAlertPopup('Booking not found.', 'error');
				}
				else if (result.statusCode == 2) {
					this.setEditBookingAlertPopup('You can not deliver pending booking.', 'error');
				}
				else if (result.statusCode == 3) {
					this.setEditBookingAlertPopup('You can not deliver this booking forcefully.', 'error');
				}
				else if (result.statusCode == 4) {
					this.setEditBookingAlertPopup('This booking does not have any driver.', 'error');
				}
				else if (result.statusCode == 5) {
					this.setEditBookingAlertPopup('Driver not found.', 'error');
				}
			}
		});
	}


	selectedBookingId: any = "";
	selectedDBookId: any = "";

	forcefullyDeliveredLoader: boolean = true;

	forcefullyDeliveredPopup: any = { display: 'none', heading: '', message: '', confirmButton: false, closeButton: true, closeMsgButton: true, img: '' };

	forcefullyDeliveredClosePopup() {
		this.forcefullyDeliveredPopup = { display: 'none', heading: '', message: '', confirmButton: false, closeButton: true, closeMsgButton: true, img: '' };
		this.selectedBookingId = "";
		this.selectedDBookId = "";
	}


	createAndDraftBooking(callType: string = 'createBooking') {
		if (Array.isArray(this.locationJson) && this.locationJson.length > 0
			&& this.carRent != undefined
			&& this.selectedCarTypeData.data.baseFare != undefined
			&& this.selectedCarTypeData.data.carTypeId != undefined
			&& this.selectedCarTypeData.data.carTypeName != undefined
			&& this.distance != undefined
			&& this.duration != undefined
			&& this.selectedCarTypeData.data.distancePrice != undefined
			&& this.selectedCarTypeData.data.durationPrice != undefined
		) {
			let companyTime = moment(this.Global.getCompanyCurrentTime(), this.Global.dateFormat).format('YYYY-MM-DD HH:mm');
			console.log("===>>this.pickupTime::::::::::", this.pickupTime);

			let assignTime = moment(this.pickupTime).subtract(this.newRideSelectedAssignTime, 'minutes').format('YYYY-MM-DD HH:mm');

			if (this.newRideOptionStatus == false && companyTime > assignTime) {
				this.setCreatBookingAlertPopup(`You have to select assign time more then current time.`, 'error');
				return;
			}

			let userJson: any = [];

			for (let i = 0; i < this.passengersForm.value.passengersDetails.length; i++) {
				if (this.passengersForm.value.passengersDetails[0].id.trim() != '' &&
					(this.passengersForm.value.passengersDetails[0].accountId.trim() == '' || this.passengersForm.value.passengersDetails[0].accountId == null)
					&& this.passengersForm.value.passengersDetails[0].emailAddress.trim() != ''
					&& this.passengersForm.value.passengersDetails[0].phone.trim() != ''
				) {
					this.passengersForm.value.passengersDetails[0].id = "";
				}

				userJson.push({
					accountId: (this.passengersForm.value.passengersDetails[i].accountId != '' && this.passengersForm.value.passengersDetails[i].accountId != null) ? this.passengersForm.value.passengersDetails[i].accountId : '',
					passengerId: this.passengersForm.value.passengersDetails[0].id,
					passengerName: this.passengersForm.value.passengersDetails[i].name,
					passengerEmail: this.passengersForm.value.passengersDetails[i].emailAddress.trim(),
					passengerPhone: this.passengersForm.value.passengersDetails[i].phone.trim(), //!vishesh
					passengerIn: this.passengersForm.value.passengersDetails[i].in,
					passengerOut: this.passengersForm.value.passengersDetails[i].out,
				});
			}

			let location = [];
			for (let i = 0; i < this.locationJson.length; i++) {
				location.push({
					"lat": this.locationJson[i].lat,
					"long": this.locationJson[i].long,
					"address": this.locationJson[i].address,
					"isStopArrived": this.locationJson[i].isStopArrived,
					"distance": this.locationJson[i].distance,
					"duration": this.locationJson[i].duration,
					"stopId": this.locationJson[i].stopId,
				});
			}

			console.log('****** this.passengersForm.value.passengersDetails[0] ******', this.passengersForm.value.passengersDetails[0]);

			console.log("===== Create JSON :: ", JSON.stringify(this.selectedCarTypeData));
			console.log("===== selectedCarTypeData.data.extraCharges :: ", this.selectedCarTypeData.data.extraCharges);

			console.log('******create this.selectedPaymentType ******', this.selectedPaymentType);
			// if ((this.passengersForm.value.passengersDetails[0].accountId != null && this.passengersForm.value.passengersDetails[0].accountId != '')
			// 	&& this.passengersForm.value.passengersDetails[0].phone.trim() == '') {
			// 	userJson[0].passengerId = ""
			// 	this.setCreatBookingAlertPopup(`Please enter user details to create an account booking`, 'error');
			// 	return
			// }

			console.log('****** after  userJson ******', userJson);
			const request: any = {
				driverId: this.newRideSetDriverId,
				userId: "",
				bookingType: 0,
				isDevice: environment.device,
				isDispatcher: 1,
				baseFare: this.selectedCarTypeData.data.baseFare,
				paymentType: 0, //! old payment type
				// paymentType: this.selectedPaymentType != 3 ? this.selectedPaymentType : 0, //! new payment type
				carRent: parseFloat("" + this.carRent).toFixed(environment.priceRoundUpNumber), //car rent //! old 
				// carRent: this.newRideTotal, //car rent //! new Vishesh 15-01-24
				currency: this.currency,
				carTypeId: this.selectedCarTypeData.data.carTypeId,
				carTypeName: this.selectedCarTypeData.data.carTypeName,
				carDetails: {
					carSeatCapacity: this.selectedCarTypeData.data.carSeatCapacity,
					carTypeName: this.selectedCarTypeData.data.carTypeName,
					carInitialKm: this.selectedCarTypeData.data.carInitialKm,
					carInitialRate: this.selectedCarTypeData.data.carInitialRate,
					perKmRate: this.selectedCarTypeData.data.perKmRate,
					perMinuteRate: this.selectedCarTypeData.data.perMinuteRate,
					carMinimumFare: this.selectedCarTypeData.data.carMinimumFare,
					carCancellationCharge: this.selectedCarTypeData.data.carCancellationCharge,
					carMultiplier: this.selectedCarTypeData.data.carMultiplier,
					carWaitPrice: this.selectedCarTypeData.data.carWaitPrice,
					mileage_waiting_time_price: this.selectedCarTypeData.data.mileage_waiting_time_price,
					priceVariant: this.selectedCarTypeData.data.priceVariant,
					destination_later_price: this.selectedCarTypeData.data.destination_later_price,
					partial_cancel_price: this.selectedCarTypeData.data.partial_cancel_price,
					isCorporateCar: this.selectedCarTypeData.data.isCorporateCar, //! vishesh new added for corporate wise pricing 17-10-23
				},

				distanceVariant: this.distanceVariant,
				distance: parseFloat("" + this.distance),
				duration: parseFloat("" + this.duration),
				surgeMultiplier: this.surgeMultiplier,
				zoneSurgePopup: this.zoneSurgePopup,
				distancePrice: this.selectedCarTypeData.data.distancePrice,
				durationPrice: this.selectedCarTypeData.data.durationPrice,
				totalPerson: parseInt("" + this.totalPerson),
				totalBags: parseInt("" + this.totalBags),
				handicappedTotal: parseInt("" + this.handiCappedTotal),
				carCount: parseInt("" + this.carCount),
				priority: (this.priority != 'Automatic') ? parseInt(this.priority) : 0,
				zoneId: this.zoneId,
				userJson: userJson,
				bookingFlag: 0,
				location: this.locationJson,
				roomNo: this.roomNo,
				flightNo: this.flightNo,
				userComment: this.userComment,
				driverComment: this.driverComment,
				stopPrice: this.selectedCarTypeData.data.stopPrice,
				extraCharges: this.selectedCarTypeData.data.extraCharges,
				toll: this.tollTax,
				serviceCharges: this.serviceCharges,
				airportParking: this.airportParking,
				priceFromAirportZone: this.isPriceFromAirportZone,
				isStreetPickup: false
			};

			console.log('****** create Booking payment request ******', request);

			console.log('***** this.passengersForm.value.passengersDetails[0] *****', this.passengersForm.value.passengersDetails[0]);

			if (this.newRide_repeatStatus == true) {
				let checkRepeateDate = this.repeatedTimeSubmit();
				if (checkRepeateDate != false && this.repeateDateArray.length > 0) {
					request['isRepet'] = true; 	//isRepet: false,// true and false
					let repeateDate = [];
					for (let i = 0; i < this.repeateDateArray.length; i++) {
						repeateDate.push(moment(this.repeateDateArray[i].date).format('YYYY-MM-DD HH:mm:ss'))
					}

					request['repeateDate'] = repeateDate;// repeateDate: YYYY-MM-DD HH:mm:ss			
				}
				else {
					return false;
				}
			}

			// if (this.passengersForm.value.passengersDetails[0].accountId && this.passengersForm.value.passengersDetails[0].accountId != '' && this.passengersForm.value.passengersDetails[0].phone.trim() != '') {
			// 	request['accountId'] = this.passengersForm.value.passengersDetails[0].accountId;
			// 	request['paymentType'] = 2;
			// }
			if (this.passengersForm.value.passengersDetails[0].accountId && this.passengersForm.value.passengersDetails[0].accountId != '') {
				request['accountId'] = this.passengersForm.value.passengersDetails[0].accountId;
				request['paymentType'] = 2;
			}

			if (this.passengersForm.value.passengersDetails[0].id.trim() != '' && (
				this.passengersForm.value.passengersDetails[0].emailAddress.trim() != ''
				||
				(this.passengersForm.value.passengersDetails[0].accountId != null && this.passengersForm.value.passengersDetails[0].accountId != '')
				&& this.passengersForm.value.passengersDetails[0].phone.trim() != '')
			) {
				request['userId'] = this.passengersForm.value.passengersDetails[0].id;
			}

			if (this.selectedCoupon != {} && this.selectedCoupon.couponCode != undefined) {
				request['couponCode'] = this.selectedCoupon.couponCode;
			}
			else {
				if (this.createBookingCouponCode.nativeElement.value.trim != '') {
					request['couponCode'] = this.createBookingCouponCode.nativeElement.value;
				}
			}

			if (this.newRideOptionStatus == false) {
				request['bookingType'] = 1;
				request['pickupTime'] = this.pickupTime;
				request['assignMinute'] = parseInt(this.newRideSelectedAssignTime);
			}

			if (this.tariffStatus == false) {
				request['tarrif'] = "Custom";
			} else {
				request['tarrif'] = "Automatic";
			}

			console.log("===== request :: ", request);


			if (callType == 'createBooking') {
				this.createBookingCall(request);
			}
			else if (callType == 'saveToDraft') {
				this.saveToDraftBookingCall(request);
			}

			return true;
		}

		return false;
	}
	createBookingRequest: any = {};
	createBookingbtn: boolean = false;
	createBookingCall(request: any) {
		this.createBookingbtn = true;
		console.log("===== Inside createBookingCall request :: ", request);

		this.CallMethod.createBookingCall(request).pipe(takeUntil(this.unsubscribe$)).subscribe((result: any) => {
			if (result.status) {
				this.createBookingRequest = request;
				this.createBookingRequest.selectedCarTypeData = this.selectedCarTypeData;
				this.setCreatBookingAlertPopup('Booking successfully created.', 'success');
				this.isAccountUser = false;
				this.clearBooking();
				this.selectedPaymentType = 0
				if (this.selectedDraftBookId != '') {
					this.deleteDraftBooking({ 'bookingId': this.selectedDraftBookId });
				}
			}
			else if (result.statusCode == 1) {
				this.setCreatBookingAlertPopup(`User current wallet balance is Rs.${result.receipt.currentBalance} Please top up by Rs.${result.receipt.addToWallet} to proceed with this booking.`, 'error');
			}
			else if (result.statusCode == 2) {
				this.setCreatBookingAlertPopup(`Your account ID is invalid.`, 'error');
			}
			else if (result.statusCode == 5) {
				this.setCreatBookingAlertPopup(`User wallet balance is low.`, 'error');
			}
			else if (result.statusCode == 7) {
				this.isAccountUser = true;
				// this.passengersForm.value.passengersDetails[0].id = result.userId;
				this.passengersForm.value.passengersDetails[0].accountId = result.userInfo.userAccountId;
				this.passengersForm.value.passengersDetails[0].id = result.userInfo.userId;
				this.passengersForm.value.passengersDetails[0].name = result.userInfo.userName;
				this.passengersForm.value.passengersDetails[0].emailAddress = result.userInfo.userEmail;
				this.passengersForm.value.passengersDetails[0].phone = result.userInfo.userMobile;
				console.log('****** result.statusCode == 7 result.userId ******', result.userId);
				// console.log('****** result.statusCode == 7 this.passengersForm.value.passengersDetails[0].id ******', this.passengersForm.value.passengersDetails[0].id);
				this.getFixedPrice()
			}
			else if (result.statusCode == 11 || result.statusCode == 12) {
				this.setCreatBookingAlertPopup(`We have encountered error verifying your coupon credentials.`, 'error');
			}
			this.createBookingbtn = false;
			this.nearestDriverList = [];
		});
	}
	clearBooking() {
		this.locationsForm.reset();
		this.locationArray.clear();
		this.locationArray.reset();
		this.locationList = [];
		this.setLocationArr();
		this.setLocationMap();
		this.locationJson = [];
		this.locationSwipe = false;
		this.carRent = 0;
		this.discountAmount = 0;
		this.currency = "";
		this.duration = 0;
		this.distance = 0;
		this.carTypeRentArray = [];
		this.distanceVariant = 1;
		this.surgeMultiplier = 1;
		this.zoneSurgePopup = 1;
		this.zoneId = "";
		this.carTypeArray = { "DAY": [], "NIGHT": [] };
		this.newRideTax = 0;
		this.withoutSurgeCarRent = 0;
		this.newRideTotal = 0;
		this.selectedCarTypeData = {};
		this.newRideSelectedHours = "00";
		this.newRideSelectedMinute = "15";
		this.newRideSelectedAssignTime = "15";
		this.setMinDate = null;
		this.newRideBookingToDate = null;
		this.newRideOptionStatus = true;
		this.pickupTime = null;
		this.accountDataList = [];
		this.passengersForm.reset();
		this.passengersArray.clear();
		this.passengersArray.reset();
		this.passengersForm = this.formBuilder.group({
			passengersDetails: this.formBuilder.array([this.newPassenger()]),
		});
		this.newRideSetDriverId = (this.Global.appMode == 1) ? 'automatic' : 'manual';
		this.tariffStatus = this.Global.tarrif == 'custom' ? false : true;
		this.newRideBookingFromDate = null;
		this.repeatedDateArray = [];
		this.totalPerson = 1;
		this.totalBags = 0;
		this.handiCappedTotal = 0;
		this.carCount = 1;
		this.priority = "Automatic";
		this.roomNo = "";
		this.flightNo = "";
		this.userComment = "";
		this.driverComment = "";
		this.showRentInfo = false;
		this.showPassengerInfo = false;
		this.newRideSelectCarTypeDisable = true;
		this.getFixPriceCallResponse = true;
		this.nearestDriverList = [];
		this.repeatedDateArray = [];
		this.selectedCoupon = {};
		this.newEstimatedExtraCharges = 0.00;
		this.newRide_RepeatCheckedOn(false, 'createBooking');
		this.cancelAllRepeatOrderDate();
		this.isPriceFromAirportZone = 0;
		this.tollTax = 0.000;
		this.serviceCharges = 0.000;
		this.airportParking = 0.000;

	}
	clearEditBooking() {
		this.editLocationsForm.reset();
		this.editLocationArray.clear();
		this.editLocationArray.reset();
		this.editLocationList = [];
		this.editLocationJson = [];
		this.setLocationArr();
		this.setLocationMap();
		this.editJobLocationSwipe = false;
		this.editJobCarRent = 0;
		this.editJobCurrency = "";
		this.editJobDuration = 0;
		this.editJobDistance = 0;
		this.editCarTypeRentArray = [];
		this.editJobDistanceVariant = 1;
		this.editJobSurgeMultiplier = 1;
		this.editJobZoneSurgePopup = 1;
		this.editJobZoneId = "";
		this.editJobCarTypeArray = { "DAY": [], "NIGHT": [] };
		this.withoutSurgeEditJobCarRent = 0;
		this.editRideTax = 0;
		this.editJobDiscountAmount = 0;
		this.editRideTotal = 0;
		this.editJobSelectedCarTypeData = {};
		this.editRideSelectedHours = "00";
		this.editRideSelectedMinute = "15";
		this.editRideSelectedAssignTime = "15";
		this.setEditMinDate = null;
		this.editRideBookingToDate = null;
		this.editRideBookingToDateTime = null;
		this.editRideOptionStatus = true;
		this.editPickupTime = null;
		this.editJobAccountDataList = [];
		this.editPassengersForm.reset();
		this.editPassengersArray.clear();
		this.editPassengersArray.reset();
		this.editPassengersForm = this.formBuilder.group({
			passengersDetails: this.formBuilder.array([this.newPassenger()]),
		});
		this.editRideSetDriverId = (this.Global.appMode == 1) ? 'automatic' : 'manual';
		this.editTariffStatus = false;
		this.editRideBookingFromDate = null;
		this.editRepeatedDateArray = [];
		this.editJobTotalPerson = 1;
		this.editJobTotalBags = 0;
		this.editJobHandiCappedTotal = 0;
		this.editJobCarCount = 1;
		this.editJobPriority = "Automatic";
		this.editJobRoomNo = "";
		this.editJobFlightNo = "";
		this.editJobUserComment = "";
		this.editJobDriverComment = "";
		this.editJobShowRentInfo = false;
		this.editShowPassengerInfo = false;
		this.editNewRideSelectCarTypeDisable = true;
		this.getFixPriceCallResponse = true;
		this.editJobLoader = true;
		this.nearestDriverList = [];
		this.editJobSelectedCoupon = {};
		this.selectedPaymentType = 0;
		this.editIsPriceFromAirportZone = 0;
		this.editTollTax = 0.000;
		this.editServiceCharges = 0.000;
		this.editAirportParking = 0.000;

	}
	reverseLocation(locationArr: any = []) {
		let rlocation = locationArr.reverse();
		let reverseLocation = [];
		for (let i = 0; i < rlocation.length; i++) {
			let location = rlocation[i];

			let distance = 0;
			let duration = 0;
			if (i != 0) {
				distance = rlocation[i - 1].distance;
				duration = rlocation[i - 1].duration;
			}

			reverseLocation.push({
				"lat": location.lat,
				"long": location.long,
				"address": location.address,
				"isStopArrived": location.isStopArrived,
				"distance": distance,
				"duration": duration,
				"stopId": location.stopId
			});
		}

		return reverseLocation;
	}
	reversReturn() {
		let reversLocationArray = this.reverseLocation(this.createBookingRequest.location);
		console.log("===== this.createBookingRequest :: ", this.createBookingRequest);
		console.log("===== this.createBookingRequest.selectedCarTypeData.data.extraCharges :: ", this.createBookingRequest.selectedCarTypeData.data.extraCharges);


		let bookingData = {
			'locationJson': reversLocationArray,
			'bookingId': this.createBookingRequest.bookingId,
			'dBookId': this.createBookingRequest.dBookId,
			'userJson': this.createBookingRequest.userJson,
			'accountId': (this.createBookingRequest.accountId && this.createBookingRequest.accountId.trim() != '') ? this.createBookingRequest.accountId : '',
			'carRent': this.createBookingRequest.carRent,
			'currency': this.createBookingRequest.currency,
			'duration': this.createBookingRequest.duration,
			'distance': this.createBookingRequest.distance,
			'distanceVariant': this.createBookingRequest.distanceVariant,
			'surgeMultiplier': this.createBookingRequest.surgeMultiplier,
			'zoneId': this.createBookingRequest.zoneId,
			'carTypeId': this.createBookingRequest.carTypeId,
			'carTypeName': this.createBookingRequest.carTypeName,
			'carDetails': this.createBookingRequest.carDetails,
			'distancePrice': this.createBookingRequest.distancePrice,
			'durationPrice': this.createBookingRequest.durationPrice,
			'baseFare': this.createBookingRequest.baseFare,
			'driverId': this.createBookingRequest.driverId,
			'tarrif': this.createBookingRequest.tarrif,
			'totalPerson': this.createBookingRequest.totalPerson,
			'totalBags': this.createBookingRequest.totalBags,
			'handicappedTotal': this.createBookingRequest.handicappedTotal,
			'carCount': this.createBookingRequest.carCount,
			'priority': this.createBookingRequest.priority,
			'roomNo': this.createBookingRequest.roomNo,
			'flightNo': this.createBookingRequest.flightNo,
			'userComment': this.createBookingRequest.userComment,
			'driverComment': this.createBookingRequest.driverComment,
			'stopPrice': this.createBookingRequest.stopPrice,
			'extraCharges': this.createBookingRequest.selectedCarTypeData.data.extraCharges ? this.createBookingRequest.selectedCarTypeData.data.extraCharges : {},
			toll: this.tollTax,
			serviceCharges: this.serviceCharges,
			airportParking: this.airportParking,
			priceFromAirportZone: this.isPriceFromAirportZone,
		}

		console.log('--------------------- this.createBookingRequest.carTypeId ---------------------', this.createBookingRequest.carTypeId)
		let setData = this.setCreateBookingDetails(bookingData);
		if (setData == true) {
			this.createBookingRequest = {};
		}
		// this.getFixPriceCall(bookingData)  //Vishesh - ADD 
	}
	getFixPriceCallResponse: boolean = true;
	getFixPriceCall(carTypeData: any) {
		this.getFixPriceCallResponse = false;
		console.log('--------------------- carTypeData.carTypeId ---------------------', carTypeData.carTypeId)
		this.getFixedPrice(carTypeData.carTypeId);
	}
	setCreateBookingDetails(bookingData: any) {
		this.clearBooking();
		console.log("=====bookingData :: ", bookingData);
		if (Array.isArray(bookingData.locationJson)
			&& Array.isArray(bookingData.userJson)
			&& bookingData.accountId != undefined
			&& bookingData.carRent != undefined
			&& bookingData.currency != undefined
			&& bookingData.duration != undefined
			&& bookingData.distance != undefined
			&& bookingData.distanceVariant != undefined
			&& bookingData.surgeMultiplier != undefined
			&& bookingData.zoneId != undefined
			&& bookingData.carTypeId != undefined
			&& bookingData.carTypeName != undefined
			&& bookingData.baseFare != undefined
			&& bookingData.distancePrice != undefined
			&& bookingData.durationPrice != undefined
			&& bookingData.carDetails.carSeatCapacity != undefined
			&& bookingData.carDetails.carTypeName != undefined
			&& bookingData.carDetails.carInitialKm != undefined
			&& bookingData.carDetails.carInitialRate != undefined
			&& bookingData.carDetails.perKmRate != undefined
			&& bookingData.carDetails.perMinuteRate != undefined
			&& bookingData.carDetails.carMinimumFare != undefined
			&& bookingData.carDetails.carCancellationCharge != undefined
			&& bookingData.carDetails.carMultiplier != undefined
			&& bookingData.carDetails.carWaitPrice != undefined
			&& bookingData.carDetails.mileage_waiting_time_price != undefined
			&& bookingData.carDetails.priceVariant != undefined
			&& bookingData.driverId != undefined
			&& bookingData.tarrif != undefined
			&& bookingData.totalPerson != undefined
			&& bookingData.totalBags != undefined
			&& bookingData.handicappedTotal != undefined
			&& bookingData.carCount != undefined
			&& bookingData.priority != undefined
			&& bookingData.roomNo != undefined
			&& bookingData.flightNo != undefined
			&& bookingData.userComment != undefined
			&& bookingData.driverComment != undefined
			&& bookingData.stopPrice != undefined
		) {

			this.isOpenCreateEditdraftjob = 0;
			this.createBookingbtn = false;

			if (bookingData.locationJson.length > 0) {
				let locationJson: any = [];
				for (let k = 0; k < bookingData.locationJson.length; k++) {
					if (k == 0) {
						bookingData.locationJson[k].addressId = 'pickup';
						this.locationsForm.get('pickupPoint')?.setValue(bookingData.locationJson[k].address);
					}
					else if (k == (bookingData.locationJson.length - 1)) {
						bookingData.locationJson[k].addressId = 'drop';
						this.locationsForm.get('dropPoint')?.setValue(bookingData.locationJson[k].address);
					} else {
						this.locationArray.push(this.formBuilder.group({
							waypoint: bookingData.locationJson[k].address
						}));
					}

					bookingData.locationJson[k].isStopArrived = 0;
					bookingData.locationJson[k].isStoparrivedTripTime = "";
					locationJson.push(bookingData.locationJson[k]);
				}

				this.locationList = bookingData.locationJson;
				this.locationJson = bookingData.locationJson;
				this.setLocationArr();
				this.setLocationMap();

				this.reloadMapData({
					'location': [bookingData.locationJson[0].lat, bookingData.locationJson[0].long],
					'bookingType': bookingData.bookingType,
					'isOpenDriverList': true
				});
			}

			this.locationSwipe = false;

			this.withoutSurgeCarRent = (bookingData.carRent / bookingData.surgeMultiplier) - this.discountAmount;

			this.showRentInfo = true;
			this.carRent = this.withoutSurgeCarRent;
			this.currency = bookingData.currency;
			this.duration = bookingData.duration;
			this.distance = bookingData.distance;
			this.carTypeRentArray = [{
				'carTypeId': bookingData.carTypeId,
				'carTypeName': bookingData.carTypeName,
			}];
			this.distanceVariant = bookingData.distanceVariant;
			this.surgeMultiplier = 1;
			this.zoneSurgePopup = 1;
			this.zoneId = bookingData.zoneId;
			// let totalTax = this.Global.taxCalculate(+this.carRent);
			// this.newRideTax = +totalTax.totalTaxAmount.toFixed(environment.roundUpNumber);
			// this.newRideTotal = +(parseFloat(this.carRent.toString()) + totalTax.totalTaxAmount).toFixed(environment.roundUpNumber);
			console.log("===== setCreateBookingDetails bookingData.extraCharges :: ", bookingData.extraCharges);


			this.selectedCarTypeData = {
				'data': {
					'baseFare': bookingData.baseFare,
					'carTypeId': bookingData.carTypeId,
					'carTypeName': bookingData.carTypeName,
					'distancePrice': bookingData.distancePrice,
					'durationPrice': bookingData.durationPrice,
					'carSeatCapacity': bookingData.carDetails.carSeatCapacity,
					'carInitialKm': bookingData.carDetails.carInitialKm,
					'carInitialRate': bookingData.carDetails.carInitialRate,
					'perKmRate': bookingData.carDetails.perKmRate,
					'perMinuteRate': bookingData.carDetails.perMinuteRate,
					'carMinimumFare': bookingData.carDetails.carMinimumFare,
					'carCancellationCharge': bookingData.carDetails.carCancellationCharge,
					'carMultiplier': bookingData.carDetails.carMultiplier,
					'carWaitPrice': bookingData.carDetails.carWaitPrice,
					'mileage_waiting_time_price': bookingData.carDetails.mileage_waiting_time_price,
					'priceVariant': bookingData.carDetails.priceVariant,
					'stopPrice': bookingData.stopPrice,
					'carRent': bookingData.carRent,
					'extraCharges': bookingData.extraCharges,
					'isCorporateCar': bookingData.isCorporateCar
				}
			}

			console.log("copy ", this.selectedCarTypeData);


			this.showPassengerInfo = true;
			if (bookingData.userJson.length > 0) {
				this.passengersForm.reset();
				this.passengersArray.clear();
				this.passengersArray.reset();

				for (let j = 0; j < bookingData.userJson.length; j++) {
					this.passengersArray.push(this.formBuilder.group({
						id: (this.Global.isCopyAccountId == true) ? bookingData.userJson[j].passengerId : "", //!vishesh 16-10-23
						// id: bookingData.userJson[j].passengerId ? bookingData.userJson[j].passengerId : "", //!vishesh 16-10-23
						// id: bookingData.userJson[j].passengerId, // new vishesh
						accountId: (this.Global.isCopyAccountId == true) ? bookingData.userJson[j].accountId : '',
						name: bookingData.userJson[j].passengerName,
						phone: bookingData.userJson[j].passengerPhone.trim(),
						emailAddress: bookingData.userJson[j].passengerEmail.trim(),
						in: bookingData.userJson[j].passengerIn,
						out: bookingData.userJson[j].passengerOut
					}));
				}
			}
			console.log('****** bookingData.userJson ******', bookingData.userJson);
			console.log('****** bookingData.accountId ******', bookingData.accountId);
			console.log('***** this.passengersArray *****', this.passengersArray.controls[0].value);

			if (bookingData.tarrif == "Custom") {
				this.tariffStatus = false;
			} else {
				this.tariffStatus = true;
			}

			this.totalPerson = bookingData.totalPerson;
			this.totalBags = bookingData.totalBags;
			this.handiCappedTotal = bookingData.handicappedTotal;
			this.carCount = bookingData.carCount;
			this.priority = (bookingData.priority == 0) ? 'Automatic' : "" + bookingData.priority;
			this.roomNo = bookingData.roomNo;
			this.flightNo = bookingData.flightNo;
			this.userComment = bookingData.userComment;
			this.driverComment = bookingData.driverComment;
			// ! vishesh - airport taxes
			this.airportParking = bookingData.airportParking;
			this.isPriceFromAirportZone = bookingData.priceFromAirportZone;
			this.serviceCharges = bookingData.serviceCharges;
			this.tollTax = bookingData.toll;

			this.isOpenCreateEditdraftjob = 0;
			if (bookingData.isDraftBooking != true) {
				this.selectedDraftBookId = '';
			}

			this.clcBookingPrice();

			return true;
		}
		else {
			this.Popup.toasterMessage({
				'status': 'error',
				'title': "Oops!",
				'message': "Please pass required parameters.",
			});
			return false;
		}
	}
	editBookingCalenderModel: any;

	editExtraCharges: any = {};
	selectedPaymentType: number = 0;
	setEditBookingDetails(bookingData: any) {
		console.log("=-=-=-=-= -SEt Edit  bookingData :: ", bookingData);

		if (bookingData.bookingId && bookingData.bookingId.trim() != ''
			&& bookingData.dBookId && bookingData.dBookId.trim() != ''
			&& Array.isArray(bookingData.locationJson)
			&& Array.isArray(bookingData.userJson)
			&& bookingData.accountId != undefined
			&& bookingData.carRent != undefined
			&& bookingData.currency != undefined
			&& bookingData.duration != undefined
			&& bookingData.distance != undefined
			&& bookingData.distanceVariant != undefined
			&& bookingData.surgeMultiplier != undefined
			&& bookingData.zoneId != undefined
			&& bookingData.carTypeId != undefined
			&& bookingData.carTypeName != undefined
			&& bookingData.baseFare != undefined
			&& bookingData.distancePrice != undefined
			&& bookingData.durationPrice != undefined
			&& bookingData.carDetails.carSeatCapacity != undefined
			&& bookingData.carDetails.carTypeName != undefined
			&& bookingData.carDetails.carInitialKm != undefined
			&& bookingData.carDetails.carInitialRate != undefined
			&& bookingData.carDetails.perKmRate != undefined
			&& bookingData.carDetails.perMinuteRate != undefined
			&& bookingData.carDetails.carMinimumFare != undefined
			&& bookingData.carDetails.carCancellationCharge != undefined
			&& bookingData.carDetails.carMultiplier != undefined
			&& bookingData.carDetails.carWaitPrice != undefined
			&& bookingData.carDetails.mileage_waiting_time_price != undefined
			&& bookingData.carDetails.priceVariant != undefined
			&& bookingData.driverId != undefined
			&& bookingData.tarrif != undefined
			&& bookingData.totalPerson != undefined
			&& bookingData.totalBags != undefined
			&& bookingData.handicappedTotal != undefined
			&& bookingData.carCount != undefined
			&& bookingData.priority != undefined
			&& bookingData.roomNo != undefined
			&& bookingData.flightNo != undefined
			&& bookingData.userComment != undefined
			&& bookingData.driverComment != undefined
			&& bookingData.stopPrice != undefined
			&& bookingData.bookingType != undefined
			&& bookingData.pickupTime != undefined
			&& bookingData.assignTime != undefined
			&& bookingData.taxJson != undefined
		) {
			this.isEnabledEditBooking = false;

			this.editExtraCharges = bookingData.extraChargesJson;
			console.log("===== setEditBookingDetails bookingData.carDetails :: ", bookingData.carDetails);

			console.log("=====this.editExtraCharges :: ", this.editExtraCharges);


			if (bookingData.distanceVariant == 'km' || bookingData.distanceVariant == 'miles') {
				bookingData.distanceVariant = (bookingData.distanceVariant == 'km') ? 0 : 1;
			}



			if (bookingData.locationJson.length > 0) {
				for (let k = 0; k < bookingData.locationJson.length; k++) {
					if (k == 0) {
						bookingData.locationJson[k].addressId = 'pickup';
						this.editLocationsForm.get('pickupPoint')?.setValue(bookingData.locationJson[k].address);
					}
					else if (k == (bookingData.locationJson.length - 1)) {
						bookingData.locationJson[k].addressId = 'drop';
						this.editLocationsForm.get('dropPoint')?.setValue(bookingData.locationJson[k].address);
					} else {
						this.editLocationArray.push(this.formBuilder.group({
							editJobWaypoint: bookingData.locationJson[k].address,
							isStopArrived: bookingData.locationJson[k].isStopArrived
						}));
					}
				}

				this.editLocationList = bookingData.locationJson;

				this.editLocationJson = bookingData.locationJson;
				this.setLocationArr();
				this.setLocationMap();
			}

			this.editJobLocationSwipe = false;
			this.editJobShowRentInfo = true;


			this.editJobSelectedCoupon = {};
			if (Object.entries(bookingData.couponDetails).length !== 0) {
				this.editJobSelectedCoupon = {
					couponId: bookingData.couponDetails.couponId,
					couponCode: bookingData.couponDetails.couponCode,
					couponDiscount: bookingData.couponDetails.couponCodeAmount,
					couponDiscountType: bookingData.couponDetails.couponCodeType,
					couponMaxDiscount: bookingData.couponDetails.couponCodeMaxAmount,
					couponTitle: bookingData.couponDetails.couponTitle,
					couponUserId: bookingData.couponDetails.couponUserId,
					isLocationSpecific: bookingData.couponDetails.isLocationSpecific,
				}
			}

			this.withoutSurgeEditJobCarRent = (bookingData.carRent / bookingData.surgeMultiplier);
			this.withoutSurgeEditJobCarRent = parseFloat(this.withoutSurgeEditJobCarRent.toFixed(environment.priceRoundUpNumber))

			console.log('****** copy booking this.withoutSurgeEditJobCarRent ******', this.withoutSurgeEditJobCarRent);

			this.editJobCarRent = bookingData.carRent;
			this.editJobCarRent = parseFloat(this.editJobCarRent.toFixed(environment.priceRoundUpNumber))
			this.editJobCurrency = bookingData.currency;
			this.editJobDuration = bookingData.duration;
			this.editJobDistance = bookingData.distance;
			this.editCarTypeRentArray = [{
				'carTypeId': bookingData.carTypeId,
				'carTypeName': bookingData.carTypeName,
			}];

			this.editRideSetDriverId = bookingData.driverId;

			this.editJobDistanceVariant = bookingData.distanceVariant;
			this.editJobSurgeMultiplier = bookingData.surgeMultiplier;
			this.editJobZoneSurgePopup = 1;
			this.editJobZoneId = bookingData.zoneId;
			this.editRideArrears = (bookingData.arrears != undefined) ? bookingData.arrears : 0.00;
			// let totalTax = this.Global.taxCalculate(+this.editJobCarRent);
			// this.editRideTax = +totalTax.totalTaxAmount.toFixed(environment.roundUpNumber);
			// this.editRideTotal = +(parseFloat(this.editJobCarRent.toString()) + totalTax.totalTaxAmount).toFixed(environment.roundUpNumber);
			console.log('--------------------- before this.editPickupTime ---------------------', this.editPickupTime)
			console.log('--------------------- bookingData.pickupTime ---------------------', bookingData.pickupTime)

			if (!this.editPickupTime) {
				this.editPickupTime = bookingData.pickupTime
			}
			console.log('--------------------- after this.editPickupTime ---------------------', this.editPickupTime)

			let getDayOrNight = this.Global.getDayNight(this.editPickupTime);
			// let getDayOrNight = this.Global.getDayNight(this.editPickupTime);
			// let getDayOrNight = this.Global.getDayNight(this.editRideBookingToDateTime);
			console.log("===== dispatcherFeesCalculate getDayOrNight :: ", getDayOrNight);
			let extraCharges = 0.00;

			if (getDayOrNight == "DAY") {
				extraCharges = this.editExtraCharges.extra_charges_day_price;
			} else if (getDayOrNight == "NIGHT") {
				extraCharges = this.editExtraCharges.extra_charges_night_price;
			}
			console.log("===== extraCharges Edit:: ", extraCharges);
			this.editExtraCharges = {
				bookingType: this.editExtraCharges.extra_charges_booking_type,
				chargeType: this.editExtraCharges.extra_charges_price_type,
				chargeData: extraCharges
			};


			this.editJobSelectedCarTypeData = {
				'data': {
					'baseFare': bookingData.baseFare,
					'carTypeId': bookingData.carTypeId,
					'carTypeName': bookingData.carTypeName,
					'distancePrice': bookingData.distancePrice,
					'durationPrice': bookingData.durationPrice,
					'carSeatCapacity': bookingData.carDetails.carSeatCapacity,
					'carInitialKm': bookingData.carDetails.carInitialKm,
					'carInitialRate': bookingData.carDetails.carInitialRate,
					'perKmRate': bookingData.carDetails.perKmRate,
					'perMinuteRate': bookingData.carDetails.perMinuteRate,
					'carMinimumFare': bookingData.carDetails.carMinimumFare,
					'carCancellationCharge': bookingData.carDetails.carCancellationCharge,
					'carMultiplier': bookingData.carDetails.carMultiplier,
					'carWaitPrice': bookingData.carDetails.carWaitPrice,
					'mileage_waiting_time_price': bookingData.carDetails.mileage_waiting_time_price,
					'destination_later_price': bookingData.carDetails.destination_later_price,
					'partial_cancel_price': bookingData.carDetails.partial_cancel_price,
					'priceVariant': bookingData.carDetails.priceVariant,
					'stopPrice': bookingData.stopPrice,
					'extraCharges': this.editExtraCharges,
					// 'paymentType' : bookingData.paymentType
					'isCorporateCar': bookingData.carDetails.isCorporateCar //! vishesh  12-02-24

				}
			}
			this.editTollTax = bookingData.toll,
				this.editServiceCharges = bookingData.serviceCharges,
				this.editAirportParking = bookingData.airportParking,
				this.editIsPriceFromAirportZone = bookingData.priceFromAirportZone,
				this.isEditStreetPickup = bookingData.isStreetPickup,

				this.selectedPaymentType = bookingData.paymentType
			console.log('***** this.selectedPaymentType *****', this.selectedPaymentType);
			console.log("===== this.editJobCarRent :: ", this.editJobCarRent);
			console.log('*****  bookingData.accountId *****', bookingData.accountId);

			this.editShowPassengerInfo = true;

			if (bookingData.userJson.length > 0) {
				this.editPassengersForm.reset();
				this.editPassengersArray.clear();
				this.editPassengersArray.reset();

				for (let j = 0; j < bookingData.userJson.length; j++) {
					this.editPassengersArray.push(this.formBuilder.group({
						id: bookingData.userJson[j].passengerId,
						accountId: bookingData.userJson[j].accountId ? bookingData.userJson[j].accountId : bookingData.accountId,
						name: bookingData.userJson[j].passengerName,
						phone: bookingData.userJson[j].passengerPhone.trim(),
						emailAddress: bookingData.userJson[j].passengerEmail.trim(),
						in: bookingData.userJson[j].passengerIn,
						out: bookingData.userJson[j].passengerOut
					}));
				}
			}

			if (bookingData.tarrif == "Custom") {
				this.editTariffStatus = false;
			} else {
				this.editTariffStatus = true;
			}

			this.editJobTotalPerson = bookingData.totalPerson;
			this.editJobTotalBags = bookingData.totalBags;
			this.editJobHandiCappedTotal = bookingData.handicappedTotal;
			this.editJobCarCount = bookingData.carCount;
			this.editJobPriority = (bookingData.priority == 0) ? 'Automatic' : "" + bookingData.priority;
			this.editJobRoomNo = bookingData.roomNo;
			this.editJobFlightNo = bookingData.flightNo;
			this.editJobUserComment = bookingData.userComment;
			this.editJobDriverComment = bookingData.driverComment;

			console.log("-----bookingData.bookingType ::: ", bookingData.bookingType);
			console.log("-----bookingData.pickupTime ::: ", bookingData.pickupTime);
			this.editRideBookingToDateTime = moment(bookingData.pickupTime, 'YYYY-MM-DD HH:mm:ss').format("YYYY-MM-DD HH:mm:ss"); //date


			if (bookingData.bookingType == 0) {
				this.editRideOptionStatus = true;
			}
			else if (bookingData.bookingType == 1) {
				this.editRideOptionStatus = false;
				let time = moment(bookingData.pickupTime).format("HH:mm:ss").split(":");
				this.editRideSelectedHours = time[0]; //hours
				this.editRideSelectedMinute = this.setMinutes(time[1]); //minutes
				this.editRideBookingToDate = moment(bookingData.pickupTime, 'YYYY-MM-DD HH:mm:ss').format("YYYY-MM-DD"); //date
				let editTime = moment(bookingData.pickupTime, 'YYYY-MM-DD HH:mm:ss').format("YYYY-MM-DD"); //date

				// this.setEditMinDate = moment(this.Global.getCompanyCurrentTime(), this.Global.dateFormat).format("YYYY-MM-DD");

				this.editBookingCalenderModel = { start: moment(editTime), end: moment(editTime) }

				let getDiff = moment(bookingData.pickupTime).diff(moment(bookingData.assignTime), 'minutes')
				this.editRideSelectedAssignTime = "" + getDiff;

				// if (this.setEditMinDate >= this.editRideBookingToDate) {
				// 	this.editRideBookingToDate = moment(this.Global.getCompanyCurrentTime(), this.Global.dateFormat).format("YYYY-MM-DD"); //date
				// }

				this.setPickupTime();
			}

			this.clcBookingPrice();

			return true;
		}
		else {
			this.Popup.toasterMessage({
				'status': 'error',
				'title': "Oovvps!",
				'message': "Please pass required parameters.",
			});

			return false;
		}
	}
	copyBookingData(callType: string, data: any) {
		console.log("===== copyBookingData data :: ", data);
		console.log('***** data.accountId *****', data.accountId);
		console.log('***** data.userJson.passengerId *****', data.userJson.passengerId);
		if (data.distanceVariant == 'km' || data.distanceVariant == 'miles') {
			data.distanceVariant = (data.distanceVariant == 'km') ? 0 : 1;
		}
		this.newRideOptionStatus = true; //for select radio btn now when copy booking

		console.log("===== this.Global.getCompanyCurrentTime() :: ", this.Global.getCompanyCurrentTime());

		let getDayNight = this.Global.getDayNight(moment(this.Global.getCompanyCurrentTime()).format("YYYY-MM-DD HH:mm:ss"));
		console.log("===== getDayNight :: ", getDayNight);
		let extraCharges = {};

		if (getDayNight == "DAY") {
			extraCharges = {
				'bookingType': data.extraChargesJson.extra_charges_booking_type,
				'chargeType': data.extraChargesJson.extra_charges_price_type,
				'chargeData': data.extraChargesJson.extra_charges_day_price
			};
		} else if (getDayNight == "NIGHT") {
			extraCharges = {
				'bookingType': data.extraChargesJson.extra_charges_booking_type,
				'chargeType': data.extraChargesJson.extra_charges_price_type,
				'chargeData': data.extraChargesJson.extra_charges_night_price
			};
		}


		let bookingData = {
			'locationJson': data.locationJson,
			'userJson': data.userJson,
			'accountId': (data.accountId && data.accountId.trim() != '') ? data.accountId : '',
			'carRent': data.estimatedAmount,
			'currency': data.currency,
			'duration': data.totalApproxTime,
			'distance': data.totalApproxKm,
			'distanceVariant': data.distanceVariant,
			'surgeMultiplier': data.surgeMultiplier,
			'zoneId': data.zoneId,
			'carTypeId': data.carTypeId,
			'bookingType': data.bookingType,
			'carTypeName': data.taxiType,
			'carDetails': data.carDetails,
			'distancePrice': data.tripDistancePrice,
			'durationPrice': data.tripTimePrice,
			'baseFare': data.baseFare,
			'driverId': data.driverId,
			'tarrif': data.tarrif,
			'totalPerson': data.totalPerson,
			'totalBags': data.totalBags,
			'handicappedTotal': data.handicappedTotal,
			'carCount': 1,
			'priority': data.priority,
			'roomNo': data.roomNo,
			'flightNo': data.flightNo,
			'userComment': data.userComment,
			'driverComment': data.driverComment,
			'stopPrice': data.stopPrice,
			'extraCharges': extraCharges,
			'toll': data.toll,
			'serviceCharges': data.serviceCharges,
			'airportParking': data.airportParking,
			'priceFromAirportZone': data.priceFromAirportZone ? data.priceFromAirportZone : data.isAirport,
		}

		this.selectedPaymentType = data.paymentType
		console.log('***** this.selectedPaymentType *****', this.selectedPaymentType);
		this.selectedBooking = bookingData;
		console.log('***** selectedBooking bookingData *****', bookingData);
		let setData = this.setCreateBookingDetails(bookingData);
	}
	bookingDetails(bookingDetails: any) {
		console.log("====== bookingDetails :: ", bookingDetails);

		this.shareData.shareData({ 'function': 'openBookingDetails-unAssignList', 'data': bookingDetails });
		this.driverAssignContextPassive = false;
		this.isUserBalance = false;
	}
	selectedBooking: any = {};
	cancelBookingButtonLoader: boolean = false;
	cancelBooking(opts: any = {}) {
		let requestObj: any = {
			'dispatcherId': this.Global.dispatcherDetails.dispatcherId,
			'bookingId': this.selectedBooking.bookingId,
			"cancelBy": "dispatcher",
			"cancelType": 0
		};

		if (opts.isRepet == 1 && this.selectedBooking.isRepet == 1 && this.selectedBooking.repetTicks.trim() != '') {
			requestObj.isRepet = 1;
			requestObj.repetTicks = this.selectedBooking.repetTicks;
		}

		this.cancelBookingButtonLoader = true
		this.CallMethod.cancelBooking(requestObj).pipe(takeUntil(this.unsubscribe$)).subscribe((result: any) => {
			if (result.status) {
				this.setEditBookingAlertPopup('Successfully cancelled booking.', 'success');
				delete this.selectedBooking['bookingId'];
			} else if (result.status == false && result.statusCode == 1) {
				this.Popup.toasterMessage({
					'status': 'error',
					'title': "Please Try Again",
					'message': "Booking doesn't Exist !!",
				});
			}
			this.cancelBookingButtonLoader = false;
			this.nearestDriverList = [];
			this.clearEditBooking();
		});
	}





	forceFullyCompletedDialog: boolean = false;
	finalDeductAmount: number = 0;
	callTypeCheck: string = ""
	header: string = ""
	boxEstPrice: any = ""
	userNameBox: any = ""
	cancelBookingConfirm(callType: string = '') {
		this.callTypeCheck = callType;
		this.userNameBox = this.selectedBooking.userJson[0].passengerName;
		console.log("this.selectedBooking.userJson.passengerName", this.selectedBooking.userJson.passengerName);
		console.log("callTypeCheck", this.callTypeCheck);
		if (this.selectedBooking && this.selectedBooking.isBox == 1 && this.selectedBooking.boxItems.length != 0 && this.selectedBooking.boxItems.boxItemType == 1 && (this.callTypeCheck == 'deliveredJobByCash' || this.callTypeCheck == 'deliveredJobByCard')) {
			console.log("select box items", this.selectedBooking);

			this.forceFullyCompletedDialog = true;
			console.log("this.selectedBooking.estimatedBoxItemPrice", this.selectedBooking.estimatedBoxItemPrice);
			this.boxEstPrice = this.selectedBooking.estimatedBoxItemPrice
			this.finalDeductAmount = this.selectedBooking.estimatedBoxItemPrice;
			console.log(":::finalDeductAmount:::", this.finalDeductAmount);

			if (callType == 'deliveredJobByCash') {
				this.header = "Forcefully Delivered By Cash"
			}
			else if (callType == 'deliveredJobByCard') {
				this.header = "Forcefully Delivered By Card"
			}

		}
		else if (this.selectedBooking.isRestaurant == 1 && this.callTypeCheck == 'deliveredJobByCard') {
			this.forcefullyDeliveredPopup = { display: 'block', heading: '#' + this.selectedBooking.dBookId, message: 'Are you sure that you want to deliver this order forcefully ? ', confirmButton: true, closeButton: true, closeMsgButton: true, img: '' };
			(document.getElementById("deliveredWithWallet") as HTMLInputElement).checked = true;
			// this.selectedBookingId = orderId;
			// this.selectedDBookId = dBookId;
		}
		else {
			let msg = 'Are you sure? Do you want to cancel this #' + this.selectedBooking.dBookId + 'booking?';

			if (callType == 'cancelAllBooking') {
				msg = 'Are you sure you want to cancel all bookings?';
			}

			else if (callType == 'deliveredJobByCash' || callType == 'deliveredJobByCard') {
				msg = 'Are you sure? Do you want to forcefully deliver this #' + this.selectedBooking.dBookId + 'booking?';
			}
			this.setEditBookingAlertPopup(msg, 'warning', true, callType);
		}

	}

	btnDisable: boolean = false;
	isEmpty: boolean = false;
	checkEstAmount(event: any) {
		console.log("event:::::::::::::", event);

		if (Number.isNaN(event.target.value)) {
			this.finalDeductAmount = 0;
			console.log("finalDeductAmount NaN:::::::::::::", this.finalDeductAmount);
		}
		if (this.finalDeductAmount <= this.Global.boxBuyLimit) {
			// this.finalDeductAmount  = event.target.value;
			console.log("finalDeductAmount if ::::::::::::", this.finalDeductAmount);
			this.btnDisable = false;
			console.log("this.finalDeductAmount == null", this.finalDeductAmount == null);
			if (this.finalDeductAmount < 0 || event.target.value == "") {
				this.isEmpty = true;
			}
			else {
				this.isEmpty = false;
			}
		}
		else {
			this.btnDisable = true;
		}
	}
	completeForceFullyBooking() {
		console.log("completeForceFullyBooking");
		this.confirmationService.confirm({
			message: 'Are you sure? Do you want to forcefully deliver this #' + this.selectedBooking.dBookId + ' booking?',
			header: 'Forcefully Delivered Confirmation',
			icon: 'pi pi-info-circle',
			accept: () => {
				console.log("vvvvvvvvvvvvvvvvvvvvvv");
				this.deliveredJob(this.callTypeCheck);
				this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'Your booking has been delivered forcefully.' });
				this.forceFullyCompletedDialog = false;
			},
			reject: (type: any) => {
				switch (type) {
					case ConfirmEventType.REJECT:
						// this.messageService.add({severity:'error', summary:'Rejected', detail:'You have rejected'});
						break;
					case ConfirmEventType.CANCEL:
						// this.messageService.add({severity:'warn', summary:'Cancelled', detail:'You have cancelled'});
						break;
				}
			}
		});
	}

	isOpenCreateEditdraftjob: number = 0;
	createEditDraftJobs(event: any) {
		this.isOpenCreateEditdraftjob = event.index;
		this.setLocationArr();
		this.setLocationMap();
		this.nearestDriverList = [];
		if (event.index == 2) {
			this.getDraftBooking(0);
		}
		else if (event.index == 0) {
			if (this.locationList.length > 0) {
				this.reloadMapData({
					'location': [this.locationList[0].lat, this.locationList[0].long],
					'bookingType': 0,
					'isOpenDriverList': true
				});
			}
		}
		else if (event.index == 1) {
			if (this.selectedBooking != undefined && this.selectedBooking.bookingId != '' && this.editLocationList.length > 0) {
				this.reloadMapData({
					'location': [this.editLocationList[0].lat, this.editLocationList[0].long],
					'bookingType': this.selectedBooking.bookingType,
					'isOpenDriverList': true
				});
			}
		}
	}
	selectedTab: string = ""
	async selectEditBooking(data: any) {

		console.log("select edit booking data", data);
		this.selectedBooking = data;

		this.isOpenCreateEditdraftjob = 1;
		await this.clearEditBooking();
		await this.reloadMapData({
			'location': [data.locationJson[0].lat, data.locationJson[0].long],
			'bookingType': data.bookingType,
			'isOpenDriverList': true
		});

		// if (this.Global.isSetDriverLimit == true) {
		// }
		// else {
		// 	this.getNearestDriver({
		// 		'location': [data.locationJson[0].lat, data.locationJson[0].long],
		// 		'bookingType': data.bookingType,
		// 		'bookingId': data.bookingId
		// 	});
		// }


		if (data.bookingFlag == 0) {
			let carRent = data.estimatedAmount;
			if (data.slidedToComplete == 1) {
				carRent = data.actual_amount;
			}

			let bookingData = {
				'bookingId': data.bookingId,
				'dBookId': data.dBookId,
				'locationJson': data.locationJson,
				'userJson': data.userJson,
				'accountId': (data.accountId != undefined && data.accountId.trim() != '') ? data.accountId : '',
				'carRent': carRent,
				'currency': data.currency,
				'duration': data.totalApproxTime,
				'distance': data.totalApproxKm,
				'distanceVariant': data.distanceVariant,
				'surgeMultiplier': data.surgeMultiplier,
				'zoneId': data.zoneId,
				'carTypeId': data.carTypeId,
				'carTypeName': data.taxiType,
				'carDetails': data.carDetails,
				'distancePrice': data.tripDistancePrice,
				'durationPrice': data.tripTimePrice,
				'baseFare': data.baseFare,
				'driverId': data.driverId,
				'tarrif': data.tarrif,
				'totalPerson': data.totalPerson,
				'totalBags': data.totalBags,
				'handicappedTotal': data.handicappedTotal,
				'carCount': 1,
				'priority': data.priority,
				'roomNo': data.roomNo,
				'flightNo': data.flightNo,
				'userComment': data.userComment,
				'driverComment': data.driverComment,
				'stopPrice': data.stopPrice,
				'bookingType': data.bookingType,
				'pickupTime': data.pickupTime,
				'assignTime': data.assignTime,
				'couponDetails': data.couponDetails,
				'taxJson': data.taxJson,
				'arrears': data.arrears,
				'extraChargesJson': (data.extraChargesJson != undefined && data.extraChargesJson != null) ? data.extraChargesJson : {},
				'paymentType': data.paymentType,
				'toll': data.toll,
				'serviceCharges': data.serviceCharges,
				'airportParking': data.airportParking,
				'priceFromAirportZone': data.priceFromAirportZone ? data.priceFromAirportZone : data.isAirport,
				'isAirport': data.isAirport,
				'isStreetPickup': data.isStreetPickup,
			}

			this.setEditBookingDetails(bookingData);
		}
	}
	isEnabledEditBooking: boolean = false;
	enabledEditBooking() {
		this.isEnabledEditBooking = true;
	}
	editJobLoader: Boolean = true;
	isAccountEditUser: boolean = false;
	isEditStreetPickup: boolean = false;
	editBooking() {
		if (this.selectedBooking.bookingId != undefined && this.selectedBooking.bookingId.trim() != ''
			&& Array.isArray(this.editLocationJson) && this.editLocationJson.length > 0
			&& this.editJobCarRent != undefined
			&& this.editJobSelectedCarTypeData.data.baseFare != undefined
			&& this.editJobSelectedCarTypeData.data.carTypeId != undefined
			&& this.editJobSelectedCarTypeData.data.carTypeName != undefined
			&& this.editJobDistance != undefined
			&& this.editJobDuration != undefined
			&& this.editJobSelectedCarTypeData.data.distancePrice != undefined
			&& this.editJobSelectedCarTypeData.data.durationPrice != undefined
		) {
			this.editJobLoader = false;
			let userJson = [];
			for (let i = 0; i < this.editPassengersForm.value.passengersDetails.length; i++) {
				if (this.editPassengersForm.value.passengersDetails[0].id.trim() != ''
					&& (this.editPassengersForm.value.passengersDetails[0].accountId.trim() == '' || this.editPassengersForm.value.passengersDetails[0].accountId == null)
					&& this.editPassengersForm.value.passengersDetails[0].emailAddress.trim() == ''
					&& this.editPassengersForm.value.passengersDetails[0].phone.trim() == ''
				) {
					this.editPassengersForm.value.passengersDetails[i].id = '';
				}

				userJson.push({
					accountId: this.editPassengersForm.value.passengersDetails[i].accountId,
					passengerId: this.editPassengersForm.value.passengersDetails[i].id,
					passengerName: this.editPassengersForm.value.passengersDetails[i].name,
					passengerEmail: this.editPassengersForm.value.passengersDetails[i].emailAddress.trim(),
					passengerPhone: this.editPassengersForm.value.passengersDetails[i].phone.trim(), //! vishesh 30-01-24
					passengerIn: this.editPassengersForm.value.passengersDetails[i].in,
					passengerOut: this.editPassengersForm.value.passengersDetails[i].out,
				});
			}

			let location = [];
			console.log("=-====this.editLocationJson :: ", this.editLocationJson);

			for (let i = 0; i < this.editLocationJson.length; i++) {
				let newLocationPush: any = {
					"lat": this.editLocationJson[i].lat,
					"long": this.editLocationJson[i].long,
					"address": this.editLocationJson[i].address,
					"isStopArrived": this.editLocationJson[i].isStopArrived,
					"distance": this.editLocationJson[i].distance,
					"duration": this.editLocationJson[i].duration,
					"stopId": this.editLocationJson[i].stopId,
				};
				console.log("=====this.editLocationJson[i].stoparrivedTripTime :: ", this.editLocationJson[i].stoparrivedTripTime);

				if (this.editLocationJson[i].stoparrivedTripTime != undefined) {
					newLocationPush.stoparrivedTripTime = this.editLocationJson[i].stoparrivedTripTime;
				}

				location.push(newLocationPush);
			}
			console.log("===== editJobSelectedCarTypeData.data.extraCharges :: ", this.editJobSelectedCarTypeData.data.extraCharges);

			const request: any = {
				bookingId: this.selectedBooking.bookingId,
				driverId: this.editRideSetDriverId,
				userId: "",
				bookingType: 0,
				isDevice: environment.device,
				baseFare: this.editJobSelectedCarTypeData.data.baseFare,
				paymentType: this.selectedPaymentType,  //! new 
				// paymentType: 0,  //! old
				carRent: parseFloat("" + this.editJobCarRent).toFixed(environment.priceRoundUpNumber), //car rent
				currency: this.editJobCurrency,
				carTypeId: this.editJobSelectedCarTypeData.data.carTypeId,
				carTypeName: this.editJobSelectedCarTypeData.data.carTypeName,
				carDetails: {
					carSeatCapacity: this.editJobSelectedCarTypeData.data.carSeatCapacity,
					carTypeName: this.editJobSelectedCarTypeData.data.carTypeName,
					carInitialKm: this.editJobSelectedCarTypeData.data.carInitialKm,
					carInitialRate: this.editJobSelectedCarTypeData.data.carInitialRate,
					perKmRate: this.editJobSelectedCarTypeData.data.perKmRate,
					perMinuteRate: this.editJobSelectedCarTypeData.data.perMinuteRate,
					carMinimumFare: this.editJobSelectedCarTypeData.data.carMinimumFare,
					carCancellationCharge: this.editJobSelectedCarTypeData.data.carCancellationCharge,
					carMultiplier: this.editJobSelectedCarTypeData.data.carMultiplier,
					carWaitPrice: this.editJobSelectedCarTypeData.data.carWaitPrice,
					mileage_waiting_time_price: this.editJobSelectedCarTypeData.data.mileage_waiting_time_price,
					priceVariant: this.editJobSelectedCarTypeData.data.priceVariant,
					destination_later_price: this.editJobSelectedCarTypeData.data.destination_later_price,
					partial_cancel_price: this.editJobSelectedCarTypeData.data.partial_cancel_price,
					isCorporateCar: this.editJobSelectedCarTypeData.data.isCorporateCar, //! vishesh new added for corporate wise pricing 17-10-23
				},
				distanceVariant: this.editJobDistanceVariant,
				distance: parseFloat("" + this.editJobDistance),
				duration: parseFloat("" + this.editJobDuration),
				surgeMultiplier: this.editJobSurgeMultiplier,
				distancePrice: this.editJobSelectedCarTypeData.data.distancePrice,
				durationPrice: this.editJobSelectedCarTypeData.data.durationPrice,
				totalPerson: parseInt("" + this.editJobTotalPerson),
				totalBags: parseInt("" + this.editJobTotalBags),
				handicappedTotal: parseInt("" + this.editJobHandiCappedTotal),
				priority: (this.editJobPriority != 'Automatic') ? parseInt(this.editJobPriority) : 0,
				zoneId: this.editJobZoneId,
				userJson: userJson,
				bookingFlag: 0,
				location: location,
				roomNo: this.editJobRoomNo,
				flightNo: this.editJobFlightNo,
				userComment: this.editJobUserComment,
				driverComment: this.editJobDriverComment,
				stopPrice: this.editJobSelectedCarTypeData.data.stopPrice,
				extraCharges: this.editJobSelectedCarTypeData.data.extraCharges,
				toll: this.editTollTax,
				serviceCharges: this.editServiceCharges,
				airportParking: this.editAirportParking,
				priceFromAirportZone: this.editIsPriceFromAirportZone,
				isStreetPickup: this.isEditStreetPickup
			};

			console.log('******EDIT this.editPassengersForm.value.passengersDetails[0].accountId ******', this.editPassengersForm.value.passengersDetails[0].accountId);

			if (this.editPassengersForm.value.passengersDetails[0].accountId && this.editPassengersForm.value.passengersDetails[0].accountId.trim() != '') {
				request['accountId'] = this.editPassengersForm.value.passengersDetails[0].accountId;
				request['paymentType'] = 2;
			}
			else {
				request['paymentType'] = this.selectedPaymentType != 2 ? this.selectedPaymentType : 0;
			}


			if (this.editPassengersForm.value.passengersDetails[0].id.trim() != '' && (
				this.editPassengersForm.value.passengersDetails[0].emailAddress.trim() != ''
				|| (this.editPassengersForm.value.passengersDetails[0].accountId != null && this.editPassengersForm.value.passengersDetails[0].accountId != '')
				|| this.editPassengersForm.value.passengersDetails[0].phone.trim() != '')
			) {
				request['userId'] = this.editPassengersForm.value.passengersDetails[0].id;
			}

			if (this.editBookingCouponCode.nativeElement.value.trim() != '') {
				request['couponCode'] = this.editBookingCouponCode.nativeElement.value;
			}

			if (this.editRideOptionStatus == false) {
				request['bookingType'] = 1;
				request['pickupTime'] = this.editPickupTime;
				request['assignMinute'] = parseInt(this.editRideSelectedAssignTime);
			}

			if (this.editTariffStatus == false) {
				request['tarrif'] = "Custom";
			} else {
				request['tarrif'] = "Automatic";
			}


			console.log('****** Edit booking request ******', request);
			// return;

			this.CallMethod.editBookingCall(request).pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
				this.editJobLoader = true;
				if (res.status) {
					this.selectedBooking = {};
					this.nearestDriverList = [];
					this.isAccountEditUser = false;
					this.clearEditBooking();
					this.setEditBookingAlertPopup('Successfully edit booking.', 'success');
				}
				else if (res.statusCode == 1) {
					this.setEditBookingAlertPopup('Booking not found.', 'error');
				}
				else if (res.statusCode == 2) {
					this.setEditBookingAlertPopup('Can not edit rental bookings.', 'error');
				}
				else if (res.statusCode == 3) {
					this.setEditBookingAlertPopup('Can not edit outstation bookings.', 'error');
				}
				else if (res.statusCode == 4) {
					this.setEditBookingAlertPopup('Only payment is pending. So, you can not edit this booking.', 'warning');
				}
				else if (res.statusCode == 5) {
					this.setEditBookingAlertPopup('Invalid account id.', 'error');
				}
				else if (res.statusCode == 6) {
					this.setEditBookingAlertPopup('booking is Active. So, you can not change time or (type) of this booking.', 'error');
				}
				else if (res.statusCode == 7) {
					this.isAccountEditUser = true;
					this.editPassengersForm.value.passengersDetails[0].accountId = res.userInfo.userAccountId;
					this.editPassengersForm.value.passengersDetails[0].id = res.userInfo.userId;
					this.editPassengersForm.value.passengersDetails[0].name = res.userInfo.userName;
					this.editPassengersForm.value.passengersDetails[0].emailAddress = res.userInfo.userEmail;
					this.editPassengersForm.value.passengersDetails[0].phone = res.userInfo.userMobile;
					console.log('****** res.statusCode == 7 res.userId ******', res.userId);
					this.getFixedPrice()
				}
				else {
					this.setEditBookingAlertPopup('Something went wrong.', 'error');
				}
			});

			return true;
		}
		else {
			this.setEditBookingAlertPopup('Please pass required parameters.', 'error');
			return false;
		}
	}
	/* Customer Deatils ModalShow start */
	setUserDetails(data: any) {
		if (this.isOpenCreateEditdraftjob == 0) {
			this.passengersArray.at(this.setUserIndex).patchValue({
				id: data.userId,
				name: data.userName,
				phone: data.userMobile,
				emailAddress: data.userEmail,
			});

			this.getUserAddressCall(data.userMobile);
		}
		if (this.isOpenCreateEditdraftjob == 1) {
			this.editPassengersArray.at(this.editJobSetUserIndex).patchValue({
				id: data.userId,
				name: data.userName,
				phone: data.userMobile,
				emailAddress: data.userEmail,
			});
		}
	}
	setUserIndex: number = 0;
	editJobSetUserIndex: number = 0;
	searchCustomer(index: number, formFieldName: string) {
		this.Global.openCustomersPopup = 'home';
		var form: any = new FormGroup({});
		if (this.isOpenCreateEditdraftjob == 0) {
			form = this.passengersForm;
			this.setUserIndex = index;
		}
		else if (this.isOpenCreateEditdraftjob == 1) {
			form = this.editPassengersForm;
			this.editJobSetUserIndex = index;
		}

		if (formFieldName != '') {
			setTimeout(() => {
				this.shareData.shareData({ function: 'customerDetailPopup', 'searchValue': form.value.passengersDetails[index][formFieldName] })
			}, 50);
		}
	}
	/* Customer Deatils ModalShow End */
	// draft job
	draftBookingbtn: boolean = false;
	saveToDraftBookingCall(request: any) {
		console.log("===== request :: ", request);
		console.log("===== request.extraCharges :: ", request.extraCharges);
		let req = {
			"driverId": request.driverId,
			"userId": request.userId,
			"isDevice": environment.device,
			"isDispatcher": 1,
			"baseFare": request.baseFare,
			"paymentType": request.paymentType,
			"carRent": request.carRent,
			"currency": request.currency,
			"carTypeId": request.carTypeId,
			"carTypeName": request.carTypeName,
			"carDetails": request.carDetails,
			"distanceVariant": request.distanceVariant,
			"distance": request.distance,
			"duration": request.duration,
			"surgeMultiplier": request.surgeMultiplier,
			"distancePrice": request.distancePrice,
			"durationPrice": request.durationPrice,
			"zoneId": request.zoneId,
			"userJson": request.userJson,
			"location": request.location,
			"stopPrice": request.stopPrice,
			"accountId": request.accountId,
			"tarrif": request.tarrif,
			"dispatcherId": request.dispatcherId,
			"companyId": this.Global.companyDetails.companyId,
			"totalPerson": request.totalPerson,
			"totalBags": request.totalBags,
			"handicappedTotal": request.handicappedTotal,
			"carCount": request.carCount,
			"priority": request.priority,
			"roomNo": request.roomNo,
			"flightNo": request.flightNo,
			"userComment": request.userComment,
			"driverComment": request.driverComment,
			"extraCharges": request.extraCharges,
			"toll": request.toll,
			"serviceCharges": request.serviceCharges,
			"airportParking": request.airportParking,
			"priceFromAirportZone": request.priceFromAirportZone,
		};

		this.draftBookingbtn = true;
		this.CallMethod.saveToDraftBookingCall(req).pipe(takeUntil(this.unsubscribe$)).subscribe((result: any) => {
			if (result.status) {
				this.createBookingRequest = request;
				this.createBookingRequest.selectedCarTypeData = this.selectedCarTypeData;
				this.setCreatBookingAlertPopup('Booking successfully save to draft.', 'success');
				this.clearBooking();
			}
			this.draftBookingbtn = false;
			this.nearestDriverList = [];
		});
	}
	draftBookingArr: any = [];
	totalDraftBooking: number = 0;
	draftBookingLimit: number = 10;
	draftBookingCurrentpage: number = 0;
	draftbookingCount: number = 0;
	draftJobShimmer: boolean = false;
	getDraftBooking(page: any) {
		let totalPage = Math.ceil(this.draftbookingCount / this.draftBookingLimit);

		if (totalPage < page) {
			page = totalPage;
		}

		this.draftBookingCurrentpage = page;
		let draftBookingOffset = (page == 0) ? 0 : ((page * this.draftBookingLimit) - this.draftBookingLimit);

		let request = {
			"limit": this.draftBookingLimit,
			"offset": draftBookingOffset
		}

		this.CallMethod.getDraftBookingCall(request).pipe(takeUntil(this.unsubscribe$)).subscribe((result: any) => {
			this.draftBookingArr = [];
			if (result.status) {
				this.draftBookingArr = result.draftData;
				this.draftbookingCount = result.draftCount;
			}

			this.draftJobShimmer = true;
		});
	}
	deleteDraftBookingConfirm(bookingId: any) {
		this.setDraftBookingAlertPopup('Are you sure you want to delete booking!', 'warning', true, 'deleteDraftBooking', { 'bookingId': bookingId });
	}
	deleteDraftBooking(bookingData: any) {
		let bookingId = bookingData.bookingId;
		let getDeleteBtn = document.getElementById('btn_delete_' + bookingId);
		let getDeleteLoader = document.getElementById('loader_' + bookingId);
		getDeleteBtn?.classList.add('hidden')
		getDeleteLoader?.classList.remove('hidden')

		let request = {
			"draftBookingId": bookingId
		}

		this.CallMethod.deleteDraftBookingCall(request).pipe(takeUntil(this.unsubscribe$)).subscribe((result: any) => {
			if (result.status) {
				this.setDraftBookingAlertPopup('Successfully delete booking.', 'success');
			}
		});
	}
	selectedDraftBookId: string = '';
	bookDraftBooking(data: any) {
		console.log("===== bookDraftBooking data :: ", data);
		console.log("===== bookDraftBooking data.extra_charges_json :: ", data.extra_charges_json);

		let bookingData = {
			'locationJson': data.locationJson,
			'userJson': data.userJson,
			'accountId': (data.accountId != undefined && data.accountId.trim() != '') ? data.accountId : '',
			'carRent': data.carRent,
			'bookingType': 0,
			'currency': data.currency,
			'duration': data.duration,
			'distance': data.distance,
			'distanceVariant': data.distanceVariant,
			'surgeMultiplier': data.surgeMultiplier,
			'zoneId': data.zoneId,
			'carTypeId': data.carTypeId,
			'carTypeName': data.carTypeName,
			'carDetails': data.carDetails,
			'distancePrice': data.distancePrice,
			'durationPrice': data.durationPrice,
			'baseFare': data.baseFare,
			'driverId': data.driverId,
			'tarrif': data.tarrif,
			'totalPerson': data.totalPerson,
			'totalBags': data.totalBags,
			'handicappedTotal': data.handicappedTotal,
			'carCount': data.carCount,
			'priority': data.priority,
			'roomNo': data.roomNo,
			'flightNo': data.flightNo,
			'userComment': data.userComment,
			'driverComment': data.driverComment,
			'stopPrice': data.stopPrice,
			'isDraftBooking': true,
			'extraCharges': data.extra_charges_json,
			"toll": data.toll,
			"serviceCharges": data.serviceCharges,
			"airportParking": data.airportParking,
			"priceFromAirportZone": data.priceFromAirportZone,
		}
		this.selectedDraftBookId = data._id;
		this.setCreateBookingDetails(bookingData);
	}
	couponDataList: any[] = [];
	editJobCouponDataList: any[] = [];
	couponDisplayDataList: any[] = [];
	editJobCouponDisplayDataList: any[] = [];
	getCoupon(event: any, type: string) {


		if ((type == 'click' && event.target.value != 'undefined' && event.target.value.trim() != '') || type != 'click' && event.target.value != 'undefined' && event.target.value.trim() != '') {
			this.searchCoupon(event.target.value);
		}
		else {
			this.getCouponCall();
		}
	}
	couponLoader: boolean = true;
	editJobCouponLoader: boolean = true;
	getCouponCall() {
		let req: any = {
			bookingFlag: 0
		}

		if (this.isOpenCreateEditdraftjob == 0) {
			if (this.selectedCarTypeData && Object.entries(this.selectedCarTypeData).length !== 0 && Object.entries(this.selectedCarTypeData.data).length !== 0 && this.selectedCarTypeData.data.carTypeId != '') {
				req.carTypeId = this.selectedCarTypeData.data.carTypeId;
				console.log("this.selectedCarTypeData.data.carTypeId isOpenCreateEditdraftjob = 0", this.selectedCarTypeData.data.carTypeId);
				console.log("req.carTypeId isOpenCreateEditdraftjob = 0", req.carTypeId);

			}

			if (this.passengersForm.value.passengersDetails[0].id.trim() != '') {
				req['userId'] = this.passengersForm.value.passengersDetails[0].id;
			}
			this.couponLoader = false;
		}
		else if (this.isOpenCreateEditdraftjob == 1) {
			if (this.editJobSelectedCarTypeData && this.editJobSelectedCarTypeData.data && Object.entries(this.editJobSelectedCarTypeData).length !== 0 && Object.entries(this.editJobSelectedCarTypeData.data).length !== 0 && this.editJobSelectedCarTypeData.data.carTypeId != '') {
				req.carTypeId = this.editJobSelectedCarTypeData.data.carTypeId;
			}
			if (this.editPassengersForm.value.passengersDetails[0].id.trim() != '') {
				req['userId'] = this.editPassengersForm.value.passengersDetails[0].id;
			}
			this.editJobCouponLoader = false;
		}

		this.CallMethod.getCoupon(req).subscribe((result: any) => {
			if (this.isOpenCreateEditdraftjob == 0) {
				this.couponLoader = true;
			}
			else if (this.isOpenCreateEditdraftjob == 1) {
				this.editJobCouponLoader = true;
			}

			if (result.status) {
				if (this.isOpenCreateEditdraftjob == 0) {
					this.couponDataList = [];
				}
				else if (this.isOpenCreateEditdraftjob == 1) {
					this.editJobCouponDataList = [];
				}

				if (result.couponData.length > 0) {
					for (let i = 0; i < result.couponData.length; i++) {
						if (this.isOpenCreateEditdraftjob == 0) {
							this.couponDataList.push({
								couponId: result.couponData[i].couponId,
								couponCode: result.couponData[i].couponCode,
								couponDiscount: result.couponData[i].couponDiscount,
								couponDiscountType: result.couponData[i].couponDiscountType,
								couponMaxDiscount: result.couponData[i].couponMaxDiscount,
								couponTitle: result.couponData[i].couponTitle,
								couponUserId: result.couponData[i].couponUserId,
								isLocationSpecific: result.couponData[i].isLocationSpecific,
							});
						}
						else if (this.isOpenCreateEditdraftjob == 1) {
							this.editJobCouponDataList.push({
								couponId: result.couponData[i].couponId,
								couponCode: result.couponData[i].couponCode,
								couponDiscount: result.couponData[i].couponDiscount,
								couponDiscountType: result.couponData[i].couponDiscountType,
								couponMaxDiscount: result.couponData[i].couponMaxDiscount,
								couponTitle: result.couponData[i].couponTitle,
								couponUserId: result.couponData[i].couponUserId,
								isLocationSpecific: result.couponData[i].isLocationSpecific,
							});
						}
					}

					if (this.isOpenCreateEditdraftjob == 0) {
						if (Object.entries(this.selectedCoupon).length !== 0) {
							let index = this.couponDataList.findIndex(((obj: any) => obj.couponCode == this.selectedCoupon.couponCode));
							if (index < 0) {
								this.selectedCoupon = {};
							}
						}
					}
					else if (this.isOpenCreateEditdraftjob == 1) {
						if (Object.entries(this.editJobSelectedCoupon).length !== 0) {
							let index = this.editJobCouponDataList.findIndex(((obj: any) => obj.couponCode == this.editJobSelectedCoupon.couponCode));
							if (index < 0) {
								this.editJobSelectedCoupon = {};
							}
						}
					}
				}

				if (this.isOpenCreateEditdraftjob == 0) {
					this.couponDisplayDataList = this.couponDataList;
				}
				else if (this.isOpenCreateEditdraftjob == 1) {
					this.editJobCouponDisplayDataList = this.editJobCouponDataList;
				}
			}
		});
	}
	@ViewChild("createBookingCouponCode") createBookingCouponCode!: ElementRef;
	@ViewChild("editBookingCouponCode") editBookingCouponCode!: ElementRef;
	searchCoupon(value: string) {
		if (this.isOpenCreateEditdraftjob == 0) {
			this.couponDisplayDataList = this.Global.filterByValueObj(this.couponDataList, "couponCode", value);
		}
		else if (this.isOpenCreateEditdraftjob == 1) {
			this.editJobCouponDisplayDataList = this.Global.filterByValueObj(this.editJobCouponDataList, "couponCode", value);
		}
	}
	selectCoupon(data: any, callType: string = '') {
		if (this.isOpenCreateEditdraftjob == 0) {
			this.selectedCoupon = data;
		}
		else if (this.isOpenCreateEditdraftjob == 1) {
			this.editJobSelectedCoupon = data;
		}

		if (callType == 'carTypeChange') {
			if (this.isOpenCreateEditdraftjob == 0) {
				this.couponDisplayDataList = [];
				this.couponDataList = [];
				this.couponDisplayDataList.push(data);
				this.couponDataList.push(data);
			}
			else if (this.isOpenCreateEditdraftjob == 1) {
				this.editJobCouponDisplayDataList = [];
				this.editJobCouponDataList = [];
				this.editJobCouponDisplayDataList.push(data);
				this.editJobCouponDataList.push(data);
			}
		}
		else {
			this.clcBookingPrice();
		}
	}
	removeCouponCode() {
		if (this.isOpenCreateEditdraftjob == 0) {
			this.selectedCoupon = {};
		}
		else if (this.isOpenCreateEditdraftjob == 1) {
			this.editJobSelectedCoupon = {};
		}

		this.clcBookingPrice();
	}
	// use function -------- this.updateArrayWithObject([pass array],['condition key name', 'condition value'],{update obj});
	updateArrayWithObject(array: any[], where: any[], setData: { [s: string]: unknown; } | ArrayLike<unknown>) {
		let objIndex = array.findIndex((obj => obj[where[0]] == where[1]));

		if (objIndex >= 0) {
			for (const [k, v] of Object.entries(setData)) {
				array[objIndex][k] = v;
			}
		}
		return array;
	}
	// use function -------- this.removeObjectInArray([pass array],['condition key name', 'condition value']);
	removeObjectInArray(array: any[], where: any[]) {
		let objIndex = array.findIndex((obj => obj[where[0]] == where[1]));

		if (objIndex >= 0) {
			array.splice(objIndex, 1);
		}
		return array;
	}
	getArrayInObject(array: any[], where: any[]) {
		let objIndex = array.findIndex((obj => obj[where[0]] == where[1]));
		const data = array[objIndex];

		let status = false;
		if (data != undefined) {
			status = true;
		}
		let object = {
			data: data,
			status: status
		}
		return object;
	}


	// !  custom Active Booking PopUp ==============================

	isExpand: boolean = false;
	// isNotify: boolean = false;
	activeBookingPopUp(isNotify: any) {
		let booking_btn = $("#bookingPopUp .icon");
		let booking_box = $("#bookingPopUp .messenger");

		if (isNotify) {

			booking_btn.addClass("expanded");
			setTimeout(() => {
				booking_box.addClass("expanded");
				this.isExpand = true;
			}, 100);

			if (this.isExpand == false) {
				booking_btn.addClass("expanded");
				setTimeout(() => {
					booking_box.addClass("expanded");
					this.isExpand = true;
				}, 100);
				// this.paginator._page = 1;
				// this.paginator._first = 0
				this.paginator.changePage(0)
				this.getAllUnApproveList(this.unApproveListParams);
			}
			else {
				if (!booking_btn.hasClass("expanded") || this.createNotifyBookingIds.length == 0) {
					booking_btn.removeClass("expanded");
					setTimeout(() => {
						booking_box.removeClass("expanded");
						this.isExpand = false;
					}, 100);
				}
			}
		}
		else {
			if (this.isExpand == false) {
				booking_btn.addClass("expanded");
				setTimeout(() => {
					booking_box.addClass("expanded");
					this.isExpand = true;
				}, 100);
				// this.paginator._page = 1;
				// this.paginator._first = 0
				this.paginator.changePage(0)
				this.getAllUnApproveList(this.unApproveListParams);
			}
			else {
				booking_btn.removeClass("expanded");
				setTimeout(() => {
					booking_box.removeClass("expanded");
					this.isExpand = false;
				}, 100);
			}
		}
		//   this.activeBookingPopUpData(this.getAllMqttBookingsData)
	}

	getAllUnApproveList(req: any) {
		console.log('***** getAllUnApproveList req *****', req);
		let rejObj = {
			limit: req.limit,
			offset: req.offset,
			search: req.search
		}
		this.CallMethod.unApproveBookingList(rejObj).subscribe((result: any) => {
			this.unAprroveSearching = true
			console.log('***** unApproveBookingList result *****', result);
			if (result.status) {
				this.createBookingNotifyData = result.data
				this.totalUnAprroveCount = result.totalCount
			}
			else {
				this.createBookingNotifyData = []
			}

		});
		this.unAprroveSearching = false;
		console.log('***** unAprroveSearching *****', this.unAprroveSearching);
	}

	//  Get custom Booking For Active Booking MQTT
	isAcceptPendingApproveBooking: boolean = false;
	acceptCreatedBookings(bookingId: any, dBookId: any) {
		this.CallMethod.unApproveBooking({ 'bookingId': bookingId }).subscribe((result: any) => {
			this.isAcceptPendingApproveBooking = true
			if (result.status) {
				console.log('***** unApproveBookingList result *****', result);
				this.Popup.toasterMessage({
					'status': 'success',
					'title': `#${dBookId} has successfully Accepted`,
					'message': '',
				});
			}
		});
		this.isAcceptPendingApproveBooking = false
		if (this.Global.audioObj.createBookingNotifyAlert.dispatcherAccept == true) {
			let bookingalert: any = document.querySelector('#acceptOrderNotification');
			bookingalert.play();
		}
	}

	pressDecline: Number = 1;

	declineCreatedBookings(bookingData: any) {
		console.log("===== declineCreatedBookings bookingData :: ", bookingData);

		let requestObj: any = {
			'dispatcherId': this.Global.dispatcherDetails.dispatcherId,
			'bookingId': bookingData.bookingId,
			"cancelBy": "dispatcher",
			"cancelType": 0
		};

		if (this.pressDecline == 1) {
			requestObj.isRepet = 1;
			requestObj.repetTicks = bookingData.repetTicks;
		}
		console.log("----- requestObj :: ", requestObj);

		this.CallMethod.cancelBooking(requestObj).subscribe((result: any) => {
			this.updateArrayWithObject(this.createBookingNotifyData, ['bookingId', bookingData.bookingId], { 'isOpenPopup': false });
		});
		if (this.Global.audioObj.createBookingNotifyAlert.dispatcherDecline == true) {
			let rejectBookingAlert: any = document.querySelector('#rejectBookingNotification');
			console.log("rejectBookingAlert", rejectBookingAlert);
			if (rejectBookingAlert != null) {
				console.log("play reject audio");
				rejectBookingAlert.play();
			}
		}
	}

	decline_confirm_button(bookingId: any, isRepeat: Number = 0) {
		this.updateArrayWithObject(this.createBookingNotifyData, ['bookingId', bookingId], { 'isOpenPopup': true });
		console.log("===== this.createBookingNotifyData :: ", this.createBookingNotifyData);
		this.pressDecline = isRepeat;
	}
	decline_close_button(bookingId: any) {
		this.updateArrayWithObject(this.createBookingNotifyData, ['bookingId', bookingId], { 'isOpenPopup': false });
	}

	openDriverChat(driverData: any) {
		console.log("driverData:::::::::::::", driverData);

		this.shareData.shareData({ function: 'openDriverChat', data: driverData });
	}

	pageIndex: number = 0;
	pageSize: number = 10;
	totalUnAprroveCount: number = 0
	unApproveListPagination(event: any) {
		console.log(":::unApproveListPagination event:", event);
		this.pageIndex = event.page;
		let req = {
			limit: 10,
			offset: (this.pageSize * event.page),
		}
		this.getAllUnApproveList(req)
	}

	unAprroveSearchParam: string = ""
	unAprroveSearching: boolean = false

	searchTaskBy: any = "";
	unAprroveSearch(search: string) {
		this.searchTaskBy = search;
		let req = {
			limit: 10,
			offset: 0,
			search: this.searchTaskBy,
		}
		this.getAllUnApproveList(req)
	}


	isReloadPage: boolean = false;
	reloadPage() {
		this.shareData.shareData({ function: "reloadData" })
		this.isReloadPage = true;
		setTimeout(() => {
			this.isReloadPage = false;
		}, 1000);
	}


	@HostListener('window:beforeunload', ['$event'])
	onBeforeUnload(event: BeforeUnloadEvent): void {
		console.log('****** onBeforeUnload event ******', event);
		console.log('*****>>> BeforeUnloadEvent <<<******');

		const isRefresh = localStorage.getItem('isRefresh');

		// Check if it's a refresh. If it's not a refresh, remove 'allDriverscachedDriversData'.
		if (!isRefresh) {
			localStorage.removeItem('allDriverscachedDriversData');
		}

		// Here, you can store your data in local storage or session storage
		// for later retrieval when the tab is reopened.
		localStorage.setItem('isReopenTab', JSON.stringify(1));
	}

	@HostListener('window:unload', ['$event'])
	onUnload(event: Event): void {
		// This event is triggered when the tab or browser is closed.
		// You can reset isReopenTab here, and set isRefresh to 0 to avoid clearing data on refresh.
		localStorage.setItem('isReopenTab', JSON.stringify(0));
		localStorage.setItem('isRefresh', JSON.stringify(0));
	}


	// ! --- Vishesh --- Cancel Order ----------------------
	cancelOrderLoader: boolean = true;
	selectOrderForCancel: any = {};
	cancelOrderPopUp: any = {
		display: false,
		heading: '',
		message: '',
		confirmButton: false,
		closeButton: true,
		closeMsgButton: true,
		img: ''
	};
	submitOrderLoader: boolean = false;
	otherReasonText: string = "";

	// cancelReasons = [
	// 	{ text: 'The ride is no longer required', checked: true },
	// 	{ text: 'The chauffeur is far from the pickup location', checked: false },
	// 	{ text: 'The customer already left', checked: false },
	// 	{ text: 'Duplicate Booking', checked: false },
	// 	{ text: 'Corporate Ride Cancelled', checked: false },
	// 	{ text: 'The customer changed their mind/plans', checked: false },
	// 	{ text: 'Pick up location wrong', checked: false },
	// 	{ text: 'System error', checked: false },
	// 	{ text: 'Others', checked: false }
	// ].map(reason => ({ ...reason, id: reason.text.replace(/\s+/g, '').toLowerCase() }));

	// cancelReasons = [
	// 	{ text: 'Traffic Congestion', checked: true },
	// 	{ text: 'Accident', checked: false },
	// 	{ text: 'Vehicle breakdown', checked: false },
	// 	{ text: 'Location issue', checked: false },
	// 	{ text: 'Vendor preparation delay', checked: false },
	// 	{ text: 'Customer Unavailable', checked: false },
	// 	{ text: 'Wrong address', checked: false },
	// 	{ text: 'High order flow', checked: false },
	// 	{ text: 'Others', checked: false }
	// ].map(reason => ({ ...reason, id: reason.text.replace(/\s+/g, '').toLowerCase() }));

	cancelReasons: any[] = []

	cancelOrderOpenPopup(bookingId: string, dBookId: string, status?: number) {

		console.log('****** dBookId ******', dBookId);
		console.log("****** dBookId.startsWith('REST') ******", dBookId.startsWith('REST'));

		if (dBookId.startsWith('REST')) {
			this.cancelReasons = [
				{ text: 'Traffic Congestion', checked: true },
				{ text: 'Accident', checked: false },
				{ text: 'Vehicle breakdown', checked: false },
				{ text: 'Location issue', checked: false },
				{ text: 'Vendor preparation delay', checked: false },
				{ text: 'Customer Unavailable', checked: false },
				{ text: 'Wrong address', checked: false },
				{ text: 'High order flow', checked: false },
				{ text: 'Others', checked: false }
			].map(reason => ({ ...reason, id: reason.text.replace(/\s+/g, '').toLowerCase() }));
		}
		else {
			this.cancelReasons = [
				{ text: 'The ride is no longer required', checked: true },
				{ text: 'The chauffeur is far from the pickup location', checked: false },
				{ text: 'The customer already left', checked: false },
				{ text: 'Duplicate Booking', checked: false },
				{ text: 'Corporate Ride Cancelled', checked: false },
				{ text: 'The customer changed their mind/plans', checked: false },
				{ text: 'Pick up location wrong', checked: false },
				{ text: 'System error', checked: false },
				{ text: 'Others', checked: false }
			].map(reason => ({ ...reason, id: reason.text.replace(/\s+/g, '').toLowerCase() }));
		}

		this.cancelOrderPopUp = {
			display: 'block',
			heading: '#' + dBookId,
			message: 'Are you sure that you want to Cancel this order? ',
			confirmButton: true,
			closeButton: true,
			closeMsgButton: true,
			img: ''
		};
		this.selectOrderForCancel.bookingId = bookingId;
		this.selectOrderForCancel.dBookId = dBookId;
		this.selectOrderForCancel.status = status;
		this.resetCancelReasons(dBookId);
		this.otherReasonText = "";
	}

	cancelOrderClosePopup() {
		this.cancelOrderPopUp = {
			display: false,
			heading: '',
			message: '',
			confirmButton: false,
			closeButton: true,
			closeMsgButton: true,
			img: ''
		};
		this.selectedBookingId = "";
		this.selectedDBookId = "";
	}

	resetCancelReasons(dBookId: string) {
		if (dBookId.startsWith('REST')) {
			this.cancelReasons.forEach(reason => reason.checked = reason.text === 'Traffic Congestion');
		}
		else {
			this.cancelReasons.forEach(reason => reason.checked = reason.text === 'The ride is no longer required');
		}
	}

	handleReasonChange(event: Event) {
		const target = event.target as HTMLInputElement;
		this.cancelReasons.forEach(reason => reason.checked = reason.id === target.id);
	}

	isOthersSelected(): boolean {
		return this.cancelReasons.find(reason => reason.id === 'others')?.checked ?? false;
	}

	submitCancelOrder() {
		const selectedReason = this.cancelReasons.find(reason => reason.checked)!;
		const reasonText = selectedReason.text === 'Others' ? this.otherReasonText : selectedReason.text;

		if (selectedReason.text === 'Others' && (!this.otherReasonText || this.otherReasonText.trim() === '')) {
			alert("Please specify the reason for cancellation.");
			return;
		}

		console.log("Selected reason:", reasonText);

		let requestObj: any = {
			dispatcherId: this.Global.dispatcherDetails.dispatcherId,
			bookingId: this.selectOrderForCancel.bookingId,
			cancelBy: "dispatcher",
			// cancelType: (this.selectOrderForCancel.status == 7 || this.selectOrderForCancel.status == 8) ? 1 : 0,
			cancelType: 0,
			reason: reasonText
		};
		this.submitOrderLoader = true;
		this.CallMethod.cancelBooking(requestObj).subscribe((data: any) => {
			console.log("===== cancel data :: ", data);

			if (data.status) {
				this.setCancelOrderReason(requestObj.bookingId, requestObj.reason)
				this.Popup.toasterMessage({
					'status': 'success',
					'title': `Cancel Order/Booking!`,
					'message': 'Order/Booking has been cancelled successfully !!!',
				});
				this.cancelOrderClosePopup();
			}
			else {
				this.Popup.toasterMessage({
					'status': 'error',
					'title': `Something went wrong!!!`,
					'message': 'Cancel Order Reason',
				});
			}
			this.submitOrderLoader = false;
		});
	}

	setCancelOrderReason(orderId: string, cancelReason: string) {
		let requestObj = {
			bookingId: orderId,
			reason: cancelReason
		}
		this.CallMethod.setCancellationReason(requestObj).subscribe((data: any) => {
			if (data.status) {

			}
			else {
				this.Popup.toasterMessage({
					'status': 'error',
					'title': `Something went wrong!!!`,
					'message': 'Cancel Reason',
				});
			}
		});
	}
}




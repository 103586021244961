import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Md5 } from "ts-md5";
import { GlobalService } from "../services/global.service";
import { PopupService } from "../services/popup.service";
import { environment } from "src/environments/environment";

@Injectable()
export class HttpconfigInterceptor implements HttpInterceptor {
	sessionService: any;
	constructor(
		public router: Router,
		public popup: PopupService,
		public global: GlobalService,
	) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		if (request.headers.has('X-Skip-Interceptor')) {
			return next.handle(request);
		}
		let nonAuthUrl = [
			'company/details/get',
			'dispatcher/login',
			'corporate/driver/switchs'
		]

		let authorization = "";

		if (nonAuthUrl.includes(request.url)) {
			let salt = Math.floor(1000 + Math.random() * 9000);
			let test = "" + salt + environment.ForceApiKey;
			authorization = "" + Md5.hashAsciiStr(test);
			request.body.salt = salt;
			request.body.dispatcherId = this.global.dispatcherDetails.dispatcherId
		}
		else {
			authorization = this.global.getLocalStorage({ 'key': 'JwtToken' });
			if (!authorization || authorization == null) {
				this.popup.toasterMessage({
					'status': 'error',
					'title': 'Oops!',
					'message': 'JwtToken is missing',
				});
			}

			let dispatcherId = this.global.getLocalStorage({ 'key': 'dispatcherId' });
			if (!dispatcherId || dispatcherId == null || dispatcherId == '') {
				this.popup.toasterMessage({
					'status': 'error',
					'title': 'Oops!',
					'message': 'Dispatcher id is missing',
				});
			}
			else {
				request.body.dispatcherId = dispatcherId;
			}
		}

		let companyId = this.global.getLocalStorage({ 'key': 'companyId' });
		if (companyId) {
			request.body.companyId = companyId;
		}

		request.body.type = environment.type;
		request.body.role = environment.role;

		request = request.clone({
			headers: request.headers.set("authorization", "" + authorization),
		});

		request = request.clone({
			headers: request.headers.set("Content-Type", "application/json"),
		});

		request = request.clone({
			headers: request.headers.set("Access-Control-Allow-Origin", "*"),
		});

		request = request.clone({
			headers: request.headers.set(
				"Access-Control-Allow-Methods",
				"GET,HEAD,OPTIONS,POST,PUT"
			),
		});

		request = request.clone({
			headers: request.headers.set(
				"Access-Control-Allow-Headers",
				"Origin, X-Requested-With, Content-Type, Accept, Authorization,access-control-allow-origin"
			),
		});

		// request = request.clone({
		// 	setHeaders: {
		// 		'Accept-Encoding': 'gzip, deflate',
		// 	},
		// });

		request = request.clone({
			url: environment.baseUrl + request.url,
		});

		return next.handle(request).pipe(map((event: HttpEvent<any>) => {
			if (event instanceof HttpResponse) {

				if (event.body.status === false) {
					if (event.body.statusCode == 0 || event.body.statusCode == -1) {
						this.popup.toasterMessage({
							'status': 'error',
							'title': 'Oops!',
							'message': 'Something went wrong',
						});
					} else if (event.body.statusCode == -3) {
						this.popup.toasterMessage({
							'status': 'error',
							'title': 'InActive!',
							'message': 'Company InActive',
						});
					}
				}
			}
			return event;
		}),
			catchError((error: HttpErrorResponse) => {
				let data = {};
				data = {
					reason: error && error.error.reason ? error.error.reason : "",
					status: error.status,
				};
				if (error.status == 403) {
					this.popup.toasterMessage({
						'status': 'error',
						'title': 'Authorization Fail',
						'message': 'Authorization is required, Please try again',
					});
					localStorage.clear();
					this.router.navigateByUrl('sign-in');
					window.location.reload();
				}
				return throwError(error);
			})
		);
	}
}

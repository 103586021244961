<div *ngIf="chatData.sendBy === 0">
  <div class="flex mt-4">
    <div class="flex align-items-end mr-1">
      <div class="mx-3">
        <div class="highStringName">
          {{ userName }}
        </div>
      </div>
    </div>
    <div class="user_chatBox" *ngIf="chatData.messageType == 0">
      <p>{{ chatData.message }}</p>
      <span class="flex justify-content-end h5">{{ sendTime }}</span>
    </div>
    <div class="user_chatBox my-2" *ngIf="chatData.messageType == 2">
      <div>
        <a [href]="locationUrl" target="_blank" class="location"
          ><i class="bx bxs-map mr-2 h2 text-danger"></i> See this location
        </a>
      </div>
      <span class="flex justify-content-end text-light h5 mx-2 mt-1">{{
        sendTime
      }}</span>
    </div>

    <div
      class="my-2"
      *ngIf="chatData.messageType == 1"
      style="padding: 0 !important"
    >
      <!-- <img [src]="imageUrl" alt="Image" height="250" width="250">
      <div class="row mt-2 d-flex justify-content-end">
      </div> -->
      <!-- Image Preview -->
      <img
        id="myImg"
        [src]="imageUrl"
        (click)="imageClicked($event)"
        width="400"
        height=""
      />

      <!-- The Modal -->
      <div id="myModal" class="modal" style="z-index: 1000 !important">
        <span (click)="closeImagePreview()" class="close">&times;</span>
        <img class="modal-content" id="img01" />
      </div>
    </div>
    <div
      class="userBookingBox"
      *ngIf="chatData.messageType == 3 && bookingUserData"
    >
      <div>
        <div class="flex justify-content-between my-2">
          <div class="mr-2 h4">
            <i class="bx bxs-user h3 mr-1"></i>
            {{
              bookingUserData.userJson[0].passengerName
                ? bookingUserData.userJson[0].passengerName
                : "No Passenger Name"
            }}
          </div>
          <div class="h4">
            <i class="bx h3 bxs-phone mr-1"></i>
            {{
              bookingUserData.userJson[0].passengerPhone
                ? bookingUserData.userJson[0].passengerPhone
                : "No Passenger Phone No"
            }}
          </div>
        </div>
      </div>
      <div class="h4 mb-2">
        {{
          bookingUserData.userJson[0].passengerName
            ? bookingUserData.userJson[0].passengerName
            : "No Passenger Name"
        }}
        has requested {{ bookingType }} booking from
      </div>
      <div
        class="flex mb-2"
        *ngFor="let location of bookingUserData.location; let i = index"
      >
        <div>
          <i
            class="bx bx-current-location mr-2 text-success"
            *ngIf="i == 0"
          ></i>
          <i
            class="bx bx-current-location mr-2 text-light"
            *ngIf="i == 1 && bookingUserData.location.length == 4"
          ></i>
          <i
            class="bx bx-current-location mr-2 text-light"
            *ngIf="
              bookingUserData.location.length == 3 &&
              bookingUserData.location.length == 3
                ? i == 1
                : i == 2
            "
          ></i>
          <i
            class="bx bx-current-location mr-2 text-danger"
            *ngIf="
              (bookingUserData.location.length == 4 && i == 3) ||
              (bookingUserData.location.length == 2 && i == 1) ||
              (bookingUserData.location.length == 3 && i == 2)
            "
          ></i>
        </div>
        <div>
          {{ location.address }}
        </div>
      </div>

      <div class="bookingButton">
        <button
          class="btn"
          [ngClass]="chatData.isBookingCreated == 1 ? 'disabled' : ''"
          [disabled]="chatData.isBookingCreated == 1"
          (click)="createBookingForNow($event, chatData.chatId)"
        >
          {{ chatData.isBookingCreated == 1 ? "Booked" : "Book Now" }}
        </button>
      </div>
      <span class="flex justify-content-end h5">{{ sendTime }}</span>
    </div>

    <div
      class="userBookingBox flex align-items-center justify-content-between"
      style="width: 30%"
      *ngIf="chatData.messageType == 3 && !bookingUserData"
    >
      <div class="flex align-items-center justify-content-center">
        <div class="h3 mb-0 flex align-items-center">
          Oops Something went wrong!
          <i class="bx bx-x-circle ml-3" style="color: #fd0303"></i>
        </div>
      </div>
      <div>
        <span class="flex justify-content-end h5 mb-">{{ sendTime }}</span>
      </div>
    </div>
  </div>
</div>

<div *ngIf="chatData.sendBy === 1 && chatData.messageType === 0">
  <div class="flex mt-4 justify-content-end">
    <div *ngIf="!chatData.isSend" class="flex align-items-center mr-2 h2">
      <i class="bx bx-x-circle" style="color: #fd0303"></i>
    </div>
    <div class="dispacher_chatBox">
      <p>{{ chatData.message }}</p>
      <span class="flex justify-content-end h5">{{ sendTime }}</span>
    </div>
    <div class="flex align-items-end mr-1">
      <div class="mx-3">
        <div class="lessStringName">
          {{ userName }}
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="chatData.sendBy === 1 && chatData.messageType === 1">
  <div class="flex mt-4 justify-content-end">
    <div *ngIf="!chatData.isSend" class="flex align-items-center mr-2 h2">
      <i class="bx bx-x-circle" style="color: #fd0303"></i>
    </div>
    <div class="my-2 dispacher_chatBox" style="padding: 0 !important">
      <!-- Image Preview -->
      <img
        id="myImg"
        [src]="imageUrl"
        (click)="imageClicked($event)"
        width="400"
        height=""
      />

      <!-- The Modal -->
      <div id="myModal" class="modal" style="z-index: 1000 !important">
        <span (click)="closeImagePreview()" class="close">&times;</span>
        <img class="modal-content" id="img01" />
      </div>
    </div>
    <div class="flex align-items-end mr-1">
      <div class="mx-3">
        <div class="lessStringName">
          {{ userName }}
        </div>
      </div>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpCacheService } from '../services/http-cache.service';


@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  constructor(private cache: HttpCacheService) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Check if request is cacheable (e.g., GET request)
    if (req.method !== 'GET') {
      return next.handle(req);
    }

    const cachedResponse = this.cache.get(req.url);

    if (cachedResponse) {
      // Return cached response as observable
      return new Observable(observer => {
        observer.next(cachedResponse);
        observer.complete();
      });
    }

    // Continue with the request
    return next.handle(req).pipe(
      tap(event => {
        // Cache the response
        if (event instanceof HttpResponse) {
          this.cache.set(req.url, event);
        }
      })
    );
  }
}

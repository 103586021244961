import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';

import { ModalComponent } from 'ng-modal-lib';
import { LocaleConfig } from 'ngx-daterangepicker-material';
import { of, Subject, Subscription } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, map, mergeMap, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CallmethodService } from '../services/callmethod.service';
import { DataShareService } from '../services/data-share.service';
import { GlobalService } from '../services/global.service';

@Component({
  selector: 'app-close-job',
  templateUrl: './close-job.component.html',
  styleUrls: ['./close-job.component.scss']
})

export class CloseJobComponent implements OnInit {
  environment: any = environment;
  selectedDateRange: any;
  dateFormat:string = 'YYYY/MM/DD'
  alwaysShowCalendars: boolean;
  maxDate: any;
  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }
  
  private subscription: Subscription = new Subscription;
	private unsubscribe$ = new Subject<void>();


  constructor(
    public CallMethod: CallmethodService,
    public shareData: DataShareService,
    public Global: GlobalService
  ) {
    this.alwaysShowCalendars = true;
    this.subscription = this.searchJob.pipe(
      map((event: any) => event.target.value),
      debounceTime(300),
      distinctUntilChanged(),
      mergeMap((search: any) => of(search).pipe(delay(0))),
    ).subscribe((data: any) => {
      this.searchValue = data;
      this.getClosedJob(0);
    });
    
  }
  ngOnInit(): void {
    this.subscription = this.shareData.getData().subscribe(data => {
      if (data) {
        if (data.function == 'closeJob') {
          this.closeJobModalShow();
        }
        else if (data.function == 'changeThemeColor') {
          this.closeJobDetailsModal.modalHeader.nativeElement.style.backgroundColor = this.Global.first_color;
          this.closeJobDetailsModal.modalFooter.nativeElement.style.backgroundColor = this.Global.third_color;
          this.closeJobDetailsModal.modalRoot.nativeElement.style.border = '1px solid '+ this.Global.first_color; 
        } else if (data.function == 'GlobalDashboardCalled'){
          let format = this.Global.dateFormat.split(' ')[0];
          this.dateFormat = format;
          this.maxDate = moment().tz(this.Global.timeZone);
        }
      }
    });
  }

  ngOnDestroy(): void {
		this.subscription.unsubscribe();
    this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

  @ViewChild("closeJobDetails") public closeJobDetailsModal!: ModalComponent;
  @ViewChild("searchJobs") searchJobs!: ElementRef;

  showCloseJobModel: boolean = false;
  moment: any = moment;
  setStyle: boolean = false;
  userSearchShimmer: boolean = false;
  searchValue: string = '';
  closeJobModalShow(searchValue: string = '') {
    this.closeJobDetailsModal.modalRoot.nativeElement.style.zIndex = this.closeJobDetailsModal.getMaxModalIndex() + 2;
    if (this.showCloseJobModel == false) {
      setTimeout(() => {
        this.closeJobDetailsModal.modalRoot.nativeElement.style.top = environment.windowPopupStyle.closeJob.top;
      }, 0);

      if (this.closeJobDetailsModal.modalBody.nativeElement.style.height.trim() == '') {
        this.closeJobDetailsModal.modalBody.nativeElement.style.height = environment.windowPopupStyle.closeJob.height;
        let width = parseInt(environment.windowPopupStyle.closeJob.width.replace('px', ''));
        if(window.innerWidth < parseInt(environment.windowPopupStyle.closeJob.width.replace('px', ''))){
          width = window.innerWidth - 2;
        }
        this.closeJobDetailsModal.modalRoot.nativeElement.style.width = width.toString() + 'px'; 
      }

      this.closeJobDetailsModal.modalHeader.nativeElement.style.backgroundColor = this.Global.first_color;
      this.closeJobDetailsModal.modalFooter.nativeElement.style.backgroundColor = this.Global.third_color;
      this.closeJobDetailsModal.modalRoot.nativeElement.style.border = '1px solid '+ this.Global.first_color; 

      this.closeJobDetailsModal.show();
      this.showCloseJobModel = true;
    }

    if (searchValue != '') {
      this.searchValue = searchValue;
      this.searchJobs.nativeElement.value = searchValue;
    }
    this.getClosedJob(0);
  }

  closedJobList: any = [];
  jobCurrentpage: number = 0;
  closedJobCount: number = 0;
  closedJobLimit: number = 10;
  closedJobShimmer: boolean = false;

  getClosedJob(page: any = 0) {
    this.closedJobShimmer = true;
    this.jobCurrentpage = page;
    let closedJobOffset = (this.jobCurrentpage == 0) ? 0 : ((this.jobCurrentpage * this.closedJobLimit) - this.closedJobLimit);

    let requestObj = {
      offset: closedJobOffset,
      limit: this.closedJobLimit,
      search: this.searchValue,
      searchBy: this.selectedCloseJobSearchBy,
      status: this.selectedCloseJobSearchFilter,
      startDate: this.closedJobSearchStartDate,
      endDate: this.closedJobSearchEndDate
    }

    if (this.searchValue != "") {
      this.filterArray.search = this.searchValue;
      this.filterArray.searchBy = this.selectedCloseJobSearchBy;
    }

    this.CallMethod.getCloseJob(requestObj).pipe(takeUntil(this.unsubscribe$)).subscribe((result: any) => {
      this.closedJobList = [];
      if (result.status) {
        this.closedJobList = result.data;
        this.closedJobCount = result.totalRecords;
      }

      this.closedJobShimmer = false;
    });
  }

  closeJobModel() {
    this.closedJobSearchStartDate = "";
    this.closedJobSearchEndDate = "";

    this.showCloseJobModel = false;
    this.setStyle = false;
    this.filterArray = {};
  }

  public searchJob = new Subject<KeyboardEvent>();
  searchClosedJob(event: any) {
    if (event.target.value.trim() != '') {
      this.searchJob.next(event);
    } else if (event.target.value == "") {
      this.searchValue = '';
      delete this.filterArray.search;
      delete this.filterArray.searchBy;
    }
  }

  selectedCloseJobSearchBy: string = "all";
  closeJobSearchBy(event: any) {
    this.selectedCloseJobSearchBy = event.target.value;

    if (this.selectedCloseJobSearchBy != 'all') {
      this.filterArray.searchBy = this.selectedCloseJobSearchBy;
    }
    this.getClosedJob(0);
  }
  selectedCloseJobSearchFilter: string = "all";
  closeJobSearchFilter(event: any) {
    this.selectedCloseJobSearchFilter = event.target.value;
    this.filterArray.status = this.selectedCloseJobSearchFilter;
    this.getClosedJob(0);
  }

  closedJobSearchStartDate: string = "";
  closedJobSearchEndDate: string = "";

  filterArray: any = {}
  showError: boolean = false;
  newRideDateChange(event:any) {
    this.showError = false;
    let startDate = moment(event.startDate).format('YYYY-MM-DD 00:00:00');
    let endDate = moment(event.endDate).format('YYYY-MM-DD 23:59:59');
    if (moment(startDate, 'YYYY-MM-DD HH:mm:ss').isValid() && moment(endDate, 'YYYY-MM-DD 23:59:59').isValid()) {
      this.closedJobSearchStartDate = startDate;
      this.closedJobSearchEndDate = endDate;
      this.filterArray.Date = this.closedJobSearchStartDate + ' -to- ' + this.closedJobSearchEndDate;
      this.getClosedJob(0);
    }
    else {
      this.showError = true;
    }
  }

  setBookingDetails(bookingData: any) {
    this.shareData.shareData({ 'function': 'openBookingDetailsByCloseJob', 'data': bookingData });
    if (this.setStyle == false) {
      setTimeout(() => {
        let left:any = parseInt("" + window.innerWidth) - parseInt(this.closeJobDetailsModal.modalRoot.nativeElement.style.width.replace('px', '')) - parseInt(environment.windowPopupStyle.bookingDetails.width.replace('px', '')) - 4;
        
        if(left < 0){
          let width = parseInt(this.closeJobDetailsModal.modalRoot.nativeElement.style.width.replace('px', '')) + left;
          width -= 2; 
          this.closeJobDetailsModal.modalRoot.nativeElement.style.width = width.toString() + 'px';
          left = 2;
        }
        
        this.closeJobDetailsModal.modalRoot.nativeElement.style.left = left.toString() + 'px';
        this.closeJobDetailsModal.modalBody.nativeElement.style.height = environment.windowPopupStyle.bookingDetails.height;
      }, 0);
      this.setStyle = true;
    }
  }

  copyBooking(bookingData: any) {
    this.shareData.shareData({ 'function': 'copyBookingDataByCloseJob', 'data': bookingData });
  }

  removeFilter(key: any) {
    if (key == 'search') {
      this.searchValue = ""
    }
    else if (key == 'Date') {
      this.closedJobSearchStartDate = "";
      this.closedJobSearchEndDate = "";
      this.selectedDateRange = "";
    }
    else if (key == 'searchBy') {
      this.selectedCloseJobSearchBy = "all"
      this.searchValue = "";
      // delete this.filterArray.search
    }
    else if (key == 'status') {
      this.selectedCloseJobSearchFilter = "all"
    }
    delete this.filterArray[key]
    this.getClosedJob(0);
  }

  isShowFilterBar: boolean = true;
  showHideFilter() {
    if (this.isShowFilterBar == true) {
      this.isShowFilterBar = false;
    }
    else {
      this.isShowFilterBar = true;
    }
  }
}

<router-outlet></router-outlet>

<!-- alert  audio-->
<audio id="sosAndRejectBookingAlert">
	<source src="./assets/music/reject-booking-and-sos.mp3" type="audio/mpeg">
</audio>

<!-- Booking warning alert  audio-->
<audio id="assignBookingRemainderAlert">
	<source src="./assets/music/assign-booking-remainder-alert.mp3" type="audio/mpeg">
</audio>
<!--sticky audio-->
<audio id="stickyAudioPlay">
	<source src="./assets/music/sticky_alert.ogg" type="audio/mpeg">
</audio>

<!-- chat audio -->
<audio id="chatAudioPlay">
	<source src="./assets/music/chat_notification.ogg" type="audio/mpeg">
</audio>

<!-- driver alert audio -->
<audio id="driverAlertAudio-offline">
	<source src="./assets/music/driver-offline.mp3" type="audio/mpeg">
</audio>
<audio id="driverAlertAudio-online">
	<source src="./assets/music/driver-online.mp3" type="audio/mpeg">
</audio>
<audio id="driverAlertAudio-offline-warning">
	<source src="./assets/music/offline-warning.ogg" type="audio/mpeg">
</audio>

<!-- alert  audio-->
<audio id="rejectBookingNotification">
	<source src="./assets/music/reject-booking-and-sos.mp3" type="audio/mpeg">
</audio>

<audio id="newCreateOrderNotification">
	<source src="./assets/music/accept-order.ogg" type="audio/mpeg">
</audio>

<audio id="acceptOrderNotification">
	<source src="../assets/music/disptcher_accept_order.mp3" type="audio/mpeg">
</audio>


<!-- Offline Driver Request Received  notify  audio-->
<audio id="offlineDriverRequestReceived">
	<source src="../assets/music/offlineRequest.wav" type="audio/mpeg">
</audio>

<!-- International Booking Alert notify  audio-->
<audio id="InternationalBookingAlert">
	<source src="../assets/music/international-noti.wav" type="audio/mpeg">
</audio>

<!-- <div [hidden]="fullPageLoader" id="appComponant" class="container">
	<div class="row">
		<div id="loader">
			<div class="dot"></div>
			<div class="dot"></div>
			<div class="dot"></div>
			<div class="dot"></div>
			<div class="dot"></div>
			<div class="dot"></div>
			<div class="dot"></div>
			<div class="dot"></div>
			<div class="loading"></div>
		</div>
	</div>
</div> -->
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { IMqttMessage } from 'ngx-mqtt';
import { Subscription } from 'rxjs';
import { CallmethodService } from '../services/callmethod.service';
import { DataShareService } from '../services/data-share.service';
import { EventMqttService } from '../services/event-mqtt.service';
import { GlobalService } from '../services/global.service';

@Component({
  selector: 'app-user-chat',
  templateUrl: './user-chat.component.html',
  styleUrls: ['./user-chat.component.scss']
})
export class UserChatComponent implements OnInit {
  public subscriptionDispatcher: Subscription = new Subscription;
  @ViewChild('scrollMe') public myScrollContainer!: ElementRef;
  chatDataArray: any = []

  constructor(
    public CallMethod: CallmethodService,
    public shareData: DataShareService,
    public Global: GlobalService,
    public mqtt: EventMqttService,
  ) {
    this.Global.dashboard().then((result: any) => {
      if (result.status) {
        this.getAllUserChat()
      }
    })
  }

  ngOnInit(): void {
    this.subscriptionDispatcher = this.mqtt.topic("userChat_" + this.Global.companyDetails.companyId).subscribe((data: IMqttMessage) => {
      try {
        let item = JSON.parse(data.payload.toString());
        this.eventHandler(item);
      } catch (error) {
        console.log(':::::::::::::::::::::::::: error ::::::::::::::::::::::::::', error);
      }
    });
  }
  isCount:number = 1
  eventHandler(opts: any) {
    console.log('::::::::::::::::::user Chat eventHandler:::::::: opts ::::::::::::::::::::::::::', opts);
    if (opts.statusFlag == 302) {
      this.shareData.shareData({ function: 'userSeenMessageMqtt', type: 'userChatView', data: opts })
    }
    else if (opts.statusFlag == 303) {
      console.log('***** createdBookingSuccessfully *****', opts);
      this.shareData.shareData({ function:"createdBookingSuccessfully", type:"chatBubble", data: opts });
      console.log(opts);
    
      if (opts.isBookingCreated) {
        console.log("Booking done", opts);
        this.shareData.shareData({ function: 'bookingCreatedSuccessfully', type: 'userChatView', data: opts})
      }

    }
    else {
      const index = this.chatDataArray.findIndex((item: any) => item.user.userId == opts.userId);
      if (opts.isNewUser) {
        let res = {
          user: opts.user,
          chats: opts.chats,
          isNewUser: true,
          isCount: 1
        }
        this.chatDataArray.unshift(res)
        this.shareData.shareData({ function: 'newUserMessageToDispatcher', type: 'userChatView', data: this.chatDataArray })
      }
      else {
        if (opts.chats) {
          const i = this.chatDataArray.findIndex((item: any) => item.user.userId == opts.user.userId);
          let tempData = this.chatDataArray.splice(i, 1);
          this.chatDataArray.unshift(tempData[0])
          this.shareData.shareData({ function: 'messageSendAndRecive', type: 'userChatView', data: opts.chats[0], isCount: 1 })
        } else {
          const index = this.chatDataArray.findIndex((item: any) => item.user.userId == opts.userId);
          if (index > -1) {
            let data = this.chatDataArray[index]
            this.chatDataArray.splice(index, 1);
            this.chatDataArray.unshift(data);
          }
            if (opts.dispatcherId == this.Global.dispatcherDetails.dispatcherId) {
              this.isCount = 0
            }
          this.shareData.shareData({ function: 'messageSendAndRecive', type: 'userChatView', data: opts, isCount:this.isCount })
        }
      }

      if (opts.isBookingCreated) {
        console.log("Booking done", opts);
        this.shareData.shareData({ function: 'bookingCreatedSuccessfully', type: 'userChatView', data: opts})
      }
    }
  }

  getAllUserChat() {
    let reqObj = {
      limit: 2000,
      offset: 0
    }
    this.CallMethod.getAllUserChat(reqObj).subscribe((result: any) => {
      if (result.status) {
        result.data.forEach((response: any) => {
          this.chatDataArray.push(response)
        })
        this.chatDataArray.forEach((chatData: any) => {
          chatData.chats.sort((a: any, b: any) => {
            const dateA = new Date(a.createdAt);
            const dateB = new Date(b.createdAt);
            return dateA.getTime() - dateB.getTime();
          })
        })
        this.shareData.shareData({ function: 'getAllUserAndChat', type: 'userChatView', data: this.chatDataArray })
      }
    });
  }
}


// export const MY_DATE_FORMATS = {
//     parse: {
//       dateInput: 'DD/MM/YYYY',
//     },
//     display: {
//       dateInput: 'MMM DD, YYYY',
//       monthYearLabel: 'MMMM YYYY',
//       dateA11yLabel: 'LL',
//       monthYearA11yLabel: 'MMMM YYYY'
//     },
// }; 

import { MatDateFormats } from "@angular/material/core";

export const MY_DATE_FORMATS: MatDateFormats = {
    parse: {
        dateInput: 'input'
    },
    display: {
        // dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
        dateInput: 'input',
        monthYearLabel: { year: 'numeric', month: 'short' },
        dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
        monthYearA11yLabel: { year: 'numeric', month: 'long' },
    }
 };
import { Injectable } from "@angular/core";
import { HttpClient, HttpBackend } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class CloudinaryUploadService {
  private http: HttpClient;

  constructor(private handler: HttpBackend) {
    this.http = new HttpClient(handler);
  }

  uploadFile(file: File): Observable<any> {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", environment.cloudinaryPresetName);
    return this.http.post(
      environment.cloudinaryBaseUrl,
      formData
    );
  }
}

<div>
  <p-toolbar>
    <div class="p-toolbar-group-start">
      <div class="filter_ios_btn_switch">
        <input id="switch" type="checkbox" (change)="timeDateFilter($event)" />
        <div class="app">
          <div class="body">
            <div class="phone">
              <div class="content">
                <label for="switch">
                  <div class="toggle"></div>
                  <div class="names">
                    <p class="light fontMediumBold">Assign Time</p>
                    <p class="dark fontMediumBold">Pickup Time</p>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="p-toolbar-group-end" style="display: flex;
    align-items: center;
    gap: 16px;">
      <div class="custom_refresh_btn">
        <button class="cssbuttons-io" [disabled]="disableBtn" (click)="reloadPage()">
          <span>
            <i  class="bx bxs-analyse bx-spin mr-1"></i>
            Refresh</span>
        </button>
      </div>
      <div class="custom_refresh_btn">
        <button class="cssbuttons-io" [disabled]="disableBtn" (click)="onExportClick()">
          <span>
            <i class='bx bx-file  mr-1' ></i>
            Export</span>
        </button>
      </div>
      <div>
        <p-button icon="pi pi-times" style="font-size: 1rem;" (click)="closeWindow()"
          styleClass="p-button-danger"></p-button>
      </div>
    </div>
  </p-toolbar>
  <div class="control-section">
    <div class="content-wrapper">
      <ejs-schedule #scheduleObj width="100%" height="95vh" [readonly]="true" [selectedDate]="selectedDate"
        [eventSettings]="eventSettings" cssClass="schedule-cell-dimension" [enablePersistence]="false"
        [workDays]="workDays" [timeScale]="timeScale" (created)="onCreated()"
        (popupOpen)="onPopupOpen($event)" [rowAutoHeight]="false"
        (actionComplete)="onActionComplete($event)" [(currentView)]="currentView"
        (eventRendered)="onEventRendered($event)"  [minDate]='minDate' [maxDate]='maxDate'>
        <!-- (eventRendered)="onEventRendered($event)" -->
        <e-resources>
          <e-resource field="eventType" title="Booking Type" name="eventType" textField="eventType" idField="eventType"
            colorField="Color" [dataSource]="bookingStatusData">
          </e-resource>
        </e-resources>
        <e-views>
          <e-view option="TimelineDay"></e-view>
          <!-- <e-view option="Week"></e-view> -->
          <!-- <e-view option="TimelineMonth"></e-view> -->
          <e-view option="TimelineWeek"></e-view>
          <e-view option="Month"></e-view>
          <e-view option="MonthAgenda"></e-view>
          <e-view option="Agenda"></e-view>
        </e-views>

        <!-- <ng-template #timeScaleMajorSlotTemplate let-data>
          <div class="majorTime">{{getMajorTime(data.date)}}</div>
        </ng-template>
        <ng-template #timeScaleMinorSlotTemplate let-data>
          <div class="minorTime" style="text-align: center">{{getMinorTime(data.date)}}</div>
        </ng-template> -->

        <!-- Header template -->
        <ng-template #quickInfoTemplatesHeader let-data>
          <div class="quick-info-header">
            <div class="quick-info-header-content" [ngStyle]="getHeaderStyles(data)">
              <div class="quick-info-title fontMediumBold">
                {{ getHeaderTitle(data) }}
              </div>
              <div class="duration-text fontMedium">
                {{ getHeaderDetails(data) }}
              </div>
              <div class="duration-text fontMedium">
                {{
                "Assign Time :" +
                this.instance.formatDate(data.assignTime, {
                skeleton: "hm"
                })
                }}
              </div>
            </div>
          </div>
        </ng-template>
        <!-- Content Template -->
        <ng-template #quickInfoTemplatesContent let-data>
          <ng-container [ngTemplateOutlet]="
              data.elementType == 'cell' ? cellContent : eventContent
            " [ngTemplateOutletContext]="{ data: data }"></ng-container>
        </ng-template>

        <ng-template #eventContent let-data="data">
          <div class="quick-info-content">
            <div class="event-content">
              <div class="meeting-type-wrap">
                <label class="fontMediumBold mr-1">Pick up Location</label>:
                <span class="fontMedium">{{ data.Subject }}</span>
              </div>
              <div class="meeting-type-wrap">
                <label class="fontMediumBold mr-1">Drop Location</label>:
                <span class="fontMedium">{{ data.Location }}</span>
              </div>
              <div class="meeting-subject-wrap">
                <label class="fontMediumBold mr-1">Status</label>:
                <span class="fontMedium">{{ getEventType(data) }}</span>
              </div>
              <div class="meeting-subject-wrap">
                <label class="fontMediumBold mr-1">Booking Type</label>:
                <span class="fontMedium">{{
                  data.Type == 0 ? "Now" : "Later"
                  }}</span>
              </div>
              <div class="meeting-subject-wrap">
                <label class="fontMediumBold mr-1">Booking Flag</label>:
                <span class="fontMedium">{{
                  data.CalendarId == 0
                  ? "City Ride"
                  : data.CalendarId == 1
                  ? "Rental"
                  : "Out Station"
                  }}</span>
              </div>
              <div class="meeting-subject-wrap" *ngIf="data.driverName">
                <label class="fontMediumBold ">Driver Name</label>:
                <span class="fontMedium custom-font-md">{{ data.driverName }}</span>
              </div>
              <div class="meeting-subject-wrap" *ngIf="data.driverPhone">
                <label class="fontMediumBold">Driver Number</label>:
                <span class="fontMedium">+{{ data.driverPhone }}</span>
              </div>
              <div class="e-event-footer driverAssign gap-5 mt-3">
                <div class="assign_btn">
                  <button class="fontMedium" (click)="assignDriver()">
                    Assign
                  </button>
                </div>
                <div class="close_btn">
                  <button class="fontMedium" (click)="closeInfoWindow()">
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
        <!-- Footer Template -->
        <ng-template #quickInfoTemplatesFooter let-data>
          <ng-container [ngTemplateOutlet]="
              data.elementType == 'cell' ? eventFooter : eventFooter
            " [ngTemplateOutletContext]="{ data: data }"></ng-container>
        </ng-template>
        <ng-template #eventFooter let-data="data"> </ng-template>
      </ejs-schedule>
    </div>
  </div>

  <div class="fontMediumBold assignModal calendar-modal">
    <p-dialog [(visible)]="driverAssignModal" [style]="{ width: '40rem' }" [baseZIndex]="10000" [draggable]="false">
      <ng-template pTemplate="header" let-item>
        <div class="flex justify-between items-center align-baseline">
          <div class="justify-start flex items-center darkfontColor fontMediumBold font-bold capitalize cursor-pointer">
            <i class="bx bx-hash text-2xl"></i> {{selectedDBookId}}
          </div>
          <div class="justify-end ml-20">
            <div class="flex justify-between flex-row gap-2 items-center">
              <div class="flex flex-col">
                <label for="all" class="btn-radio" style="align-items: center">
                  <input type="radio" id="all" class="loginThemeSwitch hidden" name="driverStatus"
                    [(ngModel)]="assignDriverFlag" (change)="changeAssignDriverList(3)" [value]="2" />
                  <svg width=".9375rem" class="mt-1" height=".9375rem" viewBox="0 0 20 20">
                    <circle cx="10" cy="10" r="9"></circle>
                    <path
                      d="M10,7 C8.34314575,7 7,8.34314575 7,10 C7,11.6568542 8.34314575,13 10,13 C11.6568542,13 13,11.6568542 13,10 C13,8.34314575 11.6568542,7 10,7 Z"
                      class="inner"></path>
                    <path
                      d="M10,1 L10,1 L10,1 C14.9705627,1 19,5.02943725 19,10 L19,10 L19,10 C19,14.9705627 14.9705627,19 10,19 L10,19 L10,19 C5.02943725,19 1,14.9705627 1,10 L1,10 L1,10 C1,5.02943725 5.02943725,1 10,1 L10,1 Z"
                      class="outer"></path>
                  </svg>
                  <div class="darkfontColor custom-font-md ml-2">All</div>
                </label>
              </div>
              <div class="flex flex-col">
                <label for="online" class="btn-radio" style="align-items: center">
                  <input type="radio" id="online" name="driverStatus" [(ngModel)]="assignDriverFlag"
                    (change)="changeAssignDriverList(1)" [value]="1" checked />
                  <svg width=".9375rem" height=".9375rem" class="mt-1" viewBox="0 0 20 20">
                    <circle cx="10" cy="10" r="9"></circle>
                    <path
                      d="M10,7 C8.34314575,7 7,8.34314575 7,10 C7,11.6568542 8.34314575,13 10,13 C11.6568542,13 13,11.6568542 13,10 C13,8.34314575 11.6568542,7 10,7 Z"
                      class="inner"></path>
                    <path
                      d="M10,1 L10,1 L10,1 C14.9705627,1 19,5.02943725 19,10 L19,10 L19,10 C19,14.9705627 14.9705627,19 10,19 L10,19 L10,19 C5.02943725,19 1,14.9705627 1,10 L1,10 L1,10 C1,5.02943725 5.02943725,1 10,1 L10,1 Z"
                      class="outer"></path>
                  </svg>
                  <div class="darkfontColor custom-font-md ml-2">Online</div>
                </label>
              </div>
              <div class="flex flex-col">
                <label for="Offline" class="btn-radio" style="align-items: center">
                  <input type="radio" id="Offline" name="driverStatus" [(ngModel)]="assignDriverFlag"
                    (change)="changeAssignDriverList(2)" [value]="0" />
                  <svg width=".9375rem" height=".9375rem" class="mt-1" viewBox="0 0 20 20">
                    <circle cx="10" cy="10" r="9"></circle>
                    <path
                      d="M10,7 C8.34314575,7 7,8.34314575 7,10 C7,11.6568542 8.34314575,13 10,13 C11.6568542,13 13,11.6568542 13,10 C13,8.34314575 11.6568542,7 10,7 Z"
                      class="inner"></path>
                    <path
                      d="M10,1 L10,1 L10,1 C14.9705627,1 19,5.02943725 19,10 L19,10 L19,10 C19,14.9705627 14.9705627,19 10,19 L10,19 L10,19 C5.02943725,19 1,14.9705627 1,10 L1,10 L1,10 C1,5.02943725 5.02943725,1 10,1 L10,1 Z"
                      class="outer"></path>
                  </svg>
                  <div class="darkfontColor custom-font-md ml-2">Offline</div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </ng-template>

      <div class="mt-3">
        Search driver
        <div class="field">
          <span class="p-float-label p-input-icon-right w-100">
            <input type="text" id="inputtext-right" class="my-2" style="width: 100%;" [(ngModel)]="driverName"
              placeholder="Search Driver" pInputText (keyup)="getDriverByName(driverName)" />
          </span>
        </div>
        <p-scrollPanel [style]="{ width: '100%', height: '310px' }" styleClass="custombar1">
          <div *ngIf="allDriverList?.length > 0 && !showDriverShimmerEffect">
            <div class="inputGroup" *ngFor="let driver of allDriverList; let j = index">
              <div class="team">
                <input id="driver_id{{ j }}" (click)="onDriverSelect(driver)" type="radio" name="radioDriver" />
                <label for="driver_id{{ j }}" class="fontMedium">
                  <div class="driverFL mr-2">
                    <span class="team_bg">
                      <i class="pi pi-users"></i>
                    </span>
                  </div>{{ driver.driverName }} <br />
                  +{{ driver.mobile }} , {{ driver.carTypeName }}
                </label>
              </div>
            </div>
          </div>
          <div style="margin-top: 7rem" *ngIf="allDriverList?.length == 0 && !showDriverShimmerEffect">
            <figure class="lead text-center">
              <i class="fa-solid fa-people-group text-3xl"></i>
              <p>No Drivers found</p>
            </figure>
          </div>
          <div class="mt-2 ml-2" *ngIf="showDriverShimmerEffect">
            <p-skeleton styleClass="mb-2" height="3.5rem"></p-skeleton>
            <p-skeleton styleClass="mb-2" height="3.5rem"></p-skeleton>
            <p-skeleton styleClass="mb-2" height="3.5rem"></p-skeleton>
            <p-skeleton styleClass="mb-2" height="3.5rem"></p-skeleton>
            <p-skeleton styleClass="mb-2" height="3.5rem"></p-skeleton>
            <p-skeleton styleClass="mb-2" height="3.5rem"></p-skeleton>
            <p-skeleton styleClass="mb-2" height="3.5rem"></p-skeleton>
            <p-skeleton styleClass="mb-2" height="3.5rem"></p-skeleton>
            <p-skeleton styleClass="mb-2" height="3.5rem"></p-skeleton>
            <p-skeleton styleClass="mb-2" height="3.5rem"></p-skeleton>
          </div>
        </p-scrollPanel>
      </div>

      <ng-template pTemplate="footer">
        <div class="driver_assign_footer">
          <button class="cssbuttons-io-button" (click)="setAssign(selectedBookingId)">
            <div class="icon">
              <svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0h24v24H0z" fill="none"></path>
                <path d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                  fill="currentColor"></path>
              </svg>
            </div>
           <span class="ml-20">Assign to driver</span> 
          </button>
        </div>
      </ng-template>
    </p-dialog>
  </div>
</div>
<!-- Modal Customer show Start -->
<app-modal #bookingDetails class="panel-booking-details" [scrollTopEnable]="false" [inViewport]="true"
    [maximizable]="true" [backdrop]="false" (closeModal)="closeBookingDetailsModel()">
    <ng-container class="app-modal-header">
        <h4 class="text-3xl self-center text-white fontMedium">
            <i class="fas fa-info-square mr-2"></i>Booking details (# {{bookingDetailObj != null &&
            bookingDetailObj.dBookId !=
            ''?bookingDetailObj.dBookId:''}})
        </h4>
    </ng-container>
    <ng-container class="app-modal-body">


        <mat-tab-group class="mat-tab-group h-full bg-color" *ngIf="bookingDetailObj != null">
            <mat-tab>
                <ng-template mat-tab-label>
                    <h1 class="text-9xl fontMediumBold mb-2 ml-3"><i
                            class="fas fa-info-circle  tab-green  mr-2"></i>General
                        Details</h1>
                </ng-template>
                <div class="overflow-auto  bg-color journal-scroll">
                    <div class="w-full mt-0 px-3 py-1" *ngIf="bookingDetailObj != null">


                        <div
                            class="boxBColor p-2 shadow-md customBoxBorder rounded-2xl  mt-4 mb-4 customCardHover cursor-pointer">
                            <div class="flex justify-between items-center fontMediumBold px-2.5 p-2">
                                <div class="flex items-center gap-2">
                                    Booking id :
                                    <div class="select-text">
                                        ({{bookingDetailObj?.dBookId}})
                                    </div>
                                </div>
                                <div>
                                    <i class="fa fa-key" aria-hidden="true"></i> OTP : <span
                                        class="select-text">{{bookingDetailObj?.otp}}</span>
                                </div>
                            </div>
                            <hr />
                            <div class="booking_details_content">
                                <div class="flex justify-between items-center  fontMediumBold px-2.5 p-2 flex-wrap">
                                    <div class="flex items-center gap-2">
                                        Booking Type :
                                        <div>

                                            <span
                                                class="inline-flex rounded-lg px-3 justify-center items-center place-content-center fontMediumBold"
                                                [ngClass]="bookingDetailObj.bookingType == 1?'btn-later':'btn-now'">
                                                {{bookingDetailObj.bookingType == 1?'Later':'Now'}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="flex items-center gap-2">
                                        Payment Type :
                                        <div>
                                            <span
                                                class="inline-flex rounded-lg px-3 bg-blue-300 justify-center items-center place-content-center fontMediumBold"
                                                *ngIf="bookingDetailObj.paymentType == 0 || bookingDetailObj.paymentType == 1 || bookingDetailObj.paymentType == 2">
                                                {{bookingDetailObj.paymentType == 0 ?
                                                'Cash':bookingDetailObj.paymentType == 1 ? 'Card':'Credit'}}
                                                <span
                                                    *ngIf="bookingDetailObj.paymentType == 2">({{bookingDetailObj.accountId}})</span>
                                            </span>
                                            <span *ngIf="bookingDetailObj.paymentType == 3">ApplePay</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex justify-between items-center fontMediumBold px-2.5  flex-wrap">
                                    <div class="flex items-center gap-2">
                                        Surge Multiplier :
                                        <div class="tracking-tight" style="word-spacing: -4px;">
                                            {{bookingDetailObj.surgeMultiplier}} <span class="text-md"
                                                style="font-size: 13px;">x</span>
                                        </div>
                                    </div>
                                    <div class="flex items-center gap-2">
                                        Type :
                                        <div>
                                            <div class="p-2" *ngIf="bookingDetailObj.bookingFlag == 1">
                                                <span
                                                    class="inline-flex btn-rental rounded-lg px-3 justify-center items-center place-content-center fontMediumBold">Rental</span>
                                            </div>

                                            <div class="p-2"
                                                *ngIf="bookingDetailObj.bookingFlag == 0 && bookingDetailObj.isRestaurant == 0">
                                                <span
                                                    class="inline-flex btn-city-ride rounded-lg px-3 justify-center items-center place-content-center fontMediumBold">City
                                                    Ride</span>
                                            </div>

                                            <div class="p-2" *ngIf="bookingDetailObj.bookingFlag == 2">
                                                <span
                                                    class="inline-flex btn-out-satation rounded-lg px-3 justify-center items-center place-content-center fontMediumBold">Outstation</span>
                                            </div>

                                            <div class="p-2" *ngIf="bookingDetailObj.isRestaurant == 1">
                                                <span
                                                    class="inline-flex btn-restaurant rounded-lg px-3 justify-center items-center place-content-center fontMediumBold">Restaurant</span>
                                            </div>
                                            <div class="p-2" *ngIf="bookingDetailObj.isBox == 1">
                                                <span
                                                    class="inline-flex btn-box rounded-lg px-3 justify-center items-center place-content-center fontMediumBold">Box</span>
                                            </div>
                                            <!-- <div class="rounded-full  ml-3 text-center px-2 "
                                                [ngClass]="bookingDetailObj.bookingFlag == 0 ?'btn-city-ride':bookingDetailObj.bookingFlag == 1?'btn-rental ':'btn-out-satation'">
                                                {{bookingDetailObj.bookingFlag == 0 ?'City
                                                Ride':bookingDetailObj.bookingFlag ==
                                                1?'Rental':'Out Station' }}
                                            </div> -->

                                            <div class="rounded-full bg-pink-700 ml-3 text-center px-2 text-white"
                                                *ngIf="bookingDetailObj.isBox == 1">
                                                Box
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div
                            class="max-w-full justify-items-center justify-center customBoxBorder boxBColor p-2 rounded-2xl tracking-wide shadow-md customCardHover cursor-pointer">
                            <h1 class="text-4xl fontMediumBold font-bold mb-3 pl-3 font-medium">
                                <i class="fas fa-map-pin  mr-2"></i>Address Details
                            </h1>
                            <hr />
                            <div class="fontColorDark custom-font-md ml-6"
                                *ngFor="let location of bookingDetailObj?.locationJson;let i = index;">
                                <div class="w-full flex justify-between" *ngIf="i == 0">
                                    <div class="w-96">
                                        <i class="fas fa-map-marker-alt text-red-500 mr-2"></i>Pickup
                                    </div>
                                    <div class="w-full flex gap-3">
                                        <span>:</span> {{(location[Global.isShowAddressType] != undefined &&
                                        location[Global.isShowAddressType] !=
                                        '')?location[Global.isShowAddressType]:'-----'}}
                                    </div>
                                </div>
                                <div class="w-full flex justify-between"
                                    *ngIf="i != 0 && i != bookingDetailObj?.locationJson.length-1">
                                    <div class="w-96">
                                        <i class="fas fa-map-marker-alt text-blue-500 mr-2"></i>Waypoint Address
                                    </div>
                                    <div class="w-full flex gap-3">
                                        <span>:</span> {{(location[Global.isShowAddressType] != undefined &&
                                        location[Global.isShowAddressType] !=
                                        '')?location[Global.isShowAddressType]:'-----'}}
                                    </div>
                                </div>
                                <div class="w-full flex justify-between mt-2"
                                    *ngIf="i == bookingDetailObj?.locationJson.length-1">
                                    <div class="w-96">
                                        <i class="fas fa-map-marker-alt text-green-500 mr-2"></i>Drop Address
                                    </div>
                                    <div class="w-full flex gap-3">
                                        <span>:</span> {{(location[Global.isShowAddressType] != undefined &&
                                        location[Global.isShowAddressType] !=
                                        '')?location[Global.isShowAddressType]:'-----'}}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="boxBColor p-2 shadow-md customBoxBorder rounded-2xl  mt-4 mb-4 customCardHover cursor-pointer"
                            *ngIf="bookingDetailObj?.riderPreviousBal != undefined && bookingDetailObj?.userWalletBal != undefined">
                            <div class="flex justify-between items-center fontMediumBold px-3 p-2">
                                <div>
                                    <i class="far fa-money-bill-alt"></i> User Wallet Balance
                                </div>
                            </div>
                            <hr />
                            <div class="booking_details_content px-4">
                                <div class="flex justify-between items-center  fontMediumBold px-2.5 p-2 flex-wrap">
                                    <div class="flex items-center gap-2 fontMediumBold">
                                        <div>
                                            Before Booking Balance
                                        </div>
                                    </div>
                                    <div class="flex items-center gap-10 text-right ">
                                        <span>:</span>
                                        <div class="fontMediumBold">
                                            {{bookingDetailObj?.currency}} <span
                                                class="text-red-600">{{bookingDetailObj?.riderPreviousBal}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex justify-between items-center  fontMediumBold px-2.5 p-2 flex-wrap">

                                    <div class="flex items-center gap-2 fontMediumBold">
                                        <div>
                                            After Booking Balance :
                                        </div>
                                    </div>
                                    <div class="flex items-center gap-10 text-right">
                                        <span>:</span>
                                        <div class="fontMediumBold">
                                            {{bookingDetailObj?.currency}} <span
                                                class="text-green-600">{{bookingDetailObj?.userWalletBal}}</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div
                            class="max-w-full justify-items-center customBoxBorder justify-center boxBColor mt-4  p-2 rounded-2xl tracking-wide shadow-md customCardHover cursor-pointer">
                            <h1 class="text-4xl fontMediumBold font-bold mb-2 pl-3 font-medium"><i
                                    class="far fa-money-bill-alt mr-2"></i>Trip Amount</h1>
                            <div class="fontColorDark custom-font-md ml-6">
                                <div class="w-full flex justify-center items-center px-8">
                                    <div
                                        class="  w-full py-2 text-center customBorder rounded-md  fontMediumBold font-bold fare-amount">
                                        {{bookingDetailObj?.currency}} {{bookingDetailObj?.status == 9?
                                        bookingDetailObj?.actual_final_amount :
                                        bookingDetailObj?.estimatedFinalAmount.toFixed(2)}}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="bookingDetailObj?.websiteCommision != undefined"
                            class="max-w-full justify-items-center customBoxBorder justify-center boxBColor mt-4  p-2 rounded-2xl tracking-wide shadow-md customCardHover cursor-pointer">
                            <h1 class="text-4xl fontMediumBold font-bold mb-2 pl-3 font-medium"><i
                                    class="far fa-money-bill-alt mr-2"></i>Company Earning</h1>
                            <div class="fontColorDark custom-font-md ml-6">
                                <div class="w-full flex justify-center items-center px-8">
                                    <div
                                        class="  w-full py-2 text-center customBorder rounded-md  fontMediumBold font-bold fare-amount">
                                        {{bookingDetailObj?.currency}} {{bookingDetailObj?.websiteCommision}}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="bookingDetailObj?.driverCommision != undefined"
                            class="max-w-full justify-items-center customBoxBorder justify-center boxBColor mt-4  p-2 rounded-2xl tracking-wide shadow-md customCardHover cursor-pointer">
                            <h1 class="text-4xl fontMediumBold font-bold mb-2 pl-3 font-medium"><i
                                    class="far fa-money-bill-alt mr-2"></i>Driver Earning</h1>
                            <div class="fontColorDark custom-font-md ml-6">
                                <div class="w-full flex justify-center items-center px-8">
                                    <div
                                        class="  w-full py-2 text-center rounded-md  customBorder fontMediumBold font-bold fare-amount">
                                        {{bookingDetailObj?.currency}} {{bookingDetailObj?.driverCommision}}
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div
                            class="max-w-full justify-items-center customBoxBorder justify-center boxBColor mt-4  p-2 rounded-2xl tracking-wide shadow-md customCardHover cursor-pointer">
                            <h1 class="text-4xl fontMediumBold font-bold mb-2 pl-3 font-medium"><i
                                    class="fas fa-car mr-2"></i>Car
                                Details</h1>
                            <hr>
                            <div class="fontColorDark custom-font-md ml-6">
                                <div class="w-full flex justify-between">
                                    <div class="w-full">
                                        Car Type
                                    </div>
                                    <div class="w-full text-right flex gap-2">
                                        <span>:</span> {{bookingDetailObj?.taxiType}}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="max-w-full justify-items-center justify-center customBoxBorder boxBColor mt-4  p-2 rounded-2xl tracking-wide shadow-md customCardHover cursor-pointer"
                            *ngIf="bookingDetailObj.isBox && (bookingDetailObj.boxItems | json) != ({} | json)">
                            <div class="flex">
                                <h1 class="text-9xl fontMediumBold font-bold mb-2 pl-3 font-medium"><i
                                        class="fas fa-box-open pr-2"></i>Box Details</h1>
                                <span
                                    class="ml-3 inline-flex  text-white rounded-full px-3 justify-center items-center place-content-center fontMedium"
                                    [style.background]="bookingDetailObj?.boxItems.boxItemType == 1?'#054a47':'#643d0d'"
                                    style="font-size: 12px;">{{bookingDetailObj?.boxItems.boxItemType ==
                                    1?'buy':'pickup'}}
                                </span>
                            </div>
                            <hr>
                            <div class="fontColorDark custom-font-md ml-6">
                                <div class="w-full flex justify-between"
                                    *ngIf="bookingDetailObj?.boxItems.boxItemName != undefined && bookingDetailObj?.boxItems.boxItemName != ''">
                                    <div class="w-full">
                                        Item Name :
                                    </div>
                                    <div class="w-full">
                                        {{bookingDetailObj?.boxItems.boxItemName !=
                                        ''?bookingDetailObj?.boxItems.boxItemName:'----'}}
                                    </div>
                                </div>
                                <div class="w-full flex justify-between"
                                    *ngIf="bookingDetailObj?.boxItems.boxItemPickupAddress != undefined && bookingDetailObj?.boxItems.boxItemPickupAddress != ''">
                                    <div class="w-full">
                                        Pickup Notes:
                                    </div>
                                    <div class="w-full">
                                        {{bookingDetailObj?.boxItems.boxItemPickupAddress ?
                                        bookingDetailObj?.boxItems.boxItemPickupAddress : '----'}}
                                    </div>
                                </div>
                                <div class="w-full flex justify-between"
                                    *ngIf="bookingDetailObj?.boxItems.boxItemDropAddress != undefined && bookingDetailObj?.boxItems.boxItemDropAddress != ''">
                                    <div class="w-full">
                                        Dropoff Notes:
                                    </div>
                                    <div class="w-full">
                                        {{bookingDetailObj?.boxItems.boxItemDropAddress ?
                                        bookingDetailObj?.boxItems.boxItemDropAddress : '----'}}
                                    </div>
                                </div>
                                <div class="w-full flex justify-between"
                                    *ngIf="bookingDetailObj?.boxItems.boxItemDescription != undefined && bookingDetailObj?.boxItems.boxItemDescription != ''">
                                    <div class="w-full">
                                        Description:
                                    </div>
                                    <div class="w-full">
                                        {{bookingDetailObj?.boxItems.boxItemDescription ?
                                        bookingDetailObj?.boxItems.boxItemDescription : '----'}}
                                    </div>
                                </div>
                                <div class="w-full ">
                                    <div class="h-28 w-28 float-left"
                                        *ngFor="let image of bookingDetailObj.boxItems.boxItemImages">
                                        <img src="{{environment.baseUrl + environment.imageUrl + environment.boxItemImages+image}}"
                                            class="h-full w-full " alt="">
                                    </div>
                                    <div class="clear-both"></div>
                                </div>
                            </div>
                        </div>

                        <div
                            class="max-w-full justify-items-center justify-center boxBColor mt-4 customBoxBorder p-2 rounded-2xl tracking-wide shadow-md customCardHover cursor-pointer">
                            <h1 class="text-4xl fontMediumBold font-bold mb-2 pl-3 font-medium"><i
                                    class="fas fa-comments mr-2"></i>User
                                Comment</h1>
                            <hr>
                            <div class="fontColorDark custom-font-md ml-6">
                                <div class="w-full flex justify-between">
                                    <div class="w-full">
                                        {{bookingDetailObj?.userComment !=''? bookingDetailObj?.userComment:'---'}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="max-w-full justify-items-center justify-center boxBColor mt-4 mb-3 p-2 customBoxBorder rounded-2xl tracking-wide shadow-md customCardHover cursor-pointer">
                            <h1 class="text-4xl fontMediumBold font-bold mb-2 pl-3 font-medium"><i
                                    class="fas fa-comments mr-2"></i>Driver Comment</h1>
                            <hr />
                            <div class="fontColorDark custom-font-md ml-6">
                                <div class="w-full flex justify-between">
                                    <div class="w-full">
                                        {{bookingDetailObj?.driverComment !='' ?bookingDetailObj?.driverComment:'---'}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>



            <mat-tab>
                <ng-template mat-tab-label>
                    <h1 class="text-9xl fontMediumBold mb-2 ml-3"><i class='bx bx-archive text-2xl tab-yellow mr-2'></i>
                        Trip Details</h1>
                </ng-template>
                <div class="overflow-auto  bg-color journal-scroll">
                    <div class="w-full mt-0 px-3 py-1">
                        <div
                            class="max-w-full justify-items-center customBoxBorder justify-center boxBColor mt-4 mb-4 p-2 rounded-2xl tracking-wide shadow-md customCardHover cursor-pointer">
                            <h1 class="text-4xl fontMediumBold font-bold mb-2 pl-3 font-medium"><i
                                    class="fas fa-user-tie mr-2"></i>User
                                Details </h1>
                            <hr />
                            <div class="fontColorDark custom-font-md ml-6 p-2"
                                *ngFor="let user of bookingDetailObj?.userJson;let i = index;"
                                [style.border-color]="Global.first_color"
                                [ngClass]="{'border-dotted   border-b-4':bookingDetailObj?.userJson.length > i+1}">
                                <div class="w-full flex justify-between">
                                    <div class="w-full">
                                        <i class="fas fa-user-tie mr-2"></i>User Name
                                    </div>
                                    <div class="w-full flex gap-2">
                                        <span>:</span> {{user?.passengerName != undefined && user?.passengerName != "" ?
                                        user?.passengerName:
                                        '---'}}
                                    </div>
                                </div>
                                <div class="w-full flex justify-between">
                                    <div class="w-full">
                                        <i class="far fa-envelope mr-2"></i>Email
                                    </div>
                                    <div class="w-full gap-2 flex">
                                        <span>:</span> {{user?.passengerEmail !=undefined && user?.passengerEmail != ""
                                        ?
                                        user?.passengerEmail
                                        : '---'}}
                                    </div>
                                </div>
                                <div class="w-full flex justify-between mb-1">
                                    <div class="w-full">
                                        <i class="fas fa-mobile-alt  mr-3"></i>Mobile
                                    </div>
                                    <div class="w-full flex gap-2">

                                        <span>:</span> {{user?.passengerPhone != undefined && user?.passengerPhone != ""
                                        ?
                                        '+'+''+user?.passengerPhone : '---'}}
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div
                            class="max-w-full justify-items-center customBoxBorder justify-center boxBColor mt-4  p-2 rounded-2xl tracking-wide shadow-md customCardHover cursor-pointer">
                            <h1 class="text-4xl fontMediumBold font-bold mb-2 pl-3 font-medium"><i
                                    class="fas fa-user mr-2"></i>Driver
                                Details</h1>
                            <div class="fontColorDark custom-font-md ml-6">
                                <div class="w-full flex justify-between">
                                    <div class="w-full">
                                        <i class="fas fa-user-tie mr-2"></i>Driver Name
                                    </div>
                                    <div class="w-full flex gap-2">
                                        <span>:</span>{{bookingDetailObj?.driverJson != undefined &&
                                        (bookingDetailObj?.driverJson | json) != ({} | json) &&
                                        bookingDetailObj?.driverJson !=''
                                        ?
                                        bookingDetailObj?.driverJson.driverName:'---'}}
                                    </div>
                                </div>
                                <div class="w-full flex justify-between">
                                    <div class="w-full">
                                        <i class="far fa-envelope mr-2"></i>Email
                                    </div>
                                    <div class="w-full flex gap-2">
                                        <span>:</span> {{bookingDetailObj?.driverJson != undefined &&
                                        (bookingDetailObj?.driverJson | json) != ({} | json) &&
                                        bookingDetailObj?.driverJson !=''
                                        ?
                                        bookingDetailObj?.driverJson.driverEmail:'---'}}
                                    </div>
                                </div>
                                <div class="w-full flex justify-between">
                                    <div class="w-full">
                                        <i class="fas fa-mobile-alt ml-1 mr-3"></i>Mobile
                                    </div>
                                    <div class="w-full flex gap-2">
                                        <span>:</span> {{bookingDetailObj?.driverJson != undefined &&
                                        (bookingDetailObj?.driverJson | json) != ({} | json) &&
                                        bookingDetailObj?.driverJson !=''
                                        ?
                                        '+'+''+bookingDetailObj?.driverJson.driverPhone:'---'}}
                                    </div>
                                </div>
                                <div class="w-full flex justify-between">
                                    <div class="w-full">
                                        <i class="fas fa-star-half-alt text-yellow-400 mr-1"></i>This Booking
                                        Rating
                                    </div>
                                    <div class="w-full text-yellow-400 gap-2 flex">
                                        <span>:</span>
                                        <div *ngIf="bookingDetailObj?.isUserReviewGave != ''">
                                            <span *ngFor='let in of [].constructor(bookingDetailObj?.isUserReviewGave)'
                                                class="fa fa-star"></span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div
                            class="max-w-full justify-items-center justify-center boxBColor mt-4 customBoxBorder p-2 rounded-2xl tracking-wide shadow-md customCardHover cursor-pointer">
                            <div class="flex items-center justify-between">

                                <h1 class="text-9xl fontMediumBold font-bold mb-2 pl-3"><i
                                        class="fas fa-calculator mr-2"></i>Estimated
                                </h1>
                                <div class="flex items-center gap-2 fontMediumBold">
                                    <span class="fontMediumBold"> Surge Multiplier : </span>
                                    <div class="tracking-tight fontMediumBold" style="word-spacing: -4px;">
                                        {{bookingDetailObj.surgeMultiplier}} <span class="text-md"
                                            style="font-size: 13px;">x</span>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="fontColorDark custom-font-md px-4">
                                <div class="w-full   grid grid-cols-4">
                                    <div class="w-full ">
                                        Base Fare:
                                    </div>
                                    <div class="w-full">
                                        :
                                    </div>
                                    <div class="w-full"></div>
                                    <div class="w-full text-right">
                                        {{bookingDetailObj?.currency}}
                                        {{bookingDetailObj?.baseFare != undefined ?
                                        bookingDetailObj?.baseFare.toFixed(environment.roundUpNumber):'---'}}
                                    </div>
                                </div>
                                <div class="w-full grid grid-cols-4 ">
                                    <div class="w-full">
                                        Distance:
                                    </div>

                                    <div class="w-full col-span-2 flex gap-8">
                                        <span>:</span>{{bookingDetailObj?.totalApproxKm}}
                                        ({{bookingDetailObj?.distanceVariant==0?'Kms':'miles'}})
                                    </div>
                                    <div class="w-full text-right">
                                        {{bookingDetailObj?.currency}}
                                        {{ bookingDetailObj?.tripDistancePrice != undefined ?
                                        bookingDetailObj?.tripDistancePrice.toFixed(environment.roundUpNumber):'---'}}
                                    </div>
                                </div>
                                <div class="w-full grid grid-cols-4">
                                    <div class="w-full">
                                        Trip Time
                                    </div>

                                    <div class="w-full col-span-2 flex gap-8">
                                        <div>
                                            :
                                        </div>
                                        {{bookingDetailObj?.totalApproxTime != undefined ?
                                        bookingDetailObj?.totalApproxTime:
                                        '---'}} Min.
                                    </div>
                                    <div class="w-full text-right">
                                        {{bookingDetailObj?.currency}}
                                        {{bookingDetailObj?.tripTimePrice != undefined ?
                                        bookingDetailObj?.tripTimePrice.toFixed(environment.roundUpNumber):'---'}}
                                    </div>
                                </div>
                                <div class="w-full grid  grid-cols-4">
                                    <div class="w-full">
                                        Stop Price
                                    </div>
                                    <div class="w-full">
                                        :
                                    </div>
                                    <div class="w-full">

                                    </div>
                                    <div class="w-full text-right">
                                        {{bookingDetailObj?.currency}}
                                        {{bookingDetailObj?.stopPrice != undefined ?
                                        bookingDetailObj?.stopPrice.toFixed(environment.roundUpNumber):'---'}}
                                    </div>
                                </div>

                                <div
                                    class="w-full grid grid-cols-4 topBorder fontMediumBold font-bold fare-amount mt-2 mb-2">
                                    <div class="w-full fontMediumBold font-bold fare-amount">
                                        Sub Total
                                    </div>
                                    <div class="w-full">
                                        :
                                    </div>
                                    <div class="w-full">

                                    </div>
                                    <div class="w-full fontMediumBold font-bold fare-amount text-right">
                                        {{bookingDetailObj?.currency}}
                                        {{ bookingDetailObj?.estimatedAmount != undefined ?
                                        bookingDetailObj?.estimatedAmount.toFixed(environment.roundUpNumber):'--'}}
                                    </div>
                                </div>
                                <div class="w-full grid grid-cols-4 ">
                                    <div class="w-full">
                                        Discount
                                    </div>

                                    <div class="w-full col-span-2 flex gap-8">
                                        <div>
                                            :
                                        </div>
                                        {{
                                        bookingDetailObj?.couponDetails != undefined && bookingDetailObj?.couponDetails
                                        != '' && (bookingDetailObj?.couponDetails | json) != ({} | json) ?
                                        (bookingDetailObj?.couponDetails.couponCodeType == 1) ?
                                        bookingDetailObj?.couponDetails.couponCodeAmount + '% to ' +
                                        bookingDetailObj?.couponDetails.couponCodeMaxAmount + '%' :
                                        bookingDetailObj?.couponDetails.couponCodeAmount.toFixed(environment.roundUpNumber)
                                        + '' + bookingDetailObj?.currency + ' upto ' +
                                        bookingDetailObj?.couponDetails.couponCodeMaxAmount.toFixed(environment.roundUpNumber)
                                        :
                                        '-----'
                                        }}
                                    </div>
                                    <div class="w-full text-right">
                                        - {{bookingDetailObj?.currency}}
                                        {{bookingDetailObj?.estimatedDiscountAmount != undefined ?
                                        bookingDetailObj?.estimatedDiscountAmount.toFixed(environment.roundUpNumber):'---'}}
                                    </div>
                                </div>
                                <div class="w-full grid grid-cols-4">
                                    <div class="w-full">
                                        Tax Amount
                                    </div>
                                    <div class="w-full">
                                        :
                                    </div>
                                    <div class="w-full">

                                    </div>

                                    <div class="w-full text-right">
                                        + {{bookingDetailObj?.currency}}
                                        {{ bookingDetailObj?.estimatedTaxAmount != undefined
                                        ?bookingDetailObj?.estimatedTaxAmount.toFixed(environment.roundUpNumber):'---'}}
                                    </div>
                                </div>
                                <div class="w-full grid grid-cols-4">
                                    <div class="w-full">
                                        Dispatch Fees
                                    </div>
                                    <div class="w-full">
                                        :
                                    </div>
                                    <div class="w-full">

                                    </div>

                                    <div class="w-full text-right">
                                        + {{bookingDetailObj?.currency}}
                                        {{ bookingDetailObj?.estimatedExtraCharges != undefined
                                        ?bookingDetailObj?.estimatedExtraCharges.toFixed(environment.roundUpNumber):'---'}}
                                    </div>
                                </div>
                                <div class="w-full grid grid-cols-4">
                                    <div class="w-full">
                                        Airport Parking
                                    </div>
                                    <div class="w-full">
                                        :
                                    </div>
                                    <div class="w-full">

                                    </div>

                                    <div class="w-full text-right">
                                        + {{bookingDetailObj?.currency}}
                                        {{ bookingDetailObj?.airportParking != undefined
                                        ?bookingDetailObj?.airportParking.toFixed(environment.roundUpNumber):'---'}}
                                    </div>
                                </div>
                                <div class="w-full grid grid-cols-4">
                                    <div class="w-full">
                                        Toll
                                    </div>
                                    <div class="w-full">
                                        :
                                    </div>
                                    <div class="w-full">

                                    </div>

                                    <div class="w-full text-right">
                                        + {{bookingDetailObj?.currency}}
                                        {{ bookingDetailObj?.toll != undefined
                                        ?bookingDetailObj?.toll.toFixed(environment.roundUpNumber):'---'}}
                                    </div>
                                </div>
                                <div class="w-full grid grid-cols-4">
                                    <div class="w-full">
                                        Service Charges
                                    </div>
                                    <div class="w-full">
                                        :
                                    </div>
                                    <div class="w-full">

                                    </div>

                                    <div class="w-full text-right">
                                        + {{bookingDetailObj?.currency}}
                                        {{ bookingDetailObj?.serviceCharges != undefined
                                        ?bookingDetailObj?.serviceCharges.toFixed(environment.roundUpNumber):'---'}}
                                    </div>
                                </div>
                                <div
                                    class="w-full grid grid-cols-4 topBorder mt-2 fontMediumBold fare-amount font-bold">
                                    <div class="w-full fontMediumBold font-bold fare-amount mt-2">
                                        Total Fare
                                    </div>
                                    <div class="w-full">
                                        :
                                    </div>
                                    <div class="w-full">

                                    </div>

                                    <div class="w-full fare-amount fontMediumBold font-bold mt-2 text-right">
                                        {{bookingDetailObj?.currency}}
                                        {{bookingDetailObj?.estimatedFinalAmount != undefined ?
                                        bookingDetailObj?.estimatedFinalAmount.toFixed(environment.roundUpNumber):'---'}}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            class="max-w-full justify-items-center justify-center boxBColor mt-4  customBoxBorder p-2 rounded-2xl tracking-wide shadow-md customCardHover cursor-pointer">
                            <h1 class="text-9xl fontMediumBold font-bold mb-3 pl-3 font-medium"><i
                                    class="fas fa-file-invoice-dollar mr-2"></i>Actual
                            </h1>
                            <hr>
                            <div class="fontColorDark custom-font-md px-4 "
                                *ngIf="(bookingDetailObj.status == 9 || bookingDetailObj.status == 4 || bookingDetailObj.status == 5)">
                                <div class="w-full   grid grid-cols-4">
                                    <div class="w-full ">
                                        <i class="far fa-money-bill-alt"></i> By Cash
                                    </div>
                                    <div class="w-full ">
                                        :
                                    </div>
                                    <div class="w-full">

                                    </div>
                                    <div class="w-full text-right">
                                        {{bookingDetailObj?.currency}}
                                        {{bookingDetailObj?.cashPayment != undefined ?
                                        bookingDetailObj?.cashPayment.toFixed(environment.roundUpNumber):'---'}}
                                    </div>
                                </div>
                                <div class="w-full   grid grid-cols-4">
                                    <div class="w-full ">
                                        <i class="far fa-credit-card"></i> By Card
                                    </div>
                                    <div class="w-full ">
                                        :
                                    </div>
                                    <div class="w-full">

                                    </div>
                                    <div class="w-full text-right">
                                        {{bookingDetailObj?.currency}}
                                        {{bookingDetailObj?.cardPayment != undefined ?
                                        bookingDetailObj?.cardPayment.toFixed(environment.roundUpNumber):'---'}}
                                    </div>
                                </div>
                                <div class="w-full   grid grid-cols-4">
                                    <div class="w-full ">
                                        Base Fare
                                    </div>
                                    <div class="w-full ">
                                        :
                                    </div>
                                    <div class="w-full">

                                    </div>
                                    <div class="w-full text-right">
                                        {{bookingDetailObj?.currency}}
                                        {{bookingDetailObj?.baseFare != undefined ?
                                        bookingDetailObj?.baseFare.toFixed(environment.roundUpNumber):'---'}}
                                    </div>
                                </div>
                                <div class="w-full grid grid-cols-4 ">
                                    <div class="w-full truncate" title="Trip Distance Charge">
                                        Trip Distance Charge
                                    </div>

                                    <div class="w-full flex col-span-2 gap-8">
                                        <div>
                                            :
                                        </div>
                                        {{bookingDetailObj?.totalActualKm}}
                                        ({{bookingDetailObj?.distanceVariant==0?'Kms':'miles'}})
                                    </div>
                                    <div class="w-full text-right">
                                        {{bookingDetailObj?.currency}}
                                        {{bookingDetailObj?.tripDistancePrice != undefined ?
                                        bookingDetailObj?.tripDistancePrice.toFixed(environment.roundUpNumber):'---'}}
                                    </div>
                                </div>
                                <div class="w-full grid grid-cols-4">
                                    <div class="w-full truncate" title="Trip Minute Charge">
                                        Trip Minute Charge
                                    </div>

                                    <div class="w-full flex col-span-2 items-center gap-8">
                                        <div>
                                            :
                                        </div> {{bookingDetailObj?.totalActualTime}} Min.
                                    </div>
                                    <div class="w-full text-right">
                                        {{bookingDetailObj?.currency}}
                                        {{bookingDetailObj?.tripTimePrice != undefined ?
                                        bookingDetailObj?.tripTimePrice.toFixed(environment.roundUpNumber):'---'}}
                                    </div>
                                </div>
                                <div class="w-full grid  grid-cols-4">
                                    <div class="w-full">
                                        Wait Time Price
                                    </div>

                                    <div class="w-full flex col-span-2 items-center gap-8 ">
                                        <div>
                                            :
                                        </div>
                                        {{bookingDetailObj?.waitTime}} Min.
                                    </div>
                                    <div class="w-full text-right">
                                        {{bookingDetailObj?.currency}}
                                        {{bookingDetailObj?.tripWaitTimePrice != undefined ?
                                        bookingDetailObj?.tripWaitTimePrice.toFixed(environment.roundUpNumber):'---'}}
                                    </div>
                                </div>

                                <div class="w-full grid grid-cols-4 ">
                                    <div class="w-full">
                                        Extra Mileage Charge
                                    </div>

                                    <div class="w-full flex col-span-2 items-center gap-8 ">
                                        <div>
                                            :
                                        </div>
                                        {{bookingDetailObj?.tripLateWaitTime}} Min.
                                    </div>
                                    <div class="w-full  text-right">
                                        {{bookingDetailObj?.currency}}
                                        {{bookingDetailObj?.tripLateWaitTimePrice != undefined
                                        ?bookingDetailObj?.tripLateWaitTimePrice.toFixed(environment.roundUpNumber):'---'}}
                                    </div>
                                </div>
                                <div class="w-full grid grid-cols-4 ">
                                    <div class="w-full">
                                        Extra KM Charge
                                    </div>

                                    <div class="w-full flex col-span-2 items-center gap-8">
                                        <div>
                                            :
                                        </div>
                                        {{bookingDetailObj?.extraKm}} Min.
                                    </div>
                                    <div class="w-full  text-right">
                                        {{bookingDetailObj?.currency}}
                                        {{bookingDetailObj?.extraKmPrice != undefined ?
                                        bookingDetailObj?.extraKmPrice.toFixed(environment.roundUpNumber):'---'}}
                                    </div>
                                </div>
                                <div class="w-full grid grid-cols-4">
                                    <div class="w-full">
                                        Driver Allowance
                                    </div>
                                    <div class="w-full">
                                        :
                                    </div>
                                    <div class="w-full">

                                    </div>

                                    <div class="w-full text-right">
                                        {{bookingDetailObj?.currency}}
                                        {{bookingDetailObj?.driverAllowance != undefined ?
                                        bookingDetailObj?.driverAllowance.toFixed(environment.roundUpNumber):'---'}}
                                    </div>
                                </div>
                                <div class="w-full grid grid-cols-4 ">
                                    <div class="w-full  ">
                                        Night Allowance
                                    </div>
                                    <div class="w-full">
                                        :
                                    </div>
                                    <div class="w-full">

                                    </div>

                                    <div class="w-full text-right ">
                                        {{bookingDetailObj?.currency}}
                                        {{bookingDetailObj?.nightAllowance != undefined ?
                                        bookingDetailObj?.nightAllowance.toFixed(environment.roundUpNumber):'---'}}
                                    </div>
                                </div>
                                <div class="w-full grid grid-cols-4  ">
                                    <div class="w-full">
                                        Stop Price
                                    </div>
                                    <div class="w-full">
                                        :
                                    </div>
                                    <div class="w-full">

                                    </div>

                                    <div class="w-full text-right">
                                        {{bookingDetailObj?.currency}}
                                        {{bookingDetailObj?.stopPrice != undefined ?
                                        bookingDetailObj?.stopPrice.toFixed(environment.roundUpNumber):'---'}}
                                    </div>
                                </div>
                                <div
                                    class="w-full grid grid-cols-4 fontMediumBold font-bold fare-amount topBorder mt-2">
                                    <div class="w-full fare-amount fontMediumBold font-bold mt-2">
                                        Sub Total
                                    </div>
                                    <div class="w-full">
                                        :
                                    </div>
                                    <div class="w-full">

                                    </div>

                                    <div class="w-full fare-amount fontMediumBold font-bold mt-2 text-right">
                                        {{bookingDetailObj?.currency}}
                                        {{bookingDetailObj?.actualAmount != undefined ?
                                        bookingDetailObj?.actualAmount.toFixed(environment.roundUpNumber):'---'}}
                                    </div>
                                </div>
                                <div class="w-full grid grid-cols-4 ">
                                    <div class="w-full">
                                        Discount
                                    </div>

                                    <div class="w-full flex col-span-2 items-center gap-8">
                                        <div>
                                            :
                                        </div>
                                        {{bookingDetailObj?.couponDetails != '' && (bookingDetailObj?.couponDetails |
                                        json) !=
                                        ({} | json) && bookingDetailObj?.couponDetails != null ?
                                        (bookingDetailObj?.couponDetails.couponCodeType == 1) ?
                                        bookingDetailObj?.couponDetails.couponCodeAmount + '% to ' +
                                        bookingDetailObj?.couponDetails.couponCodeMaxAmount + '%' :
                                        bookingDetailObj?.couponDetails.couponCodeAmount.toFixed(environment.roundUpNumber)
                                        + '
                                        ' + bookingDetailObj?.currency + ' upto ' +
                                        bookingDetailObj?.couponDetails.couponCodeMaxAmount.toFixed(environment.roundUpNumber)
                                        :
                                        '-----'}}
                                    </div>
                                    <div class="w-full text-right">
                                        - {{bookingDetailObj?.currency}}
                                        {{bookingDetailObj?.actualDiscountAmount != undefined
                                        ?bookingDetailObj?.actualDiscountAmount.toFixed(environment.roundUpNumber):'---'}}
                                    </div>
                                </div>
                                <div class="w-full grid grid-cols-4">
                                    <div class="w-full">
                                        Tax Amount
                                    </div>
                                    <div class="w-full">
                                        :
                                    </div>
                                    <div class="w-full">

                                    </div>

                                    <div class="w-full text-right">
                                        + {{bookingDetailObj?.currency}}
                                        {{bookingDetailObj?.actualTaxAmount != undefined ?
                                        bookingDetailObj?.actualTaxAmount.toFixed(environment.roundUpNumber):'---'}}
                                    </div>
                                </div>
                                <div class="w-full grid grid-cols-4">
                                    <div class="w-full">
                                        Dispatch Fees
                                    </div>
                                    <div class="w-full">
                                        :
                                    </div>
                                    <div class="w-full">

                                    </div>

                                    <div class="w-full text-right">
                                        + {{bookingDetailObj?.currency}}
                                        {{bookingDetailObj?.actualExtraCharges != undefined ?
                                        bookingDetailObj?.actualExtraCharges.toFixed(environment.roundUpNumber):'---'}}
                                    </div>
                                </div>
                                <div class="w-full grid grid-cols-4">
                                    <div class="w-full">
                                        Arrears
                                    </div>
                                    <div class="w-full">
                                        :
                                    </div>
                                    <div class="w-full">

                                    </div>
                                    <div class="w-full text-right">
                                        + {{bookingDetailObj?.currency}}
                                        {{bookingDetailObj?.arrears != undefined ?
                                        bookingDetailObj?.arrears.toFixed(environment.roundUpNumber):'---'}}
                                    </div>
                                </div>
                                <div class="w-full grid grid-cols-4">
                                    <div class="w-full">
                                        Airport Parking
                                    </div>
                                    <div class="w-full">
                                        :
                                    </div>
                                    <div class="w-full">

                                    </div>

                                    <div class="w-full text-right">
                                        + {{bookingDetailObj?.currency}}
                                        {{ bookingDetailObj?.airportParking != undefined
                                        ?bookingDetailObj?.airportParking.toFixed(environment.roundUpNumber):'---'}}
                                    </div>
                                </div>
                                <div class="w-full grid grid-cols-4">
                                    <div class="w-full">
                                        Toll
                                    </div>
                                    <div class="w-full">
                                        :
                                    </div>
                                    <div class="w-full">

                                    </div>

                                    <div class="w-full text-right">
                                        + {{bookingDetailObj?.currency}}
                                        {{ bookingDetailObj?.toll != undefined
                                        ?bookingDetailObj?.toll.toFixed(environment.roundUpNumber):'---'}}
                                    </div>
                                </div>
                                <div class="w-full grid grid-cols-4">
                                    <div class="w-full">
                                        Service Charges
                                    </div>
                                    <div class="w-full">
                                        :
                                    </div>
                                    <div class="w-full">

                                    </div>

                                    <div class="w-full text-right">
                                        + {{bookingDetailObj?.currency}}
                                        {{ bookingDetailObj?.serviceCharges != undefined
                                        ?bookingDetailObj?.serviceCharges.toFixed(environment.roundUpNumber):'---'}}
                                    </div>
                                </div>
                                <div class="w-full grid grid-cols-4">
                                    <div class="w-full truncate" title="Toll & Parking Fee">
                                        Toll & Parking Fee
                                    </div>
                                    <div class="w-full">
                                        :
                                    </div>
                                    <div class="w-full">

                                    </div>

                                    <div class="w-full text-right">
                                        + {{bookingDetailObj?.currency}}
                                        {{bookingDetailObj?.tollCharge != undefined ?
                                        bookingDetailObj?.tollCharge.toFixed(environment.roundUpNumber):'---'}}
                                    </div>
                                </div>

                                <div
                                    class="w-full grid grid-cols-4 topBorder mt-2 fontMediumBold font-bold fare-amount">
                                    <div class="w-full fare-amount fontMediumBold font-bold mt-1">
                                        Total Fare
                                    </div>
                                    <div class="w-full">
                                        :
                                    </div>
                                    <div class="w-full"> </div>
                                    <div class="w-full text-right fare-amount fontMediumBold font-bold mt-2">
                                        {{bookingDetailObj?.currency}} {{bookingDetailObj?.actualFinalAmount !=
                                        undefined ? bookingDetailObj?.actualFinalAmount : '---'}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>

            <mat-tab>
                <ng-template mat-tab-label>
                    <h1 class="text-9xl fontMediumBold mb-2 ml-3">
                        <i class="bx bx-edit-alt mr-2 tab-indigo text-2xl"></i>More Info
                    </h1>
                </ng-template>
                <div class="overflow-auto  bg-color journal-scroll">
                    <div class="w-full mt-0 px-3 py-1">
                        <div
                            class="max-w-full flex customBoxBorder justify-between w-full boxBColor mt-4  p-2 rounded-2xl tracking-wide shadow-md customCardHover cursor-pointer">
                            <div class="flex justify-center text-center flex-col items-center pl-3 pr-2 w-full">
                                <h1 class="text-9xl fontMediumBold font-bold"><i
                                        class="fas fa-info-circle mr-2"></i>Booking Status</h1>
                                <div class="w-full flex justify-center text-center items-center flex-col">
                                    <div class="w-full">
                                        Booking Cancel Reason
                                    </div>
                                    <div class="text-center ">
                                        {{bookingDetailObj?.cancelReason ?
                                        bookingDetailObj?.cancelReason
                                        :'---'}}
                                    </div>
                                </div>
                            </div>
                            <div class="fontColorDark custom-font-md px-4 w-full flex items-center justify-center">
                                <div class="fontColorDark custom-font-md ">
                                    <!-- Pending -->
                                    <div class="stamp_img"
                                        *ngIf="bookingDetailObj?.status == 1 && (bookingDetailObj?.driverId == 'automatic' || bookingDetailObj?.driverId == 'manual')">
                                        <img src="../../assets/images/stamp_pending.png">
                                    </div>
                                    <!-- User Cancelled -->
                                    <div class=" stamp_img" *ngIf="bookingDetailObj?.status == 4">
                                        <img src="../../assets/images/stamp_cancel.png">
                                    </div>
                                    <!-- Driver Cancelled -->
                                    <div class=" stamp_img" *ngIf="bookingDetailObj?.status == 5">
                                        <img src="../../assets/images/stamp_cancel.png">
                                    </div>

                                    <div class=" stamp_img" *ngIf="bookingDetailObj?.status == 3">
                                        <img src="../../assets/images/stamp_green-accepted-stamp.png">
                                    </div>
                                    <!-- Dispatcher Cancelled -->
                                    <div class="stamp_img" *ngIf="bookingDetailObj?.status == 11">
                                        <img src="../../assets/images/stamp_cancel.png">
                                    </div>

                                    <!-- Completed -->
                                    <div class=" stamp_img"
                                        *ngIf="bookingDetailObj?.status == 9 && bookingDetailObj?.isForcefullyDelivered == 0">
                                        <img src="../../assets/images/stamp_completed.png">
                                    </div>

                                    <!-- Forcefully delivered -->
                                    <div class=" stamp_img"
                                        *ngIf="bookingDetailObj?.status == 9 && bookingDetailObj?.isForcefullyDelivered == 1">
                                        <img src="../../assets/images/stamp_forcefully.png">
                                    </div>


                                    <!-- On Trip -->
                                    <div class=" stamp_img" *ngIf="bookingDetailObj?.status == 8">
                                        <img src="../../assets/images/stamp-road-trip-.png">
                                    </div>

                                    <!-- Driver Unavailable -->
                                    <div class=" stamp_img" *ngIf="bookingDetailObj?.status == 6">
                                        <img src="../../assets/images/stamp_driver_unavailable.png">
                                    </div>

                                    <!-- Driver Arrived -->
                                    <div class=" stamp_img" *ngIf="bookingDetailObj?.status == 7">
                                        <img src="../../assets/images/stamp_arrived.png">
                                    </div>


                                    <!-- Driver requested -->
                                    <div class=" stamp_img"
                                        *ngIf="bookingDetailObj?.status == 1 && bookingDetailObj?.driverId != 'automatic' && bookingDetailObj?.driverId != 'manual'">
                                        <img src="../../assets/images/stamp_pending1.png">
                                    </div>




                                </div>

                            </div>
                        </div>

                        <div
                            class="max-w-full justify-items-center customBoxBorder justify-center boxBColor mt-4  p-2 rounded-2xl tracking-wide shadow-md customCardHover cursor-pointer">
                            <div class="flex justify-between">
                                <h1 class="text-9xl fontMediumBold font-bold mb-2 pl-3 font-medium"><i
                                        class="fas fa-info-circle mr-2"></i>More Info.</h1>
                            </div>
                            <hr />
                            <div class="fontColorDark custom-font-md px-4">
                                <div class="w-full flex justify-between">
                                    <div class="w-full">
                                        Total Passengers
                                    </div>
                                    <div class="w-full flex gap-8">
                                        <span>:</span> {{bookingDetailObj?.totalPerson}}
                                    </div>
                                </div>
                                <div class="w-full flex justify-between">
                                    <div class="w-full">
                                        Coupon Code:
                                    </div>
                                    <div class="w-full flex gap-8">
                                        <span>:</span> {{bookingDetailObj?.couponDetails !='' &&
                                        bookingDetailObj?.couponDetails !=
                                        undefined ?
                                        bookingDetailObj?.couponDetails.couponCode :
                                        '---'}}
                                    </div>
                                </div>

                                <div class="w-full flex justify-between">
                                    <div class="w-full">
                                        Days:
                                    </div>
                                    <div class="w-full flex gap-8">
                                        <span>:</span> {{bookingDetailObj?.days ? bookingDetailObj?.days : 0}}
                                    </div>
                                </div>
                                <div class="w-full flex justify-between">
                                    <div class="w-full">
                                        Total Bags:
                                    </div>
                                    <div class="w-full flex gap-8">
                                        <span>:</span> {{bookingDetailObj?.totalBags ? bookingDetailObj?.totalBags
                                        :0}}
                                    </div>
                                </div>
                                <div class="w-full flex justify-between">
                                    <div class="w-full">
                                        Total Handicapped:
                                    </div>
                                    <div class="w-full flex gap-8">
                                        <span>:</span> {{bookingDetailObj?.handicappedTotal ?
                                        bookingDetailObj?.handicappedTotal : 0}}
                                    </div>
                                </div>
                                <div class="w-full flex justify-between">
                                    <div class="w-full">
                                        Account ID:
                                    </div>
                                    <div class="w-full flex gap-8">
                                        <span>:</span> {{bookingDetailObj?.accountId ? bookingDetailObj?.accountId:
                                        '---'}}
                                    </div>
                                </div>
                                <div class="w-full flex justify-between">
                                    <div class="w-full">
                                        Website Commission:
                                    </div>
                                    <div class="w-full flex gap-8">
                                        <span>:</span> {{bookingDetailObj?.websiteCommision}}
                                    </div>
                                </div>
                                <div class="w-full flex justify-between">
                                    <div class="w-full">
                                        Packages Info:
                                    </div>
                                    <div class="w-full flex gap-8">
                                        <span>:</span> {{bookingDetailObj?.packageId}}
                                    </div>
                                </div>
                                <div class="w-full flex justify-between">
                                    <div class="w-full">
                                        Packages:
                                    </div>
                                    <div class="w-full flex gap-8">
                                        <span>:</span> ---
                                    </div>
                                </div>
                                <div class="w-full flex justify-between">
                                    <div class="w-full">
                                        Day & Night:
                                    </div>
                                    <div class="w-full flex gap-8">
                                        <span>:</span> {{bookingDetailObj?.timeType == 0?'Day':'Night'}}
                                    </div>
                                </div>
                                <div class="w-full flex justify-between">
                                    <div class="w-full">
                                        Room No.:
                                    </div>
                                    <div class="w-full flex gap-8">
                                        <span>:</span> {{bookingDetailObj?.roomNo?bookingDetailObj?.roomNo:'---'}}
                                    </div>
                                </div>

                                <div class="w-full flex justify-between">
                                    <div class="w-full">
                                        Flight No.:
                                    </div>
                                    <div class="w-full flex gap-8">
                                        <span>:</span>
                                        {{bookingDetailObj?.flightNo?bookingDetailObj?.flightNo:'---'}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>






    </ng-container>
    <ng-container class="app-modal-footer">
        <div class="flex justify-content-end gap-2" *ngIf="bookingDetailObj != null">
            <button (click)="bookingEventDetails(bookingDetailObj.bookingId,bookingDetailObj.dBookId)"
                class="fontMediumBold font-bold custom-blue-btn flex items-center">
                <i class="bx bx-calendar-event text-2xl mr-1" aria-hidden="true"></i>
                Events
            </button>
            <button type="submit" (click)="copyBooking(bookingDetailObj)"
                [disabled]="bookingDetailObj.bookingFlag == 1 || bookingDetailObj.bookingFlag == 2"
                class="disableButtonCursor fontMediumBold font-bold custom-green-btn flex items-center">
                <i class="fa fa-clone" aria-hidden="true"></i> Copy
            </button>
            <button
                *ngIf="bookingDetailObj?.status != 9 && bookingDetailObj?.status != 4 && bookingDetailObj?.status != 5 && bookingDetailObj?.status != 11"
                type="submit" (click)="editBooking(bookingDetailObj)"
                class="fontMediumBold font-bold custom-indigo-btn flex items-center" style="height: 30px;"><i
                    class="bx bx-edit-alt text-2xl mr-1"></i> Edit</button>
        </div>
    </ng-container>
</app-modal>
<!-- Modal Customer show End -->
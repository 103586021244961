<app-modal #bookingEventModal class="booking-event" [scrollTopEnable]="false" [inViewport]="true" [maximizable]="true" [backdrop]="false"
	(closeModal)="closeBookingEventModel()">
	<ng-container class="app-modal-header" [style.background]="Global.first_color">
		<div class="text-white fontMedium text-2xl">#{{selectedBookingEvent.dBookId}}</div>
	</ng-container>
	<ng-container class="app-modal-body">
		<div class="flex flex-col">
			<div class="">
				<div class="py-2 align-middle inline-block min-w-full">
					<div class="  overflow-hidden">
						<table class="min-w-full divide-y divide-gray-200">
							<thead class="bg-color text-2xl fontMediumBold uppercase" [hidden]="bookingActivityBtn">
								<tr>
									<th scope="col" class="px-6 py-3 fontMediumBold  uppercase tracking-wider">
										Time
									</th>
									<th scope="col" class="px-6 py-3 fontMediumBold uppercase tracking-wider">
										Event
									</th>
									<th scope="col" class="px-6 py-3 fontMediumBold uppercase tracking-wider">
										BY
									</th>
								</tr>
							</thead>
							<tbody [hidden]="bookingActivityBtn" class="boxBColor divide-y divide-gray-200">
								<tr *ngFor="let activityItem of bookingEventList" class="cursor-pointer">
									<td class="px-6 py-4 fontMediumBold">
										{{activityItem.displayDate}}
									</td>
									<td class="px-6 py-4">
										<span
											class="px-2 inline-flex leading-10 fontMediumBold rounded-lg btn-completed">{{activityItem.purpose}}</span>
									</td>
									<td class="px-6 py-4 fontMediumBold">
										{{activityItem.info}}
									</td>
								</tr>
							</tbody>
							<tbody [hidden]="!bookingActivityBtn" class="animate-pulse " data-placeholder>
								<tr *ngFor='let in of [].constructor(10) ;let i = index'>
									<td width="33.33%" class="px-3 py-4" data-placeholder>
										<div class="w-36 h-5 bg-gray-400 bg-opacity-75 rounded">
										</div>
									</td>
									<td width="33.33%" class="px-3 py-4" data-placeholder>
										<div class="w-28 h-5 bg-gray-400 bg-opacity-75 rounded">
										</div>
									</td>
									<td width="33.33%" class="px-2 py-4" data-placeholder>
										<div class="w-52 h-5 bg-gray-400 bg-opacity-75 rounded">
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</ng-container>
	<ng-container class="app-modal-footer">
		<div class="flex justify-end">
			<button
				class="text-white fontMediumBold text-2xl py-2 px-4 rounded transition duration-500 ease-in-out driverListCustomStyle inline-flex items-center"
				(click)="getBookingEvent(selectedBookingEvent?.bookingId)" [style.background]="Global.first_color">
				<i class="fas fa-sync mr-2"></i>
				<span>Refresh</span>
			</button>
		</div>
	</ng-container>
</app-modal>
import { Component, HostListener, Input, OnChanges, SimpleChanges } from '@angular/core';
import { GlobalService } from '../services/global.service';
import { NotificationService } from '../user-chat/chat-services/notification.service';

@Component({
  selector: 'app-fab-button',
  templateUrl: './fab-button.component.html',
  styleUrls: ['./fab-button.component.scss']
})
export class FabButtonComponent implements OnChanges {

  constructor(
    public Global: GlobalService,
    private notificationService: NotificationService
  ) { }
  fabAnimation: boolean = false
  userShortName: boolean = false
  countAnimation: boolean = false
  @Input() userChatBubble: any
  positionTop = 100; // Initial top position in pixels
  positionLeft = 550; // Initial left position in pixels
  isDragging = false;
  dragStartX = 0;
  dragStartY = 0;

  userLastMessage: any
  ngOnChanges(changes: SimpleChanges) {

    // messageType : 0 = Text
    // messageType : 1 = Image
    // messageType : 2 = Location
    // messageType : 3 = Booking
    if (this.userChatBubble.messageType == 1) {
      this.userLastMessage = `${this.userChatBubble.userName} sent an image`;
    } else if (this.userChatBubble.messageType == 2) {
      this.userLastMessage = `${this.userChatBubble.userName} sent a location`;
    } else if (this.userChatBubble.messageType == 3) {
      this.userLastMessage = `${this.userChatBubble.userName} requested for booking`;
    } else {
      this.userLastMessage = this.userChatBubble.message
    }
    if (this.Global.disableUserChatNotification) {
      this.notificationService.playNotificationSound();
    }
    this.userShortName = true
    this.fabAnimation = true
    setTimeout(() => {
      this.fabAnimation = false
    }, 3000)
  }

  @HostListener('window:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (this.isDragging) {
      const offsetX = event.clientX - this.dragStartX;
      const offsetY = event.clientY - this.dragStartY;
      this.positionTop += offsetY;
      this.positionLeft += offsetX;
      this.dragStartX = event.clientX;
      this.dragStartY = event.clientY;
    }
  }

  @HostListener('window:mouseup')
  onMouseUp() {
    this.isDragging = false;
  }

  onDragStart(event: MouseEvent) {
    this.isDragging = true;
    this.dragStartX = event.clientX;
    this.dragStartY = event.clientY;
  }

  onDragEnd() {
    this.isDragging = false;
  }

  openPerticularUser() {
    window.open("chat/" + this.userChatBubble.userId, "_blank");
    this.isNotificationClose();
  }

  isUserNotificationClose: boolean = false
  notificationClose() {
    this.isUserNotificationClose = !this.isUserNotificationClose
  }

  isNotificationClose() {
    this.isUserNotificationClose = false
    this.userShortName = false
  }
}

import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})

export class CallmethodService {

	constructor(
		private http: HttpClient
	) { }

	//********************  getCompanyDetails  Service  ***************


	getCompanyDetails(opts: any) {
		const endPoint = "company/details/get";
		return this.http.post(endPoint, opts);
	}

	//********************  login  Service  ***************

	login(opts: any) {
		const endPoint = "dispatcher/login";
		return this.http.post(endPoint, opts);
	}

	//********************  dashboard  Service  ***************

	dashboard(opts: any) {
		const endPoint = "dispatcher/dashboard";
		return this.http.post(endPoint, opts);
	}

	//********************  dashboard  Service  ***************

	getNearestDriver(opts: any) {
		const endPoint = "nearest/driver/get";
		return this.http.post(endPoint, opts);
	}

	//********************  delivered job  ***************

	deliveredJob(opts: any) {
		const endPoint = "booking/force/delivered";
		return this.http.post(endPoint, opts);
	}


	//********************  alert  Service  ***************

	alert(opts: any) {
		const endPoint = "dispatcher/alert/get";
		return this.http.post(endPoint, opts);
	}

	//********************  get zone  Service  ***************

	getZone(opts: any) {
		const endPoint = "dispatcher/zone/get";
		return this.http.post(endPoint, opts)
	}

	//********************  get user address  ***************

	getUserAddress(opts: any) {
		const endPoint = "user/address/find";
		return this.http.post(endPoint, opts)
	}

	//********************  get unassign, assign and active booking Service  ***************

	get_Unassign_Assign_Active_Booking(opts: any) {
		const endPoint = "dispatcher/bookings/get";
		return this.http.post(endPoint, opts)
	}

	getCoupon(opts: any) {
		const endPoint = "dispatcher/coupon/get";
		return this.http.post(endPoint, opts)
	}

	//********************  get driver Service  ***************
	// flag 1 -> onlineDriver
	// flag 2 -> offlineDriver
	// flag 3 -> allDriver
	getAllDriverList(opts: any) {
		const endPoint = "dispatcher/drivers/list/get";
		return this.http.post(endPoint, opts)
	}

	//******************** getServerDataTable //********************//
	getServerDataTable(formRequest: any) {
		const endPoint = "server/side/data/table";
		return this.http.post(endPoint, formRequest);
	}

	//********************  get Driver Details Service  ***************
	getdriverDetails(opts: any) {
		const endPoint = "dispatcher/driver/single/detail/get";
		return this.http.post(endPoint, opts);
	}

	//********************  Driver Switch Service  ***************
	driverSwitchs(opts: any) {
		const endPoint = "corporate/driver/switchs";
		return this.http.post(endPoint, opts);
	}

	//********************  Driver Switch Service  ***************
	driverBackToBack(opts: any) {
		const endPoint = "/driver/back/to/back/set";
		return this.http.post(endPoint, opts);
	}

	//********************  assign booking Driver  ***************
	setAssign(opts: any) {
		const endPoint = "dispatcher/set/assign";
		return this.http.post(endPoint, opts);
	}


	//********************  booking Event  Service  ***************
	bookingEvent(opts: any) {
		const endPoint = "dispatcher/get/activity";
		return this.http.post(endPoint, opts);
	}


	//********************  get auto complete address  ***************

	getAutoCompleteAddress(opts: any) {
		const endPoint = "address/get";
		return this.http.post(endPoint, opts);
	}

	//********************  set auto complete address  ***************
	setAutoCompleteAddress(opts: any) {
		const endPoint = "address/set";
		return this.http.post(endPoint, opts);
	}

	//********************  get fix price  ***************
	getFixedPrice(opts: any) {
		const endPoint = "fix/price/get";
		return this.http.post(endPoint, opts);
	}

	//********************  Create booking  ***************
	createBookingCall(opts: any) {
		const endPoint = "/booking/create";
		return this.http.post(endPoint, opts);
	}

	//********************  Search account user  ***************
	getSearchAccountId(opts: any) {
		const endPoint = "dispatcher/user/get/accountId";
		return this.http.post(endPoint, opts)
	}

	//********************  Search users ***************
	userSearch(opts: any) {
		const endPoint = "dispatcher/single/user/get";
		return this.http.post(endPoint, opts)
	}

	//********************  Search users bookings ***************
	getUserBookings(opts: any) {
		const endPoint = "dispatcher/user/bookings/get";
		return this.http.post(endPoint, opts)
	}

	//********************  Get Single bookings ***************
	getSingleBookingDetails(opts: any) {
		const endPoint = "dispatcher/booking/details/get";
		return this.http.post(endPoint, opts)
	}

	//********************  driver wallet update  Service  ***************

	driverWalletUpdate(opts: any) {
		const endPoint = "dispatcher/driver/wallet/update";
		return this.http.post(endPoint, opts)
	}

	sendMessageToDriver(opts: any) {
		const endPoint = "/dispatcher/send/message";
		return this.http.post(endPoint, opts);
	}

	sendMessageToDispatcher(opts: any) {
		const endPoint = "/dispatcher/send/message/dispatcher";
		return this.http.post(endPoint, opts);
	}

	// *******************************cancel booking ************************
	cancelBooking(opts: any) {
		const endPoint = "booking/cancel";
		return this.http.post(endPoint, opts);
	}

	// *******************************close job ************************
	getCloseJob(opts: any) {
		const endPoint = "dispatcher/closed/job/get";
		return this.http.post(endPoint, opts);
	}

	// *******************************search job ************************
	getSearchJob(opts: any) {
		const endPoint = "dispatcher/search/job/get";
		return this.http.post(endPoint, opts);
	}

	// *******************************search job ************************
	saveToDraftBookingCall(opts: any) {
		const endPoint = "dispatcher/draft/save";
		return this.http.post(endPoint, opts);
	}

	// *******************************get draft booking ************************
	getDraftBookingCall(opts: any) {
		const endPoint = "dispatcher/draft/get";
		return this.http.post(endPoint, opts);
	}

	// *******************************get draft booking ************************
	editBookingCall(opts: any) {
		const endPoint = "dispatcher/booking/update";
		return this.http.post(endPoint, opts);
	}

	// *******************************delete draft booking ************************
	deleteDraftBookingCall(opts: any) {
		const endPoint = "dispatcher/draft/delete";
		return this.http.post(endPoint, opts);
	}

	//remainder  :::: change endpoint to dispatcherControllers.currently pointed to testControlles
	getDriverChat(opts: any) {
		const endPoint = "dispatcher/chat/get";
		return this.http.post(endPoint, opts);
	}

	//remainder  :::: change endpoint to dispatcherControllers.currently pointed to testControlles
	getAllDispatcher(opts: any) {
		const endPoint = "/all/dispatcher/get";
		return this.http.post(endPoint, opts);
	}

	// *******************************get sticky ************************
	getSticky(opts: any) {
		const endPoint = "/dispatcher/sticky/get";
		return this.http.post(endPoint, opts);
	}
	// *******************************create & update sticky ************************
	saveSticky(opts: any) {
		const endPoint = "dispatcher/sticky/save";
		return this.http.post(endPoint, opts);
	}

	// *******************************delete sticky ************************
	deleteSticky(opts: any) {
		const endPoint = "dispatcher/sticky/remove";
		return this.http.post(endPoint, opts);
	}

	// *******************************driver Alert ************************
	getDriverAlert(opts: any) {
		const endPoint = "dispatcher/driver/alert/get";
		return this.http.post(endPoint, opts);
	}

	// *******************************get  Roles ************************
	getDispatcherRoles(opts: any) {
		const endPoint = "/role/dispatcher/get";
		return this.http.post(endPoint, opts);
	}

	//********************  Create Booking accept Notify  ***************
	acceptCreatedBooking(opts: any) {
		const endPoint = "dispatcher/create/accept/notify";
		return this.http.post(endPoint, opts);
	}

	//********************  logout dispatcher  Service  ***************
	logout(opts: any) {
		const endPoint = "dispatcher/logout";
		return this.http.post(endPoint, opts);
	}

	//********************  Get Calender Bookings Data dispatcher  Service  ***************
	bookingCalender(opts: any) {
		const endPoint = "/dispatcher/calender/bookings";
		return this.http.post(endPoint, opts);
	}

	//*******************  Get Calender Shift View Data dispatcher  Service  **************
	driverShiftView(opts: any) {
		const endPoint = "driver/shift/list";
		return this.http.post(endPoint, opts);
	}

	//*******************  Get Calender Shift View Data dispatcher  Service  **************
	driverShiftList(opts: any) {
		const endPoint = "shifts/list";
		return this.http.post(endPoint, opts);
	}

	//*******************  Get Calender Shift View Data dispatcher  Service  **************
	unApproveBooking(opts: any) {
		const endPoint = "/pending/booking/approve";
		return this.http.post(endPoint, opts);
	}

	//*******************  Get Calender Shift View Data dispatcher  Service  **************
	unApproveBookingList(opts: any) {
		const endPoint = "/get/pendingapprove/booking";
		return this.http.post(endPoint, opts);
	}
	//********************  UserChat Service  ***************
	getAllUserChat(opts: any) {
		const endPoint = "helpdesk/get/all";
		return this.http.post(endPoint, opts);
	}
	sendMessageToUser(opts: any) {
		const endPoint = "dispatcher/send/message/user";
		return this.http.post(endPoint, opts);
	}
	getSingleUserChat(opts: any) {
		const endPoint = "helpdesk/get/user";
		return this.http.post(endPoint, opts);
	}
	userPullToRefresh(opts: any) {
		const endPoint = "helpDesk/get/chat/list";
		return this.http.post(endPoint, opts);
	}
	// ************** Leaderboard *****************
	getTopDispatcherList(opts: any) {
		const endPoint = "get/top/dispatcher/list";
		return this.http.post(endPoint, opts);
	}
	// ************** Check User Balance *****************
	checkUserBalance(opts: any) {
		return this.http.post("/get/card", opts);
	}

	// *******************************driver Break Alert ************************
	getDriverBreakAlert(opts: any) {
		const endPoint = "dispatcher/driver/break/alert/get";
		return this.http.post(endPoint, opts);
	}
	getDriverShiftRequestList(opts: any) {
		return this.http.post('/operation/get/driver/offline/request', opts)
	}
	rejectDriverRequest(opts: any) {
		return this.http.post('admin/rejected/driver/offline/request', opts)
	}

	setCancellationReason(opts: any) {
		return this.http.post('/booking/cancel/reason/update', opts)
	}

	getShiftWiseDriverSummary(opts: any) {
		return this.http.post('drivers/shift/summary/get', opts)
	}

}

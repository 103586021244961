<p-dialog [(visible)]="isOpenDriverRequestModel" [draggable]="false" position="top" [style]="{width: '72em'}"
    [keepInViewport]="true">
    <ng-template pTemplate="header">
        <div class="fontMediumBold">
            Driver Offline Request List
        </div>
    </ng-template>
    <section class="notification_container">
        <ng-container *ngIf="!isLoading && driverRequestList.length > 0; else empty">
            <div>
                <ol class="ol-cards alternate" *ngFor="let driver of driverRequestList">
                    <li style="--ol-cards-color-accent:#f68121" *ngIf="driver.status == 0">
                        <div class="step"><i class="fa-solid fa-spinner"></i></div>
                        <div class="title fontMediumBold">Pending Approval</div>
                        <div class="content fontMedium">Driver {{driver.driverName}} has
                            requested to go offline at {{Global.setDateTimeFormat(driver.createdAt)}} while he is on
                            an ongoing {{driver.shiftName}} ({{driver.startTime}} - {{driver.endTime}})
                            shift. Are you sure you want to approve?
                            <div class="btn_container">
                                <button class="button" [disabled]="driverOfflineHide || driver.status == 1"
                                    (click)="offlineDriver(driver)" style="--color-accent:#00a560">
                                    Approve
                                </button>

                                <button class="button" [disabled]="driverOfflineHide || driver.status == 1"
                                    (click)="rejectDriverRequest(driver)" style="--color-accent:#ed1c24">
                                    Reject
                                </button>
                            </div>
                        </div>
                    </li>
                    <li style="--ol-cards-color-accent:#ed1c24" *ngIf="driver.status == 2">
                        <div class="step"><i class="fa-solid fa-circle-exclamation"></i></div>
                        <div class="title fontMedium">Rejected</div>
                        <div class="content fontMedium">Dispatcher {{driver.approvedByName}} has rejected the offline
                            request made by driver {{driver.driverName}} for the {{driver.shiftName}} shift scheduled
                            from {{driver.startTime}} to {{driver.endTime}}. The request was rejected on
                            {{Global.setDateTimeFormat(driver.approvedAt)}}.
                        </div>
                    </li>
                    <li style="--ol-cards-color-accent:#00a560" *ngIf="driver.status == 1">
                        <div class="step"><i class="fa-solid fa-check-double"></i></div>
                        <div class="title fontMedium">Approved</div>
                        <div class="content fontMedium">Dispatcher {{driver.approvedByName}} has approved the offline
                            request made by driver {{driver.driverName}} for the {{driver.shiftName}} shift scheduled
                            from {{driver.startTime}} to {{driver.endTime}}. The request was approved on
                            {{Global.setDateTimeFormat(driver.approvedAt)}}.
                        </div>
                    </li>
                </ol>
            </div>
        </ng-container>
        <div *ngIf="isLoading" class="flex flex-col gap-4">
            <div class="relative p-8 bg-white overflow-hidden rounded-lg shadow-xl">
                <div class="shimmer-overlay"></div>
                <div class="flex">
                    <div class="h-10 w-10 rounded-full bg-purple-400 mr-2"></div>
                    <div style="width: 100%">
                        <div class="w-full h-6 bg-gray-300 rounded mb-3"></div>
                        <div class="w-80 h-6 bg-gray-300 rounded mb-3"></div>
                        <div class="w-64 h-4 bg-gray-300 rounded"></div>
                    </div>
                </div>
            </div>
            <div class="relative p-8 bg-white overflow-hidden rounded-lg shadow-xl">
                <div class="shimmer-overlay"></div>
                <div class="flex">
                    <div class="h-10 w-10 rounded-full bg-purple-400 mr-2"></div>
                    <div style="width: 100%">
                        <div class="w-full h-6 bg-gray-300 rounded mb-3"></div>
                        <div class="w-80 h-6 bg-gray-300 rounded mb-3"></div>
                        <div class="w-64 h-4 bg-gray-300 rounded"></div>
                    </div>
                </div>
            </div>
            <div class="relative p-8 bg-white overflow-hidden rounded-lg shadow-xl">
                <div class="shimmer-overlay"></div>
                <div class="flex">
                    <div class="h-10 w-10 rounded-full bg-purple-400 mr-2"></div>
                    <div style="width: 100%">
                        <div class="w-full h-6 bg-gray-300 rounded mb-3"></div>
                        <div class="w-80 h-6 bg-gray-300 rounded mb-3"></div>
                        <div class="w-64 h-4 bg-gray-300 rounded"></div>
                    </div>
                </div>
            </div>
            <div class="relative p-8 bg-white overflow-hidden rounded-lg shadow-xl">
                <div class="shimmer-overlay"></div>
                <div class="flex">
                    <div class="h-10 w-10 rounded-full bg-purple-400 mr-2"></div>
                    <div style="width: 100%">
                        <div class="w-full h-6 bg-gray-300 rounded mb-3"></div>
                        <div class="w-80 h-6 bg-gray-300 rounded mb-3"></div>
                        <div class="w-64 h-4 bg-gray-300 rounded"></div>
                    </div>
                </div>
            </div>
            <div class="relative p-8 bg-white overflow-hidden rounded-lg shadow-xl">
                <div class="shimmer-overlay"></div>
                <div class="flex">
                    <div class="h-10 w-10 rounded-full bg-purple-400 mr-2"></div>
                    <div style="width: 100%">
                        <div class="w-full h-6 bg-gray-300 rounded mb-3"></div>
                        <div class="w-80 h-6 bg-gray-300 rounded mb-3"></div>
                        <div class="w-64 h-4 bg-gray-300 rounded"></div>
                    </div>
                </div>
            </div>
            <div class="relative p-8 bg-white overflow-hidden rounded-lg shadow-xl">
                <div class="shimmer-overlay"></div>
                <div class="flex">
                    <div class="h-10 w-10 rounded-full bg-purple-400 mr-2"></div>
                    <div style="width: 100%">
                        <div class="w-full h-6 bg-gray-300 rounded mb-3"></div>
                        <div class="w-80 h-6 bg-gray-300 rounded mb-3"></div>
                        <div class="w-64 h-4 bg-gray-300 rounded"></div>
                    </div>
                </div>
            </div>
        </div>
        <ng-template #empty>
            <div *ngIf="!isLoading" class="flex justify-center align-center">
                <img style="width: 300px;" src="../../../assets/images/empty-box.png" alt="" srcset="">
            </div>
        </ng-template>
    </section>
    <p-footer>
        <div *ngIf="driverRequestList.length > 0" class="flex px-16 items-center justify-between w-full bg-color">
            <div>
                <p-paginator [rows]="pageSize" [first]="currentPage * pageSize" [totalRecords]="totalRecords"
                    [showCurrentPageReport]="true" (onPageChange)="nextPage($event)"></p-paginator>
            </div>
            <div>
                <span class="fontMediumBold">Total Records : </span> <span class="fontMeduim"> {{totalRecords}}</span>
            </div>
        </div>
    </p-footer>

</p-dialog>
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataShareService {
  constructor() {}
  
  private subject = new Subject<any>();

  shareData(data: any) {
      this.subject.next(data);
  }

  getData(): Observable<any> {
      return this.subject.asObservable();
  }

}

<app-header></app-header>
<section>
    <main>
        <div class="main_tab_driver_shift mt-2">
            <p-tabView (onChange)="selectShiftDay(selectedIndex)" [(activeIndex)]="selectedIndex">
                <p-tabPanel header="Current Shift">
                    <p-toolbar class="p-toolbar_custom_global" style="margin-top: 1.5rem;">
                        <div class="p-toolbar-group-start">
                            <div class="justify-between flex items-center" style="align-items: center; gap: 20px;">
                                <!--  DROPDOWN DRIVER SHIFT NAME -->
                                <div class="custom_hide_show_filter_dropdown" style="width: 300px">
                                    <p-dropdown [options]="driverShiftViewList" filterBy="name"
                                        placeholder="Select Shift" (onChange)="shiftChange($event)"
                                        [(ngModel)]="selectedShift">
                                        <ng-template pTemplate="selectedItem">
                                            <div class="country-item country-item-value"
                                                style="display: flex; gap: 10px;">
                                                <div>{{ selectedShift.shiftName }}</div>
                                                <div>{{ selectedShift.startTime }}</div>
                                                <div>{{ selectedShift.endTime }}</div>
                                            </div>
                                        </ng-template>
                                        <ng-template let-selectedPlanOpt pTemplate="item">
                                            <div class="country-item">
                                                <div class="font-bold">{{ selectedPlanOpt.shiftName }}</div>
                                                <div>Start Time : {{ selectedPlanOpt.startTime }}</div>
                                                <div>End Time : {{ selectedPlanOpt.endTime }}</div>
                                            </div>
                                        </ng-template>
                                    </p-dropdown>
                                </div>
                                <div>
                                    <p-overlayPanel #op>
                                        <ng-template pTemplate>
                                            <div class="overlay-content">
                                                <div class="form-container">
                                                    <form [formGroup]="emailForm" class="fontMedium">
                                                        <div class="input-group">
                                                            <input type="text fontMedium" formControlName="email" />
                                                            <span>Email</span>
                                                            <div style="clear: both;"></div>
                                                        </div>
                                                        <div *ngIf="email?.invalid && (email?.dirty || email?.touched)"
                                                            class="error-message">
                                                            <div *ngIf="email?.errors?.required">Email is required.
                                                            </div>
                                                            <div *ngIf="email?.errors?.email">Invalid email address.
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>

                                                <div class="button-container">
                                                    <button (click)="getShiftWiseDriverSummary('export')"
                                                        class="export-btn disabled-loader fontMedium"
                                                        [disabled]="disabledForm || emailForm.invalid">
                                                        Export
                                                    </button>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </p-overlayPanel>

                                    <button class="Btn" (click)="op.toggle($event)">
                                        <span class="text fontMedium">Export</span>
                                        <span class="lineIcon"></span>
                                        <span class="svgIcon">
                                            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 7V14M12 14L15 11M12 14L9 11" stroke="white"
                                                    stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M16 17H12H8" stroke="white" stroke-width="1.5"
                                                    stroke-linecap="round" />
                                                <path opacity="0.5"
                                                    d="M2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12Z"
                                                    stroke="white" stroke-width="1.5" />
                                            </svg>
                                        </span>
                                    </button>

                                    <!-- <button pButton icon="pi pi-upload" pTooltip="Export Report"
                                        style="height: 50px; width: 50px;" (click)="op.toggle($event)"></button> -->
                                </div>
                            </div>
                        </div>
                        <div class="list-card">
                            <div class="card-badge px-2 items-center boxBColor customBoxShadow rounded-md transition-all"
                                [class.disabledDiv]="isLoading"
                                [ngStyle]="{'background':filterBy == 1?'#024aeb32':null}" id="card_7"
                                [attr.disabled]="isLoading ? true : false" (click)="setFilter(1)">
                                <div class="icon">
                                    <i class='bx bx-food-menu text-4xl'></i>
                                </div>
                                <div class="card-info">
                                    <div class="badgeCount">{{allDriversCount.all}}</div>
                                    <div class="status fontMedium">All</div>
                                </div>
                            </div>

                            <div class="card-badge px-2 items-center boxBColor customBoxShadow rounded-md transition-all"
                                [class.disabledDiv]="isLoading"
                                [ngStyle]="{'background':filterBy == 4?'#024aeb32':null}" id="card_7"
                                [attr.disabled]="isLoading ? true : false" (click)="setFilter(4)">
                                <div class="icon">
                                    <i class='bx bx-food-menu text-4xl'></i>
                                </div>
                                <div class="card-info">
                                    <div class="badgeCount">{{allDriversCount.online}}</div>
                                    <div class="status fontMedium">Online</div>
                                </div>
                            </div>
                            <div class="card-badge px-2 items-center boxBColor customBoxShadow rounded-md transition-all"
                                [class.disabledDiv]="isLoading"
                                [ngStyle]="{'background':filterBy == 5?'#024aeb32':null}" id="card_7"
                                [attr.disabled]="isLoading ? true : false" (click)="setFilter(5)">
                                <div class="icon">
                                    <i class='bx bx-food-menu text-4xl'></i>
                                </div>
                                <div class="card-info">
                                    <div class="badgeCount">{{allDriversCount.offline}}</div>
                                    <div class="status fontMedium">Offline</div>
                                </div>
                            </div>
                            <div class="card-badge px-2 items-center boxBColor customBoxShadow rounded-md transition-all"
                                [class.disabledDiv]="isLoading"
                                [ngStyle]="{'background':filterBy == 2?'#024aeb32':null}" id="card_7"
                                [attr.disabled]="isLoading ? true : false" (click)="setFilter(2)">
                                <div class="icon">
                                    <i class='bx bx-food-menu text-4xl'></i>
                                </div>
                                <div class="card-info">
                                    <div class="badgeCount">{{allDriversCount.present}}</div>
                                    <div class="status fontMedium">Present</div>
                                </div>
                            </div>
                            <div class="card-badge px-2 items-center boxBColor customBoxShadow rounded-md transition-all"
                                [class.disabledDiv]="isLoading"
                                [ngStyle]="{'background':filterBy == 3?'#024aeb32':null}" id="card_7"
                                [attr.disabled]="isLoading ? true : false" (click)="setFilter(3)">
                                <div class="icon">
                                    <i class='bx bx-food-menu text-4xl'></i>
                                </div>
                                <div class="card-info">
                                    <div class="badgeCount">{{allDriversCount.absent}}</div>
                                    <div class="status fontMedium">Absent</div>
                                </div>
                            </div>
                        </div>

                    </p-toolbar>
                    <div class="relative">
                        <div *ngIf="tableLoader" class="loader-line"></div>
                        <p-tabView>
                            <p-tabPanel *ngFor="let date of global.getObjectKeys(shiftWiseDriverList)" [header]="date">
                                <div class="table-wrapper">
                                    <p-table [value]="shiftWiseDriverList[date].list">
                                        <ng-template pTemplate="header">
                                            <tr class="fontMedium">
                                                <th>Emp. ID</th>
                                                <th>Driver Name</th>
                                                <th>Driver Mobile</th>
                                                <th>Driver Status</th>
                                                <th>First Time On Time</th>
                                                <th>On Time</th>
                                                <th>Last Off Time</th>
                                                <th>Total Working Time</th>
                                                <th>Total Break time</th>
                                                <th>Breaks Counts</th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-rowData>
                                            <tr class="fontMedium">
                                                <td>{{ rowData.driverEmpId }}</td>
                                                <td>{{ rowData.driverName }}</td>
                                                <td>{{ rowData.mobile }}</td>
                                                <td>

                                                    <div *ngIf="isDayOff" class="text-left driver_status">
                                                        <div class="btn-driver-on-break-time">
                                                            Day Off
                                                        </div>
                                                    </div>
                                                    <div *ngIf="!isDayOff" class="text-left driver_status">

                                                        <div *ngIf="!rowData.isAbsent"
                                                            [ngClass]="{'offline_driver': rowData.driverStatus == 0, 'online_driver': rowData.driverStatus == 1 ,'btn-driver-on-break-time' : rowData.driverStatus == 2}">
                                                            <span
                                                                *ngIf="rowData.driverStatus == 0 || rowData.driverStatus == 1">
                                                                {{ rowData.driverStatus == 0 ?
                                                                "Offline" : "Online" }}
                                                            </span>
                                                            <span *ngIf="rowData.driverStatus == 2">
                                                                On Break
                                                            </span>
                                                        </div>
                                                        <div *ngIf="rowData.isAbsent" class="btn-driver-absent-time">
                                                            Absent
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>{{ rowData.dayOfFirstOnTime ? rowData.dayOfFirstOnTime : '---'
                                                    }}
                                                </td>
                                                <td>{{ rowData.onTime ? rowData.onTime : '---' }}</td>
                                                <td>{{ rowData.offTime ? rowData.offTime : '---' }}</td>
                                                <td>
                                                    <div class="btn-driver-working-time"
                                                        id="timer-{{ rowData.driverId }}"> {{
                                                        convertMinutes(rowData.totalMinutes) }} </div>
                                                </td>
                                                <td id="breakTimer-{{ rowData.driverId }}">{{
                                                    convertMinutes(rowData.totalBreakTime) }}</td>
                                                <td>{{ rowData.totalBreaks }}</td>
                                            </tr>
                                        </ng-template>
                                    </p-table>


                                    <div *ngIf="allDriversCount.allFilter > 0"
                                        class="flex px-16 items-center justify-between fixed bottom-0 w-full bg-color">
                                        <p-paginator [rows]="perPageLimit" [first]="currentPage * perPageLimit"
                                            [totalRecords]="allDriversCount.allFilter" [showCurrentPageReport]="true"
                                            (onPageChange)="nextPage($event)"></p-paginator>
                                        <div>
                                            <span class="fontMediumBold">Total Records : </span> <span
                                                class="fontMeduim"> {{allDriversCount.allFilter}}</span>
                                        </div>
                                    </div>
                                </div>
                            </p-tabPanel>

                            <!-- <table class="table-shimmer" *ngIf="isLoading">
                                <thead>
                                    <tr>
                                        <th>Emp. ID</th>
                                        <th>Driver Name</th>
                                        <th>Driver Mobile</th>
                                        <th>Driver Status</th>
                                        <th>First Time On Time</th>
                                        <th>On Time</th>
                                        <th>Last Off Time</th>
                                        <th>Total Working Time</th>
                                        <th>Total Break Time</th>
                                        <th>Break Counts</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr> 
                            </tbody>
                            </table> -->
                        </p-tabView>
                        <div *ngIf="!tableLoader && shiftWiseDriverList.length == 0">
                            <div class="no_data_found_">
                                <img src="../../assets/images/no_data.jpg" />
                            </div>
                        </div>
                    </div>
                </p-tabPanel>
                <p-tabPanel header="Past Shift">
                    <div>
                        <p-toolbar class="p-toolbar_custom_global" style="margin-top: 1.5rem;">
                            <div class="p-toolbar-group-start">
                                <div class="justify-between flex" style="align-items: center; gap: 20px;">
                                    <!--  DROPDOWN DRIVER NAME -->
                                    <div class="custom_hide_show_filter_dropdown" style="width: 300px">
                                        <p-dropdown [options]="driverShiftViewList" filterBy="name"
                                            placeholder="Select Shift" (onChange)="shiftChange($event)"
                                            [(ngModel)]="selectedShift">
                                            <ng-template pTemplate="selectedItem">
                                                <div class="country-item country-item-value"
                                                    style="display: flex; gap: 10px;">
                                                    <div>{{ selectedShift.shiftName }}</div>
                                                    <div>{{ selectedShift.startTime }}</div>
                                                    <div>{{ selectedShift.endTime }}</div>
                                                </div>
                                            </ng-template>
                                            <ng-template let-selectedPlanOpt pTemplate="item">
                                                <div class="country-item">
                                                    <div class="font-bold">{{ selectedPlanOpt.shiftName }}</div>
                                                    <div>Start Time : {{ selectedPlanOpt.startTime }}</div>
                                                    <div>End Time : {{ selectedPlanOpt.endTime }}</div>
                                                </div>
                                            </ng-template>
                                        </p-dropdown>
                                    </div>
                                    <div class="flex flex-col">
                                        <input type="text" id="dateRangePickerValue" style="width: 175px;"
                                            class="custom-select rounded-md boxBColor px-2 fontMedium p-2 w-80 h-14 outline-none focus:outline-none "
                                            ngxDaterangepickerMd [showCustomRangeLabel]="true"
                                            [(ngModel)]="selectedDate" [locale]="{format:dateFormat}"
                                            (datesUpdated)="dateRangeSelect($event)" [maxDate]="maxDate"
                                            [minDate]="minDate" [alwaysShowCalendars]="true" [ranges]="ranges"
                                            [linkedCalendars]="true" [showClearButton]="true" placeholder="Select Date"
                                            autocomplete="off" />
                                    </div>

                                    <div class="radio_btn_container">

                                        <div class="radio-inputs">
                                            <label>
                                                <input class="radio-input" type="radio" name="dateMode"
                                                    [(ngModel)]="currentMode" (change)="setDateMode('past')"
                                                    value="past">
                                                <span class="radio-tile">
                                                    <span class="radio-icon">
                                                        <!-- Add an SVG or icon here if needed -->
                                                    </span>
                                                    <span class="radio-label fontMedium">Past Date</span>
                                                </span>
                                            </label>
                                            <label>
                                                <input class="radio-input" type="radio" name="dateMode"
                                                    [(ngModel)]="currentMode" (change)="setDateMode('future')"
                                                    value="future">
                                                <span class="radio-tile">
                                                    <span class="radio-icon">
                                                        <!-- Add an SVG or icon here if needed -->
                                                    </span>
                                                    <span class="radio-label fontMedium">Future Date</span>
                                                </span>
                                            </label>
                                        </div>

                                    </div>

                                    <!-- <button (click)="setDateMode('past')"
                                        [ngClass]="{'active': currentMode === 'past'}">Past Dates</button>
                                    <button (click)="setDateMode('future')"
                                        [ngClass]="{'active': currentMode === 'future'}">Future Dates</button> -->
                                    <div>

                                        <p-overlayPanel #op1>
                                            <ng-template pTemplate>
                                                <div class="overlay-content">
                                                    <div class="form-container">
                                                        <form [formGroup]="emailForm" class="fontMedium">
                                                            <div class="input-group">
                                                                <input type="text fontMedium" formControlName="email" />
                                                                <span>Email</span>
                                                                <div style="clear: both;"></div>
                                                            </div>
                                                            <div *ngIf="email?.invalid && (email?.dirty || email?.touched)"
                                                                class="error-message">
                                                                <div *ngIf="email?.errors?.required">Email is required.
                                                                </div>
                                                                <div *ngIf="email?.errors?.email">Invalid email address.
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>

                                                    <div class="button-container">
                                                        <button (click)="getShiftWiseDriverSummary('export')"
                                                            class="export-btn disabled-loader fontMedium"
                                                            [disabled]="disabledForm || emailForm.invalid">
                                                            Export
                                                        </button>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </p-overlayPanel>

                                        <!-- <p-overlayPanel #op1>
                                            <ng-template pTemplate>
                                                <div class="flex align-item-center justify-content-center gap-2"
                                                    style="width: 500px;">
                                                    <div class="w-full mt-1">
                                                        <form [formGroup]="emailForm">
                                                            <div
                                                                class="cd-btn main-action custom-input-icon-left w-full">
                                                                <input type="text" formControlName="email" />
                                                                <span>Email</span>
                                                                <div style="clear: both;"></div>
                                                            </div>
                                                            <div *ngIf="email?.invalid && (email?.dirty || email?.touched)"
                                                                class="error-message">
                                                                <div *ngIf="email?.errors?.required">Email is required.
                                                                </div>
                                                                <div *ngIf="email?.errors?.email">Invalid email address.
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>

                                                    <div class="custom_table_filters">
                                                        <button (click)="getShiftWiseDriverSummary('export')"
                                                            class="export_btn disabled_loader"
                                                            [disabled]="disabledForm || emailForm.invalid">
                                                            Export
                                                        </button>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </p-overlayPanel> -->

                                        <button class="Btn" (click)="op1.toggle($event)">
                                            <span class="text fontMedium">Export</span>
                                            <span class="lineIcon"></span>
                                            <span class="svgIcon">
                                                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12 7V14M12 14L15 11M12 14L9 11" stroke="white"
                                                        stroke-width="1.5" stroke-linecap="round"
                                                        stroke-linejoin="round" />
                                                    <path d="M16 17H12H8" stroke="white" stroke-width="1.5"
                                                        stroke-linecap="round" />
                                                    <path opacity="0.5"
                                                        d="M2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12Z"
                                                        stroke="white" stroke-width="1.5" />
                                                </svg>
                                            </span>
                                        </button>


                                        <!-- <button pButton icon="pi pi-upload" style="height: 50px; width: 50px;"
                                            pTooltip="Export Report" (click)="op1.toggle($event)"></button> -->
                                    </div>
                                </div>
                            </div>
                        </p-toolbar>
                    </div>
                    <div>
                        <div *ngIf="tableLoader" class="loader-line"></div>
                        <p-tabView>
                            <p-tabPanel *ngFor="let date of global.getObjectKeys(shiftWiseDriverList)" [header]="date">
                                <div *ngIf="isExport; else dataTable">
                                    <p class="export-message">Data has been exported and will be sent to you shortly.
                                    </p>
                                </div>
                                <ng-template #dataTable>
                                    <!-- <div class="counts-wrapper">
                                        <button (click)="filterData(date, 'all')">
                                            All: {{ shiftWiseDriverCounts[date]?.total || 0 }}
                                        </button>
                                        <button (click)="filterData(date, 'present')">
                                            Present: {{ shiftWiseDriverCounts[date]?.present || 0 }}
                                        </button>
                                        <button (click)="filterData(date, 'absent')">
                                            Absent: {{ shiftWiseDriverCounts[date]?.absent || 0 }}
                                        </button>
                                    </div> -->
                                    <div class="table-wrapper">
                                        <p-table [value]="getFilteredData(date)">
                                            <ng-template pTemplate="header">
                                                <tr class="fontMedium">
                                                    <th>Emp. ID</th>
                                                    <th>Driver Name</th>
                                                    <th>Driver Mobile</th>
                                                    <th>Driver Status</th>
                                                    <th>First Time On Time</th>
                                                    <th>On Time</th>
                                                    <th>Last Off Time</th>
                                                    <th>Total Working Time</th>
                                                    <th>Total Break time</th>
                                                    <th>Breaks Counts</th>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body" let-rowData>
                                                <tr class="fontMedium">
                                                    <td>{{ rowData.driverEmpId }}</td>
                                                    <td>{{ rowData.driverName }}</td>
                                                    <td>{{ rowData.mobile }}</td>
                                                    <td>

                                                        <div *ngIf="isDayOff" class="text-left driver_status">
                                                            <div class="btn-driver-on-break-time">
                                                                Day Off
                                                            </div>
                                                        </div>

                                                        <div *ngIf="!isDayOff">
                                                            <div *ngIf="!rowData.isFutureDate"
                                                                class="text-left driver_status">
                                                                <div *ngIf="!rowData.isAbsent" class="online_driver">
                                                                    Present
                                                                </div>
                                                                <div *ngIf="rowData.isAbsent"
                                                                    class="btn-driver-absent-time">Absent</div>
                                                            </div>
                                                            <div *ngIf="rowData.isFutureDate"
                                                                class="text-left driver_status">
                                                                <div class="online_driver">Awaiting Shift
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>{{ rowData.dayOfFirstOnTime ? rowData.dayOfFirstOnTime : '---'
                                                        }}</td>
                                                    <td>{{ rowData.onTime ? rowData.onTime : '---' }}</td>
                                                    <td>{{ rowData.offTime ? rowData.offTime : '---' }}</td>
                                                    <td>{{ convertMinutes(rowData.totalMinutes) }}</td>
                                                    <td>{{ convertMinutes(rowData.totalBreakTime) }}</td>
                                                    <td>{{ rowData.totalBreaks }}</td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                                    </div>
                                    <p>Data Count: d</p>
                                </ng-template>
                            </p-tabPanel>
                        </p-tabView>
                        <div *ngIf="!tableLoader && shiftWiseDriverList.length == 0">
                            <div class="no_data_found_">
                                <img src="../../assets/images/no_data.jpg" />
                            </div>
                        </div>
                    </div>
                </p-tabPanel>
            </p-tabView>
        </div>
    </main>
</section>
<p-toast></p-toast>

<p-dialog header="Export Sheet" [(visible)]="isOpenPastSheetExportModel" [draggable]="false" [modal]="true"
    [style]="{width: '40vw'}" [resizable]="false" [responsive]="true">

    <div class="overlay-content" style="width: 100%;">
        <div class="form-container">
            <form [formGroup]="emailForm" class="fontMedium">
                <div class="input-group">
                    <input type="text fontMedium" formControlName="email" />
                    <span>Email</span>
                    <div style="clear: both;"></div>
                </div>
                <div *ngIf="email?.invalid && (email?.dirty || email?.touched)" class="error-message">
                    <div *ngIf="email?.errors?.required">Email is required.
                    </div>
                    <div *ngIf="email?.errors?.email">Invalid email address.
                    </div>
                </div>
            </form>
        </div>

        <div class="button-container">
            <button (click)="getShiftWiseDriverSummary('export')" class="export-btn disabled-loader fontMedium"
                [disabled]="disabledForm || emailForm.invalid">
                Export
            </button>
        </div>
    </div>

    <!-- <div class="flex align-item-center justify-content-center gap-2">
        <div class="w-full mt-1">
            <form [formGroup]="emailForm">
                <div class="cd-btn main-action custom-input-icon-left w-full">
                    <input type="text" formControlName="email" />
                    <span>Email</span>
                    <div style="clear: both;"></div>
                </div>
                <div *ngIf="email?.invalid && (email?.dirty || email?.touched)" class="error-message">
                    <div *ngIf="email?.errors?.required">Email is required.
                    </div>
                    <div *ngIf="email?.errors?.email">Invalid email address.
                    </div>
                </div>
            </form>
        </div>

        <div class="custom_table_filters">
            <button (click)="getShiftWiseDriverSummary('export')" class="export_btn disabled_loader"
                [disabled]="disabledForm || emailForm.invalid">
                Export
            </button>
        </div>
    </div> -->
</p-dialog>
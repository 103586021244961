import { Component, Input } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent {
  @Input() dispatcherDetails:any = {};
  profilePicUrl: any

  constructor( public Global: GlobalService ) { }
}
<div class="fab" *ngIf="userShortName" [style.top.px]="positionTop" [style.left.px]="positionLeft"
  [ngClass]="userShortName ? 'fabAnimation request-loader' : ''" (mousedown)="onDragStart($event)"
  (mousemove)="onMouseMove($event)" (mouseup)="onDragEnd()" (dblclick)="openPerticularUser()" (click)="notificationClose()">
  <button mat-fab color="primary">
    {{this.Global.getShortName(userChatBubble.userName)}}
  </button>
  <div class="count">
    <button class="Btn">
      <i class='bx bxs-bell bx-tada svgIcon'></i>
      <span class="tooltip" [ngClass]="userChatBubble.messageType == 2 || userChatBubble.messageType == 3 ? 'tooltipType' : ''">{{userLastMessage}}</span>
    </button>
  </div>
  <div class="isUserNotificationClose" (click)="isNotificationClose()" *ngIf="isUserNotificationClose">
    <div>
      <i class='bx bx-x text-light'></i>
    </div>
  </div>
</div>

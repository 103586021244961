import { Component, Input, OnInit } from "@angular/core";
import * as moment from "moment";
import { DataShareService } from "src/app/services/data-share.service";
import { GlobalService } from "src/app/services/global.service";

@Component({
  selector: "app-chat-bubble",
  templateUrl: "./chat-bubble.component.html",
  styleUrls: ["./chat-bubble.component.scss"],
})
export class ChatBubbleComponent implements OnInit {
  @Input() chatData: any;
  locationUrl: any;
  imageUrl: any;
  sendTime: any;
  userName: any;
  bookingUserData: any;
  bookingType: string = "";
  Object: any;
  isBookingCreatedSuccess: boolean = true;
  constructor(
    public Global: GlobalService,
    public shareData: DataShareService
  ) {}
  ngOnInit(): void {
    this.Global.shareData.getData().subscribe((result: any) => {
      if (
        result.function == "bookingCreatedSuccessfully" &&
        result.type == "userChatView" &&
        result.data != ""
      ) {
        console.log(
          "****bookingCreatedSuccessfully* result.data *****",
          result.data
        );

        if(this.chatData.chatId == result.data.chatId) {
          console.log(':::::::::::::::::::::::::: Trueee ::::::::::::::::::::::::::',result.data.isBookingCreated);
          this.chatData.isBookingCreated = 1;
          // this.chatData.isLeadBooking = response.data.isLeadBooking
        }

      }
    });
    // bookingFlag = 0 City Ride && isBox = 0
    // bookingFlag = 0 Buttler && isBox = 1
    // bookingFlag = 1 Rental
    // bookingFlag = 2 Outstation
    if (this.chatData.messageType === 2) {
      let data = this.chatData.message.split(",");
      this.locationUrl = `https://www.google.com/maps?q=${data[0]},${data[1]}`;
    }
    if (this.chatData.messageType === 1) {
      this.imageUrl = `${this.chatData.message}`;
    }
    if (this.chatData.messageType === 3) {
      this.bookingUserData = this.chatData.bookingJson
        ? this.chatData.bookingJson
        : undefined;

      if (this.bookingUserData) {
        if (
          this.bookingUserData.isBox &&
          this.bookingUserData.isBox == 1 &&
          this.bookingUserData.bookingFlag == 0
        ) {
          this.bookingType = "Delivery";
        } else if (
          this.bookingUserData.isBox == 0 &&
          this.bookingUserData.bookingFlag == 0
        ) {
          this.bookingType = "Ride";
        } else if (this.bookingUserData.bookingFlag == 1) {
          this.bookingType = "Rentals";
        } else if (this.bookingUserData.bookingFlag == 2) {
          this.bookingType = "Outstation";
        }
      }
    }
    this.sendTime = moment(this.chatData.createdAt).format("hh:mm A");
    this.userName = this.Global.getShortName(
      this.chatData.sendBy === 0
        ? this.chatData.userName
        : this.chatData.dispatcherName
    );

    console.log("Chat Details", this.chatData);
  }

  async createBookingForNow(event: Event, chatId: any) {
    console.log("############### event.target ###############", event.target);
    this.shareData.getData().subscribe((response) => {
      if (response) {
        if (
          response.function == "cancelBooking" &&
          response.type == "chatBubble"
        ) {
          (event.target as HTMLButtonElement).disabled = false;
          (event.target as HTMLElement).classList.remove("disabled");
          (event.target as HTMLElement).innerHTML = "Book Now";
        } else if (
          response.function == "createdBookingSuccessfully" &&
          response.type == "chatBubble" && response.data != ""
        ) {
          console.log('***** this.chatData.chatId === response.data.chatId *****',this.chatData.chatId === response.data.chatId);
              console.log(':::::::::::::::::::::::::: this.chatData after booking ::::::::::::::::::::::::::',this.chatData);
              console.log(':::::::::::::::::::::::::: response.data after booking ::::::::::::::::::::::::::',response.data);
              if(this.chatData.chatId === response.data.chatId) {
                console.log(':::::::::::::::::::::::::: Trueee ::::::::::::::::::::::::::',response.data.isBookingCreated);
                this.chatData.isBookingCreated = 1;
                // this.chatData.isLeadBooking = response.data.isLeadBooking
              }
          (event.target as HTMLButtonElement).disabled = true;
          (event.target as HTMLElement).classList.add("disabled");
          (event.target as HTMLElement).innerHTML = "Booked";
        }
      }
    });
    let data = { ...this.bookingUserData, chatId: chatId };
    this.shareData.shareData({
      function: "createBookingForNow",
      type: "chatBubble",
      data: data,
    });
  }

  imageClicked(event: any): void {
    let url = event.target.src;
    let modal = document.getElementById("myModal") as HTMLElement;
    let img = event.target as HTMLImageElement;
    var modalImg = document.getElementById("img01") as HTMLImageElement;
    modal.style.display = "block";
    modalImg.src = url;
  }

  closeImagePreview(): void {
    (document.getElementById("myModal") as HTMLElement).style.display = "none";
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { DataShareService } from '../../../../services/data-share.service';

@Component({
  selector: 'app-chat-top-view',
  templateUrl: './chat-top-view.component.html',
  styleUrls: ['./chat-top-view.component.scss']
})
export class ChatTopViewComponent implements OnInit {
  @Input() selectedUserName: any
  selectedUserChats: any[] = []
  constructor(
    public Global: GlobalService,
    public shareData: DataShareService,

  ) { }

  ngOnInit(): void {
    this.shareData.getData().subscribe((result) => {
      if (result.function == 'chatDataArray' && result.type == 'chatLeftSide' && result.data != '') {
        this.selectedUserChats = result.data
        console.log('**bnfgng*** this.selectedUserChats *****',this.selectedUserChats);
        this.selectedUserName = this.selectedUserName != undefined && this.selectedUserName != null ? this.selectedUserName : this.selectedUserChats[0].userName
      }
    });
  }

  searchedUserList: any
  userSearchedChats: any
  isSearch: boolean = true
  globalChatSearch(event: any) {
    this.searchedUserList = event.target.value
    if (this.searchedUserList) {
      this.userSearchedChats = this.selectedUserChats.filter((item: any) => item.message.toLowerCase().includes(this.searchedUserList.toLowerCase()));
      if (this.userSearchedChats.length > 0) {
        this.shareData.shareData({ function: 'searchedChats', type: 'chatTopView', data: this.userSearchedChats })
      }
    }
  }
}
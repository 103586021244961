import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import './polyfills';

import { registerLicense } from '@syncfusion/ej2-base';

// Registering Syncfusion license key
registerLicense('Mgo+DSMBaFt/QHRqVVhkVFpFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQF5jS35adkJgX3pZeXRQQA==;Mgo+DSMBPh8sVXJ0S0J+XE9AflRDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS31TdEVqWXhddnFST2JZVw==;ORg4AjUWIQA/Gnt2VVhkQlFacldJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkdjUH9Zc3JQQWlYU0U=;MTA5NzU0OUAzMjMwMmUzNDJlMzBjV3pVTGptNjlIZ3R1U3BPbi95amxjeEJOZ0JjSHVlOVJRYkl4ZjFMSGFvPQ==;MTA5NzU1MEAzMjMwMmUzNDJlMzBCZHdhM1FVNlZQRCs5czBHWWY0d1doOVlsbC8yblM1ekduSFVjKytFUjNrPQ==;NRAiBiAaIQQuGjN/V0Z+WE9EaFtKVmJLYVB3WmpQdldgdVRMZVVbQX9PIiBoS35RdUVhWXZed3dTQ2hcU0xw;MTA5NzU1MkAzMjMwMmUzNDJlMzBQa0dLOWt6ODZkSkxGUS9PSnhWeG52YXlGZWFzUk5yN0RGek9GblB4UmdZPQ==;MTA5NzU1M0AzMjMwMmUzNDJlMzBXSEpmQTdtNnpJd0R6SGJLd21HSDY4enlzcTNMWmc4VHlyb3B0ajAxbjJ3PQ==;Mgo+DSMBMAY9C3t2VVhkQlFacldJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkdjUH9Zc3JQTmFcV0U=;MTA5NzU1NUAzMjMwMmUzNDJlMzBnZEh4bEg2cUFLOWlOenJuLzkvRGMyc2dtRUxKNy96SkZyemFWQTNib0pVPQ==;MTA5NzU1NkAzMjMwMmUzNDJlMzBic2RuZXpYVTF3Q0tyc0dWNDN0b0tjTlZvT3dHMHZzUVlYMUxGVEI3YkhRPQ==;MTA5NzU1N0AzMjMwMmUzNDJlMzBQa0dLOWt6ODZkSkxGUS9PSnhWeG52YXlGZWFzUk5yN0RGek9GblB4UmdZPQ==');

if (environment.production) {
  enableProdMode();
  window.console.log = () => { };
  window.console.info = () => { };
  window.console.warn = () => { };
  window.console.error = () => { };
  window.console.time = () => { };
  window.console.timeEnd = () => { };
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

import { Component, ElementRef, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { ModalComponent } from 'ng-modal-lib';
import { of, Subject, Subscription } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, map, mergeMap, takeUntil } from 'rxjs/operators';
import { HomeComponent } from '../home/home.component';
import { CallmethodService } from '../services/callmethod.service';
import { DataShareService } from '../services/data-share.service';
import { GlobalService } from '../services/global.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})

export class CustomersComponent implements OnInit {
  private subscription: Subscription = new Subscription;
  private unsubscribe$ = new Subject<void>();

  environment: any = environment;
  moment: any = moment;

  @ViewChildren(HomeComponent) homeC!: HomeComponent;

  constructor(
    public Global: GlobalService,
    public CallMethod: CallmethodService,
    public shareData: DataShareService
  ) {
    this.subscription = this.searchUser.pipe(
      map((event: any) => event.target.value),
      debounceTime(500),
      distinctUntilChanged(),
      mergeMap((search: any) => of(search).pipe(delay(0))),
    ).subscribe((data: any) => {
      this.searchValue = data;
      this.userSearchCall(0);
      this.getBookings(0);
    });
  }

  ngOnInit(): void {
    this.subscription = this.shareData.getData().subscribe(data => {
      if (data) {
        if (data.function == 'changeThemeColor') {
          this.cutomerDetailsModal.modalHeader.nativeElement.style.backgroundColor = this.Global.first_color;
          this.cutomerDetailsModal.modalFooter.nativeElement.style.backgroundColor = this.Global.third_color;
          this.cutomerDetailsModal.modalRoot.nativeElement.style.border = '1px solid ' + this.Global.first_color;
        }
        else if (data.function == 'customerDetailPopup') {
          this.customerDetailsModalShow(data.searchValue)
        }
      }
    });
  }
  ngOnDestroy(): void {

    this.subscription.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();

  }

  @ViewChild("customerDetails") public cutomerDetailsModal!: ModalComponent;
  @ViewChild("searchUsers") searchUsers!: ElementRef;
  showCustomerPopup: boolean = false;
  customerDetailsModalShow(searchValue: string = '') {
    this.cutomerDetailsModal.modalRoot.nativeElement.style.zIndex = this.cutomerDetailsModal.getMaxModalIndex() + 2;
    if (this.showCustomerPopup == false) {
      setTimeout(() => {
        this.cutomerDetailsModal.modalRoot.nativeElement.style.top = environment.windowPopupStyle.customers.top;
      }, 0);

      if (this.cutomerDetailsModal.modalBody.nativeElement.style.height.trim() == '') {
        this.cutomerDetailsModal.modalBody.nativeElement.style.height = environment.windowPopupStyle.customers.height;
        let width = parseInt(environment.windowPopupStyle.customers.width.replace('px', ''));
        if (window.innerWidth < parseInt(environment.windowPopupStyle.customers.width.replace('px', ''))) {
          width = window.innerWidth - 2;
        }
        this.cutomerDetailsModal.modalRoot.nativeElement.style.width = width.toString() + 'px';
      }

      this.cutomerDetailsModal.modalHeader.nativeElement.style.backgroundColor = this.Global.first_color;
      this.cutomerDetailsModal.modalFooter.nativeElement.style.backgroundColor = this.Global.third_color;
      this.cutomerDetailsModal.modalRoot.nativeElement.style.border = '1px solid ' + this.Global.first_color;

      this.cutomerDetailsModal.show();
      this.showCustomerPopup = true;
    }

    setTimeout(() => { // this will make the execution after the above boolean has changed
      this.searchUsers.nativeElement.focus();
    }, 500);

    if (searchValue != '') {
      this.searchValue = searchValue;
      this.searchUsers.nativeElement.value = searchValue;
      this.userSearchCall(0);
      this.getBookings(0);
    }
  }
  closeCustomerModel() {
    this.showCustomerPopup = false;
    this.setStyle = false;
  }

  searchValue: string = '';
  userList: any = [];
  userCurrentpage: number = 0;
  userCount: number = 0;
  userLimit: number = 10;

  public searchUser = new Subject<KeyboardEvent>();
  seachUser(event: any) {
    if (this.searchValue.trim() != '' || event.target.value.trim() != '') {
      this.searchUser.next(event);
    }
  }

  userSearchShimmer: boolean = false;
  userSearchCall(page: any) {
    // if (this.searchValue.trim() != '') {
      this.isOpenTab = 0;
    this.userSearchShimmer = true;
    this.userCurrentpage = parseInt(page);
    let userOffset = (page == 0) ? 0 : ((page * this.userLimit) - this.userLimit);

    let req = {
      search: this.searchValue,
      offset: userOffset,
      limit: this.userLimit,
    };
    this.CallMethod.userSearch(req).pipe(takeUntil(this.unsubscribe$)).subscribe((result: any) => {
      this.userList = [];
      if (result.status) {
        if (result.data.length > 0) {
          for (let i = 0; i < result.data.length; i++) {
            this.userList.push({
              userEmail: result.data[i].userEmail,
              userId: result.data[i].userId,
              userFLName: this.Global.getFirstCharacter(result.data[i].userName),
              userIsCorporate: result.data[i].userIsCorporate,
              userMobile: result.data[i].userMobile,
              userName: result.data[i].userName,
            });
          }
        }

        this.userCount = parseInt(result.totalCount);
      }
      this.userSearchShimmer = false;
    });
    // }
  }
  userBookingList: any[] = [];
  userBookingCurrentpage: number = 0;
  userBookingCount: number = 0;
  userBookingLimit: number = 10;
  userBookingUserId: string = "";
  getUserBookings(userId: string) {
    this.userBookingUserId = userId;
    this.getUserBookingsCall(0);
  }

  isLoadUserBookingData: boolean = false;
  getUserBookingsCall(page: any = 0) {
    this.isLoadUserBookingData = true;
    this.isOpenTab = 1;
    this.userBookingCurrentpage = page;
    let userBookingOffset = (this.userBookingCurrentpage == 0) ? 0 : ((this.userBookingCurrentpage * this.userBookingLimit) - this.userBookingLimit);
    let request = {
      userId: this.userBookingUserId,
      offset: userBookingOffset,
      limit: this.userLimit,
    }
    this.CallMethod.getUserBookings(request).pipe(takeUntil(this.unsubscribe$)).subscribe((result: any) => {
      this.isLoadUserBookingData = false;
      this.userBookingList = [];
      if (result.status) {
        this.userBookingList = result.data;
        this.userBookingCount = result.totalCount;
      }
    })
  }

  bookingList: any[] = [];
  bookingCurrentpage: number = 0;
  bookingCount: number = 0;
  bookingLimit: number = 10;
  isLoadBookingData: boolean = false;
  getBookings(page: any = 0){
    this.isLoadBookingData = true;
    this.bookingCurrentpage = page;
    let bookingOffset = (this.bookingCurrentpage == 0) ? 0 : ((this.bookingCurrentpage * this.bookingLimit) - this.bookingLimit);
    let request = {
      search: this.searchValue,
      offset: bookingOffset,
      limit: this.bookingLimit,
    }

    this.CallMethod.getSearchJob(request).pipe(takeUntil(this.unsubscribe$)).subscribe((result: any) => {
      this.isLoadBookingData = false;
      this.bookingList = [];
      if (result.status) {
        this.bookingList = result.data;
        this.bookingCount = result.totalRecords;
      }
    })
  }


  selectedBookingId: any = null;
  setStyle: boolean = false;
  setBookingDetails(bookingData: any) {
    this.selectedBookingId = bookingData.bookingId;
    this.shareData.shareData({ 'function': 'openBookingDetails', 'data': bookingData });
    if (this.setStyle == false) {
      setTimeout(() => {
        let left: any = parseInt("" + window.innerWidth) - parseInt(this.cutomerDetailsModal.modalRoot.nativeElement.style.width.replace('px', '')) - parseInt(environment.windowPopupStyle.bookingDetails.width.replace('px', '')) - 4;

        if (left < 0) {
          let width = parseInt(this.cutomerDetailsModal.modalRoot.nativeElement.style.width.replace('px', '')) + left;
          width -= 2;
          this.cutomerDetailsModal.modalRoot.nativeElement.style.width = width.toString() + 'px';
          left = 2;
        }

        this.cutomerDetailsModal.modalRoot.nativeElement.style.left = left.toString() + 'px';
        this.cutomerDetailsModal.modalBody.nativeElement.style.height = environment.windowPopupStyle.bookingDetails.height;

      }, 0);
      this.setStyle = true;
    }
  }

  setUserDetails(data: any) {
    this.shareData.shareData({ 'function': 'setUserDetails', 'data': data });
  }
  copyBookingData(data: any) {
    this.shareData.shareData({ 'function': 'copyBookingData', 'data': data });
  }

  isOpenTab: number = 0;
  selectUserBookingAndBookingTab(event: any) {
    if (event.index == 0) {
      this.isOpenTab = 0;
    }
    else if (event.index == 1) {
      this.isOpenTab = 1;
    }
  }
}

import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { IMqttMessage } from 'ngx-mqtt';
import { MessageService } from 'primeng/api';
import { interval, Subscription } from 'rxjs';

import { DataShareService } from '../services/data-share.service';
import { EventMqttService } from '../services/event-mqtt.service';
import { GlobalService } from '../services/global.service';
import { CallmethodService } from '../services/callmethod.service';




// driver.model.ts
export interface Driver {
  driverId: string;
  driverName: string;
  driverStatus: number;
  shiftId: string;
  driverEmpId: string;
  dayOfFirstOnTime: string;
  onTime: string;
  offTime: string;
  totalMinutes: number;
  totalBreakTime: number;
  totalBreaks: number;
  isAbsent: boolean;
}


export interface DriverCount {
  allFilter: number;
  all: number;
  present: number;
  absent: number;
  offline: number;
  online: number;
}

// shift-wise-driver-summary.model.ts
export interface ShiftWiseDriverSummary {
  list: Driver[];
  allRecord: number;
  totalAbsentCount: number;
  totalPresentCount: number;
}

@Component({
  selector: 'app-shift-management',
  templateUrl: './shift-management.component.html',
  styleUrls: ['./shift-management.component.scss']
})
export class ShiftManagementComponent implements OnInit, AfterViewInit, OnDestroy {

  private subscription: Subscription = new Subscription;


  //--------------------------------------------->>date filter <<-------------------------------------------------
  alwaysShowCalendars: boolean = true;
  singleDatePicker: boolean = true;
  dateFormat: string = 'YYYY/MM/DD';
  maxDate: moment.Moment = moment(); // Today, prevents selecting future dates
  // minDate: moment.Moment = moment().subtract(45, 'days'); // 45 days before today
  minDate: moment.Moment = moment(); // 45 days before today
  startDate: any = null;
  endDate: any = null;
  selectedDate: any;
  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    // 'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    // 'This Month': [moment().startOf('month'), moment().endOf('month')],
    // 'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  };

  companyDayStart: any = moment()
  companyDayEnd: any = moment()
  emailForm!: FormGroup;
  private subscriptionMqtt: Subscription = new Subscription;
  timerSubscriptions: any = {};

  constructor(
    private callMethod: CallmethodService,
    public global: GlobalService,
    private dataShare: DataShareService,
    private fb: FormBuilder, public toaster: MessageService,
    private mqtt: EventMqttService,
    private cdr: ChangeDetectorRef
  ) {
    this.setDateMode('past');
  }


  getCompanyTimeZone: any = ""
  ngOnInit(): void {

    this.emailForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });

    this.global.dashboard().then((result: any) => {
      if (result.status) {
        this.getCompanyTimeZone = this.global.timeZone;
        console.log('****** this.getCompanyTimeZone ******', this.getCompanyTimeZone);

        const IsSubMqtt = this.global.getLocalStorage({ key: 'companyId' }) ? this.global.getLocalStorage({ key: 'companyId' }) : this.global.companyDetails.companyId
        console.log('***** IsSubMqtt', IsSubMqtt);
        this.subscriptionMqtt = this.mqtt.topic('dispatcher_' + IsSubMqtt).subscribe((data: IMqttMessage) => {
          try {
            let item = JSON.parse(data.payload.toString());
            this.eventHandler(item);
            // this.shareData.shareData({ function: 'mqttFromOrderManagement', 'data': item });
          } catch (error) {
            console.log('****** Driver Shift Management Error ******', error);
          }
        });
        this.driverShiftListView();
      }
    })

    // this.subscription = this.dataShare.getData().subscribe(result => {
    //   console.log('****** shift management data result ******', result);
    //   if (result.function == "GlobalDashboardCalled" && result.data) {

    //     this.getCompanyTimeZone = this.global.timeZone;
    //     console.log('****** this.getCompanyTimeZone ******', this.getCompanyTimeZone);

    //     const IsSubMqtt = this.global.getLocalStorage({ key: 'companyId' }) ? this.global.getLocalStorage({ key: 'companyId' }) : this.global.companyDetails.companyId
    //     console.log('***** IsSubMqtt', IsSubMqtt);
    //     this.subscriptionMqtt = this.mqtt.topic('dispatcher_' + IsSubMqtt).subscribe((data: IMqttMessage) => {
    //       try {
    //         let item = JSON.parse(data.payload.toString());
    //         this.eventHandler(item);
    //         // this.shareData.shareData({ function: 'mqttFromOrderManagement', 'data': item });
    //       } catch (error) {
    //         console.log('****** Driver Shift Management Error ******', error);
    //       }
    //     });
    //     this.driverShiftListView();
    //   }
    // });





  }

  currentMode: string = 'past'; // Default mode
  setDateMode(mode: string) {
    this.currentMode = mode;
    if (mode === 'past') {
      this.minDate = moment().subtract(45, 'days'); // 45 days before today
      this.maxDate = moment(); // Today
      this.ranges = {
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'Last 45 Days': [moment().subtract(44, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      };
    } else if (mode === 'future') {
      this.minDate = moment(); // Today
      this.maxDate = moment().add(7, 'days'); // 7 days from today
      this.ranges = {
        'Next 7 Days': [moment(), moment().add(7, 'days')]
      };
    }
  }

  destroyInterval: any = 0;
  ngAfterViewInit(): void {

  }

  get email() {
    return this.emailForm.get('email');
  }

  ngOnDestroy(): void {
    this.clearAllTimers();
    this.subscription.unsubscribe();
    this.subscriptionMqtt.unsubscribe();
    Object.values(this.timerSubscriptions).forEach((sub: any) => sub.unsubscribe());
  }

  driverShiftViewList: any[] = [];
  shiftBufferTime: number = 0;
  driverShiftListView() {
    this.callMethod.driverShiftList({}).subscribe((result: any) => {
      this.driverShiftViewList = result.data
      this.shiftBufferTime = result.shiftBufferTime

      console.log('****** this.bufferShiftTime ******', this.shiftBufferTime);
      console.log("::::driverShiftViewList::::", this.driverShiftViewList);
    })
  }




  isExport: boolean = false;
  shiftWiseDriverList: any = {};
  disabledForm: boolean = false;
  perPageLimit: number = 100;
  shiftWiseDriverCounts: any = {};
  isOpenPastSheetExportModel: boolean = false;
  tableLoader: boolean = false;

  getShiftWiseDriverSummary(action?: any) {

    if (this.selectedShiftId == "") {
      return
    }

    if (this.selectedIndex == 1) {
      // Calculate the date difference in days
      if (this.selectedDate.startDate == null && this.selectedDate.endDate == null) {
        this.toaster.add({
          severity: 'warn',
          summary: 'Oops!',
          detail: 'Please select date Range',
        });
        return
      }

      const startDate = this.selectedDate.startDate;
      const endDate = this.selectedDate.endDate;
      const dateDifference = endDate.diff(startDate, 'days');
      console.log('****** startDate ******', startDate);
      console.log('****** endDate ******', endDate);

      console.log('****** dateDifference ******', dateDifference);
      // Check if the date range is more than 7 days
      this.isExport = dateDifference > 7;
    }

    console.log('******  this.isExport ******', this.isExport);
    if (this.isExport && !this.isOpenPastSheetExportModel) {
      this.shiftWiseDriverList = [];
      this.isOpenPastSheetExportModel = true;
      return
    }

    if ((action == 'export' || this.isExport)) {
      this.request.sheetExport = true;
      this.request.email = this.email?.value
    }
    else {
      this.request.sheetExport = false;
      this.request.email = ""
      this.shiftWiseDriverList = [];
      this.shiftWiseDriverCounts = {};
    }

    this.disabledForm = true;
    this.isLoading = true;
    this.tableLoader = true;
    this.callMethod.getShiftWiseDriverSummary(this.request).subscribe((result: any) => {
      console.log("::::getShiftWiseDriverSummary::::", result);
      console.log("****** action == 'export' && this.email?.valid ******", action == 'export' && this.email?.valid);
      if (action == 'export' && this.email?.valid) {
        if (result.status) {
          this.toaster.add({
            severity: 'success',
            summary: 'Successfully exported',
            detail: result.message,
          });
          this.isOpenPastSheetExportModel = false;
        }
        else {
          this.toaster.add({
            severity: 'error',
            summary: 'Oops!',
            detail: result.message,
          });
        }
      }
      else {
        if (result.status) {

          // Assign driver data
          this.shiftWiseDriverList = [];
          this.shiftWiseDriverList = result.data;

          // Extract counts from the date-specific object
          const dateKey = Object.keys(this.shiftWiseDriverList)[0];
          const dateData: any = this.shiftWiseDriverList[dateKey];
          console.log('****** dateData ******', dateData);
          this.allDriversCount.all = dateData?.totalCounts ? dateData.totalCounts : 0;
          this.allDriversCount.allFilter = dateData?.totalFilterCount ? dateData.totalFilterCount : 0;
          this.allDriversCount.present = dateData?.totalPresentCounts ? dateData.totalPresentCounts : 0;
          this.allDriversCount.online = dateData?.totalOnlineCounts ? dateData.totalOnlineCounts : 0;
          this.allDriversCount.offline = dateData?.totalOfflineCounts ? dateData.totalOfflineCounts : 0;
          this.allDriversCount.absent = dateData?.totalAbsentCounts ? dateData.totalAbsentCounts : 0;

          console.log('****** this.allDriversCount.all ******', this.allDriversCount.all);
          console.log('****** this.shiftWiseDriverList ******', this.shiftWiseDriverList);

          this.calculateCounts();
          this.clearAllTimers();
          this.startTimersForDrivers()
        }
        else {
        }

      }
      this.tableLoader = false;
    },
      (error: any) => {
        // Handle error if needed
        console.error('API call failed:', error);
      },
      () => {
        // Re-enable the button after API call completes (success or error)
        this.isLoading = false;
        this.disabledForm = false;
      })
  }

  calculateCounts() {
    Object.keys(this.shiftWiseDriverList).forEach(date => {
      const dateData = this.shiftWiseDriverList[date];
      this.shiftWiseDriverCounts[date] = {
        total: dateData.totalCounts,
        present: dateData.totalPresentCounts,
        absent: dateData.totalAbsentCounts
      };
    });
  }

  currentFilter: any = {};

  filterData(date: string, filterType: string) {
    this.currentFilter[date] = filterType;
  }

  getFilteredData(date: string) {
    const filterType = this.currentFilter[date] || 'all';
    const data = this.shiftWiseDriverList[date]?.list || [];
    if (filterType === 'all') {
      return data;
    } else if (filterType === 'present') {
      return data.filter((driver: any) => !driver.isAbsent);
    } else if (filterType === 'absent') {
      return data.filter((driver: any) => driver.isAbsent);
    }
    return data;
  }

  currentPage: number = 0;
  nextPage(event: any) {
    console.log('****** event ******', event);
    console.log('****** event.page ******', event.page);
    this.currentPage = event.page;
    this.request.offset = (this.perPageLimit * this.currentPage);
    this.getShiftWiseDriverSummary();
  }

  allShiftSelect: boolean = true;
  selectedShiftId: any = "";
  selectedShift: any = "";
  isDayOff: boolean = false;
  shiftChange(event: any) {
    console.log("event", event);
    this.selectedShift = event.value
    this.allShiftSelect = false;
    console.log("++++++++++event+++++++++++", event);
    console.log('****** event.value.shiftId ******', event.value.shiftId);
    this.selectedShiftId = event.value.shiftId;
    this.request.shiftId = this.selectedShiftId
    this.isDayOff = event.value.isDayOff
    this.filterBy = 1;
    this.request.filterBy = 1;
    this.request.offset = 0;
    if (this.selectedIndex == 0) {
      this.request.isCurrentShift = true;
    }
    else {
      this.request.isCurrentShift = false;
    }

    this.getShiftWiseDriverSummary();
    if (this.selectedIndex == 0) {
      this.isAPICalled = false;
      this.lastAPICallTime = moment().tz(this.global.timeZone); // Record the time of the API call
      clearInterval(this.clearIntervalId)
      this.startCheckingTimeCondition();
    }

  }

  request: any = {
    shiftId: this.selectedShiftId,
    startDate: this.startDate,
    endDate: this.endDate,
    isCurrentShift: true,
    filterBy: 1,
    offset: 0,
    limit: this.perPageLimit
  }
  dateRangeSelect(event: any) {
    if (event.startDate != null && event.endDate != null) {
      this.startDate = moment(event.startDate).format('YYYY-MM-DD HH:mm:ss');
      this.endDate = moment(event.endDate).format('YYYY-MM-DD 23:59:59');
      this.request.isCurrentShift = false;
      this.request.startDate = this.startDate;
      this.request.endDate = this.endDate;
      this.getShiftWiseDriverSummary();
    }
  }


  convertMinutesHumanReadableFormat(minutes: number): string {
    let totalMinutes = Math.abs(minutes);
    const numberOfDays = Math.floor(totalMinutes / 1440);
    const numberOfHours = Math.floor((totalMinutes % 1440) / 60);
    const remainingMinutes = totalMinutes % 60;

    let result = '';
    if (numberOfDays > 0) {
      result += `${numberOfDays} day(s) `;
    }
    if (numberOfHours > 0) {
      result += `${numberOfHours} hour(s) `;
    }
    if (remainingMinutes > 0) {
      result += `${remainingMinutes} minute(s)`;
    }
    if (totalMinutes == 0) {
      result += `${totalMinutes} minute(s)`;
    }
    return result.trim();
  }

  startTimersForDrivers() {
    Object.keys(this.shiftWiseDriverList).forEach(dateKey => {
      console.log('****** startTimersForDrivers dateKey ******', dateKey);
      if (this.shiftWiseDriverList[dateKey] && this.shiftWiseDriverList[dateKey].list) {
        this.shiftWiseDriverList[dateKey].list.forEach((driver: any) => {
          console.log('****** driver.onTime && !driver.offTime ******', driver.onTime && !driver.offTime);
          console.log('****** startTimersForDrivers driver.driverStatus ******', driver.driverStatus);
          if (driver.onTime && !driver.offTime && (driver.driverStatus == 1 || driver.driverStatus == 2)) {
            console.log('****** startTimersForDrivers totalBreakTime ******', driver.totalBreakTime);
            this.startTimer(driver);
          }
          else {
            this.stopTimer(driver);
          }
        });
      }
    });
  }



  startTimer(driver: any) {
    // console.log('****** driver ******', driver);
    // console.log('****** this.timerSubscriptions[driver.driverId] ******', this.timerSubscriptions[driver.driverId]);

    // console.log('****** startTimer >>>>>>>>> driver.driverStatus ******', driver.driverStatus);

    let driverId = driver.driverId;
    let totalBreakTime = driver.totalBreakTime;
    let totalMinutes = driver.totalMinutes;
    if (!this.timerSubscriptions[driverId] && this.selectedIndex === 0) {
      const timer$ = interval(1000).subscribe(() => {
        // console.log('****** <<<<<<<<<< startTimer driver.driverStatus ******', driver.driverStatus);

        // ! for driver On time
        if (driver.driverStatus == 1) {
          totalMinutes += 1 / 60;
        }

        // ! for driver Break
        if (driver.driverStatus == 2) {
          totalBreakTime += 1 / 60;
        }


        this.updateHTMLTimer(driverId, totalMinutes, totalBreakTime, driver.driverStatus);
        // console.log('****** startTimer driver.totalBreakTime ******', totalBreakTime);
        // console.log('****** this.selectedIndex ******', this.selectedIndex);
        // console.log('****** driver.totalMinutes ******', totalMinutes);
      });
      this.timerSubscriptions[driverId] = timer$;
      if (driver.driverStatus == 0) {
        this.stopTimer(driverId)
      }
    }
  }

  stopTimer(driverId: string) {
    console.log('*****>>> Stop Timer <<<******');
    if (this.timerSubscriptions[driverId]) {
      this.timerSubscriptions[driverId].unsubscribe();
      delete this.timerSubscriptions[driverId];
    }
  }

  clearAllTimers() {
    Object.keys(this.timerSubscriptions).forEach(driverId => this.stopTimer(driverId));
  }

  updateHTMLTimer(driverId: string, totalMinutes: number, totalBreakTime: number, driverStatus: number) {
    const onTimerElement = document.getElementById(`timer-${driverId}`);
    const breakTimerElement = document.getElementById(`breakTimer-${driverId}`);
    if (onTimerElement && breakTimerElement) {
      onTimerElement.textContent = this.convertMinutes(totalMinutes);
      breakTimerElement.textContent = this.convertMinutes(totalBreakTime);

      Object.keys(this.shiftWiseDriverList).forEach(dateKey => {
        // console.log('****** startTimersForDrivers dateKey ******', dateKey);
        if (this.shiftWiseDriverList[dateKey] && this.shiftWiseDriverList[dateKey].list) {
          this.shiftWiseDriverList[dateKey].list.forEach((driver: any) => {
            // console.log('****** driverId == driver.driverId ******', driverId == driver.driverId);
            // console.log('****** driver.driverId ******', driver.driverId);
            // console.log('****** driverId ******', driverId);
            if (driverId == driver.driverId && driver.onTime && !driver.offTime) {
              this.shiftWiseDriverList[dateKey].list = this.updateArrayWithObject(this.shiftWiseDriverList[dateKey].list, ['driverId', driverId], { totalMinutes: totalMinutes, totalBreakTime: totalBreakTime, driverStatus: driverStatus });
            }
          });
        }
      });
    }
  }


  convertMinutes(minutes: number): string {
    const totalSeconds = Math.abs(Math.floor(minutes * 60));
    const numberOfHours = Math.floor(totalSeconds / 3600);
    const remainingMinutes = Math.floor((totalSeconds % 3600) / 60);
    const remainingSeconds = totalSeconds % 60;

    const hoursStr = numberOfHours.toString().padStart(2, '0');
    const minutesStr = remainingMinutes.toString().padStart(2, '0');
    const secondsStr = remainingSeconds.toString().padStart(2, '0');

    return `${hoursStr}:${minutesStr}:${secondsStr}`;
  }


  isLoading: boolean = false;
  allDriversCount: DriverCount = {
    all: 0,
    present: 0,
    absent: 0,
    offline: 0,
    online: 0,
    allFilter: 0,
  };
  filterBy: number = 1
  setFilter(filterBy: number) {
    console.log('****** filterBy ******', filterBy);
    this.request.filterBy = filterBy;
    this.request.offset = 0;
    this.currentPage = 0;
    this.filterBy = filterBy;
    console.log('****** this.request ******', this.request);
    this.getShiftWiseDriverSummary();
  }



  selectedIndex: number = 0;
  selectShiftDay(day: number) {
    console.log('******selectShiftDay day ******', day);
    if (day == 0) {
      this.selectedIndex = 0
      this.request.isCurrentShift = true;
      this.request.sheetExport = false;
      this.isOpenPastSheetExportModel = false;
      this.isExport = false;
    }
    else {
      this.selectedIndex = 1
      this.request.isCurrentShift = false;
      clearInterval(this.clearIntervalId)
    }
    this.resetFilter()
  }

  isCurrentShift: boolean = true;
  resetFilter() {
    console.log('*****>>> Reset Filters <<<******');
    this.shiftWiseDriverList = [];
    this.selectedShiftId = "";
    this.selectedShift = "";
    this.startDate = null;
    this.endDate = null;
    this.isCurrentShift = true;
    this.filterBy = 1;
    this.allDriversCount = {
      allFilter: 0,
      all: 0,
      present: 0,
      absent: 0,
      offline: 0,
      online: 0,
    };
    this.request.isCurrentShift = true;
    this.request.startDate = null;
    this.request.endDate = null;
    console.log('****** this.endDate ******', this.endDate);
    console.log('****** this.startDate ******', this.startDate);
    console.log('****** this.isCurrentShift ******', this.isCurrentShift);
    this.clearAllTimers();
  }



  eventHandler(opts: any) {
    if (opts.statusFlag && this.selectedIndex == 0) {
      console.log('****** Driver Shift opts eventHandler ******', opts);
      if (opts.statusFlag == this.global.statusFlags['DRIVER_OFFLINE'] || opts.statusFlag == this.global.statusFlags['DRIVER_ONLINE'] || opts.statusFlag == this.global.statusFlags['DRIVER_BREAK_ON'] || opts.statusFlag == this.global.statusFlags['DRIVER_BREAK_OFF']) {
        // this.updateDriverData(opts.driverData);
        this.setOnlineOfflineDriverData(opts.driverData, opts.statusFlag);
      }
      if (opts.statusFlag == this.global.statusFlags['DRIVER_SHIFT_CRON']) {
        opts.driverData.shiftId == this.selectedShiftId && this.getShiftWiseDriverSummary();
      }
    }
  }


  DRIVER_SHIFT_FILTER_TYPE = {
    ALL: 1,
    PRESENT: 2,
    ABSENT: 3,
    ONLINE: 4,
    OFFLINE: 5,
  }


  updateCounts(driver: any) {
    console.log('****** driver ******', driver);


    // Reset counts if needed, depends on your logic (you might want to comment these lines out if not required)
    // this.allDriversCount.allFilter = 0;

    // Update counts based on driverStatus
    if (driver.driverStatus == 1 && !driver.isBreakRelease) {
      console.log('*****>>> Online Drivers <<<******');
      this.allDriversCount.online += 1;
      this.allDriversCount.offline -= 1;
      console.log('****** driver.firstTimeCheckIn && !driver.isAbsent ******', driver.firstTimeCheckIn && !driver.isAbsent);
      if (driver.firstTimeCheckIn && !driver.isAbsent) {
        console.log('*****>>> Present True <<<******');
        this.allDriversCount.present += 1;
        this.allDriversCount.absent -= 1;
      }
    } else if (driver.driverStatus == 0) {
      console.log('*****>>> Offline Drivers <<<******');
      this.allDriversCount.online -= 1;
      this.allDriversCount.offline += 1;
    }

    // Update allFilter based on filterBy and driverStatus
    if (this.filterBy !== this.DRIVER_SHIFT_FILTER_TYPE.ALL) {
      if (this.filterBy == this.DRIVER_SHIFT_FILTER_TYPE.ONLINE) {
        this.allDriversCount.allFilter = this.allDriversCount.online;
      } else if (this.filterBy == this.DRIVER_SHIFT_FILTER_TYPE.OFFLINE) {
        this.allDriversCount.allFilter = this.allDriversCount.offline;
      } else if (this.filterBy == this.DRIVER_SHIFT_FILTER_TYPE.PRESENT) { // Assuming there's a present filter
        this.allDriversCount.allFilter = this.allDriversCount.present;
      } else if (this.filterBy == this.DRIVER_SHIFT_FILTER_TYPE.ABSENT) {
        this.allDriversCount.allFilter = this.allDriversCount.absent;
      }

      // Make an API call if total drivers are less than perPageLimit
      console.log('****** this.allDriversCount.allFilter ******', this.allDriversCount.allFilter);
      console.log('****** this.perPageLimit ******', this.perPageLimit);
      console.log('****** this.allDriversCount.allFilter <= this.perPageLimit ****** && this.currentPage > 1', this.allDriversCount.allFilter <= this.perPageLimit && this.currentPage > 1);
      console.log('****** this.currentPage ******', this.currentPage);
      if (this.allDriversCount.allFilter <= this.perPageLimit && this.currentPage > 1) {
        this.getShiftWiseDriverSummary(); // Assuming this method fetches additional data
      }


    }

    console.log('****** this.allDriversCount.online ******', this.allDriversCount.online);
    console.log('****** this.allDriversCount.offline ******', this.allDriversCount.offline);
    console.log('****** this.allDriversCount.present ******', this.allDriversCount.present);
    console.log('****** this.allDriversCount.absent ******', this.allDriversCount.absent);
    console.log('****** this.allDriversCount.allFilter ******', this.allDriversCount.allFilter);
  }


  updateCountsOld(driver: any) {

    console.log('****** driver ******', driver);
    if (driver.driverStatus == 1 && !driver.isDriverOnBreak) {
      console.log('*****>>> Online Drivers <<<******');
      this.allDriversCount.online += 1;
      this.allDriversCount.offline -= 1;
      this.allDriversCount.present += driver.isFirstTimeLogin == "" && !driver.isAbsent ? 1 : 0;
      this.allDriversCount.absent -= driver.isFirstTimeLogin == "" && !driver.isAbsent ? 1 : 0;
    }
    else if (driver.driverStatus == 0) {
      console.log('*****>>> Offline Drivers <<<******');
      this.allDriversCount.online -= 1;
      this.allDriversCount.offline += 1;
    }

    if (this.filterBy !== this.DRIVER_SHIFT_FILTER_TYPE.ALL) {
      if (this.filterBy == this.DRIVER_SHIFT_FILTER_TYPE.ONLINE) {
        this.allDriversCount.allFilter = this.allDriversCount.online += 1;
      }
      if (this.filterBy == this.DRIVER_SHIFT_FILTER_TYPE.OFFLINE) {
        this.allDriversCount.allFilter = this.allDriversCount.offline += 1;
      }
      if (this.filterBy == this.DRIVER_SHIFT_FILTER_TYPE.ABSENT) {
        this.allDriversCount.allFilter = this.allDriversCount.absent += 1;
      }
      if (this.filterBy == this.DRIVER_SHIFT_FILTER_TYPE.OFFLINE) {
        this.allDriversCount.allFilter = this.allDriversCount.offline += 1;
      }
    }


    console.log('****** this.allDriversCount.online ******', this.allDriversCount.online);
    console.log('****** this.allDriversCount.offline ******', this.allDriversCount.offline);
    console.log('****** this.allDriversCount.present ******', this.allDriversCount.present);
    console.log('****** this.allDriversCount.absent ******', this.allDriversCount.absent);
    // this.allDriversCount.absent += 1;

  }


  setOnlineOfflineDriverData(driverData: any, statusFlag: number) {
    console.log('****** setOnlineOfflineDriverData driverData ******', driverData);

    let MqqtTStatusFlag = statusFlag;

    let driverList: any[] = []
    let dayOfFirstOnTime
    console.log('****** driverData.shiftId ******', driverData.shiftId);
    console.log('****** his.selectedShiftId ******', this.selectedShiftId);
    console.log('****** driverData.shiftId == this.selectedShiftId ******', driverData.shiftId == this.selectedShiftId);
    console.log('****** this.filterBy ******', this.filterBy);
    if (driverData.shiftId == this.selectedShiftId) {

      console.log('****** driverData.todayBreakTime ******', driverData.todayBreakTime);

      let driverIndex: number = -1
      for (const date in this.shiftWiseDriverList) {
        driverList = this.shiftWiseDriverList[date].list;
        driverIndex = driverList.findIndex((driver: any) => {
          console.log('****** array driver.driverId ******', driver.driverId);
          console.log('****** Mqtt driverData.driverId ******', driverData.driverId);
          console.log('****** driver.driverId === driverData.driverId ******', driver.driverId === driverData.driverId);
          return driver.driverId === driverData.driverId
        });


        if (this.filterBy == 2) {

          let driver = {
            "driverId": driverData.driverId,
            "driverName": driverData.driverName,
            "mobile": driverData.mobile,
            "driverStatus": driverData.driverStatus,
            "driverEmpId": driverData.driverCustomId,
            "dayOfFirstOnTime": driverData.dayOfFirstOnTime,
            "onTime": driverData.onTime ? driverData.onTime : "",
            "offTime": driverData.offTime ? driverData.offTime : "",
            "totalMinutes": driverData.totalMinutes ? driverData.totalMinutes : 0,
            "totalBreakTime": driverData.todayBreakTime,
            "totalBreaks": driverData.breakCount,
            "isAbsent": driverData.isAbsent ? driverData.isAbsent : false,
          }
          this.updateArrayWithObject(this.shiftWiseDriverList[date].list, ['driverId', driverData.driverId], driver)
          this.stopTimer(driverData.driverId);
          this.startTimer(driver);

        }

        console.log('****** this.perPageLimit > driverList.length ******', this.perPageLimit > driverList.length);
        console.log('****** this.perPageLimit ******', this.perPageLimit);
        console.log('****** driverList.length ******', driverList.length);
        if ((this.filterBy == 4 && driverData.driverStatus == 1 && driverIndex == -1 && this.perPageLimit > driverList.length)) {
          let driver = {
            "driverId": driverData.driverId,
            "driverName": driverData.driverName,
            "mobile": driverData.mobile,
            "driverStatus": driverData.driverStatus,
            "driverEmpId": driverData.driverCustomId,
            "dayOfFirstOnTime": driverData.dayOfFirstOnTime,
            "onTime": driverData.onTime ? driverData.onTime : "",
            "offTime": driverData.offTime ? driverData.offTime : "",
            "totalMinutes": driverData.totalMinutes ? driverData.totalMinutes : 0,
            "totalBreakTime": driverData.todayBreakTime,
            "totalBreaks": driverData.breakCount,
            "isAbsent": driverData.isAbsent ? driverData.isAbsent : false,
          }
          this.shiftWiseDriverList[date].list.unshift(driver)
          this.stopTimer(driverData.driverId);
          this.startTimer(driver);
          console.log('****** add Data this.shiftWiseDriverList[date].list ******', this.shiftWiseDriverList[date].list);
        }
        else if ((this.filterBy == 4) && driverData.driverStatus == 1 || driverData.driverStatus == 2) {
          let driver = {
            "driverId": driverData.driverId,
            "driverName": driverData.driverName,
            "mobile": driverData.mobile,
            "driverStatus": driverData.driverStatus,
            "driverEmpId": driverData.driverCustomId,
            "dayOfFirstOnTime": driverData.dayOfFirstOnTime,
            "onTime": driverData.onTime ? driverData.onTime : "",
            "offTime": driverData.offTime ? driverData.offTime : "",
            "totalMinutes": driverData.totalMinutes ? driverData.totalMinutes : 0,
            "totalBreakTime": driverData.todayBreakTime,
            "totalBreaks": driverData.breakCount,
            "isAbsent": driverData.isAbsent ? driverData.isAbsent : false,
          }
          this.updateArrayWithObject(this.shiftWiseDriverList[date].list, ['driverId', driverData.driverId], driver)
          this.stopTimer(driverData.driverId);
          this.startTimer(driver);
        }
        else if (this.filterBy == 1) {
          let driver = {
            "driverId": driverData.driverId,
            "driverName": driverData.driverName,
            "mobile": driverData.mobile,
            "driverStatus": driverData.driverStatus,
            "driverEmpId": driverData.driverCustomId,
            "dayOfFirstOnTime": driverData.dayOfFirstOnTime,
            "onTime": driverData.onTime ? driverData.onTime : "",
            "offTime": driverData.offTime ? driverData.offTime : "",
            "totalMinutes": driverData.totalMinutes ? driverData.totalMinutes : 0,
            "totalBreakTime": driverData.todayBreakTime,
            "totalBreaks": driverData.breakCount,
            "isAbsent": driverData.isAbsent ? driverData.isAbsent : false,
          }
          this.updateArrayWithObject(this.shiftWiseDriverList[date].list, ['driverId', driverData.driverId], driver)
          this.stopTimer(driverData.driverId);
          this.startTimer(driver);
        }
        if ((this.filterBy == 5 && driverData.driverStatus == 0 && driverIndex == -1 && this.perPageLimit > driverList.length)) {
          let driver = {
            "driverId": driverData.driverId,
            "driverName": driverData.driverName,
            "mobile": driverData.mobile,
            "driverStatus": driverData.driverStatus,
            "driverEmpId": driverData.driverCustomId,
            "dayOfFirstOnTime": driverData.dayOfFirstOnTime,
            "onTime": driverData.onTime ? driverData.onTime : "",
            "offTime": driverData.offTime ? driverData.offTime : "",
            "totalMinutes": driverData.totalMinutes ? driverData.totalMinutes : 0,
            "totalBreakTime": driverData.todayBreakTime,
            "totalBreaks": driverData.breakCount,
            "isAbsent": driverData.isAbsent ? driverData.isAbsent : false,
          }
          this.shiftWiseDriverList[date].list.unshift(driver)
          this.stopTimer(driverData.driverId);
          this.startTimer(driver);
          console.log('****** add Data this.shiftWiseDriverList[date].list ******', this.shiftWiseDriverList[date].list);
        }
        else if (this.filterBy == 4 && driverData.driverStatus == 0) {
          this.removeObjectInArray(this.shiftWiseDriverList[date].list, ['driverId', driverData.driverId])
        }

        if ((this.filterBy == 3 || this.filterBy == 5) && driverData.driverStatus == 1) {
          this.removeObjectInArray(this.shiftWiseDriverList[date].list, ['driverId', driverData.driverId])
        }

        dayOfFirstOnTime = driverData.onTime
        console.log('****** driverIndex ******', driverIndex);
      }

      if (MqqtTStatusFlag == this.global.statusFlags['DRIVER_ONLINE'] || MqqtTStatusFlag == this.global.statusFlags['DRIVER_OFFLINE']) {

        console.log('****** driverData.firstTimeCheckIn ******', driverData.firstTimeCheckIn);
        let updateDriverData = {
          driverStatus: driverData.driverStatus,
          isFirstTimeLogin: driverData.dayOfFirstOnTime,
          isAbsent: driverData.isAbsent,
          offTime: driverData.offTime,
          dayOfFirstOnTime: dayOfFirstOnTime,
          isBreakRelease: driverData.isBreakRelease,
          firstTimeCheckIn: driverData.firstTimeCheckIn,

        }
        console.log('****** MQtt updateDriverData ******', updateDriverData);
        console.log('****** MQtt updateDriverData  firstTimeCheckIn******', updateDriverData.firstTimeCheckIn);
        console.log('****** isBreakRelease ******', updateDriverData.isBreakRelease);
        // Update counts after modifying the driver data
        this.updateCounts(updateDriverData);
      }

    }
  }

  removeObjectInArray(array: any[], where: any[]) {
    let objIndex = array.findIndex((obj => obj[where[0]] == where[1]));
    if (objIndex >= 0) {
      array.splice(objIndex, 1);
    }
    return array;
  }

  updateArrayWithObject(array: any[], where: any[], setData: { [s: string]: unknown; } | ArrayLike<unknown>) {
    let objIndex = array.findIndex((obj => obj[where[0]] == where[1]));

    if (objIndex >= 0) {
      for (const [k, v] of Object.entries(setData)) {
        array[objIndex][k] = v;
      }
    }
    return array;
  }


  isAPICalled: boolean = false;
  // Function to get the effective start time
  getEffectiveStartTime() {
    if (!this.selectedShift || this.shiftBufferTime === 0) return;

    const shiftStartTime = this.selectedShift.startTime; // e.g., "10:00"
    const [hours, minutes] = shiftStartTime.split(':').map(Number);
    const effectiveStartTime = moment()
      .set({ hour: hours, minute: minutes, second: 0, millisecond: 0 })
      .add(this.shiftBufferTime, 'minutes');
    return effectiveStartTime;
  }

  clearIntervalId: any = null;
  lastAPICallTime: moment.Moment | null = null;
  // Function to check the current time against the effective start time
  checkTimeCondition() {
    // console.log('****** this.isAPICalled ******', this.isAPICalled);
    const effectiveStartTime = this.getEffectiveStartTime();
    const currentTime = moment().tz(this.global.timeZone); // Replace with the correct timezone

    // console.log('****** currentTime ******', currentTime);
    // console.log('****** effectiveStartTime ******', effectiveStartTime);
    // console.log('****** currentTime ******', moment(currentTime).format('MM-DD-YYYY HH:mm:ss'));
    // console.log('****** effectiveStartTime ******', moment(effectiveStartTime).format('MM-DD-YYYY HH:mm:ss'));

    if (this.isAPICalled) {
      if (this.lastAPICallTime && currentTime.diff(this.lastAPICallTime, 'minutes') < 1) {
        // If the last API call was made within the last minute, skip the call
        return;
      } else {
        this.isAPICalled = false; // Reset the flag if it's been longer than a minute
      }
    }

    // console.log('****** multiple call currentTime.isSame(effectiveStartTime) ******', currentTime.isSame(effectiveStartTime, 'second'));
    if (this.selectedIndex == 0 && currentTime.isSame(effectiveStartTime, 'second')) {
      this.getShiftWiseDriverSummary();
      this.isAPICalled = true;
      this.lastAPICallTime = currentTime; // Record the time of the API call
    }
  }

  // Set interval to check the condition
  startCheckingTimeCondition() {
    this.checkTimeCondition(); // Check immediately
    // setInterval(() => this.checkTimeCondition(), 60000); // Check every minute
    this.clearIntervalId = setInterval(() => {
      if (this.selectedIndex == 0) {
        // console.log('****** checkTimeCondition ******',);
        this.checkTimeCondition()
      }
    }, 1000); // Check every minute 

  }

}


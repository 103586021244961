<div class="flex  items-center min-h-screen p-4 bg-gray-100 bgImage bgImages lg:justify-center">
	<div [hidden]="fullPageLoader"
		class="flex flex-col overflow-hidden relative  rounded-md shadow-2xl blur-xl  max md:flex-row md:flex-1 lg:max-w-screen-md">
		<div  [ngStyle]="Global.setGradientStyle()" [style.color]="Global.second_color"
			class="p-4 py-6 text-white bg-blue-500  cd-btn main-action bouncy md:w-1/2 md:flex-shrink-0 md:flex md:flex-col md:items-center md:justify-evenly">
			<div class="my-3 text-4xl font-bold tracking-wider text-center">
				<img loading="lazy" [src]="Global.companyDetails.companyLogo"
					class="w-80 h-54  cd-btn main-action bouncy" alt="" />
			</div>
			<p class="mt-6 text-center md:mt-0 fontMedium" [style.color]="Global.second_color">
				Get moving with {{Global.companyDetails.companyName}}
			</p>
		</div>

		<!-- Dark Light toggle -->
		<div>
			<input type="checkbox" id="themeSwitch" class="toggle-checkbox loginThemeSwitch lightSwitch "
				(click)="signinThemeChange()" />
			<label for="themeSwitch" class="toggle-wrapper">
				<div class="toggle-thumb">
					<div class="crater-1"></div>
					<div class="crater-2"></div>
					<div class="crater-3"></div>
				</div>
				<div class="toggle-container">
					<div class="toggle-night">
						<ul class="stars">
							<li></li>
							<li></li>
							<li></li>
							<li></li>
							<li></li>
							<li></li>
							<li></li>
							<li></li>
						</ul>
					</div>
					<div class="toggle-day">
						<div class="surfer"></div>
						<ul class="waves">
							<li></li>
							<li></li>
							<li></li>
							<li></li>
							<li></li>
							<li></li>
							<li></li>
							<li></li>
							<li></li>
						</ul>
					</div>
				</div>
			</label>
		</div>

		<!--Login With Email box -->
		<div class="p-5 cd-intro md:flex-1 loginMainBox  cd-btn main-action bouncy" *ngIf="EmailOrPhone===1 ">
			<h3 class="my-4 text-2xl font-semibold fontMedium fontColor">Account Login</h3>
			<form (submit)="LoginBtnApiCall()" class="flex flex-col space-y-5">
				<div class="flex flex-col space-y-1 action-wrapper">
					<div class="custom-input cd-btn main-action w-full">
						<input type="text" name="email" id="email" spellcheck="false" required />
						<span> Email Address</span>
						<div style="clear: both;"></div>
					</div>
					<div class="cd-btn main-action custom-input-icon-right w-full">
						<input [type]="fieldTextType ? 'text' : 'password'" name="password" id="password"
							spellcheck="false" required />
						<span>Paasword</span>
						<div class="input-icon-right">
							<i class="fa fa-eye" (click)="toggleFieldTextType()"
								[ngClass]="{ 'fa-eye-slash': !fieldTextType,'fa-eye': fieldTextType }"></i>
						</div>
						<div style="clear: both;"></div>
					</div>
				</div>
				<div>
					<button type="submit" [hidden]="LoginBtn" type="submit" (keydown.enter)="LoginBtnApiCall()"
						 [ngStyle]="Global.setGradientStyle()" [style.color]="Global.second_color"
						class="w-full cd-btn main-action px-4 py-2 outline-none group input text-2xl font-semibold  transition-colors duration-300 rounded-full h-16 shadow hover:opacity-80 focus:outline-none focus:ring-blue-200 focus:ring-4">
						Log in
					</button>
					<div [hidden]="!LoginBtn" class="text-center justify-content-center flex input">
						<img class="profile-img mb-1 btnLoader" src="../../assets/images/loading.gif" alt="Workflow" />
					</div>
				</div>
			</form>

			<div class="flex flex-col cd-btn main-action space-y-5 mt-4">
				<span class="flex items-center justify-center space-x-2">
					<span class="h-px bg-gray-400 w-14"></span>
					<span class="fontMedium fontColor">Or Login With</span>
					<span class="h-px bg-gray-400 w-14"></span>
				</span>
				<div class="flex flex-col space-y-4">
					<button type="menu" value="Login" (click)="SelectEmail()"
						class="w-full px-4 py-2 text-xl loginWithPhone fontMedium fontColor font-semibold transition-colors duration-300 rounded-full h-16 focus:outline-none">
						<i class="fas fa-mobile-alt mr-2 mt-1"></i>Login with Phone
					</button>
				</div>
			</div>
		</div>

		<!--Login With Phone box  -->
		<div class="p-5 md:flex-1 loginMainBox  cd-btn main-action bouncy" *ngIf="EmailOrPhone===0 ">
			<h3 class="my-4 text-2xl font-semibold fontColor fontMedium">Account Login</h3>
			<form (submit)="LoginBtnApiCall()" class="flex flex-col space-y-5">
				<div class="flex flex-col space-y-1 action-wrapper iti">
					<div class="mb-2 mt-2 cd-btn main-action iti--allow-dropdown ">
						<form #f="ngForm" [formGroup]="phoneForm">
							<ngx-intl-tel-input [preferredCountries]="preferredCountries" id="phone"
								formControlName="phone" onkeypress="return AvoidSpace(event)" class="outline1 " required
								[enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
								[searchCountryField]="[
									SearchCountryField.Iso2,
									SearchCountryField.Name 
									]" [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.India"
								[cssClass]="'ngx-intl-tel-input w-full fontColor z-50  focus:outline-none fontMediumBold cd-btn main-action custom-input'"
								[phoneValidation]="true" [separateDialCode]="true" (click)="changePreferredCountries()"
								[numberFormat]="PhoneNumberFormat.National" name="phone">
							</ngx-intl-tel-input>
						</form>
					</div>
					<div class="custom-input-icon-right cd-btn main-action w-full">
						<input [type]="fieldTextType ? 'text' : 'password'" name="password" id="password"
							spellcheck="false" required />
						<span>Paasword</span>
						<div class="input-icon-right">
							<i class="fa fa-eye" (click)="toggleFieldTextType()"
								[ngClass]="{ 'fa-eye-slash': !fieldTextType,'fa-eye': fieldTextType }"></i>
						</div>
						<div style="clear: both;"></div>
					</div>
				</div>
				<div>
					<button type="submit" [hidden]="LoginBtn" type="submit" (keydown.enter)="LoginBtnApiCall()"
						 [ngStyle]="Global.setGradientStyle()" [style.color]="Global.second_color"
						class="w-full px-4 py-2 cd-btn main-action group input outline-none text-2xl font-semibold  transition-colors duration-300  rounded-full h-16 shadow hover:opacity-40 focus:outline-none focus:ring-blue-200 focus:ring-4">
						Log in
					</button>
					<div [hidden]="!LoginBtn" class="text-center justify-content-center flex input">
						<img class="profile-img mb-1 btnLoader" src="../../assets/images/loading.gif" alt="Workflow" />
					</div>
				</div>
			</form>
			<div class="flex flex-col space-y-5 mt-4 cd-btn main-action">
				<span class="flex items-center justify-center space-x-2">
					<span class="h-px bg-gray-400 w-14"></span>
					<span class="fontMedium fontColor">Or Login With</span>
					<span class="h-px bg-gray-400 w-14"></span>
				</span>
				<div class="flex flex-col space-y-4">
					<button type="menu" value="Login" (click)="SelectPhone()"
						class="w-full cd-btn px-4 py-2 cd-btn main-action text-xl fontMedium loginWithPhone fontColor font-semibold transition-colors duration-300 rounded-full h-16 focus:outline-none">
						<i class="far fa-envelope mr-1 mt-1"></i>Login With Email
					</button>
				</div>
			</div>
		</div>
	</div>

	<div [hidden]="!fullPageLoader"
		class="flex flex-col overflow-hidden loginPageAnimation rounded-md shadow-2xl blur-xl  max md:flex-row md:flex-1 lg:max-w-screen-md animate-pulse">
		<div
			class="p-4 py-6 text-white bg-gray-300  cd-btn main-action bouncy md:w-1/2 md:flex-shrink-0 md:flex md:flex-col md:items-center md:justify-evenly animate-pulse">
			<div class="w-80  h-40 mt-24 mb-32 bg-gray-400 bg-opacity-75 rounded"></div>
			<div class="mt-6  h-8 w-80 mb-24  bg-gray-400 bg-opacity-75 rounded" >
			</div>
		</div>
		<div class="p-5 cd-intro md:flex-1 loginMainBox  cd-btn main-action bouncy animate-pulse">
			<div class="my-4  h-5 w-28 bg-gray-300 bg-opacity-75 rounded"></div>
			<div  class="flex flex-col space-y-5">
				<div class="flex flex-col space-y-1 action-wrapper">
					<div class="w-full h-14 bg-gray-300 bg-opacity-75 rounded-full mb-2 "></div>
					<div class="w-full h-14 bg-gray-300 bg-opacity-75 rounded-full mb-2 "></div>
					<div class="w-full h-14 bg-gray-300 bg-opacity-75 rounded-full mb-2 mt-4"></div>
				</div>
			</div>
			<div class="flex flex-col cd-btn main-action space-y-5 mt-4 items-center">
				<div class="w-52 h-4 bg-gray-300 bg-opacity-75 rounded-full mb-2 mt-4"></div>
			</div>
			<div class="flex flex-col space-y-4 mt-4">
				<div class="w-full h-14 bg-gray-300 bg-opacity-75 rounded-full mb-2 "></div>
			</div>
		</div>
	</div>
</div>
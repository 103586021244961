import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { DriverShiftManagementComponent } from './driver-shift-management/driver-shift-management.component';
import { HomeComponent } from './home/home.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { TaskTimelineCalenderComponent } from './task-timeline-calender/task-timeline-calender.component';
import { UserChatComponent } from './user-chat/user-chat.component';
import { VisheshChatComponent } from './vishesh-chat/vishesh-chat.component';
import { LeaderboardComponent } from './leaderboard/leaderboard.component';
import { ShiftManagementComponent } from './shift-management/shift-management.component';

const routes: Routes = [
	{ path: '', redirectTo: 'sign-in', pathMatch: 'full' },
	{ path: 'sign-in', component: SignInComponent },
	{ path: 'home', component: HomeComponent },
	{ path: 'driver-shift', component: DriverShiftManagementComponent },
	{ path: 'task-timeline', component: TaskTimelineCalenderComponent },
	{ path: 'chat', component: UserChatComponent },
	{ path: 'chat/:id', component: UserChatComponent },
	{ path: 'vishesh-chat', component: VisheshChatComponent },
	{ path: 'leaderboard', component: LeaderboardComponent },
	{ path: 'shiftManagement', component: ShiftManagementComponent },
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
	exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { DataShareService } from 'src/app/services/data-share.service';
import { GlobalService } from 'src/app/services/global.service';

declare let responsiveVoice: any

interface UserCount {
  userId: string;
  count: number;
}

@Component({
  selector: 'app-chat-left-side-view',
  templateUrl: './chat-left-side-view.component.html',
  styleUrls: ['./chat-left-side-view.component.scss']
})
export class ChatLeftSideViewComponent implements OnInit, OnDestroy {
  @ViewChild('scrollMe') public myScrollContainer!: ElementRef;
  private unsubscribe$ = new Subject<void>();
  public subscription!: Subscription;
  // @Input() userAndChatDataArray: any = [];
  searchResults: any[] = [];
  userAndChatDataArray: any[] = [];
  selectedUser: any;
  searchArray = false;
  isbadgeNo: boolean = false
  lestSideUserIds: string[] = [];
  dispatcherDetails: any;
  selectedUserData: any;
  userName: string = '';
  userIdsAndCounts: UserCount[] = [];
  badgeNumbers: any

  constructor(
    public shareData: DataShareService,
    public Global: GlobalService,
    public route: ActivatedRoute,
  ) {
    this.dispatcherDetails = this.Global.dispatcherDetails;
  }
  setBadgeUserId: any
  isBadgeNo: any
  ngOnInit(): void {
    this.shareData.getData().subscribe((result: any) => {
      if (result.function == 'getAllUserAndChat' && result.type == 'userChatView' && result.data != '') {
        this.userAndChatDataArray = result.data
        this.userAndChatDataArray.forEach((response: any) => {
          let data = this.Global.getLocalStorage({ 'key': `badgeNumbers_${response.user.userId}` })
          response.user.isBadge = data
        })
      }
    });

    this.subscription = this.shareData.getData().pipe(debounceTime(300), takeUntil(this.unsubscribe$)).subscribe((result) => {
      if (result.function === 'messageSendAndRecive' && result.type === 'userChatView' && result.data !== '') {
        this.processMessageSendAndReceive(result);
        this.userAndChatDataArray.forEach((response: any) => {
          if (result.data.userId === response.user.userId) {
            this.setBadgeNumber(result.data.userId)
          }
        });
        this.userAndChatDataArray.forEach((response: any) => {
          if (this.selectedUser && response.user.userId == this.selectedUser.userId) {
            response.user.isBadge = this.removeBadgeNumber(response.user.userId, 0)
          } else {
            let data = this.Global.getLocalStorage({ 'key': `badgeNumbers_${response.user.userId}` })
            response.user.isBadge = data
          }
        })
      }

      if (result.function === 'newUserMessageToDispatcher' && result.type === 'userChatView' && result.data !== '') {
        this.userAndChatDataArray = result.data
        this.processNewUserMessageToDispatcher(result.data[result.data.length - 1])
        this.Global.setLocalStorage({ "key": `badgeNumbers_${result.data[result.data.length - 1].user.userId}`, "value": result.data[result.data.length - 1].isCount });
        this.userAndChatDataArray.forEach((response: any) => {
          if (response.user.userId == result.data[result.data.length - 1].user.userId) {
            let data = this.Global.getLocalStorage({ "key": `badgeNumbers_${result.data[result.data.length - 1].user.userId}` });
            response.user.isBadge = data
          }
        })
      }
      if (result.function === 'userSeenMessageMqtt' && result.type === 'userChatView' && result.data !== '') {
        this.userAndChatDataArray.forEach((response: any) => {
          if (result.data.userId === response.user.userId) {
            response.user.isGreenTick = 0
          }
        });
      }
      if (result.function == 'dispatcherSendMessage' && result.type == 'chatMiddleView' && result.data != '') {
        console.log('***** resufdvdffbfgghngjymyhxcfg lg bvcxbuylt.data *****',result.data);
        this.userAndChatDataArray.forEach((response: any) => {
          console.log('***** this.userAndChatDataArray *****',this.userAndChatDataArray);
          if (result.data == response.user.userId) {
            response.user.isGreenTick = 1
            console.log('***** this.userAndChatDataArray *****',this.userAndChatDataArray);
          }
        });
      }
    })
    this.searchResults = this.userAndChatDataArray;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private processMessageSendAndReceive(result: any): void {
    const userIdsAndCounts: UserCount = {
      userId: result.data.userId,
      count: result.isCount
    }


    const existingUserIndex = this.userIdsAndCounts.findIndex(item => item.userId === userIdsAndCounts.userId);
    if (existingUserIndex > 0 || existingUserIndex == 0) {
      this.userIdsAndCounts[existingUserIndex].count = userIdsAndCounts.count;
    } else {
      this.userIdsAndCounts.push(userIdsAndCounts);
    }

    this.userIdsAndCounts = this.userIdsAndCounts.filter((item, index, self) => (
      index === self.findIndex(t => t.userId === item.userId)
    ));

    this.userIdsAndCounts.forEach((userCount) => {
      console.log('*fs**** userCount *****', userCount);
      if (userCount.userId == result.data.userId) {
        if (this.Global.getLocalStorage({ "key": `badgeNumbers_${userCount.userId}` })) {
          let incrementCounts = this.Global.getLocalStorage({ "key": `badgeNumbers_${userCount.userId}` })
          this.Global.setLocalStorage({ "key": `badgeNumbers_${userCount.userId}`, "value": incrementCounts + 1 })
        }
        else {
          this.Global.setLocalStorage({ "key": `badgeNumbers_${userCount.userId}`, "value": userCount.count });
        }
      }
    });
  }

  private processNewUserMessageToDispatcher(result: any): void {
    this.Global.setLocalStorage({ "key": `badgeNumbers_${result.user.userId}`, "value": result.isCount });

    this.userAndChatDataArray.forEach((response: any) => {
      if (response.user.userId == result.user.userId) {
        let data = this.Global.getLocalStorage({ "key": `badgeNumbers_${result.user.userId}` });
        response.user.isBadge = data
      } else {
        console.log("dasdasdasd");
      }
    })
  }

  isChatBubble:any
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.getUserLastMessage(this.route.snapshot.paramMap.get("id"))
      this.scrollToBottom();
    },5000)
  }
  getUserLastMessage(data: any) {
    this.userAndChatDataArray.forEach((response: any) => {
      if (response.user.userId == data) {
    this.isChatBubble = data
        this.clickedRecentChat(response);
      }
    });
  }

  recentChatClick(recentChat: any): void {
    setTimeout(() => {
      this.scrollToBottom();
    }, 1);
  }

  scrollToBottom() {
    if (this.myScrollContainer) {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    }
  }

  private setBadgeNumber(userId: any): void {
    this.userAndChatDataArray.forEach((response: any) => {
      if (response.user.userId == userId) {
        response.user.isBadge = this.getBadgeCountForUser(userId);
      }
    })
  }

  private removeBadgeNumber(userId: string, count: number): void {
    const badgeCountKey = `badgeNumbers_${userId}`;
    this.Global.removeLocalStorage({ 'key': badgeCountKey });
  }

  getBadgeCountForUser(userId: string): number {
    const badgeCountKey = `badgeNumbers_${userId}`;
    const badgeCount = this.Global.getLocalStorage({ "key": badgeCountKey });
    return badgeCount;
  }

  clickedRecentChat(data: any) {
    this.selectedUser = data.user
    console.log('*****csd this.selectedUser *****',this.selectedUser);
    this.shareData.shareData({ function: 'recentChatData', type: 'recentChatsView', data: data.chats, isCurrentChat: true });
    this.Global.currentOffset = 0;
    this.userAndChatDataArray.forEach((response: any) => {
      if (response.user.userId == data.user.userId) {
        response.user.isBadge = this.removeBadgeNumber(data.user.userId, 0);
      }
    })
  }

  showLastMessageTime(result: any) {
    return moment(result).format('hh:mm A');
  }

  isSearchedValue: boolean = false
  searchUsers(event: any) {
    if (event.data == null) {
      this.isSearchedValue = true
    }
    const inputValue = event.target.value;

    this.searchResults = this.userAndChatDataArray.filter((item, index) => {
      return item.user.userName.toLowerCase().includes(inputValue.toLowerCase().trim());
    });
  }
}
<div *ngIf="!loadAllRecentChats" class="h-100">
  <div class="flex h-100">
    <div class="chatLeftSidebar">
      <app-chat-left-side-view></app-chat-left-side-view>
    </div>
    <div
      class="w-75 bg-white p-0 overflow-y-auto overflow-x-hidden"
      style="height: 100%"
    >
      <app-chat-middle-view
        [userAndChatDataArray]="userAndChatDataArray"
      ></app-chat-middle-view>
    </div>

    <div
      class="custonActiveInactive"
      [class.active]="bookingCreatePopup"
      *ngIf="bookingCreatePopup"
    >
      <div class="card">
        <div class="header">
          <div class="image">
            <svg
              aria-hidden="true"
              stroke="currentColor"
              stroke-width="1.5"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                stroke-linejoin="round"
                stroke-linecap="round"
              ></path>
            </svg>
          </div>
          <div class="content">
            <span class="title">Create Booking</span>
            <p class="message">Are you sure you want create this booking?</p>
          </div>
          <div class="actions mt-5">
            <button
              pButton
              pRippl
              *ngIf="!btnLoader"
              class="disabledBtn_global desactivate"
            >
              <div class="w-100 flex align-items-center justify-content-center">
                Book Now
                <div class="disabled_loader"></div>
              </div>
            </button>
            <button
              *ngIf="btnLoader"
              class="desactivate mb-1"
              (click)="createBookingCall()"
            >
              Yes, Book Now
            </button>
            <button class="cancel" (click)="cancelBookingCall()">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="chatProgress" *ngIf="loadAllRecentChats">
  <div class="w-100">
    <div class="loading">
      <svg viewBox="0 0 187.3 93.7" height="200px" width="300px" class="svgbox">
        <defs>
          <linearGradient y2="0%" x2="100%" y1="0%" x1="0%" id="gradient">
            <stop stop-color="pink" offset="0%"></stop>
            <stop stop-color="blue" offset="100%"></stop>
          </linearGradient>
        </defs>
        <path
          stroke="url(#gradient)"
          d="M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z"
        ></path>
      </svg>
    </div>
    <div style="width: 40%; margin: 50px auto">
      <div class="progress">
        <div
          class="progress-bar"
          role="progressbar"
          aria-valuenow="60"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <span class="title">Load All Chats</span>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="topbar_position">
    <div class="topbar_things">
        <div class="flex justify-content-center align-items-center">
            <div>
                <div class="rounded-circle">
                    <div class="lessStringName">
                        {{Global.getShortName(selectedUserName)}}
                    </div>
                </div>
            </div>
            <div class="text-light fs-1 ml-5">{{ selectedUserName }}</div>
        </div>
    </div>
</div>
<div class="Chat__wrapper">
    <ul class="Chat">
      <li class="Chat_item Chat_item_l">
        <div class="i_man">
          <img src="https://i.postimg.cc/L5v3P42G/IMG-20180513-182600080.jpg" class="i_man-image" />
        </div>
        <div class="Chat_msgs">
          <div class="msg">
            <div class="msg-content">
              Hello
            </div>
          </div>
        </div>
      </li>
      <li class="Chat_item Chat_item_r">
        <div class="Chat_msgs">
          <div class="msg">
            <div class="msg-content">
              It Just an Sample Chat UI 
            </div>
          </div>
          <div class="msg">
            <div class="msg-content">
              I hope u like it
            </div>
          </div>
        </div>
      </li>
      <li class="Chat_item Chat_item_l">
        <div class="i_man">
          <img src="https://i.postimg.cc/L5v3P42G/IMG-20180513-182600080.jpg" class="i_man-image" />
        </div>
        <div class="Chat_msgs">
          <div class="msg">
            <div class="msg-content">
              It will change color on Scroll...from here all are dummy text
            </div>
          </div>
          <div class="msg">
            <div class="msg-content">
              is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been th
            </div>
          </div>
        </div>
      </li>
      <li class="Chat_item Chat_item_r">
        <div class="Chat_msgs">
          <div class="msg">
            <div class="msg-content">
              to make a type specimen book. It has survived not only five centuries,
            </div>
          </div>
          <div class="msg">
            <div class="msg-content">
              Contrary to popular belief, Lorem Ipsum is not simply random te
            </div>
          </div>
          <div class="msg">
            <div class="msg-content">
              Scroll to see effect 
            </div>
          </div>
        </div>
      </li>
      <li class="Chat_item Chat_item_l">
        <div class="i_man">
          <img src="https://i.postimg.cc/L5v3P42G/IMG-20180513-182600080.jpg" class="i_man-image" />
        </div>
        <div class="Chat_msgs">
          <div class="msg">
            <div class="msg-content">
              here are many variations of passage
            </div>
          </div>
          <div class="msg">
            <div class="msg-content">
              hunks as necessary, making this the first true generator on the Internet. It uses a di
            </div>
          </div>
        </div>
      </li>
      <li class="Chat_item Chat_item_r">
        <div class="Chat_msgs">
          <div class="msg">
            <div class="msg-content">
              Lorem ipsum dolorLorem ipsum dolorLorem ipsum dolor
            </div>
          </div>
          <div class="msg">
            <div class="msg-content">
              Lorem ipsum dolor
            </div>
          </div>
        </div>
      </li>
      <li class="Chat_item Chat_item_l">
        <div class="i_man">
          <img src="https://i.postimg.cc/L5v3P42G/IMG-20180513-182600080.jpg" class="i_man-image" />
        </div>
        <div class="Chat_msgs">
          <div class="msg">
            <div class="msg-content">
              L also the leap into electro also the leap into electro also the leap into electro
            </div>
          </div>
          <div class="msg">
            <div class="msg-content">
              also the leap into electro also the leap into electro 
            </div>
          </div>
        </div>
      </li>
      <li class="Chat_item Chat_item_r">
        <div class="Chat_msgs">
          <div class="msg">
            <div class="msg-content">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
            </div>
          </div>
          <div class="msg">
            <div class="msg-content">
              Lorem ipsum dolor
            </div>
          </div>
          <div class="msg">
            <div class="msg-content">
              ver 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites 
            </div>
          </div>
        </div>
      </li>
      <li class="Chat_item Chat_item_l">
        <div class="i_man">
          <img src="https://i.postimg.cc/L5v3P42G/IMG-20180513-182600080.jpg" class="i_man-image" />
        </div>
        <div class="Chat_msgs">
          <div class="msg">
            <div class="msg-content">
              a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Ma
            </div>
          </div>
          <div class="msg">
            <div class="msg-content">
              Exercitationem corrupti, dignissimos ab dolore laborum unde minus quo velit quisquam dolor earum quia provident harum illo amet praesentium quibusdam voluptatibus deserunt. 
            </div>
          </div>
        </div>
      </li>
      <li class="Chat_item Chat_item_l">
        <div class="i_man">
          <img src="https://i.postimg.cc/L5v3P42G/IMG-20180513-182600080.jpg" class="i_man-image" />
        </div>
        <div class="Chat_msgs">
          <div class="msg">
            <div class="msg-content">
              iam itaque molestiae alias, fuga voluptatum autem quis nulla!
            </div>
          </div>
        </div>
      </li>
      <li class="Chat_item Chat_item_r">
        <div class="Chat_msgs">
          <div class="msg">
            <div class="msg-content">
              ent here, content here', making it look like readable English. Ma
            </div>
          </div>
          <div class="msg">
            <div class="msg-content">
              Dsitatibus voluptas illum iure, maxime dolorem voluptatibus quaerat obcaecati natus?
            </div>
          </div>
        </div>
      </li>
      <li class="Chat_item Chat_item_l">
        <div class="i_man">
          <img src="https://i.postimg.cc/L5v3P42G/IMG-20180513-182600080.jpg" class="i_man-image" />
        </div>
        <div class="Chat_msgs">
          <div class="msg">
            <div class="msg-content">
              Eaque tempora at totam! Id corporis blanditiis dicta neque non ex quasi provident exercitationem quidem voluptas itaque illum saepe, temporibus veritatis corrupti.
            </div>
          </div>
          <div class="msg">
            <div class="msg-content">
              Fugit officiis aut inventore voluptatem commodi repudiandae nobis voluptas reiciendis, facere, voluptatibus quos ipsam quaerat cumque odio animi corporis, labore dolore corrupti! 
            </div>
          </div>
        </div>
      </li>
      <li class="Chat_item Chat_item_l">
        <div class="i_man">
          <img src="https://i.postimg.cc/L5v3P42G/IMG-20180513-182600080.jpg" class="i_man-image" />
        </div>
        <div class="Chat_msgs">
          <div class="msg">
            <div class="msg-content">
              a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Ma
            </div>
          </div>
          <div class="msg">
            <div class="msg-content">
              Exercitationem corrupti, dignissimos ab dolore laborum unde minus quo velit quisquam dolor earum quia provident harum illo amet praesentium quibusdam voluptatibus deserunt. 
            </div>
          </div>
        </div>
      </li>
      <li class="Chat_item Chat_item_l">
        <div class="i_man">
          <img src="https://i.postimg.cc/L5v3P42G/IMG-20180513-182600080.jpg" class="i_man-image" />
        </div>
        <div class="Chat_msgs">
          <div class="msg">
            <div class="msg-content">
              iam itaque molestiae alias, fuga voluptatum autem quis nulla!
            </div>
          </div>
        </div>
      </li>
      <li class="Chat_item Chat_item_r">
        <div class="Chat_msgs">
          <div class="msg">
            <div class="msg-content">
              ent here, content here', making it look like readable English. Ma
            </div>
          </div>
          <div class="msg">
            <div class="msg-content">
              Dsitatibus voluptas illum iure, maxime dolorem voluptatibus quaerat obcaecati natus?
            </div>
          </div>
        </div>
      </li>
      <li class="Chat_item Chat_item_l">
        <div class="i_man">
          <img src="https://i.postimg.cc/L5v3P42G/IMG-20180513-182600080.jpg" class="i_man-image" />
        </div>
        <div class="Chat_msgs">
          <div class="msg">
            <div class="msg-content">
              Eaque tempora at totam! Id corporis blanditiis dicta neque non ex quasi provident exercitationem quidem voluptas itaque illum saepe, temporibus veritatis corrupti.
            </div>
          </div>
          <div class="msg">
            <div class="msg-content">
              Fugit officiis aut inventore voluptatem commodi repudiandae nobis voluptas reiciendis, facere, voluptatibus quos ipsam quaerat cumque odio animi corporis, labore dolore corrupti! 
            </div>
          </div>
        </div>
      </li>
      <li class="Chat_item Chat_item_l">
        <div class="i_man">
          <img src="https://i.postimg.cc/L5v3P42G/IMG-20180513-182600080.jpg" class="i_man-image" />
        </div>
        <div class="Chat_msgs">
          <div class="msg">
            <div class="msg-content">
              a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Ma
            </div>
          </div>
          <div class="msg">
            <div class="msg-content">
              Exercitationem corrupti, dignissimos ab dolore laborum unde minus quo velit quisquam dolor earum quia provident harum illo amet praesentium quibusdam voluptatibus deserunt. 
            </div>
          </div>
        </div>
      </li>
      <li class="Chat_item Chat_item_l">
        <div class="i_man">
          <img src="https://i.postimg.cc/L5v3P42G/IMG-20180513-182600080.jpg" class="i_man-image" />
        </div>
        <div class="Chat_msgs">
          <div class="msg">
            <div class="msg-content">
              iam itaque molestiae alias, fuga voluptatum autem quis nulla!
            </div>
          </div>
        </div>
      </li>
      <li class="Chat_item Chat_item_r">
        <div class="i_man">
            <img src="https://i.postimg.cc/L5v3P42G/IMG-20180513-182600080.jpg" class="i_man-image" />
          </div>
        <div class="Chat_msgs">
          <div class="msg">
            <div class="msg-content">
              ent here, content here', making it look like readable English. Ma
            </div>
          </div>
          <div class="msg">
            <div class="msg-content">
              Dsitatibus voluptas illum iure, maxime dolorem voluptatibus quaerat obcaecati natus?
            </div>
          </div>
        </div>
      </li>
      <li class="Chat_item Chat_item_l">
        <div class="i_man">
          <img src="https://i.postimg.cc/L5v3P42G/IMG-20180513-182600080.jpg" class="i_man-image" />
        </div>
        <div class="Chat_msgs">
          <div class="msg">
            <div class="msg-content">
              Eaque tempora at totam! Id corporis blanditiis dicta neque non ex quasi provident exercitationem quidem voluptas itaque illum saepe, temporibus veritatis corrupti.
            </div>
          </div>
          <div class="msg">
            <div class="msg-content">
              Fugit officiis aut inventore voluptatem commodi repudiandae nobis voluptas reiciendis, facere, voluptatibus quos ipsam quaerat cumque odio animi corporis, labore dolore corrupti! 
            </div>
          </div>
        </div>
      </li>
      <li class="Chat_item Chat_item_l">
        <div class="i_man">
          <img src="https://i.postimg.cc/L5v3P42G/IMG-20180513-182600080.jpg" class="i_man-image" />
        </div>
        <div class="Chat_msgs">
          <div class="msg">
            <div class="msg-content">
              a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Ma
            </div>
          </div>
          <div class="msg">
            <div class="msg-content">
              Exercitationem corrupti, dignissimos ab dolore laborum unde minus quo velit quisquam dolor earum quia provident harum illo amet praesentium quibusdam voluptatibus deserunt. 
            </div>
          </div>
        </div>
      </li>
      <li class="Chat_item Chat_item_l">
        <div class="i_man">
          <img src="https://i.postimg.cc/L5v3P42G/IMG-20180513-182600080.jpg" class="i_man-image" />
        </div>
        <div class="Chat_msgs">
          <div class="msg">
            <div class="msg-content">
              iam itaque molestiae alias, fuga voluptatum autem quis nulla!
            </div>
          </div>
        </div>
      </li>
      <li class="Chat_item Chat_item_r">
        <div class="Chat_msgs">
          <div class="msg">
            <div class="msg-content">
              ent here, content here', making it look like readable English. Ma
            </div>
          </div>
          <div class="msg">
            <div class="msg-content">
              Dsitatibus voluptas illum iure, maxime dolorem voluptatibus quaerat obcaecati natus?
            </div>
          </div>
        </div>
      </li>
      <li class="Chat_item Chat_item_l">
        <div class="i_man">
          <img src="https://i.postimg.cc/L5v3P42G/IMG-20180513-182600080.jpg" class="i_man-image" />
        </div>
        <div class="Chat_msgs">
          <div class="msg">
            <div class="msg-content">
              Eaque tempora at totam! Id corporis blanditiis dicta neque non ex quasi provident exercitationem quidem voluptas itaque illum saepe, temporibus veritatis corrupti.
            </div>
          </div>
          <div class="msg">
            <div class="msg-content">
              Fugit officiis aut inventore voluptatem commodi repudiandae nobis voluptas reiciendis, facere, voluptatibus quos ipsam quaerat cumque odio animi corporis, labore dolore corrupti! 
            </div>
          </div>
        </div>
      </li>
      <li class="Chat_item Chat_item_r">
        <div class="Chat_msgs">
          <div class="msg">
            <div class="msg-content">
              t the majority have suffered alteration in some form, by injected humour, or randomised words which don't
            </div>
          </div>
          <div class="msg">
            <div class="msg-content">
              peat predefined chunks as ne
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
<section style="background-color: #101010;height: 100vh;display: flex;align-items: center; justify-content: center;">
    <div class="flex align-content-center justify-content-around w-100">
        <div class="flex" style="width: 100%;">
            <div class="w-25 flex justify-content-center">
                <div>
                    <div
                        style="background-color: #eef2f6; width: 300px;height: 150px; margin: 20px 0px;border-radius: 7px;">
                        <div class="flex justify-content-around align-items-center h-100">
                            <div>
                                <div class="h4">My Rank</div>
                                <div class="h1 mt-4">{{currentDispatcherRank}} PLACE</div>
                            </div>
                            <div>
                                <div class="h4">My Score</div>
                                <div class="h2 mt-4 text-end">{{currentDispatcherScore}}</div>
                            </div>
                        </div>
                    </div>
                    <div
                        style="background-color: #16171a; width: 300px; margin: 20px 0px;height: 300px;border-radius: 7px;border: .5px solid #2d2d2d;">
                        <div class="flex justify-content-center align-items-center h-100">
                            <div class="flex align-items-center">
                                <div class="text-light">
                                    <div class="h4">Dispatcher Name</div>
                                    <div><img style="border-radius: 50%;margin: 20px 0px;"
                                            src="../../assets/images/user_profile_img.png" height="150px" width="150px"
                                            alt=""></div>
                                    <div class="h3"
                                        style="color: #00cec9;margin: 8px 0px;display: flex;justify-content: center;">
                                        {{Global.dispatcherDetails.dispatcherName}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-50">
                <div class="w-100 my-4"
                    style="border: 0.5px solid #2d2d2d; border-radius: 4px; padding: 10px 20px;height: 87vh;">
                    <div class="flex align-items-center justify-content-between">
                        <div class="text-light h2">Leader Board</div>
                        <button class="allShift fontMediumBold" (click)="refreshLeaderBorard()"  [disabled]="disableBtn">
                            Refresh
                          </button>

                        <!-- <div class="selectedDateDropdown">
                            <header>
                                <div class="menu">
                                    <div class="tabs">
                                        <input type="radio" name="tabs" id="tab1" checked
                                            (click)="convertedTabeWiseDate('currentWeek')">
                                        <label for="tab1" class="tab">Current Week</label>
                                        <input type="radio" name="tabs" id="tab2"
                                            (click)="convertedTabeWiseDate('currentMonth')">
                                        <label for="tab2" class="tab">Current Month</label>
                                        <input type="radio" name="tabs" id="tab3"
                                            (click)="convertedTabeWiseDate('lastMonth')">
                                        <label for="tab3" class="tab">Last Month</label>
                                        <input type="radio" name="tabs" id="tab4"
                                            (click)="convertedTabeWiseDate('lastThreeMonth')">
                                        <label for="tab4" class="tab">Last Three Months</label>
                                        <span class="selector"></span>
                                    </div>
                                </div>
                            </header>
                        </div> -->
                    </div>
                    <div class="my-4">
                        <div class="flex w-100 text-light h3 align-items-center"
                            style="border: .5px solid #2d2d2d;padding: 15px; border-radius: 4px;">
                            <div class="w-25 flex justify-content-center">Rank</div>
                            <div class="w-50 text-center">Dispatcher</div>
                            <div class="w-25 text-center">Converted Lead</div>
                        </div>

                        <div class="mt-4" style="height: 75vh; overflow-y: auto;">
                            <div style="padding: 15px;margin: 10px 0; border-radius: 4px;border: .5px solid #2d2d2d;display: flex;"
                                *ngFor="let data of leaderboardAllData;  let i = index">
                                <div class="w-25 flex justify-content-center align-items-center" style="border-right: 1px solid #2d2d2d;">
                                    <div>
                                        <i class='bx bxs-crown h1' style='color:#f7ba00'
                                            *ngIf="i == 0"></i>
                                         <i class='bx bxs-crown h1' style='color:#e3e3e3'
                                            *ngIf="i == 1"></i>
                                        <i class='bx bxs-crown h1' style='color:#914900'
                                            *ngIf="i == 2"></i> 
                                    </div>
                                </div>
                                <div class="w-50 flex align-items-center justify-content-center"
                                    style="border-right: 1px solid #2d2d2d;">
                                    <div class="mr-5 w-25 flex align-items-center justify-content-center">
                                        <div class="getDispatcherShortName">
                                            {{Global.getShortName(data.dispatcherName)}}
                                        </div>
                                    </div>
                                    <div class="text-light w-75">{{data.dispatcherName}}</div>
                                </div>
                                <div class="w-25 text-light flex align-items-center justify-content-center mr-4">
                                    {{data.convertedLead}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="w-25">
                <section class="dispatcherleaderboard">
                    <div>
                        <div class="dispatcherAllRecord" style="background-color: #75a6e270;">
                            <div class="tasksIcon">
                                <i class='bx bx-calendar-week'></i>
                            </div>
                            <div class="dispatcherrecordDescription">
                                <div>
                                    <div class="totalCounts1">{{totalLead != undefined ?
                                        totalLead : 0}}</div>
                                    <div class="leadNames1">Total Generated Lead</div>
                                </div>
                            </div>
                        </div>
                        <div class="dispatcherAllRecord" style="background-color: #48715abf;">
                            <div class="tasksIcon">
                                <i class='bx bx-task'></i>
                            </div>
                            <div class="dispatcherrecordDescription">
                                <div>
                                    <div class="totalCounts2">{{totalLeadConverted != undefined ?
                                        totalLeadConverted : 0}}</div>
                                    <div class="leadNames2">Total Converted Lead</div>
                                </div>
                            </div>
                        </div>
                        <div class="dispatcherAllRecord" style="background-color: #a857676b;">
                            <div class="tasksIcon">
                                <i class='bx bx-task-x'></i>
                            </div>
                            <div class="dispatcherrecordDescription">
                                <div>
                                    <div class="totalCounts3">{{totalLeadNotConverted != undefined ?
                                        totalLeadNotConverted : 0}}</div>
                                    <div class="leadNames3">Total Not Converted Lead</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</section>
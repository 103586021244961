import { NativeDateAdapter } from "@angular/material/core";
import * as moment from "moment";


export class MyDateAdapter extends NativeDateAdapter {
    format(date: Date, displayFormat: Object): string {
        if (displayFormat == "input") {
            let day = date.getDate();
            let month = date.getMonth() + 1;
            let year = date.getFullYear();
            // return this._to2digit(day) + '/' + this._to2digit(month) + '/' + year;
            return year +  '-' + this._to2digit(month) + '-' + this._to2digit(day) ;
        } else {
            // let day = date.getDate();
            // let month = date.getMonth() + 1;
            // let year = date.getFullYear();
            // console.log("--mydateAdapter::",year +  '-' + this._to2digit(month) + '-' + this._to2digit(day));
            (moment(date).format('YYYY-MM-DD'))
            // return year +  '-' + this._to2digit(month) + '-' + this._to2digit(day) ;
            return moment(date).format('YYYY-MM-DD');
        }
    }
 
    private _to2digit(n: number) {
        return ('00' + n).slice(-2);
    } 
 }
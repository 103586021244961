import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalComponent } from 'ng-modal-lib';
import { of, Subject, Subscription } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, map, mergeMap, takeUntil } from 'rxjs/operators';
import { CallmethodService } from '../services/callmethod.service';
import { DataShareService } from '../services/data-share.service';
import { GlobalService } from '../services/global.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';

declare let responsiveVoice: any
@Component({
  selector: 'app-driver-break-alert',
  templateUrl: './driver-break-alert.component.html',
  styleUrls: ['./driver-break-alert.component.scss']
})
export class DriverBreakAlertComponent implements OnInit {

  private unsubscribe$ = new Subject<void>();
  public subscription: Subscription = new Subscription;
  moment: any = moment;


  selectedDateRange: any;
  dateFormat: string = 'YYYY/MM/DD'
  alwaysShowCalendars: boolean;
  maxDate: any;
  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }


  constructor(
    public Global: GlobalService,
    public CallMethod: CallmethodService,
    public shareData: DataShareService,

  ) {
    this.maxDate = moment().tz(this.Global.timeZone);
    this.alwaysShowCalendars = true;
    this.subscription = this.searchDriver.pipe(
      map((event: any) => event.target.value),
      debounceTime(300),
      distinctUntilChanged(),
      mergeMap((search: any) => of(search).pipe(delay(0))),
    ).subscribe((data: any) => {
      this.searchValue = data;
      this.getDriverAlert(0);
    });
  }

  isOnline: number = 0;

  // unAssignfilterStatus: any = [];
  // dropdownSettings: any = {};
  filterArray: any = [];
  ngOnInit(): void {
    this.subscription = this.shareData.getData().subscribe(data => {
      if (data.function == 'driverBreakAlert') {
        this.searchJobModalShow();
      }
      if (data.function == 'driverAlertMqtt') {
        // if (this.showDriverAlertModel == false) {
        //   this.Global.unReadDriverAlert += 1;
        //   this.Global.setLocalStorage({ 'key': 'unReadDriverAlert', 'value': this.Global.unReadDriverAlert });
        // }
      }
      if (data.function == 'changeThemeColor') {
        this.driverAlertModal.modalHeader.nativeElement.style.backgroundColor = this.Global.first_color;
        this.driverAlertModal.modalFooter.nativeElement.style.backgroundColor = this.Global.third_color;
        this.driverAlertModal.modalRoot.nativeElement.style.border = '1px solid ' + this.Global.first_color;
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.subscription.unsubscribe();
  }
  @ViewChild("driverAlertModal") public driverAlertModal!: ModalComponent;
  showDriverAlertModel: boolean = false;

  searchJobModalShow() {
    this.driverAlertModal.modalRoot.nativeElement.style.zIndex = this.driverAlertModal.getMaxModalIndex() + 2;
    if (this.showDriverAlertModel == false) {
      setTimeout(() => {
        this.driverAlertModal.modalRoot.nativeElement.style.top = environment.windowPopupStyle.searchJob.top;
      }, 0);

      if (this.driverAlertModal.modalBody.nativeElement.style.height.trim() == '') {
        this.driverAlertModal.modalBody.nativeElement.style.height = environment.windowPopupStyle.searchJob.height;
        let width = parseInt(environment.windowPopupStyle.searchJob.width.replace('px', ''));
        if (window.innerWidth < parseInt(environment.windowPopupStyle.searchJob.width.replace('px', ''))) {
          width = window.innerWidth - 2;
        }
        this.driverAlertModal.modalRoot.nativeElement.style.width = width.toString() + 'px';
      }

      this.driverAlertModal.modalHeader.nativeElement.style.backgroundColor = this.Global.first_color;
      this.driverAlertModal.modalFooter.nativeElement.style.backgroundColor = this.Global.third_color;
      this.driverAlertModal.modalRoot.nativeElement.style.border = '1px solid ' + this.Global.first_color;

      this.driverAlertModal.show();
      this.showDriverAlertModel = true;

      this.getDriverAlert(0);
    }

  }

  alertDataLimit: number = 20;
  alertPanel: boolean = true;
  alertLoader: boolean = false;
  totalAlert: number = 0;
  alertCurrentpage: number = 0;
  alertData: any = [];

  getDriverAlert(page: any) {

    this.alertPanel = true;
    this.alertLoader = false;
    this.alertCurrentpage = page;
    this.alertData = []
    let alertDataOffset = (page == 0) ? 0 : ((page * this.alertDataLimit) - this.alertDataLimit);


    let requestObj = {
      offset: alertDataOffset,
      limit: this.alertDataLimit,
      search: this.searchValue,
      status: this.driverStatus,
      startDate: this.alertStartDate,
      endDate: this.alertEndDate
    }

    if (this.searchValue != "") {
      this.filterArray.search = this.searchValue;
    }


    this.CallMethod.getDriverBreakAlert(requestObj).pipe(takeUntil(this.unsubscribe$)).subscribe((result: any) => {
      if (result.status) {
        console.log('****** getDriverBreakAlert result ******', result);
        // this.Global.unReadDriverAlert = 0;
        // this.Global.setLocalStorage({ 'key': 'unReadDriverAlert', 'value': this.Global.unReadDriverAlert });
        this.alertData = result.data;
        this.totalAlert = result.totalCount
      }
      this.alertPanel = false;
      this.alertLoader = true;
    });

  }

  closeAlertModel() {
    this.showDriverAlertModel = false;
  }
  alertLimitSet(event: any) {
    let value = +event.target.value;
    this.alertDataLimit = value;
    this.alertData = [];
    this.totalAlert = 0;
    this.alertCurrentpage = 0;
    this.getDriverAlert(0);
  }


  //------------filter start--------------------------------
  alertStartDate: string = "";
  alertEndDate: string = "";

  alertDateSelect(event: any) {
    // this.showError = false;
    let startDate = moment(event.startDate).format('YYYY-MM-DD 00:00:00');
    let endDate = moment(event.endDate).format('YYYY-MM-DD 23:59:59');
    if (moment(startDate, 'YYYY-MM-DD HH:mm:ss').isValid() && moment(endDate, 'YYYY-MM-DD 23:59:59').isValid()) {
      this.alertStartDate = startDate;
      this.alertEndDate = endDate;
      this.filterArray.Date = this.alertStartDate + ' -to- ' + this.alertEndDate;
      this.getDriverAlert(0);
    }
    else {
      // this.showError = true;
    }
  }

  public searchDriver = new Subject<KeyboardEvent>();
  searchValue: string = '';
  searchDriverAlert(event: any) {
    if (event.target.value.trim() != '') {
      this.searchValue = event.target.value;
      this.searchDriver.next(event);
    } else if (event.target.value == "") {
      this.searchValue = '';
      delete this.filterArray.search;
      delete this.filterArray.searchBy;
    }
  }

  driverStatus: string = "all";
  filterByDriverStatus(event: any) {
    this.driverStatus = event.target.value;
    this.filterArray.status = this.driverStatus;
    this.getDriverAlert(0);
  }

  removeFilter(key: any) {
    if (key == 'search') {
      this.searchValue = ""
    }
    else if (key == 'Date') {
      this.alertStartDate = "";
      this.alertEndDate = "";
      this.selectedDateRange = "";
    }

    else if (key == 'status') {
      this.driverStatus = "all"
    }
    delete this.filterArray[key]
    this.getDriverAlert(0);
  }

  //------------filter end---  -----------------------------

}

import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { extend, Internationalization } from '@syncfusion/ej2-base';

import { AgendaService, DayService, EventRenderedArgs, EventSettingsModel, ExcelExportService, ExportFieldInfo, ExportOptions, MonthAgendaService, MonthService, ResourcesModel, ScheduleComponent, TimelineMonthService, TimelineViewsService, TimeScaleModel, View, WeekService, WorkWeekService } from '@syncfusion/ej2-angular-schedule';
import * as moment from 'moment';
import { CallmethodService } from '../services/callmethod.service';
import { GlobalService } from '../services/global.service';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PopupService } from '../services/popup.service';


@Component({
  selector: 'app-task-timeline-calender',
  templateUrl: './task-timeline-calender.component.html',
  styleUrls: ['./task-timeline-calender.component.scss'],
  providers: [TimelineViewsService, TimelineMonthService, AgendaService, WeekService, DayService, MonthService, MonthAgendaService, WorkWeekService, ExcelExportService]
})
export class TaskTimelineCalenderComponent implements OnInit {
  @ViewChild('scheduleObj') public scheduleObj!: ScheduleComponent;
  cellContent!: TemplateRef<any> | null;
  cellFooter!: TemplateRef<any> | null;
  offlineDriverList: any;
  onlineDriverList: any;
  allDriverList: any;
  selectedBooking: {} | undefined;
  public today: Date = new Date();
  public currentYear: number = this.today.getFullYear();
  public currentMonth: number = this.today.getMonth();
  public currentDay: number = this.today.getDate();
  public dateValue: Object = new Date(new Date().setDate(14));
  public minDate: Object = new Date(this.currentYear, this.currentMonth, 7);
  public maxDate: Object = new Date(this.currentYear, this.currentMonth, 31);

  constructor(public Global: GlobalService, private CallMethod: CallmethodService, public Popup: PopupService) { }

  calenderData: any = []
  eventSettings!: EventSettingsModel;
  calData: any = [];
  filterStartDate: any = "";
  filterEndDate: any = "";

  ngOnInit(): void {
    localStorage.setItem("calenderStartTime", moment().format('YYYY-MM-DD'))
    localStorage.setItem("calenderEndTime", moment().format('YYYY-MM-DD'))
  }

  driverAssignModal: boolean = false;

  public instance: Internationalization = new Internationalization();

  public selectedDate: Date = new Date();
  public currentView: View = 'TimelineDay';
  // 1.Pending,2.Driver requested,3.Accept,4.User Cancelled,5.Driver Canceled, 6. driver unavaliable, 7.Driver arrived, 8. on trip, 9.completed
  public timeScale: TimeScaleModel = {
    enable: true, interval: 15,
    slotCount: 6,
  };
  // public timeScale: TimeScaleModel = { enable: true, interval: 60,
  //   slotCount: 6, majorSlotTemplate: '#majorSlotTemplate',
  // minorSlotTemplate: '#minorSlotTemplate'};
  public enablePersistence: Boolean = false;
  public workDays: number[] = [0, 1, 2, 3, 4, 5];
  public rowAutoHeight: boolean = true;
  public bookingStatusData: Record<string, any>[] = [
    { EventType: 1, Color: '#1d4ed8', Type: 'Pending', slideToComplete: 0 },
    { EventType: 2, Color: '#7fa900', Type: 'Driver requested', slideToComplete: 0 },
    { EventType: 3, Color: '#4c1d95', Type: 'Accept', slideToComplete: 0 },
    { EventType: 6, Color: '#fec200', Type: 'driver unavailable', slideToComplete: 0 },
    { EventType: 7, Color: '#000000', Type: 'Driver arrived', slideToComplete: 0 },
    { EventType: 8, Color: '#991b1b', Type: 'on trip', slideToComplete: 0 },
    { EventType: 8, Color: '#d87606', Type: 'Pending Payment', slideToComplete: 1 },
  ];
  getMajorTime(date: Date): string {
    return this.instance.formatDate(date, { skeleton: 'hm' });
  }
  getMinorTime(date: Date): string {
    return this.instance.formatDate(date, { skeleton: 'ms' }).replace(':00', '');
  }

  disableBtn: boolean = false;
  calenderBookings(req: any) {
    console.log('***** calenderBookings *****');
    this.calData = [];
    this.disableBtn = true;
    this.CallMethod.bookingCalender(req).subscribe((res: any) => {
      if (res) {
        this.calenderData = res.data;
        for (let i = 1; i < this.calenderData.length; i++) {
          let ColorData = this.bookingStatusData.find((x: any) => x.EventType == this.calenderData[i].status)
          console.log('***** ColorData *****', ColorData);
          let data =
          {
            Id: i,
            Subject: this.calenderData[i].pickupAddress,
            bookingId: this.calenderData[i].bookingId,
            Location: this.calenderData[i].dropoffAddress,
            StartTime: moment(this.calenderData[i].pickupTime).toDate(),
            assignTime: moment(this.calenderData[i].assignTime).toDate(),
            EndTime: moment(this.calenderData[i].estimateCompleteBookingTime).toDate(),
            CalendarId: this.calenderData[i].bookingFlag,
            Type: this.calenderData[i].bookingType,
            EventType: this.calenderData[i].status,
            ProjectId: this.calenderData[i].slidedToComplete,
            Tags: this.calenderData[i].dBookId,
            Color: this.calenderData[i].slidedToComplete == 0 ? ColorData?.Color : this.bookingStatusData[6].Color,
            driverName: this.calenderData[i].driverJson ? this.calenderData[i].driverJson.driverName : '',
            driverPhone: this.calenderData[i].driverJson ? this.calenderData[i].driverJson.driverPhone : ''
          };
          this.calData.push(data);
        }
        this.eventSettings = { dataSource: extend([], this.calData, null!, true) as Record<string, any>[], enableTooltip: false, enableIndicator: true };
      }
      this.disableBtn = false;
    });
  }

  closeInfoWindow() {
    this.scheduleObj.closeQuickInfoPopup();
  }

  isChecked: boolean = false;
  timeDateFilter(event: any) {
    this.isChecked = event.target['checked'];
    console.log('***** isChecked *****', this.isChecked);
    localStorage.setItem("myFilter", JSON.stringify(this.isChecked))
    if (!this.filterStartDate || !this.filterEndDate) {
      console.log('***** if  *****', this.filterStartDate);
      let req = {
        "from": moment().format('YYYY-MM-DD'),
        "to": moment().format('YYYY-MM-DD'),
        "filterBy": this.isChecked == false ? "assignTime" : "pickupTime"
      }
      console.log('***** moment().format start *****',moment().format('YYYY-MM-DD'),);
      localStorage.setItem("calenderStartTime", moment().format('YYYY-MM-DD'))
      localStorage.setItem("calenderEndTime", moment().format('YYYY-MM-DD'))
      this.calenderBookings(req);
    }
    else {
      console.log('*****else filterEndDate *****');

      let req = {
        "from": moment(this.filterStartDate).format('YYYY-MM-DD'),
        "to": moment(this.filterEndDate).format('YYYY-MM-DD'),
        "filterBy": this.isChecked == false ? "assignTime" : "pickupTime"
      }
      this.calenderBookings(req);
    }

  }
  selectedBookingId: any = "";
  selectedDBookId: any = "";
  public onPopupOpen(args: any) {
    this.selectedBookingId = args.data.bookingId;
    this.selectedDBookId = args.data.Tags;
  }

  onEventRendered(args: EventRenderedArgs): void {
    console.log('***** args *****', args);
    let Color: string = args.data.Color as string;
    if (!args.element || !Color) {
      return;
    }
    if (this.scheduleObj.currentView == 'Agenda' || this.scheduleObj.currentView == 'MonthAgenda') {
      (args.element.firstChild as HTMLElement).style.borderLeftColor = Color;
    } else {
      args.element.style.backgroundColor = Color;
    }
  }

  reloadPage() {

    if (!this.filterStartDate || !this.filterEndDate) {
      console.log('***** if  *****', this.filterStartDate);
      let req = {
        "from": moment().format('YYYY-MM-DD'),
        "to": moment().format('YYYY-MM-DD'),
        "filterBy": this.isChecked == false ? "assignTime" : "pickupTime"
      }
      console.log('***** moment().format start *****',moment().format('YYYY-MM-DD'),);
      localStorage.setItem("calenderStartTime", moment().format('YYYY-MM-DD'))
      localStorage.setItem("calenderEndTime", moment().format('YYYY-MM-DD'))
      this.calenderBookings(req);


    }
    else {
      console.log('*****else filterEndDate *****');

      let req = {
        "from": moment(this.filterStartDate).format('YYYY-MM-DD'),
        "to": moment(this.filterEndDate).format('YYYY-MM-DD'),
        "filterBy": this.isChecked == false ? "assignTime" : "pickupTime"
      }
      this.calenderBookings(req);
    }
  }

  public getResourceData(data: Record<string, any>): Record<string, any> {
    const resources: ResourcesModel = this.scheduleObj.getResourceCollections()[0];
    const resourceData: Record<string, any> = (resources.dataSource as Record<string, any>[]).filter((resource: Record<string, any>) =>
      resource.EventType === data.EventType)[0] as Record<string, any>;
    return resourceData;
  }

  public getHeaderStyles(data: Record<string, any>): Record<string, any> {
    const resourceData: Record<string, any> = this.getResourceData(data);
    return { background: resourceData.EventType === data.EventType && data.ProjectId == 0 ? resourceData.Color : this.bookingStatusData[6].Color, color: '#FFFFFF' };
  }

  public getHeaderTitle(data: Record<string, any>): string {
    return `Booking Details   #${data.Tags}`;
  }

  public getHeaderDetails(data: { [key: string]: Date }): string {
    return `${this.instance.formatDate(data.StartTime, { type: 'date', skeleton: 'full' })}  (
      ${this.instance.formatDate(data.StartTime, { skeleton: 'hm' })}  - 
      ${this.instance.formatDate(data.EndTime, { skeleton: 'hm' })})`;
  }

  public getEventType(data: any): string {
    const resourceData: Record<string, any> = this.getResourceData(data);
    return resourceData.EventType === data.EventType && data.ProjectId == 0 ? resourceData.Type : this.bookingStatusData[6].Type as string;
  }


  onCreated(): void {
    console.log('***** onCreated *****')
    let startDate
    let endDate
    let getCurrentView = this.scheduleObj.currentView
    if (getCurrentView) {
      let currentViewDates: any = this.scheduleObj.getCurrentViewDates();
      startDate = currentViewDates[0];
      endDate = currentViewDates[currentViewDates.length - 1];
      console.log(startDate);
      console.log(endDate);
    }

    localStorage.getItem('myFilter')
    this.filterStartDate = localStorage.getItem('calenderStartTime');
    this.filterEndDate = localStorage.getItem('calenderEndTime');
    console.log('***** this.filterStartDate *****', this.filterStartDate);
    console.log('***** this.filterEndDate *****', this.filterEndDate);
    if (!this.filterStartDate || !this.filterEndDate) {
      console.log('***** if  *****', this.filterStartDate);
      let req = {
        "from": moment().format('YYYY-MM-DD'),
        "to": moment().format('YYYY-MM-DD'),
        "filterBy": "assignTime"
      }
      console.log('***** moment().format start *****',moment().format('YYYY-MM-DD'),);
      localStorage.setItem("calenderStartTime", moment().format('YYYY-MM-DD'))
      localStorage.setItem("calenderEndTime", moment().format('YYYY-MM-DD'))
      this.calenderBookings(req);


    }
    else {
      console.log('*****else filterEndDate *****');

      let req = {
        "from": moment(this.filterStartDate).format('YYYY-MM-DD'),
        "to": moment(this.filterEndDate).format('YYYY-MM-DD'),
        "filterBy": "assignTime"
      }
      this.calenderBookings(req);
    }

  }

  //To get the start and end dates of current view and current Date range
  onActionComplete(args: any): void {
    console.log('***** onActionComplete *****', args);
    if (args.requestType === "viewNavigate" || args.requestType === "dateNavigate") {
      let currentViewDates = this.scheduleObj.getCurrentViewDates();
      window.localStorage.view = this.scheduleObj.currentView;
      let startDate: any = currentViewDates[0];
      let endDate: any = currentViewDates[currentViewDates.length - 1];

      localStorage.setItem("calenderStartTime", moment(startDate).format('YYYY-MM-DD'))
      localStorage.setItem("calenderEndTime", moment(endDate).format('YYYY-MM-DD'))
      let req = {
        "from": moment(startDate).format('YYYY-MM-DD'),
        "to": moment(endDate).format('YYYY-MM-DD'),
        "filterBy": "assignTime"
      }
      this.calenderBookings(req);
    }
  }

  assignDriver() {
    this.driverAssignModal = true;
    this.getDrivers(1);
  }

  // **************** start assign driver ***************
  private unsubscribe$ = new Subject<void>();
  assignDriverFlag: number = 1;
  selectedDriverList: number = 1;
  @ViewChild("searchAssignDriver") searchAssignDriver!: ElementRef;

  changeAssignDriverList(value: number) {
    this.selectedDriverList = value;
    this.getDrivers(value);

  }
  /* Set Assign Call */
  setAssign(bookingId: string) {
    console.log('***** bookingId *****', bookingId);
    if (this.selectedDriver.driverId != undefined && this.selectedDriver.driverId != '') {
      this.CallMethod.setAssign({ bookingId: bookingId, driverId: this.selectedDriver.driverId }).pipe(takeUntil(this.unsubscribe$)).subscribe((result: any) => {
        if (result.status == true) {
          this.Popup.toasterMessage({
            status: 'success',
            title: 'Booking Assign!',
            message: "Selected Booking Assign to Driver.",
          });
          this.driverAssignModal = false;
        }
        else {
          if (result.statusCode == 1) {
            this.Popup.toasterMessage({
              status: 'error',
              title: 'Booking not found!',
              message: "Something wrong please try again.",
            });
          }
          else if (result.statusCode == 2) {
            this.Popup.toasterMessage({
              status: 'error',
              title: 'Driver not found!',
              message: "Something wrong please try again.",
            });
          }
          else if (result.statusCode == 3) {
            this.Popup.toasterMessage({
              status: 'error',
              title: 'Only payment is pending!',
              message: "Only payment is pending, So cannot transfer this booking to other driver.",
            });
          }
          else {
            this.Popup.toasterMessage({
              status: 'error',
              title: 'Something wrong!',
              message: "Please Try Again",
            });
          }
        }
      });
    } else {
    }
  }


  driverName: string = ''
  showDriverShimmerEffect: boolean = false
  nearestDriverIds: any = [];
  filterDriverLimit: number = 10;
  filterDriverOffset: number = 0;

  selectedDriver: any = {
    id: '',
    name: ''
  }

  getDriverByName(driverName: string): void {
    this.selectedDriver.id = ''
    this.selectedDriver.name = ''
    if (driverName) {
      this.allDriverList = this.allDriverList.filter((item: any) => item.driverName.toLowerCase().includes(driverName.toLowerCase()));
    } else {
      this.allDriverList = this.finalDriverList;
    }
  }

  onDriverSelect(driverObject: any) {
    this.selectedDriver.driverId = driverObject.driverId
    this.selectedDriver.name = driverObject.driverName
    console.log(' :::::::: driverObject ::::::::', this.selectedDriver.driverId);
  }

  finalDriverList: any = []
  getDrivers(opts: any = {}) {
    return new Promise((resolve, reject) => {
      let request: any = {};
      // this.driverListShimmer = (opts.loadShimmer) ? true : false;
      if (opts.request != undefined) {
        request = opts.request;
      }
      else {
        request.flag = opts;
      }
      if (this.Global.isSetDriverLimit == true) {
        request.driverIds = this.nearestDriverIds;
      }

      if (this.Global.isSetDriverLimit == true && ((opts.isVehicleFilter != undefined && opts.isVehicleFilter == true) || opts.callType != undefined && opts.callType == 'setAssign')) {
        request.driverIds = undefined;
        request.limit = this.filterDriverLimit;
        request.offset = this.filterDriverOffset;
      }

      this.showDriverShimmerEffect = true
      this.CallMethod.getAllDriverList(request).pipe(takeUntil(this.unsubscribe$)).subscribe((result: any) => {
        if (result.status) {
          this.allDriverList = this.selectedDriverList == 3 ? result.all : this.selectedDriverList == 1 ? result.online : result.offline
          this.finalDriverList = this.selectedDriverList == 3 ? result.all : this.selectedDriverList == 1 ? result.online : result.offline
          console.log('***** this.allDriverList *****', this.allDriverList);
          resolve({ status: true, 'drivers': result.online });
        } else {
        }
        this.showDriverShimmerEffect = false;
      });
    });
  }



  // **************** end assign driver ***************

  // ! --------------------------------------------------------------------- Export Sheet ------------------------------------------------------------

  public onExportClick(): void {
    console.log('***** onExportClick *****');
    const customFields: ExportFieldInfo[] = [
      { name: 'Tags', text: 'Booking ID' },
      { name: 'Subject', text: 'Pickup Location' },
      { name: 'Location', text: 'Drop off Location' },
      { name: 'StartTime', text: 'Start Time' },
      { name: 'EndTime', text: 'End Time' },
      { name: 'driverName', text: 'driver Name' },
      { name: 'driverPhone', text: 'driver Phone' }
    ];
    const exportValues: ExportOptions = { fieldsInfo: customFields };
    this.scheduleObj.exportToExcel(exportValues);
  }

  closeWindow() {
    window.close();
  }

}


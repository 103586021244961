import { Component, OnInit } from "@angular/core";
import { CallmethodService } from "src/app/services/callmethod.service";
import { DataShareService } from "src/app/services/data-share.service";
import { GlobalService } from "src/app/services/global.service";
import { PopupService } from "src/app/services/popup.service";

@Component({
  selector: "app-chat-bar",
  templateUrl: "./chat-bar.component.html",
  styleUrls: ["./chat-bar.component.scss"],
})
export class ChatBarComponent implements OnInit {
  loadAllRecentChats: boolean = false;
  userAndChatDataArray: any[] = [];
  bookingCreatePopup: boolean = false;
  btnLoader: boolean = true;
  bookingJsonData: any;
  constructor(
    public shareData: DataShareService,
    public CallMethod: CallmethodService,
    public Global: GlobalService,
    public popup: PopupService
  ) { }

  ngOnInit(): void {
    this.shareData.getData().subscribe((result: any) => {
      if (
        result.function == "getAllUserAndChat" &&
        result.type == "userChatView" &&
        result.data != ""
      ) {
        this.userAndChatDataArray = result.data;
      }
      if (
        result.function == "createBookingForNow" &&
        result.type == "chatBubble" &&
        result.data != ""
      ) {
        this.bookingJsonData = result.data;
        this.bookingCreatePopup = true;
      }
    });
    // setTimeout(() => {
    //   this.loadAllRecentChats = false
    // }, 3000);
  }

  locationJson: any = [];
  createBookingCall() {
    this.btnLoader = false;
    // let tempObj = [{ ...this.bookingJsonData.userJson[0], accountId: '', }]
    let tempObj = {
      driverId: "manual",
      tarrif: "Automatic",
      isDevice: 3,
      isDispatcher: 1,
      userChatId: this.bookingJsonData.chatId,
      isLeadBooking: true,
    };
    let data = { ...this.bookingJsonData, ...tempObj };



    this.CallMethod.createBookingCall(data).subscribe((result: any) => {
      console.log(" MQTT Result:", result);
      if (result.status) {
        this.bookingCreatePopup = false;
        this.popup.toasterMessage({
          status: "success",
          title: "Great!",
          message: "Booking Was Created Successfully!",
        });
      }
      else if (result.statusCode == 1) {

        this.popup.toasterMessage({
          status: "error",
          title: "Oops!",
          message: `User current wallet balance is Rs.${result.receipt.currentBalance} Please top up by Rs.${result.receipt.addToWallet} to proceed with this booking.`,
        });
      }
      else if (result.statusCode == 2) {
        this.popup.toasterMessage({
          status: "error",
          title: "Oops!",
          message: "Your account ID is invalid.",
        });
      }
      else if (result.statusCode == 5) {
        this.popup.toasterMessage({
          status: "error",
          title: "Oops!",
          message: "User wallet balance is low.",
        });
      }
      else if (result.statusCode == 7) {
        this.popup.toasterMessage({
          status: "error",
          title: "Oops!",
          message: "Booking Not Created Right Now!",
        });
      }
      else if (result.statusCode == 11 || result.statusCode == 12) {
        this.popup.toasterMessage({
          status: "error",
          title: "Oops!",
          message: "We have encountered error verifying your coupon credentials.",
        });
      }
      else {
        this.popup.toasterMessage({
          status: "error",
          title: "Oops!",
          message: "Booking Not Created!",
        });
      }
      setTimeout(() => {
        this.btnLoader = true;
      }, 1000);

    });
  }

  cancelBookingCall() {
    this.shareData.shareData({
      function: "cancelBooking",
      type: "chatBubble",
    });
    this.bookingCreatePopup = false;
  }
}

import { Component, OnInit } from '@angular/core';
import * as moment from "moment";
import { Observable } from 'rxjs';
import { CallmethodService } from '../services/callmethod.service';
import { DataShareService } from '../services/data-share.service';
import { GlobalService } from '../services/global.service';

@Component({
  selector: 'app-leaderboard',
  templateUrl: './leaderboard.component.html',
  styleUrls: ['./leaderboard.component.scss']
})
export class LeaderboardComponent implements OnInit
{
  unsubscribe$!: Observable<any>;
  isFirstTimeGetDispatchers: boolean = true
  constructor(
    public Global: GlobalService,
    public CallMethod: CallmethodService,
    public shareData: DataShareService

  ) { }
  currentDate = moment();
  startDate: any
  endDate: any
  leaderboardAllData: any[] = []
  dispatcherDetails: any
  ngOnInit(): void
  {
    this.Global.dashboard().then((result: any) =>
    {
      if (result.status)
      {
        if (this.isFirstTimeGetDispatchers)
        {
          // let startDate = this.currentDate.clone().subtract(0, 'week').startOf('week');
          // let endDate = this.currentDate.clone().subtract(0, 'week').endOf('week');
          // this.getTopDispatcherList(startDate, endDate)
          this.convertedTabeWiseDate('lastThreeMonth');
          this.isFirstTimeGetDispatchers = false
        }
      }
    })
  }

  formattedStartDate: any = "";
  formattedEndDate: any = "";
  convertedTabeWiseDate(type: any)
  {
    console.log('***** type *****',type);
    if (type == 'currentWeek')
    {
      this.startDate = this.currentDate.clone().subtract(0, 'week').startOf('week');
      this.endDate = this.currentDate.clone().subtract(0, 'week').endOf('week');
    }
    else if (type == 'currentMonth')
    {
      this.startDate = this.currentDate.clone().subtract(0, 'month').startOf('month');
      this.endDate = this.currentDate.clone().subtract(0, 'month').endOf('month');

    }
    else if (type == 'lastThreeMonth')
    {
      
      this.startDate = this.currentDate.clone().subtract(3, 'months').startOf('months');
      this.endDate = this.currentDate;
      console.log('***** this.startDate *****',this.startDate);
      console.log('***** this.endDate *****',this.endDate);
    }
    // else
    // {
    //   this.startDate = this.currentDate.clone().subtract(1, 'month').startOf('month');
    //   this.endDate = this.currentDate.clone().subtract(1, 'month').endOf('month');
    // }
    this.formattedStartDate = this.startDate.format('YYYY-MM-DD 00:00:00');
    this.formattedEndDate = this.endDate.format('YYYY-MM-DD 23:59:59');
    this.getTopDispatcherList(this.formattedStartDate, this.formattedEndDate);
  }

  // currentDispatcherRank: any
  // currentDispatcherScore: any
  // totalLead: number = 0;
  // totalLeadConverted: number = 0;
  // totalLeadNotConverted: number = 0;
  // disableBtn: boolean = false;
  // getTopDispatcherList(startDate: any, endDate: any)
  // {
  //   console.log('***** startDate *****',startDate);
  //   console.log('***** endDate *****',endDate);
  //   let reqObj = {
  //     limit: 10,
  //     offset: 0,
  //     startDate: startDate,
  //     endDate: endDate
  //   }

  //   this.disableBtn = true
  //   this.CallMethod.getTopDispatcherList(reqObj).subscribe((result: any) =>
  //   {
  //     if (result.status)
  //     {
  //       this.leaderboardAllData = result.data.dispatchers;
  //       this.totalLead = result.data.totalGeneratedLead
  //       this.totalLeadConverted = result.data.totalConvertedLead
  //       this.totalLeadNotConverted = result.data.notConvertedLead
  //       this.leaderboardAllData.forEach((response: any, index: any) =>
  //       {
  //         if (response.dispatcherId == this.Global.dispatcherDetails.dispatcherId)
  //         {
  //           this.currentDispatcherScore = response.convertedLead
  //           if (index == 0)
  //           {
  //             this.currentDispatcherRank = `${ index + 1 }'ST`
  //           }
  //           if (index == 1)
  //           {
  //             this.currentDispatcherRank = `${ index + 1 }'ND`
  //           }
  //           if (index == 2)
  //           {
  //             this.currentDispatcherRank = `${ index + 1 }'RD`
  //           }
  //           if (index >= 3)
  //           {
  //             this.currentDispatcherRank = `${ index + 1 }'TH`
  //           }
  //         }
  //       })
  //     }
  //   });
  //   this.disableBtn = false
  // }

  currentDispatcherRank: string = '';
currentDispatcherScore: any;
totalLead: number = 0;
totalLeadConverted: number = 0;
totalLeadNotConverted: number = 0;
disableBtn: boolean = false;

getTopDispatcherList(startDate: any, endDate: any) {
  console.log('***** startDate *****', startDate);
  console.log('***** endDate *****', endDate);

  const reqObj = {
    limit: 10,
    offset: 0,
    startDate,
    endDate
  };

  this.disableBtn = true;

  this.CallMethod.getTopDispatcherList(reqObj).subscribe((result: any) => {
    if (result.status) {
      console.log("*********** result ***********", JSON.stringify(result));
      const dispatcherData = result.data.dispatchers;
      this.leaderboardAllData = dispatcherData;
      this.totalLead = result.data.totalGeneratedLead;
      this.totalLeadConverted = result.data.totalConvertedLead;
      this.totalLeadNotConverted = result.data.notConvertedLead;

      console.log('***** this.Global.dispatcherDetails.dispatcherId *****',this.Global.dispatcherDetails.dispatcherId);
      const currentDispatcherIndex = dispatcherData.findIndex(
        (response: any) => response.dispatcherId == this.Global.dispatcherDetails.dispatcherId
      );
      console.log('***** currentDispatcherIndex *****',currentDispatcherIndex);

      if (currentDispatcherIndex !== -1) {
        this.currentDispatcherScore = dispatcherData[currentDispatcherIndex].convertedLead;
        console.log('***** currentDispatcherScore *****',this.currentDispatcherScore);

        switch (currentDispatcherIndex) {
          case 0:
            this.currentDispatcherRank = `${currentDispatcherIndex + 1}ST`;
            break;
          case 1:
            this.currentDispatcherRank = `${currentDispatcherIndex + 1}ND`;
            break;
          case 2:
            this.currentDispatcherRank = `${currentDispatcherIndex + 1}RD`;
            break;
          default:
            this.currentDispatcherRank = `${currentDispatcherIndex + 1}TH`;
            break;
        }
        console.log('***** this.currentDispatcherRank *****',this.currentDispatcherRank);
      }
      else
      {
        this.currentDispatcherRank = "---";
        this.currentDispatcherScore = 0
      }
    }
    this.disableBtn = false;
  });
}

  
  refreshLeaderBorard()
  {
    this.getTopDispatcherList(this.formattedStartDate, this.formattedEndDate)
  }

}


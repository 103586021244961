import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalComponent } from 'ng-modal-lib';
import { Subject, Subscription } from 'rxjs';
import { CallmethodService } from '../services/callmethod.service';
import { DataShareService } from '../services/data-share.service';
import { GlobalService } from '../services/global.service';
import { environment } from 'src/environments/environment';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-booking-event',
  templateUrl: './booking-event.component.html',
  styleUrls: ['./booking-event.component.scss']
})
export class BookingEventComponent implements OnInit {

  private subscription: Subscription = new Subscription;
	private unsubscribe$ = new Subject<void>();


  constructor(
    public Global: GlobalService,
    public CallMethod: CallmethodService,
    public shareData: DataShareService
  ) {}

  ngOnInit(): void {
    this.subscription = this.shareData.getData().subscribe(data => {
      if (data) {
        if (data.function == 'openBookingEventDetails') {
          this.bookingEvent(data);
        }
        else if (data.function == 'changeThemeColor') {
          this.bookingEventModal.modalHeader.nativeElement.style.backgroundColor = this.Global.first_color;
          this.bookingEventModal.modalFooter.nativeElement.style.backgroundColor = this.Global.third_color;
          this.bookingEventModal.modalRoot.nativeElement.style.border = '1px solid '+ this.Global.first_color;
        }
      }
    });
  }
  ngOnDestroy(): void {
		this.subscription.unsubscribe();
    this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

  @ViewChild("bookingEventModal") public bookingEventModal!: ModalComponent;
  selectedBookingEvent: any = {};
  showPopup: boolean = false;
  bookingActivityBtn: boolean = false;
  bookingEventList: any[] = [];

  bookingEvent(bookingItem: any) {
    this.selectedBookingEvent = bookingItem.data;

    this.bookingEventModal.modalRoot.nativeElement.style.zIndex = this.bookingEventModal.getMaxModalIndex()+2;
    if (this.showPopup == false) {
      setTimeout(() => {
        this.bookingEventModal.modalRoot.nativeElement.style.top = environment.windowPopupStyle.bookingEvent.top;
      }, 0);

      if (this.bookingEventModal.modalBody.nativeElement.style.height.trim() == '') {
        this.bookingEventModal.modalBody.nativeElement.style.height = environment.windowPopupStyle.bookingEvent.height;
        let width = parseInt(environment.windowPopupStyle.bookingEvent.width.replace('px', ''));
        if(window.innerWidth < parseInt(environment.windowPopupStyle.bookingEvent.width.replace('px', ''))){
          width = window.innerWidth - 2;
        }
        this.bookingEventModal.modalRoot.nativeElement.style.width = width.toString() + 'px'; 
      }

      this.bookingEventModal.modalHeader.nativeElement.style.backgroundColor = this.Global.first_color;
      this.bookingEventModal.modalFooter.nativeElement.style.backgroundColor = this.Global.third_color;
      this.bookingEventModal.modalRoot.nativeElement.style.border = '1px solid '+ this.Global.first_color; 

      this.bookingEventModal.show();
      this.showPopup = true;
    }

    this.getBookingEvent(bookingItem.data.bookingId);
  }

  getBookingEvent(bookingId: any) {
    this.bookingActivityBtn = true;
    this.bookingEventList = [];
    this.CallMethod.bookingEvent({ "bookingId": bookingId }).pipe(takeUntil(this.unsubscribe$)).subscribe((result: any) => {
      this.bookingEventList = [];
      if (result.status) {
        if (result.data.length > 0) {
          for (let j = 0; j < result.data.length; j++) {
            let bookingActivityData = {
              displayDate: result.data[j]['displayDate'],
              info: result.data[j]['info'],
              purpose: result.data[j]['purpose'],
              _id: result.data[j]['_id']
            }
            this.bookingEventList.push(bookingActivityData);
          }
        }
      }
      this.bookingActivityBtn = false;
    })
  }

  closeBookingEventModel() {
    this.showPopup = false;
  }

}

import { AfterViewInit, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DragAndDropService, ResizeService, ScheduleComponent, TimelineViewsService } from '@syncfusion/ej2-angular-schedule';
import { extend } from '@syncfusion/ej2-base';
import { EventSettingsModel, ExportFieldInfo, ExportOptions, GroupModel, RenderCellEventArgs, TimeScaleModel, View, WorkHoursModel } from '@syncfusion/ej2-schedule';
import * as moment from 'moment';
import { CallmethodService } from '../services/callmethod.service';
 
import { GlobalService } from '../services/global.service';

@Component({
    selector: 'app-driver-shift-management',
    templateUrl: './driver-shift-management.component.html',
    styleUrls: ['./driver-shift-management.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [TimelineViewsService, ResizeService, DragAndDropService],
})
export class DriverShiftManagementComponent implements OnInit {

    eventSettings!: EventSettingsModel;
    public rowAutoHeight: boolean = true;
    public selectedDate: Date = new Date();
    public timeScale: TimeScaleModel = { enable: true, interval: 30, slotCount: 4 };
    public workHours: WorkHoursModel = { start: '08:00', end: '18:00' };
    public currentView: View = 'TimelineDay';
    

    public group: GroupModel = {
        enableCompactView: false,
        resources: ['MeetingRoom'],
    };
    resourceDataSource: any = [];

    

    public allowMultiple = true;
    @ViewChild('scheduleObj') public scheduleObj!: ScheduleComponent;
    // date  control
    today: Date = new Date() ;
    

    currentYear: number = this.today.getFullYear();
    currentMonth: number = this.today.getMonth();
    currentDay: number = this.today.getDate();
    minDate: Object = new Date(this.currentYear, this.currentMonth - 1);
    maxDate: Object = new Date(this.currentYear, this.currentMonth, this.currentDay);
    pageIndex: number = 0;
    pageSize: number = 50;


    ngOnInit(): void {
        // this.driverShiftListView();
        // this.resourceDataSource = this.allDriverDetails;

        this.driverShiftListView();
        this.resourceDataSource = this.allDriverDetails;
        this.filterStartDate = localStorage.getItem('shiftStartTime');
        this.filterEndDate = localStorage.getItem('shiftEndTime')

        console.log('this.filterEndDate', this.filterEndDate);
        console.log('***** filterStartDate *****', this.filterStartDate);
        this.selectedShift = JSON.parse(localStorage.getItem('selectedShift')!)
        console.log("selectedShift",this.selectedShift);
        


        if ((this.filterStartDate != "" && this.filterStartDate != undefined) && (this.filterEndDate != "" && this.filterEndDate != undefined)) {

            this.filterStartDate = localStorage.getItem('shiftStartTime');
            this.filterEndDate = localStorage.getItem('shiftEndTime')

            this.selectedDate = moment(this.filterStartDate).toDate()

            console.log("currentDate",this.selectedDate);
            
           
            console.log('this.filterEndDate in if', this.filterEndDate);
            console.log('***** filterStartDate *****', this.filterStartDate);
        
        } else {
            console.log('this.filterEndDate in else', this.filterEndDate);
            localStorage.setItem("shiftStartTime", moment().format('YYYY-MM-DD'))
            localStorage.setItem("shiftEndTime", moment().format('YYYY-MM-DD'))
           
        }

        setInterval(() => {
            this.reloadCurrentPage();
        }, 600000);
    }
    constructor(
        public callMethod: CallmethodService,
        public Global: GlobalService,
        // public pageSettings: Object,


    ) {

    }

    shiftData: any;
    allDriverDetails: any = [];
    timeLine: any = [];
    driverShift: any = [];
    driverShiftViewList: any = [];
    driverShiftTime: any;
    filterStartDate: any;
    filterEndDate: any;
    shiftBookings: any;
    disableBtn: boolean = false;
    isChecked: boolean = false;

    selectedShift: any = []
    onActionComplete(args: any): void {
        if (args.requestType === "viewNavigate" || args.requestType === "dateNavigate") {
            let currentShiftViewDates = this.scheduleObj.getCurrentViewDates();
            console.log('***** currentViewDates *****', currentShiftViewDates)

            window.localStorage.view = this.scheduleObj.currentView;

            console.log('*****  window.localStorage.view *****', window.localStorage.view);
            let shiftStartDate: any = currentShiftViewDates[0];
            let shiftEndDate: any = currentShiftViewDates[currentShiftViewDates.length - 1];

            console.log('*****  startDate*****', shiftStartDate);
            console.log('*****  endDate *****', shiftEndDate);


            localStorage.setItem("shiftStartTime", moment(shiftStartDate).format('YYYY-MM-DD'))
            localStorage.setItem("shiftEndTime", moment(shiftEndDate).format('YYYY-MM-DD'))

            let req = {
                shiftId: this.selectedShiftId,
                from: moment(shiftStartDate).format('YYYY-MM-DD'),
                to: moment(shiftEndDate).format('YYYY-MM-DD'),
                limit: this.pageSize,
                offset: (this.pageIndex == 0 ? 0 : this.pageSize * this.pageIndex),
            }
            this.driverShiftView(req);
        }
    }

    onCreated(): void {
        console.log('***** onCreated *****')
        let startDate
        let endDate
        let getCurrentView = this.scheduleObj.currentView
        if (getCurrentView) {
            let currentViewDates: any = this.scheduleObj.getCurrentViewDates();
            startDate = currentViewDates[0];
            endDate = currentViewDates[currentViewDates.length - 1];
            console.log(startDate);
            console.log(endDate);
        }
        console.log('***** getCurruntView *****', getCurrentView);
        console.log('***** this.filterStartDate *****', this.filterStartDate);
        console.log('***** this.filterEndDate *****', this.filterEndDate);

        this.filterStartDate = localStorage.getItem('shiftStartTime');
        this.filterEndDate = localStorage.getItem('shiftEndTime')
        localStorage.getItem('myFilter')

        if (!this.filterStartDate && !this.filterEndDate) {
            this.selectedDate = moment(this.filterStartDate).toDate()

            console.log('***** if local *****', this.filterStartDate);
            console.log('***** if local *****', this.filterEndDate);
            startDate = moment().startOf('week');
            endDate = moment().endOf('week');
            console.log('***** 000000000000000000000000 *****');
            console.log({
                from_date: startDate.toString(),
                today: moment().toString(),
                to_date: endDate.toString(),
            });

        }
        else {
            let currentViewDates: any = this.scheduleObj.getCurrentViewDates();
            console.log('***** currentViewDates *****', currentViewDates);

            let req = {
                shiftId: this.selectedShiftId,
                from: this.filterStartDate,
                to: this.filterEndDate,
                limit: this.pageSize,
                offset: (this.pageIndex == 0 ? 0 : this.pageSize * this.pageIndex),
            }
            this.driverShiftView(req);
        }

    }

    onRenderCell(args: any): void {
        const target: HTMLElement = args.element.querySelector(
            '.e-resource-text'
        ) as HTMLElement;
        target.innerHTML =
            '<div class="name">Driver Name</div><div class="type">Current Status</div>';
    }

    // driver shift view detail

    totalDriverCount: any = ""
    driverShiftView(req: any) {

        this.disableBtn = true
        this.callMethod.driverShiftView(req).subscribe((res: any) => {
            this.timeLine = [];
            this.allDriverDetails = [];
            this.resourceDataSource = [];
            this.totalDriverCount = res.totalCount;

            for (let i = 0; i < res.data.driver.length; i++) {
                let fields = {
                    Id: res.data.driver[i].driverId,
                    text: res.data.driver[i].driverName,
                    driverStatus: res.data.driver[i].driverStatus,
                    color: res.data.driver[i].driverStatus == 0 ? '#01be7f' : '#01be7f',
                }
                this.allDriverDetails.push(fields);
                this.resourceDataSource = this.allDriverDetails
            }
            console.log(":::::::::::::resourceDataSource:::::::::::", this.allDriverDetails);

            for (let j = 0; j < res.data.timeLine.length; j++) {
                var then = res.data.timeLine[j].onTime;
                var now = moment().format("YYYY-MM-DD HH:mm:ss");
           
                const diffDuration = moment.utc(moment(now, "YYYY-MM-DD HH:mm:ss").diff(moment(then, "YYYY-MM-DD HH:mm:ss"))).format("HH:mm")
                const minit = moment.duration(diffDuration).asMinutes()
                let driverHours = ''
                if(res.data.timeLine[j].offTime != null)
                {
                    let numberOfDays = Math.floor(res.data.timeLine[j].minutes / 1440);
                    let numberOfHours = Math.floor((res.data.timeLine[j].minutes % 1440) / 60);
                    let numberOfMinutes = Math.floor((res.data.timeLine[j].minutes % 1440) % 60)
                    if(res.data.timeLine[j].minutes > 0){
                     driverHours = ` 
                        ${numberOfDays == 0 || numberOfDays < 0 ? '' : numberOfDays + ' day' + (numberOfDays > 0 ? 's' : '')}
                        ${(numberOfHours == 0 || numberOfHours < 0 ? '' : numberOfHours) + (numberOfHours > 0 ? 'h' : '')} 
                        ${(Math.abs(numberOfMinutes))} min
                       `
                    }else{
                        driverHours = "0 min."   
                    }

                }
                else
                {
                    let numberOfDays = Math.floor(minit / 1440);
                    let numberOfHours = Math.floor((minit % 1440) / 60);
                    let numberOfMinutes = Math.floor((minit % 1440) % 60)
                    if(minit > 0){
                       driverHours = ` 
                        ${numberOfDays == 0 || numberOfDays < 0 ? '' : numberOfDays + ' day' + (numberOfDays > 0 ? 's' : '')}
                        ${(numberOfHours == 0 || numberOfHours < 0 ? '' : numberOfHours) + (numberOfHours > 0 ? 'h' : '')} 
                        ${(Math.abs(numberOfMinutes))} min
                       `
                    }
                    else{
                        driverHours = "0 min."   
                    }
                }

                let fields = {
                    RoomId: res.data.timeLine[j].driverId,
                    Subject:  driverHours,
                    // Location: res.data.timeLine[j].bookings,
                    StartTime: moment(res.data.timeLine[j].onTime).toDate(),
                    EndTime: res.data.timeLine[j].offTime != null ? moment(res.data.timeLine[j].offTime).toDate() : moment(new Date()).toDate(),
                }
                console.log("fields:::::",fields);
                
                this.timeLine.push(fields);
            }

            this.disableBtn = false

            console.log("::::::::::::timeline::::::::::::", this.timeLine);
            this.scheduleObj.refreshEvents(true)
            this.eventSettings = { dataSource: extend([], this.timeLine, null!, true) as Record<string, any>[], enableTooltip: false, enableIndicator: true };
            console.log("this.eventSettings", this.eventSettings);

        });
    }

    driverShiftListView() {

        this.callMethod.driverShiftList({}).subscribe((res: any) => {
            this.driverShiftViewList = res.data
            console.log("::::driverShiftViewList::::", this.driverShiftViewList);
        })
    }

    allShiftSelect: boolean = true;
    selectedShiftId: any
    shiftChange(event: any) {
        console.log("event",event);
        
        this.selectedShift = event.value


        localStorage.setItem("selectedShift",JSON.stringify(this.selectedShift))


        this.filterStartDate = localStorage.getItem('shiftStartTime');
        this.filterEndDate = localStorage.getItem('shiftEndTime')

        this.allShiftSelect = false;
        console.log("++++++++++event+++++++++++", event);
        this.selectedShiftId = event.value.shiftId;

        let req = {

            shiftId: event.value.shiftId,
            from: this.filterStartDate,
            to: this.filterEndDate,
            limit: this.pageSize,
            offset: (this.pageIndex == 0 ? 0 : this.pageSize * this.pageIndex),

        }
        this.driverShiftView(req)
    }

    allShift() {

        this.selectedShift = []
        this.filterStartDate = localStorage.getItem('shiftStartTime');
        this.filterEndDate = localStorage.getItem('shiftEndTime')


        this.allShiftSelect = true;
        let req = {
            shiftId: "",
            from: this.filterStartDate,
            to: this.filterEndDate,
            limit: this.pageSize,
            offset: (this.pageIndex == 0 ? 0 : this.pageSize * this.pageIndex),
        }
        this.driverShiftView(req)

    }

    reloadCurrentPage(): void {
        this.filterStartDate = localStorage.getItem('shiftStartTime');
        this.filterEndDate = localStorage.getItem('shiftEndTime')

        if (!this.filterStartDate || !this.filterEndDate) {
            let req = {
                shiftId: this.allShiftSelect != true ? this.selectedShiftId : '',
                from: moment().format('YYYY-MM-DD'),
                to: moment().format('YYYY-MM-DD'),
                limit: this.pageSize,
                offset: (this.pageIndex == 0 ? 0 : this.pageSize * this.pageIndex),
            }
            this.driverShiftView(req)
        }
        else {
            let req = {
                shiftId: this.allShiftSelect != true ? this.selectedShiftId : '',
                from: this.filterStartDate,
                to: this.filterEndDate,
                limit: this.pageSize,
                offset: (this.pageIndex == 0 ? 0 : this.pageSize * this.pageIndex),
            }
            this.driverShiftView(req)
        }
    }

    closeWindow() {
        window.close();
    }

    nextPage(event: any) {
        this.filterStartDate = localStorage.getItem('shiftStartTime');
        this.filterEndDate = localStorage.getItem('shiftEndTime')
        console.log(":::;event:", event);
        let req = {
            shiftId: !this.allShiftSelect ? this.selectedShiftId.shiftId : "",
            from: this.filterStartDate,
            to: this.filterEndDate,
            limit: 10,
            offset: (this.pageSize * event.page),
        }
        this.driverShiftView(req)
        console.log("next click", req);
    }

}

<div>
  <p-toolbar class="p-toolbar_custom_global">
    <div class="p-toolbar-group-start">
      <div class="justify-between flex">
        <!--  DROPDOWN DRIVER NAME -->
        <div class="custom_hide_show_filter_dropdown" style="width: 100%">
          <p-dropdown [options]="driverShiftViewList" filterBy="name" placeholder="Select Shift"
            (onChange)="shiftChange($event)" [(ngModel)]="selectedShift">
            <ng-template pTemplate="selectedItem">
              <div class="country-item country-item-value" style="display: flex; gap: 10px;">
                <div class="fontMediumBold">{{ selectedShift.shiftName }}</div>
                <div class="fontMediumBold">{{ selectedShift.startTime }}</div>
                <div class="fontMediumBold">{{ selectedShift.endTime }}</div>
              </div>
            </ng-template>
            <ng-template let-selectedPlanOpt pTemplate="item">
              <div class="country-item">
                <div class="fontMediumBold">{{ selectedPlanOpt.shiftName }}</div>
                <div class="fontMediumBold">Start Time : {{ selectedPlanOpt.startTime }}</div>
                <div class="fontMediumBold">End Time : {{ selectedPlanOpt.endTime }}</div>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
        <button class="allShift fontMediumBold" (click)="allShift()"  [disabled]="disableBtn">
          All Drivers
        </button>
      </div>
    </div>

    <div class="p-toolbar-group-end" style="display: flex; align-items: center; gap: 16px">
      <div>
        <p-paginator [rows]="pageSize" [showCurrentPageReport]="true" [totalRecords]="totalDriverCount" (onPageChange)="nextPage($event)"></p-paginator>
      </div>
      <div class="fontMediumBold">
        Total Records :  {{totalDriverCount}}
      </div>
      <div class="custom_refresh_btn">
        <button class="cssbuttons-io fontMediumBold" [disabled]="disableBtn" ejs-button cssClass="e-info"
          (click)="reloadCurrentPage()">
          <span class="fontMediumBold">
            <i class="bx bxs-analyse bx-spin mr-1"></i>
            Refresh</span>
        </button>
      </div>
      <!-- <div class="custom_refresh_btn">
        <button class="cssbuttons-io" [disabled]="disableBtn" (click)="onExportClick()">
          <span>
            <i class='bx bx-file  mr-1'></i>
            Export</span>
        </button>
      </div> -->
     
      <div>
        <p-button icon="pi pi-times" style="font-size: 1rem" (click)="closeWindow()"
          styleClass="p-button-danger"></p-button>
      </div>
    </div>
  </p-toolbar>
  <div>
    <div class="control-section">
      <div class="col-lg-12 content-wrapper">
        <ejs-schedule #scheduleObj width="100%" cssClass="timeline-resource" height="90vh" [timeScale]="timeScale"
          [currentView]="currentView" [workHours]="workHours" [group]="group" (created)="onCreated()"
          [enablePersistence]="false" [eventSettings]="eventSettings" (actionComplete)="onActionComplete($event)"
          (renderCell)="onRenderCell($event)" [selectedDate]="selectedDate" [rowAutoHeight]="rowAutoHeight"
          readonly="true" [minDate]="minDate" [maxDate]="maxDate">
          <e-resources>
            <e-resource field="RoomId" [dataSource]="resourceDataSource" [allowMultiple]="allowMultiple"
              name="MeetingRoom" textField="text" idField="Id" colorField="color"></e-resource>
          </e-resources>
          <e-views>
            <e-view option="TimelineDay"></e-view>
            <e-view option="TimelineWeek"></e-view>
          </e-views>
          <ng-template #resourceHeaderTemplate let-data>
            <div class="template-wrap">
              <div class="name">{{ data.resourceData.text }}</div>
              <div class="type" style="color:red" *ngIf=" data.resourceData.driverStatus == 0">
                Offline
               </div>
               <div class="type" style="color:green" *ngIf=" data.resourceData.driverStatus == 1">
                 Online
               </div>
            </div>
          </ng-template>
        </ejs-schedule>
      </div>
    </div>
    
         <!-- Header template -->
         <ng-template #quickInfoTemplatesHeader let-data>
          <div class="quick-info-header">
            <div class="quick-info-header-content">
              <div class="quick-info-title fontMediumBold">
                {{ data.Subject }}
              </div>
              <div class="duration-text fontMedium">
                {{ data }}
              </div>
              <div class="duration-text fontMedium">
                {{
                "Assign Time :"
                }}
              </div>
            </div>
          </div>
        </ng-template>

  </div>
</div>
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { ModalComponent } from 'ng-modal-lib';
import { of, Subject, Subscription } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, map, mergeMap, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CallmethodService } from '../services/callmethod.service';
import { DataShareService } from '../services/data-share.service';
import { GlobalService } from '../services/global.service';
import { PopupService } from '../services/popup.service';

@Component({
  selector: 'app-sticky-note',
  templateUrl: './sticky-note.component.html',
  styleUrls: ['./sticky-note.component.scss']
})
export class StickyNoteComponent implements OnInit {

  private subscription: Subscription = new Subscription;
	private unsubscribe$ = new Subject<void>();

  @ViewChild("addStickyAreaContains") public addStickyAreaContains!: ElementRef;
  @ViewChild("editStickyAreaContains") public editStickyAreaContains!: ElementRef;

  constructor(
    public CallMethod: CallmethodService,
    public shareData: DataShareService,
    public Global: GlobalService,
    public Popup: PopupService,
  ) {
    this.subscription = this.stickySave.pipe(
      map((event: any) => event),
      debounceTime(500),
      distinctUntilChanged(),
      mergeMap((search: any) => of(search).pipe(delay(0))),
    ).subscribe((data: any) => {
      this.editStickyRequest(data)
    });
  }

  ngOnInit(): void {
    this.subscription = this.shareData.getData().subscribe(data => {
      if (data) {
        if (data.function == 'stickyNotes') {
          this.stickyNoteArray = [];
          this.othersStickyNoteArray = [];
          this.stickyNoteModalShow();
        }
        else if (data.function == 'mqttShareToSticky') {

          this.eventHandler(data.mqttData)
        }
        else if (data.function == 'renderData' && this.showStickyNotesModel == true) {
          if (this.isOpenEditableSticky == false && this.addStickyAreaContains.nativeElement.contains(this.Global.renderData.target) == false) {
            this.createNewSticky();
          }
          else if (this.isOpenEditableSticky == true && this.editStickyAreaContains.nativeElement.contains(this.Global.renderData.target) == false) {
            this.isOpenEditableSticky = false;
          }
        }
        else if (data.function == 'changeThemeColor') {
          this.stickyNotesModal.modalHeader.nativeElement.style.backgroundColor = this.Global.first_color;
          this.stickyNotesModal.modalFooter.nativeElement.style.backgroundColor = this.Global.third_color;
          this.stickyNotesModal.modalRoot.nativeElement.style.border = '1px solid '+ this.Global.first_color; 
        }
      }
    });
  }

  ngOnDestroy(): void {
		this.subscription.unsubscribe();
    this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

  eventHandler(opts: any) {
    if (opts.statusFlag) {
      if (opts.statusFlag == 60) {
        if (this.showStickyNotesModel == false) {

          let count = this.Global.getLocalStorage({ 'key': 'countStickyAdd' });
          count = count == undefined ? 1 : count + 1;
          this.Global.setLocalStorage({ 'key': 'countStickyAdd', 'value': count });

          this.shareData.shareData({ 'function': 'stickyBadgeCount' });

        } else {
          if (this.Global.dispatcherDetails.dispatcherId != opts.dispatcherId) {
            let objIndex = this.othersStickyNoteArray.findIndex(((obj: any) => obj.stickyId == opts.stickyId));

            if (objIndex == -1) {
              let sticky = {
                'editTime': moment(opts.editTime, 'YYYY-MM-DD HH:mm:ss').format(this.Global.dateFormat),
                'dispatcherId': opts.dispatcherId,
                'dispatcherName': opts.dispatcherName,
                'stickyTitle': opts.stickyTitle,
                'stickyDescription': opts.stickyDescription,
                'pinned': opts.pinned,
                'stickyId': opts.stickyId,
                'stickyColor': opts.stickyColor
              }
              this.othersStickyNoteArray.push(sticky);
              this.Global.sortArrayOfObject(this.othersStickyNoteArray, 'editTime', 'desc');
            }
          }
        }
      }
      else if (opts.statusFlag == 61) {
        if (this.showStickyNotesModel == false) {
          //for update when popupclose]
          this.Global.setLocalStorage({ 'key': 'stickyUpdated', 'value': true });
          this.shareData.shareData({ 'function': 'stickyUpdated' });

        } else {
          if (this.Global.dispatcherDetails.dispatcherId != opts.dispatcherId) {
            let pinnedArrIndex = this.stickyNoteArray.findIndex(((obj: any) => obj.stickyId == opts.stickyId));
            let unpinnedArrIndex = this.othersStickyNoteArray.findIndex(((obj: any) => obj.stickyId == opts.stickyId));
            let sticky = {
              'editTime': moment(opts.editTime, 'YYYY-MM-DD HH:mm:ss').format(this.Global.dateFormat),
              'dispatcherId': opts.dispatcherId,
              'dispatcherName': opts.dispatcherName,
              'stickyTitle': opts.stickyTitle,
              'stickyDescription': opts.stickyDescription,
              'pinned': opts.pinned,
              'stickyId': opts.stickyId,
              'stickyColor': opts.stickyColor
            };

            if (opts.pinned == 1) {
              if (unpinnedArrIndex != -1) {
                //other's arr sticky clicked;
                //remove from other && add it to pinnedArr
                // this.othersStickyNoteArray[unpinnedArrIndex].pinned = 1;
                this.stickyNoteArray.unshift(sticky);
                this.othersStickyNoteArray.splice(unpinnedArrIndex, 1);
                this.openStickyObj == undefined ? this.openStickyObj = {} : this.openStickyObj;


                // this.openStickyObj.openStickyPinned = 1;
              } else {
                this.stickyNoteArray[pinnedArrIndex] = sticky
              }
              if (this.isOpenEditableSticky == true && this.openStickyObj.openStickyId == opts.stickyId) {
                this.openStickyObj = {
                  openStickyTitle: sticky.stickyTitle,
                  openStickyDescription: sticky.stickyDescription,
                  openStickyId: sticky.stickyId,
                  openStickyColor: sticky.stickyColor,
                  openStickyPinned: sticky.pinned,
                  openStickyEditTime: sticky.editTime,
                }
                setTimeout(() => {
                  let contenteditable = <HTMLElement>document.getElementById('desc' + sticky.stickyId);
                  let selection = document.getSelection();
                  let range = document.createRange();
                  range.setStart(contenteditable, contenteditable.childNodes.length);
                  selection?.removeAllRanges();
                  selection?.addRange(range);
                  contenteditable.focus()
                }, 1);
              }
              this.Global.sortArrayOfObject(this.stickyNoteArray, 'editTime', 'desc');

            } else {

              if (pinnedArrIndex != -1) {
                //pinned arr 
                this.othersStickyNoteArray.unshift(sticky);
                this.stickyNoteArray.splice(pinnedArrIndex, 1)
                this.openStickyObj == undefined ? this.openStickyObj = {} : this.openStickyObj;
              } else {
                this.othersStickyNoteArray[unpinnedArrIndex] = sticky
              }
              if (this.isOpenEditableSticky == true && this.openStickyObj.openStickyId == opts.stickyId) {
                this.openStickyObj = {
                  openStickyTitle: sticky.stickyTitle,
                  openStickyDescription: sticky.stickyDescription,
                  openStickyId: sticky.stickyId,
                  openStickyColor: sticky.stickyColor,
                  openStickyPinned: sticky.pinned,
                  openStickyEditTime: sticky.editTime,

                }
                setTimeout(() => {
                  let contenteditable = <HTMLElement>document.getElementById('desc' + sticky.stickyId);
                  let selection = document.getSelection();
                  let range = document.createRange();
                  range.setStart(contenteditable, contenteditable.childNodes.length);
                  selection?.removeAllRanges();
                  selection?.addRange(range);
                  contenteditable.focus()
                }, 1);
              }
              this.Global.sortArrayOfObject(this.othersStickyNoteArray, 'editTime', 'desc');
            }
          }
        }
      }
      else if (opts.statusFlag == 62) {
        if (this.showStickyNotesModel == false) {
          this.Global.setLocalStorage({ 'key': 'stickyDeleted', 'value': true });
          this.shareData.shareData({ 'function': 'stickyDeleted' });
        }
        else {
          if (this.showStickyNotesModel && this.Global.dispatcherDetails.dispatcherId != opts.dispatcherId) {

            if (opts.pinned == 1) {
              let objIndex = this.stickyNoteArray.findIndex(((obj: any) => obj.stickyId == opts.stickyId));
              if (objIndex != -1) {
                this.stickyNoteArray.splice(objIndex, 1)
              }
            }
            else {
              let objIndex = this.othersStickyNoteArray.findIndex(((obj: any) => obj.stickyId == opts.stickyId));
              if (objIndex != -1) {
                this.othersStickyNoteArray.splice(objIndex, 1)
              }
            }
            if (this.isOpenEditableSticky == true) {
              this.isOpenEditableSticky = false;
            }

          }
        }
      }
    }
  }

  @ViewChild("stickyNotes") public stickyNotesModal!: ModalComponent;
  showStickyNotesModel: boolean = false;
  stickyNoteModalShow(searchValue: string = '') {
    this.stickyNotesModal.modalRoot.nativeElement.style.zIndex = this.stickyNotesModal.getMaxModalIndex() + 2;
    if (this.showStickyNotesModel == false) {
      setTimeout(() => {
        this.stickyNotesModal.modalRoot.nativeElement.style.top = environment.windowPopupStyle.sticky.top;
      }, 0);

      if (this.stickyNotesModal.modalBody.nativeElement.style.height.trim() == '') {
        this.stickyNotesModal.modalBody.nativeElement.style.height = environment.windowPopupStyle.sticky.height;
        let width = parseInt(environment.windowPopupStyle.sticky.width.replace('px', ''));
        if (window.innerWidth < parseInt(environment.windowPopupStyle.sticky.width.replace('px', ''))) {
          width = window.innerWidth - 2;
        }
        this.stickyNotesModal.modalRoot.nativeElement.style.width = width.toString() + 'px';
      }

      this.stickyNotesModal.modalHeader.nativeElement.style.backgroundColor = this.Global.first_color;
      this.stickyNotesModal.modalFooter.nativeElement.style.backgroundColor = this.Global.third_color;
      this.stickyNotesModal.modalRoot.nativeElement.style.border = '1px solid '+ this.Global.first_color;

      this.stickyNotesModal.show();
      this.showStickyNotesModel = true;
    }

    this.getStickyNotes(true)
  }
  closeStickyNoteModel() {
    this.showStickyNotesModel = false;
  }

  stickyColorArr = ['#ffffff','#d0c4e7','#c2dae1','#ffd0d0','#ffe1a7','#fff7ad','#f3a6cf','#bffb92','#9fe0d2','#f0c3d3','#cdb2d6','#c6f2c9'];
  stickyNoteArray: any = [];
  othersStickyNoteArray: any = [];
  getStickyNotes(isAnimation: boolean = false) {
    let requestObj = {
      companyId: this.Global.companyDetails.companyId,
    }
    this.CallMethod.getSticky(requestObj).pipe(takeUntil(this.unsubscribe$)).subscribe((result: any) => {
      if (result.status) {
        let stickyData = result.data;
        this.stickyNoteArray = [];
        this.othersStickyNoteArray = [];
        for (let i = 0; i < stickyData.length; i++) {
          let sticky: any = {
            'editTime': moment(stickyData[i].editTime, 'YYYY-MM-DD HH:mm:ss').format(this.Global.dateFormat),
            'dispatcherId': stickyData[i].dispatcherId,
            'dispatcherName': stickyData[i].dispatcherName,
            'stickyTitle': stickyData[i].stickyTitle,
            'stickyDescription': stickyData[i].stickyDescription,
            'pinned': stickyData[i].pinned,
            'stickyId': stickyData[i]._id,
            'stickyColor': stickyData[i].stickyColor,
          }

          if (isAnimation == true) {
            sticky.isAddSticky = 1;
          }

          if (sticky.pinned == 1) {
            this.stickyNoteArray.push(sticky)
          } else {
            this.othersStickyNoteArray.push(sticky)
          }
        }

        this.Global.sortArrayOfObject(this.stickyNoteArray, 'editTime', 'desc');
        this.Global.sortArrayOfObject(this.othersStickyNoteArray, 'editTime', 'desc');
        this.Global.setLocalStorage({ 'key': 'countStickyAdd', 'value': 0 });
        this.Global.setLocalStorage({ 'key': 'stickyUpdated', 'value': false });
      }
    });
  }

  isCreateSrikcy: boolean = false;
  createStickyAreaOpenClose(e: any, focusType: String) {
    if (focusType == "focusIn") {
      this.isCreateSrikcy = true;
    }
    else {
      this.isCreateSrikcy = false;
    }
  }
  createNewSticky() {
    let title = (<HTMLInputElement>document.getElementById("title")).value;
    let description = (<HTMLInputElement>document.getElementById("description")).value;

    if (title.trim() != "" || description.trim() != "") {
      let requestObj = {
        "stickyTitle": title,
        "stickyDescription": description,
        "dispatcherId": this.Global.dispatcherDetails.dispatcherId,
        "dispatcherName": this.Global.dispatcherDetails.dispatcherName,
        "stickyColor": '#FFFFFF',
        "pinned": 0
      };

      this.saveStickyCall(requestObj).then((res: any) => {
        if (res.status) {
          let updatedsticky = {
            'editTime': moment(res.data.editTime, 'YYYY-MM-DD HH:mm:ss').format(this.Global.dateFormat),
            'dispatcherId': res.data.dispatcherId,
            'dispatcherName': res.data.dispatcherName,
            'stickyTitle': res.data.stickyTitle,
            'stickyDescription': res.data.stickyDescription,
            'pinned': res.data.pinned,
            'stickyId': res.data._id,
            'stickyColor': res.data.stickyColor,
            'isAddSticky': 1
          }

          if (updatedsticky.pinned == 1) {
            this.stickyNoteArray.push(updatedsticky);
            this.Global.sortArrayOfObject(this.stickyNoteArray, 'editTime', 'desc');
          }
          else {
            this.othersStickyNoteArray.push(updatedsticky)
            this.Global.sortArrayOfObject(this.othersStickyNoteArray, 'editTime', 'desc');
          }
        } else {
          this.Popup.toasterMessage({
            'status': 'error',
            'title': 'Oops..',
            'message': 'sticky did not created!!!'
          });
        }
      });
    }

    (<HTMLInputElement>document.getElementById("title")).value = "";
    (<HTMLInputElement>document.getElementById("description")).value = "";
    this.isCreateSrikcy = false;
  }
  saveStickyCall(reqData: any) {
    return new Promise((resolve, reject) => {
      this.CallMethod.saveSticky(reqData).pipe(takeUntil(this.unsubscribe$)).subscribe((result: any) => {
        if (result.status) {
          return resolve({ 'status': true, 'data': result.data });
        } else {
          return reject({ 'status': false });
        }
      });
    })
  }
  stickydelete(stickyId: string, isPinned: number) {
    this.isStickyOpen = false;

    let trashIcon = <HTMLElement>document.getElementById('trash_' + stickyId);
    let trashLoader = <HTMLElement>document.getElementById('trashloader_' + stickyId);

    if (this.isOpenEditableSticky == true) {
      trashIcon = <HTMLElement>document.getElementById('opentrash_' + stickyId);
      trashLoader = <HTMLElement>document.getElementById('opentrashloader_' + stickyId);
    }
    trashIcon.classList.remove('block');
    trashIcon.classList.add('hidden');

    trashLoader.classList.remove('hidden');
    trashLoader.classList.add('block');

    let requestObj = {
      'stickyId': stickyId,
      'pinned': isPinned
    }

    this.CallMethod.deleteSticky(requestObj).pipe(takeUntil(this.unsubscribe$)).subscribe((result: any) => {
      if (result.status == true) {
        if (isPinned == 1) {
          let objIndex = this.stickyNoteArray.findIndex(((obj: any) => obj.stickyId == stickyId));
          this.stickyNoteArray.splice(objIndex, 1)
        } else {
          let objIndex = this.othersStickyNoteArray.findIndex(((obj: any) => obj.stickyId == stickyId));
          this.othersStickyNoteArray.splice(objIndex, 1)
        }

        if (this.isOpenEditableSticky == true) {
          this.isOpenEditableSticky = false;
        }
      }

      trashIcon.classList.remove('hidden');
      trashIcon.classList.add('block');

      trashLoader.classList.remove('block');
      trashLoader.classList.add('hidden');
    });

  }

  @ViewChild("zoomedStickyNote") public openSticky!: ElementRef;
  public stickySave = new Subject<KeyboardEvent>();
  saveStickyPipe(event: any) {

    if (event.target.getAttribute('stickyTitleOrDesc') == 'description') {
      let selectedstickyId = event.target.getAttribute('stickyId');

      let typeValue = (<HTMLDivElement>document.getElementById('desc' + selectedstickyId))?.textContent?.trim();

      setTimeout(() => {
        if ((<HTMLDivElement>document.getElementById('desc' + selectedstickyId))?.textContent?.trim() != typeValue) {
          this.stickySave.next(event);
        }
      }, 500);
    } else {
      let typeValue = event.target.value.trim();

      setTimeout(() => {
        if (event.target.value.trim() != typeValue) {
          this.stickySave.next(event);
        }
      }, 500);
    }
  }

  editStickyRequest(event: any) {

    let selectedstickyId = event.target.getAttribute('stickyId');

    let isStickyPinned: number = Number(event.target.getAttribute('isPinned'));

    let objIndex = this.othersStickyNoteArray.findIndex(((obj: any) => obj.stickyId == selectedstickyId));

    if (isStickyPinned) {
      objIndex = this.stickyNoteArray.findIndex(((obj: any) => obj.stickyId == selectedstickyId));
    }


    let sticky = isStickyPinned == 1 ? this.stickyNoteArray[objIndex] : this.othersStickyNoteArray[objIndex];
    let titleOrDesc = event.target.getAttribute('stickyTitleOrDesc');

    let requestObj = {
      "stickyTitle": titleOrDesc == 'title' ? event.target.value : sticky.stickyTitle,
      "stickyDescription": titleOrDesc == 'description' ? (<HTMLDivElement>document.getElementById('desc' + selectedstickyId))?.textContent : sticky.stickyDescription,
      "dispatcherId": this.Global.dispatcherDetails.dispatcherId,
      "dispatcherName": this.Global.dispatcherDetails.dispatcherName,
      "pinned": sticky.pinned,
      "stickyId": selectedstickyId,
      'stickyColor': sticky.stickyColor != undefined ? sticky.stickyColor : '#FFFFF'
    };

    if (requestObj.stickyTitle == "" && requestObj.stickyDescription == "") {
      //if sticky doesn't have title or desc then delete 
      this.stickydelete(selectedstickyId, sticky.pinned)
    } else {
      this.editStickyCall(requestObj)
    }

  }

  editStickyCall(requestObj: any) {

    this.saveStickyCall(requestObj).then((res: any) => {
      if (res.status) {
        let updatedsticky = {
          'editTime': moment(res.data.editTime, 'YYYY-MM-DD HH:mm:ss').format(this.Global.dateFormat),
          'dispatcherId': res.data.dispatcherId,
          'dispatcherName': res.data.dispatcherName,
          'stickyTitle': res.data.stickyTitle,
          'stickyDescription': res.data.stickyDescription,
          'pinned': res.data.pinned,
          'stickyId': res.data._id,
          'stickyColor': res.data.stickyColor
        };
        if (res.data.pinned == 1) {
          let stickyIndex = this.stickyNoteArray.findIndex(((obj: any) => obj.stickyId == res.data._id));
          this.stickyNoteArray[stickyIndex] = updatedsticky;
          this.Global.sortArrayOfObject(this.stickyNoteArray, 'editTime', 'desc');

        } else {
          let stickyIndex = this.othersStickyNoteArray.findIndex(((obj: any) => obj.stickyId == res.data._id));
          this.othersStickyNoteArray[stickyIndex] = updatedsticky;
          this.Global.sortArrayOfObject(this.othersStickyNoteArray, 'editTime', 'desc');

        }

        this.openStickyObj == undefined ? this.openStickyObj = {} : this.openStickyObj;
        this.openStickyObj.openStickyColor = res.data.stickyColor;
        this.isStickyOpen = false;
      } else {
        this.Popup.toasterMessage({
          'status': 'error',
          'title': 'Oops..',
          'message': 'sticky did not updated!!!'
        });
      }
    });
  }

  stickyZoomId: string = ""
  isBlur: boolean = false;
  isStickyOpen: boolean = false;
  stickyZoom(stickyId: string) {
    this.stickyZoomId = stickyId;
    this.isBlur = true;
    this.isStickyOpen = true;


    // this.toggleClass != this.toggleClass
  }

  stickyClose(stickyId: string) {
    // zoomedStickyNote

    let activeClass = (<HTMLAnchorElement>document.getElementById('sticky' + stickyId));
    activeClass.classList.remove("active");
    this.isStickyOpen = false;
    this.stickyZoomId = ";"

  }

  pinSticky(stickyId: string) {
    let pinnedArrIndex = this.stickyNoteArray.findIndex(((obj: any) => obj.stickyId == stickyId));
    let otherStickyArrIndex = this.othersStickyNoteArray.findIndex(((obj: any) => obj.stickyId == stickyId));


    if (otherStickyArrIndex != -1) {
      //other's arr sticky clicked;
      //remove from other && add it to pinnedArr
      this.othersStickyNoteArray[otherStickyArrIndex].pinned = 1;
      this.stickyNoteArray.unshift(this.othersStickyNoteArray[otherStickyArrIndex]);

      this.othersStickyNoteArray.splice(otherStickyArrIndex, 1);

      this.editStickyCall(this.stickyNoteArray[0]);
      if (this.isOpenEditableSticky == true) {
        this.openStickyObj.openStickyPinned = 1;
      }


    } else if (pinnedArrIndex != -1) {
      this.stickyNoteArray[pinnedArrIndex].pinned = 0;
      this.othersStickyNoteArray.unshift(this.stickyNoteArray[pinnedArrIndex]);
      this.stickyNoteArray.splice(pinnedArrIndex, 1)
      this.editStickyCall(this.othersStickyNoteArray[0]);
      if (this.isOpenEditableSticky == true) {
        this.openStickyObj.openStickyPinned = 0;
      }

    }


  }

  isHidden: boolean = true;
  divHidden() {
    this.isHidden = false
  }

  colorSelect(event: any, stickyId: string) {
    let isStickyPinned: number = Number(event.target.getAttribute('isPinned'));

    if (isStickyPinned == 1) {
      let objIndex = this.stickyNoteArray.findIndex(((obj: any) => obj.stickyId == stickyId));
      this.stickyNoteArray[objIndex].stickyColor = event.target.value;
      this.editStickyCall(this.stickyNoteArray[objIndex])
    } else {
      let objIndex = this.othersStickyNoteArray.findIndex(((obj: any) => obj.stickyId == stickyId));
      this.othersStickyNoteArray[objIndex].stickyColor = event.target.value;
      this.editStickyCall(this.othersStickyNoteArray[objIndex])
    }
  }

  isOpenEditableSticky: boolean = false;
  openStickyObj: any;
  openEditableSticky(sticky: any = '') {
    if (this.isOpenEditableSticky == true) {
      this.isOpenEditableSticky = false;
    }
    else {
      if (sticky != '') {
        this.openStickyObj = {
          openStickyTitle: sticky.stickyTitle,
          openStickyDescription: sticky.stickyDescription,
          openStickyId: sticky.stickyId,
          openStickyColor: sticky.stickyColor,
          openStickyPinned: sticky.pinned,
          openStickyEditTime: sticky.editTime,
        }

        setTimeout(() => {
          this.isOpenEditableSticky = true;
        }, 0);

        setTimeout(() => {
          let contenteditable = <HTMLElement>document.getElementById('desc' + sticky.stickyId);
          if (this.Global.roleJson.sticky['update'] == 1) {
            let selection = document.getSelection();
            let range = document.createRange();
            range.setStart(contenteditable, contenteditable.childNodes.length);
            selection?.removeAllRanges();
            selection?.addRange(range);
            contenteditable.focus()
          } else {
            contenteditable.contentEditable = "false";
          }
        }, 1);
      }
    }
  }
}

import { Component, ElementRef, Input, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CallmethodService } from 'src/app/services/callmethod.service';
import { DataShareService } from 'src/app/services/data-share.service';
import { GlobalService } from 'src/app/services/global.service';
import { NotificationService } from '../../chat-services/notification.service';
import { CloudinaryUploadService } from '../../chat-services/cloudinary-upload.service';

@Component({
  selector: 'app-chat-middle-view',
  templateUrl: './chat-middle-view.component.html',
  styleUrls: ['./chat-middle-view.component.scss']
})
  
export class ChatMiddleViewComponent implements OnChanges {
  @ViewChild('scrollMe') private myScrollContainer!: ElementRef;
  @ViewChild('msgInput') private myInput!: ElementRef;
  private unsubscribe$ = new Subject<void>();
  chatDataSubscription!: Subscription;
  @Input() userAndChatDataArray: any[] = []
  selectedUserChats: any[] = []
  selectedUserId: any
  bookingJsonData: any = {}
  selectedUserName: any
  sendMessageType: number = 0;
  isBookingRequest: boolean = false;
  isCurrentChat: boolean = false;
  scrollBarStopBtn: boolean = false;
  message: any
  badgeNumbers: any[] = [];
  messageInput: any
  selectedFile: File | null = null;
  imgUrl: string | null = null;
  constructor(
    public shareData: DataShareService,
    public CallMethod: CallmethodService,
    public Global: GlobalService,
    private notificationService: NotificationService,
    private cloudinaryUploadService: CloudinaryUploadService
  ) { }
  disabledSendBtn: boolean = false
  ngOnChanges(changes: SimpleChanges) {
    // if(changes['messageInput']) {
    //   console.log('***** Hi *****');
    // }
    // if (this.messageInput &&  this.messageInput != undefined && this.messageInput.length > 0) {
    //   console.log('***** Yes *****',this.messageInput);
    //   this.disabledSendBtn = true
    // }
    // else {
    //   this.disabledSendBtn = false
    // }
  }

  toggleSendBtn(): void {
    if (this.messageInput.trim().length > 0) {
      this.disabledSendBtn = true
    } else {
      this.disabledSendBtn = false;
    }
  }

  focusOnInput() {
    if (this.myInput) {
      this.messageType(0)
      this.myInput.nativeElement.focus()
    }
  }

  isNotSeenBadge: number = 0
  ngOnInit(): void {
    this.chatDataSubscription = this.shareData.getData().subscribe((result) => {
      if (result.function == 'recentChatData' && result.type == 'recentChatsView' && result.data != '') {
        this.isCurrentChat = true;
        if (result.isCurrentChat) {
          this.selectedUserChats = result.data;
          result.data.forEach((element: any) => {
            this.selectedUserId = element.userId
            this.selectedUserName = element.userName
            this.scrollToBottom();
          });
        }
      }

      if (result.function == 'messageSendAndRecive' && result.type == 'userChatView' && result.data != '') {
        if (this.selectedUserChats.length > 0 && result.data.userId == this.selectedUserChats[0].userId) {
          console.log('***** result.data ****vfdbfg*', result.data);
          if (this.scrollBarStopBtn == true) {
            if (result.data) {
              this.isNotSeenBadge = this.isNotSeenBadge + 1
            }
          }
          if (this.scrollBarStopBtn == false) {
            this.isNotSeenBadge = 0
          }
          console.log('***** this.isNotSeenBadge *****', this.isNotSeenBadge);
          this.selectedUserChats.push(result.data);
        }
        else {
          // this.notificationService.playNotificationSound();
          this.userAndChatDataArray.forEach((response: any) => {
            if (response.user.userId == result.data.userId) {
              response.chats.push(result.data)
            }
          });
        }
        result.data.bookingJson == '' ? this.isBookingRequest = false : this.isBookingRequest = true
        if (this.isBookingRequest && this.isBookingRequest != null && this.isBookingRequest != undefined) {
          this.bookingJsonData = result.data.bookingJson
        }
      }
      setTimeout(() => {
        if (this.scrollBarStopBtn == false) {
          this.isNotSeenBadge = 0
          this.scrollToBottom();
          // this.focusOnInput();
        }
      }, 0.001);
    });
  }

  loadAllRecentChats: boolean = false
  scrollToBottom() {
    if (this.myScrollContainer) {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
      this.Global.disableScrollToBottom = true
    }
  }

  // messageType : 0 = Text
  // messageType : 1 = Image
  // messageType : 2 = Location
  // messageType : 3 = Booking
  messageType(messageType: number) {
    this.sendMessageType = messageType
  }

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
    this.uploadFile();
    this.imgUrl = null;
    event.target.value = '';
  }

  // sendBy : 0 = User
  // sendBy : 1 = Dispatcher
  sendMessage(): void {
    switch (this.sendMessageType) {
      case 0:
        if (this.messageInput.trim().length > 0) {
          let reqObj = {
            userId: this.selectedUserId,
            userName: this.selectedUserName,
            dispatcherId: this.Global.dispatcherDetails.dispatcherId,
            dispatcherName: this.Global.dispatcherDetails.dispatcherName,
            message: this.messageInput.trim(),
            messageType: this.sendMessageType,
            sendBy: 1,
            isSend: true
          }
          this.messageInput = "";
          this.disabledSendBtn = false;
          this.shareData.shareData({ function: 'dispatcherSendMessage', type: 'chatMiddleView', data: reqObj.userId })
          // this.selectedUserChats.push(reqObj)
          this.CallMethod.sendMessageToUser(reqObj).pipe(takeUntil(this.unsubscribe$)).subscribe((result: any) => {
            if (!result.status) {
              this.selectedUserChats.unshift({ ...reqObj, isSend: false })
            }
          })
        }
        break;
      case 1:
        if (this.imgUrl !== null && this.imgUrl.trim().length > 0) {
          let reqObj = {
            userId: this.selectedUserId,
            userName: this.selectedUserName,
            dispatcherId: this.Global.dispatcherDetails.dispatcherId,
            dispatcherName: this.Global.dispatcherDetails.dispatcherName,
            message: this.imgUrl.trim(),
            messageType: this.sendMessageType,
            sendBy: 1,
            isSend: true
          }
          this.shareData.shareData({ function: 'dispatcherSendMessage', type: 'chatMiddleView', data: reqObj.userId })
          this.CallMethod.sendMessageToUser(reqObj).pipe(takeUntil(this.unsubscribe$)).subscribe((result: any) => {
            if (!result.status) {
              this.selectedUserChats.unshift({ ...reqObj, isSend: false })
            }
          })
        }
        break;
    }
    
  }

  uploadFile() {
    if (this.selectedFile) {
      this.cloudinaryUploadService.uploadFile(this.selectedFile)
        .subscribe(
          response => {
            console.log('Upload successful:', response);
            this.imgUrl = response.url;
            this.sendMessage();
          },
          error => {
            console.error('Upload error:', error);
          }
        );
    }
  }

  previousScrollPosition: number = 0;
  numberOfScrolls: number = 0;

  chatScrolled(event: Event): void {
    console.log('***** event scroll *****', event);
    const element = event.target as HTMLElement;
    const currentScrollPosition = element.scrollTop;
    const maxScrollPosition = element.scrollHeight - element.clientHeight;
    if (currentScrollPosition === maxScrollPosition) {
      this.numberOfScrolls = 0;
      this.scrollBarStopBtn = false;
      this.isNotSeenBadge = 0
    }
    if (currentScrollPosition < this.previousScrollPosition) {
      this.numberOfScrolls++;
      if (this.numberOfScrolls > 20) {

        this.scrollBarStopBtn = true
      }
    }
    this.previousScrollPosition = currentScrollPosition;
  }
}
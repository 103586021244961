import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalComponent } from 'ng-modal-lib';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CallmethodService } from '../services/callmethod.service';
import { DataShareService } from '../services/data-share.service';
import { GlobalService } from '../services/global.service';

@Component({
  selector: 'app-booking-details',
  templateUrl: './booking-details.component.html',
  styleUrls: ['./booking-details.component.scss']
})
export class BookingDetailsComponent implements OnInit {
  private subscription: Subscription = new Subscription;
  environment: any = environment;
  constructor(public Global: GlobalService,
    public CallMethod: CallmethodService,
    public shareData: DataShareService
  ) { }

  ngOnInit(): void {
    this.subscription = this.shareData.getData().subscribe(data => {
      if (data) {
        if (data.function == 'openBookingDetails' || data.function == 'openBookingDetails-unAssignList' || data.function == 'openBookingDetailsByCloseJob') {
          this.bookingDetailsModalShow(data);
        }
        else if (data.function == 'changeThemeColor') {
          this.bookingDetailsModal.modalHeader.nativeElement.style.backgroundColor = this.Global.first_color;
          this.bookingDetailsModal.modalFooter.nativeElement.style.backgroundColor = this.Global.third_color;
          this.bookingDetailsModal.modalRoot.nativeElement.style.border = '1px solid '+ this.Global.first_color;
        }
      }
    });
  }

  @ViewChild("bookingDetails") public bookingDetailsModal!: ModalComponent;
  @ViewChild("closeJobDetails") public closeJobDetailsModal!: ModalComponent;
  showBookingDetailsPopup: boolean = false;
  bookingDetailObj: any = null;

  testStyle:string = '-100px';

  bookingDetailsModalShow(bookingDetails: any) {
    console.log("===== bookingDetails :: ", bookingDetails);
    
    let response = true;
    this.bookingDetailsModal.modalRoot.nativeElement.style.zIndex = this.bookingDetailsModal.getMaxModalIndex() + 2;
    if (this.showBookingDetailsPopup == false) {
      setTimeout(() => {
        if (bookingDetails.function == 'openBookingDetails') {
        }
        else if (bookingDetails.function == 'openBookingDetailsByCloseJob') {
        }
        
        let left:any = parseInt("" + window.innerWidth) - parseInt(environment.windowPopupStyle.bookingDetails.width.replace('px', '')) - 2;
        this.bookingDetailsModal.modalRoot.nativeElement.style.left = left.toString() + 'px';
        this.bookingDetailsModal.modalRoot.nativeElement.style.top = environment.windowPopupStyle.bookingDetails.top;
        this.bookingDetailsModal.modalBody.nativeElement.style.height = environment.windowPopupStyle.bookingDetails.height;
        this.bookingDetailsModal.modalRoot.nativeElement.style.width = environment.windowPopupStyle.bookingDetails.width;
      }, 0);

      this.bookingDetailsModal.modalHeader.nativeElement.style.backgroundColor = this.Global.first_color;
      this.bookingDetailsModal.modalFooter.nativeElement.style.backgroundColor = this.Global.third_color;
      this.bookingDetailsModal.modalRoot.nativeElement.style.border = '1px solid '+ this.Global.first_color;

      this.bookingDetailsModal.show();
      this.showBookingDetailsPopup = true;
    }

    this.bookingDetailObj = bookingDetails.data;

    if (this.bookingDetailObj.locationJson != undefined || Array.isArray(this.bookingDetailObj.locationJson) == true || this.bookingDetailObj.locationJson.length != 0
      && this.bookingDetailObj.userJson != undefined || Array.isArray(this.bookingDetailObj.userJson) == true || this.bookingDetailObj.userJson.length != 0
    ) {
      response = true;
    } else {
      response = false;
    }
  }

  closeBookingDetailsModel() {
    this.showBookingDetailsPopup = false;
  }

  bookingEventDetails(bookingId: string, dBookId: string) {
    this.shareData.shareData({ 'function': 'openBookingEventDetails', 'data': { 'bookingId': bookingId, 'dBookId': dBookId } });
  }

  copyBooking(bookingData: any) {
    this.shareData.shareData({ 'function': 'bookingDetailPopupCopyBooking', 'data': bookingData });
  }

  editBooking(bookingData: any) {
    bookingData.slidedToComplete = 0;
    this.shareData.shareData({ 'function': 'bookingDetailPopupEditBooking', 'data': bookingData });
  }
}

<div class="w-100 py-2 px-0">
  <div class="flex align-items-center justify-content-start">
    <div class="mx-4">
      <div>
        <div class="lessStringName">
          {{Global.getShortName(dispatcherDetails.dispatcherName)}}
        </div>
      </div>
    </div>
    <div>
      <h2 class="text-light" style="font-weight: 500;">{{ dispatcherDetails.dispatcherName }}</h2>
    </div>
  </div>
</div>
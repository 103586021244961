import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2'

@Injectable({
	providedIn: 'root'
})
export class PopupService {

	constructor(
		public Toaster: ToastrService
	) {

	}

	popupMessage(opts: any) {
		Swal.fire({
			title: opts.message,
			showClass: {
				popup: 'animate__animated animate__fadeInDown'
			},
			hideClass: {
				popup: 'animate__animated animate__fadeOutUp'
			}
		})
	}

	toasterMessage(opts: any) {
		if (opts.status === 'success') {
			this.Toaster.success(opts.message, opts.title)
		}
		else if (opts.status === 'error') {
			this.Toaster.error(opts.message, opts.title)
		}
		else if (opts.status === 'warning') {
			this.Toaster.warning(opts.message, opts.title)
		}
	}
}

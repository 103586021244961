import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DataShareService } from 'src/app/services/data-share.service';
import { GlobalService } from 'src/app/services/global.service';
import { PopupService } from 'src/app/services/popup.service';
import { CallmethodService } from '../services/callmethod.service';

@Component({
  selector: 'app-driver-shift-offline-alerts',
  templateUrl: './driver-shift-offline-alerts.component.html',
  styleUrls: ['./driver-shift-offline-alerts.component.scss']
})
export class DriverShiftOfflineAlertsComponent implements OnInit, OnDestroy {

  private subscription: Subscription = new Subscription();
  isOpenDriverRequestModel: boolean = false;
  driverRequestList: any[] = [];
  isLoading: boolean = false;
  driverOfflineHide: boolean = false;
  listRequestObj = {
    limit: 30,
    offset: 0,
    isRestaurant: true,
  };
  totalRecords: number = 0;
  pageSize: number = 30;
  currentPage: number = 0;


  constructor(
    private dataShareService: DataShareService,
    private callMethod: CallmethodService,
    public Global: GlobalService,
    private Popup: PopupService,
  ) { }


  ngOnInit(): void {
    this.subscription = this.dataShareService.getData().subscribe(result => {
      if (result.function === 'openDriverOfflineRequestModel') {
        this.isOpenDriverRequestModel = result.data;
        this.dataShareService.shareData({ function: 'readCounts', data: 'read' });
        this.getDriverShiftList();
      }
    });
  }

  getDriverShiftList(): void {
    console.log('*****>>> getDriverShiftList <<<******');
    this.driverRequestList = [];
    this.isLoading = true;
    this.callMethod.getDriverShiftRequestList(this.listRequestObj).subscribe({
      next: (result: any) => {
        if (result.status) {
          this.driverRequestList = result.data;
          this.totalRecords = result.totalCount;
        }
        this.isLoading = false;
      },
      error: (error: any) => {
        console.error('Error fetching driver shift request list:', error);
        this.isLoading = false;
      }
    });
  }

  offlineDriver(driver: any): void {
    this.driverOfflineHide = true;

    const reqObj = {
      isDispatcher: 1,
      driverStatus: 0,
      driverId: driver.driverId,
      dispatcherName: this.Global.dispatcherDetails.dispatcherName,
      lat: driver.lat,
      long: driver.long,
      isApprovedByAdmin: true
    };

    this.callMethod.driverSwitchs(reqObj).subscribe({
      next: (result: any) => {
        this.driverOfflineHide = false;
        if (result.status) {
          this.Popup.toasterMessage({
            status: 'success',
            title: 'Switch Off',
            message: 'Driver offline successfully.'
          });
          this.getDriverShiftList();
        } else {
          this.handleSwitchOffError(result);
        }
      },
      error: (error: any) => {
        console.error('Error switching driver offline:', error);
        this.driverOfflineHide = false;
      }
    });
  }

  rejectDriverRequest(driver: any): void {
    this.driverOfflineHide = true;

    const reqObj = {
      isDispatcher: 1,
      driverId: driver.driverId,
      dispatcherName: this.Global.dispatcherDetails.dispatcherName,
    };

    this.callMethod.rejectDriverRequest(reqObj).subscribe({
      next: (result: any) => {
        this.driverOfflineHide = false;
        if (result.status) {
          this.getDriverShiftList();
        } else {
          this.Popup.toasterMessage({
            status: 'warning',
            title: 'Oops!!!',
            message: 'You cannot turn off this driver because this driver has a booking.'
          });
        }
      },
      error: (error: any) => {
        console.error('Error rejecting driver request:', error);
        this.driverOfflineHide = false;
      }
    });
  }

  handleSwitchOffError(result: any): void {
    switch (result.statusCode) {
      case 1:
      case 2:
        break;
      case 3:
        this.Popup.toasterMessage({
          status: 'warning',
          title: 'Oops!!!',
          message: 'You cannot turn off this driver because this driver has a booking.'
        });
        break;
    }
  }
  nextPage(pageEvent: any): void {
    this.currentPage = pageEvent.page;
    this.listRequestObj.limit = this.pageSize;
    this.listRequestObj.offset = (this.pageSize * pageEvent.page);
    this.getDriverShiftList();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
